import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    additionalServicesEnabled: false,
    ecommerceUrlEnabled:false,
    businessName:'',
    packageFeeEnabled:false
}

const configsSlice = createSlice({
    name: 'configs',
    initialState,
    reducers: {
        setAdditionalServices:(state,action) => {
            state.additionalServicesEnabled = action.payload;
        },
        setEcommerceUrl:(state,action) => {
            state.ecommerceUrlEnabled = action.payload;
        },
        setBusinessName:(state,action) => {
            state.businessName = action.payload;
        },
        setPackageFeeEnabled:(state,action) => {
            state.packageFeeEnabled = action.payload;
        },
    }
});


export const {setAdditionalServices,setEcommerceUrl,setBusinessName,setPackageFeeEnabled} = configsSlice.actions;
export default configsSlice.reducer;


