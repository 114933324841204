import { Box, Grid, Typography, InputAdornment, TextField } from '@mui/material'
import logoImage from "../../images/eastwestLogo.svg"

// sx={{ height:{xs:'100%', lg:'391px'}, margin: '50px 0 0', padding: {lg:'32px 203px 42px 380px'}, backgroundColor: '#f5fcfc' }}

// sx={{ width: '1533px',backgroundColor: '#f5fcfc' }}

// sx={{ml:{xs:'3%',lg:'0%'}}}

// sx={{ marginTop: {sm:'50px',lg:'100px'},ml:'3%' }}

// sx={{ marginTop:{lg:'160px',sm:'20px'}, ml:{sm:'0%',lg:'1%'} }}

// sx={{ marginTop:{lg:'100px',sm:'10px'}, ml: {xs:'3%',lg:'10%'} }}>

// style={{ width: '172.4px', height: '114.4px', margin:{lg:'0 159.6px 28.6px 0',sm:''}, objectFit: 'contain' }}

const Footer = () => {
    return (
        <Box component={'div'} width={'100%'}  sx={{background:'#f5fcfc' , bottom:0 , position:'relative' , minHeight:"391px",flexShrink:0}}>
            <Grid container spacing={2}  maxWidth="lg" sx={{marginRight:'auto' , marginLeft:'auto' , height:'100%', alignItems:'center', marginTop:{lg:'30px'}, padding:{lg:'60px 0px' , xs:'10px 10px'}}}>
                <Grid item xs={12} sm={6} md={3} lg={4}>
                    <Box component={'img'} src={logoImage} alt='logo' sx={{width:'100px' , height:'100px' , objectFit:'contain'}}/>
                    <Box sx={{ display: 'flex' , alignItems:'center' , gap:1, width:"100%"}} >
                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, color: '#032541' }}>Address:</Typography>
                        <Typography sx={{fontFamily: 'Poppins', fontSize: '14px', color: '#707070' }}>Moi Avenue Sonalux Building
                            Basement Floor, Nairobi - Kenya</Typography>
                    </Box>
                    <Box sx={{ display: 'flex' , width:"100%" , alignItems:'center' , gap:1}}>
                        <Typography sx={{  fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, lineHeight: 2.57, textAlign: 'left', color: '#032541' }}>Contacts:</Typography>
                        <Typography sx={{  fontFamily: 'Poppins', fontSize: '14px', textAlign: 'left', color: '#707070' }}>+254701028611</Typography>
                    </Box>
                    <Box sx={{ display: 'flex' , alignItems:'center' , gap:1 }} >
                        <Typography sx={{  fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, lineHeight: 2.57, textAlign: 'left', color: '#032541', textWrap: 'nowrap' }}>Email Address:</Typography>
                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', textAlign: 'left', color: '#707070' }}>orders@eastwestfashions.com</Typography>
                    </Box>
                    <Box sx={{ display: 'flex' , alignItems:'center' , gap:1 }} >
                        <Typography sx={{  fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, lineHeight: 2.57, textAlign: 'left', color: '#032541', textWrap: 'nowrap' }}>Working hours:</Typography>
                        <Typography sx={{  fontFamily: 'Poppins', fontSize: '14px', textAlign: 'left', color: '#707070', textWrap: 'nowrap' }}>09:00 - 18:00, Mon - Sat</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Typography sx={{fontFamily: 'Poppins', fontSize: '16px', fontWeight: 600, lineHeight: 2.25, textAlign: 'left', color: "#032541", textWrap: 'nowrap' }}>Useful Links</Typography>
                    <Typography sx={{ margin: '0 98px 14px 0', fontFamily: 'Poppins', fontSize: '14px', lineHeight: 1.29, textAlign: 'left', color: '#000' }}>
                        Contact Us
                    </Typography>
                    <Typography sx={{ margin: '0 98px 14px 0', fontFamily: 'Poppins', fontSize: '14px', lineHeight: 1.29, textAlign: 'left', color: '#000' }}>
                        About Us
                    </Typography>
                    <Typography sx={{ textWrap: 'nowrap', margin: '0 98px 14px 0', fontFamily: 'Poppins', fontSize: '14px', lineHeight: 1.29, textAlign: 'left', color: '#000' }}>
                        Terms & Conditions
                    </Typography>
                    <Typography sx={{ margin: '0 98px 14px 0', fontFamily: 'Poppins', fontSize: '14px', lineHeight: 1.29, textAlign: 'left', color: '#000' }}>
                        FAQS
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Box component={'div'} gap={2} display={'flex'} justifyItems={'flex-start'} justifyContent={'flex-start'} flexDirection={'column'}>
                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', lineHeight: 1.29,  color: '#000',}}>
                            Returns & Exchanges
                        </Typography>
                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', lineHeight: 1.29, color: '#000',  }}>
                            Shipping & Delivery
                        </Typography>
                        <Typography sx={{  fontFamily: 'Poppins', fontSize: '14px', lineHeight: 1.29, color: '#000', }}>
                            Privacy Policy
                        </Typography>
                        <Typography sx={{fontFamily: 'Poppins', fontSize: '14px', lineHeight: 1.29, color: '#000', }}>
                            Payment Terms
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Typography sx={{ margin: '0 91px 14px 0', fontFamily: 'Poppins', fontSize: '16px', lineHeight: 2.25, textAlign: 'left', color: '#032541', fontWeight: 600,textWrap:'nowrap' }}>
                        Join Our Newsletter Now
                    </Typography>
                    <Typography sx={{ margin: ' 0px 1px 13px 0', fontFamily: 'Poppins', fontSize: '14px', lineHeight: 1.29, textAlign: 'left', color: '#707070' }}>
                        Get E-mail updates about our latest shop
                        and special offers.
                    </Typography>
                    <Box sx={{width:'100%',maxWidth:'287px'}}>
                        <TextField name={"Email"} value={""} onChange={() => { }} placeholder="Enter your Email" variant="outlined" sx={{textAlign:'center'}}
                            InputProps={{
                                endAdornment: (<InputAdornment position="end">
                                    <button style={{ border: 'none', backgroundColor: '#02b3ad', height: '55px', margin: "0 -14px 0 0px", width: "100px", borderRadius: '4px', padding: ' 14px 10px 15px 16px' }}>
                                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', textAlign: 'left', color: '#fff' }}>
                                            Subscribe
                                        </Typography>
                                    </button>
                                </InputAdornment>), width: '287px', height: '48px',textAlign:'center', margin: '0 1px 0 0', padding: '14.5px 156px 13.5px 20px', borderRadius: '4px', border: 'solid 1px #89afad'
                            }} />
                    </Box>
                </Grid>
            </Grid>
        </Box>

    );
}

export default Footer;