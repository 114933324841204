
export default function DateFormatter(value) {
	try {

		const dateString = value;
		const date = new Date(dateString);

		const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		const month = months[date.getMonth()];
		const day = date.getDate();
		const year = date.getFullYear();

		const hours = date.getHours();
		const minutes = date.getMinutes();
		const seconds = date.getSeconds();

		const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

		return `${month} ${day}, ${year} ${time}`;

	} catch (e) {
		console.log(`Error Formatting Currency`, e.message);
	}
}