import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material"
import { HttpRequest } from "../../HttpRequest";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import DateFormatter, { currencyconverter } from "../../../utils/dateFormatter";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

const MyCustomerOrders = () => {
    let customerId = secureLocalStorage.getItem('customerId');
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, });
    const [activeTab, setActiveTab] = useState('');

    const navigate = useNavigate();

    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(min-width:601px) and (max-width:960px)');
    const isLargeScreen = useMediaQuery('(min-width:961px)');

    const handleTableChange = (tabValue) => {
        setActiveTab(tabValue);
    }
    const getCustomerOrders = () => {
        setPageState({ ...pageState, isLoading: true });
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/getzedecommercecustomerorder?customerId=${customerId}&page=${pageState.page}&limit=${pageState.pageSize}&orderStatus=${activeTab}`
        }).then((data) => {
            if (data?.status === 200) {
                const ascOrders = data?.response.data?.sort((a, b) => parseInt(b.orderNumber) - parseInt(a.orderNumber));
                setPageState({ ...pageState, isLoading: false, data: ascOrders, total: data?.response?.count, });
            } else {
                setPageState({ ...pageState, isLoading: false })
            }
        }).catch((error) => {
            console.log('Error fetching customer orders');
        });
    }
    useEffect(() => {
        getCustomerOrders();
    }, [customerId, dataGridPageSize, pageState.page,activeTab])

    const columns = [
        {
            field: "orderNumber", headerName: "Order N0.", flex: 0.5,
            renderHeader: (params) => { return (<Box sx={{ color: "#032541", fontSize: "14px", fontWeight: "500", textAlign: 'left', }}>Order No.</Box>) },
            renderCell: (params) => {
                return <Typography onClick={()=>navigate(`/customer/order/${params?.row?._id}`)} sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', textAlign: 'left', color: '#707070', alignItems: 'center', verticalAlign: 'middle', textDecoration: 'underline', lineHeight: 'normal', mt: '10px',cursor:'pointer' }}>{params?.row?.orderNumber}</Typography>
            }
        },
        {
            field: 'amount', headerName: 'Amount', flex: 0.5,
            renderHeader: (params) => { return (<Box sx={{ color: "#032541", fontSize: "14px", fontWeight: "500", textAlign: 'left', }}>Amount</Box>) },
            renderCell: (params) => {
                return <Typography onClick={()=>navigate(`/customer/order/${params?.row?._id}`)} sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', textAlign: 'left', color: '#707070', alignItems: 'center', verticalAlign: 'middle', lineHeight: 'normal', mt: '10px' }}>{currencyconverter(params?.row?.amount)}</Typography>
            }
        },
        !isSmallScreen && {
            field: 'createdAt', headerName: 'Created At', flex: 1,
            renderHeader: (params) => { return (<Box sx={{ color: "#032541", fontSize: "14px", fontWeight: "500", textAlign: 'left', }}>Created At</Box>) },
            renderCell: (params) => {
                return <Typography onClick={()=>navigate(`/customer/order/${params?.row?._id}`)} sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', textAlign: 'left', color: '#707070', alignItems: 'center', verticalAlign: 'middle', lineHeight: 'normal', mt: '10px' }}>{DateFormatter(params?.row?.createdAt)}</Typography>
            }
        },
        {
            field: "orderStatus", headerName: "Order Status", flex: 1,
            renderHeader: (params) => { return (<Box sx={{ color: "#032541", fontSize: "14px", fontWeight: "500", textAlign: 'left', }}>Order Status</Box>) },
            renderCell: (params) => {
                return <Typography onClick={()=>navigate(`/customer/order/${params?.row?._id}`)} sx={{ color: getOrderStatusColor(params), fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', textAlign: 'left', alignItems: 'center', verticalAlign: 'middle', lineHeight: 'normal', mt: '10px' }}>{params?.row?.status === 'paid' ? params.row?.orderStatus : 'Payment Failed'}</Typography>
            }
        }
    ].filter(Boolean);

    function getOrderStatusColor(params) {
        const statusColors = {
            'Received': '#707070',
            'Delivered': '#17ae7b',
            'Shipped': '#000000',
            'Processing': '#f79009',
            'Pending':'#dc3545',
        };
        return statusColors[params.row?.orderStatus] || '#dc3545';
    }
    return (

        <Box sx={{ width: '100%' }}>
            <Typography sx={{ margin: '0 0 16px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 600, textAlign: 'left', color: '#000' }}>My Orders</Typography>
            <Box sx={{ display: 'flex' }}>
                <Button onClick={() => handleTableChange('')} variant="contained" sx={{ width: '100%', maxWidth: '129px', height: '100%', maxHeight: '40px', margin: '0 10px 0 0', padding: '10px 30px 10px 31px', borderRadius: '5px', backgroundColor: activeTab === '' ? '#e7fcfc' : '#f3f5f8', border: 'none', boxShadow: 'none', '&:hover': { backgroundColor: activeTab === '' ? '#e7fcfc' : '#f3f5f8', boxShadow: 'none' } }}>
                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: activeTab === '' ? 600 : 'normal', textAlign: 'center', color: activeTab === '' ? '#02b3ad' : '#707070', textTransform: 'capitalize', textWrap: 'nowrap' }}> All </Typography>
                </Button>
                <Button onClick={() => handleTableChange('Received')} variant="contained" sx={{ width: '100%', maxWidth: '129px', height: '100%', maxHeight: '40px', margin: '0 10px 0 0', padding: '10px 30px 10px 31px', borderRadius: '5px', backgroundColor: activeTab === 'Received' ? '#e7fcfc' : '#f3f5f8', border: 'none', boxShadow: 'none', '&:hover': { backgroundColor: activeTab === 'Received' ? '#e7fcfc' : '#f3f5f8', boxShadow: 'none' } }}>
                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: activeTab === 'Received' ? 600 : 'normal', textAlign: 'center', color: activeTab === 'Received' ? '#02b3ad' : '#707070', textTransform: 'capitalize', textWrap: 'nowrap' }}>Received</Typography>
                </Button>
                <Button onClick={() => handleTableChange('Processing')} variant="contained" sx={{ width: '100%', maxWidth: '129px', height: '100%', maxHeight: '40px', margin: '0 10px 0 0', padding: '10px 30px 10px 31px', borderRadius: '5px', backgroundColor: activeTab === 'Processing' ? '#e7fcfc' : '#f3f5f8', border: 'none', boxShadow: 'none', '&:hover': { backgroundColor: activeTab === 'Processing' ? '#e7fcfc' : '#f3f5f8', boxShadow: 'none' } }}>
                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: activeTab === 'Processing' ? 600 : 'normal', textAlign: 'center', color: activeTab === 'Processing' ? '#02b3ad' : '#707070', textTransform: 'capitalize', textWrap: 'nowrap' }}>Processing</Typography>
                </Button>
                <Button onClick={() => handleTableChange('Delivered')} variant="contained" sx={{ width: '100%', maxWidth: '150px', height: '100%', maxHeight: '40px', margin: '0 10px 0 0', padding: '10px 30px 10px 31px', borderRadius: '5px', backgroundColor: activeTab === 'Delivered' ? '#e7fcfc' : '#f3f5f8', border: 'none', boxShadow: 'none', '&:hover': { backgroundColor: activeTab === 'Delivered' ? '#e7fcfc' : '#f3f5f8', boxShadow: 'none' } }}>
                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: activeTab === 'Delivered' ? 600 : 'normal', textAlign: 'center', color: activeTab === 'Delivered' ? '#02b3ad' : '#707070', textTransform: 'capitalize', textWrap: 'nowrap' }}> Delivered </Typography>
                </Button>
            </Box>
            <DataGrid sx={{ maxHeight: '500px', height: '100%', width: '100%', maxWidth: '676px', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0,overflowX:'auto' }}
                rowCount={pageState.total}
                pagination
                loading={pageState.isLoading}
                page={pageState.page - 1}
                pageSizeOptions={[1, 10, 20, 50, 100]}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: dataGridPageSize,
                            page: pageState.page - 1,
                        },
                    },
                }}
                pageSize={dataGridPageSize}
                onPaginationModelChange={(data) => {
                    const { pageSize, page } = data;
                    setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                    setPageSize(pageSize)
                }}
                paginationMode="server"
                columns={columns}
                rows={pageState.data}
                getRowId={(row) => row._id}
            />
        </Box>
    )
}

export { MyCustomerOrders }