import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cart: [],
        package:[]
    },
    reducers: {
        addToCart: (state, action) => {
            const { id, variationId, quantity = 1 } = action.payload;
            const existingItem = state.cart.find(
                (item) => item.id === id && item.variationId === variationId
            );
            if (existingItem) {
                existingItem.quantity += quantity;
            } else {
                console.log('found')
                state.cart.push({ ...action.payload, quantity });
            }
        },
        incrementQuantity: (state, action) => {
            console.log('action payload increment',action.payload);
            const item = state.cart.find((item) => item.id === action.payload);
            item.quantity++;
        },
        decrementQuantity: (state, action) => {
            console.log('action payload decrement',action.payload);
            const item = state.cart.find((item) => item.id === action.payload);
            if (item.quantity === 1) {
                item.quantity = 1
            } else {
                item.quantity--;
            }
        },
        removeItem: (state, action) => {
            const { itemId, variationId } = action.payload;
            const removeItem = state.cart.filter((item) => item.id !== itemId || item.variationId !== variationId);
            state.cart = removeItem;
        },        
        clearCart: (state) => {
            state.cart = [];
        },
        setPackage: (state, action) => {
            console.log('payload package',action)
           state.package=action.payload;
        },
    },
});

export const cartReducer = cartSlice.reducer;
export const {
    addToCart,
    incrementQuantity,
    decrementQuantity,
    removeItem,
    clearCart,
    setPackage
} = cartSlice.actions;