import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import BannerOne from "../../images/banner1.png";
// import BannerTwo from "../../images/banner2.png";
// import BannerThree from "../../images/banner3.png";
// import BannerFour from "../../images/banner4.png";
// import BannerFive from "../../images/banner5.png";
// import BannerSix from "../../images/banner6.png";
import BannerOne from "../../images/banners/Eastwest-Banners-1.png";
import BannerTwo from "../../images/banners/Eastwest-Banners-2.png";
import BannerThree from "../../images/banners/Eastwest-Banners-3.png";
import BannerFour from "../../images/banners/Eastwest-Banners-4.png";
import BannerFive from "../../images/banners/Eastwest-Banners-5.png";
import BannerSix from "../../images/banners/Eastwest-Banners-6.png";
import BannerSeven from "../../images/banners/Eastwest-Banners-7.png";
import BannerEight from "../../images/banners/Eastwest-Banners-8.png";
import BannerNine from "../../images/banners/Eastwest-Banners-9.png";
import BannerTen from "../../images/banners/Eastwest-Banners-10.png";
import BannerEleven from "../../images/banners/Eastwest-Banners-11.png";

import "./index.css";
import { useNavigate } from 'react-router-dom';

const BannerPage = () => {
    const [slidesToShow, setSlidesToShow] = useState(3);
    const navigate = useNavigate();
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 600) {
                setSlidesToShow(1);
            } else if (window.innerWidth <= 960) {
                setSlidesToShow(2);
            } else {
                setSlidesToShow(3);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize(); 
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToShow,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    const sliderStyle = {
        // margin: '-10px -40px 0 -40px',
         margin: '15px 0px 0 0px',
    };

    return (
        <Box style={{ marginTop: "40px",marginBottom:'5%',border:'none'}}>
            <Typography variant="h1" component="h2" sx={{ color: "#343434", fontSize: "16px", fontWeight: "bold" }}> Great Offers!!</Typography>
            <Box className="banner" style={{border:'none'}}>
                <Box className="banner__content" >
                    <Slider {...settings} style={sliderStyle}>
                        <div style={{border:'none',cursor:'pointer'}} onClick={()=>navigate('/products?q=Harambee Stars')}>
                            <img style={{border:'none',cursor:'pointer',maxWidth:'380px',height:'100%'}} src={BannerOne} alt="Image 1" />
                        </div>
                        <div style={{border:'none',cursor:'pointer'}}>
                            <img style={{border:'none',cursor:'pointer',maxWidth:'380px',height:'100%'}} src={BannerTwo} alt="Image 2" onClick={()=>navigate('/products?q=Harambee Stars')} />
                        </div>
                        <div style={{border:'none',cursor:'pointer'}}>
                            <img style={{border:'none',cursor:'pointer',maxWidth:'380px',height:'100%'}} src={BannerThree} alt="Image 3" onClick={()=>navigate('/products?q=Harambee Stars')}/>
                        </div>
                        <div style={{border:'none',cursor:'pointer'}}>
                            <img style={{border:'none',cursor:'pointer',maxWidth:'380px',height:'100%'}} src={BannerFour} alt="Image 4" onClick={()=>navigate('/products?q=Harambee Stars')}/>
                        </div>
                        <div style={{border:'none',cursor:'pointer'}}>
                            <img style={{border:'none',cursor:'pointer',maxWidth:'380px',height:'100%'}} src={BannerFive} alt="Image 5" onClick={()=>navigate('/products?q=Harambee Stars')}/>
                        </div>
                        <div style={{border:'none',cursor:'pointer'}}>
                            <img style={{border:'none',cursor:'pointer',maxWidth:'380px',height:'100%'}} src={BannerSix} alt="Image 6" onClick={()=>navigate('/products?q=Harambee Stars')}/>
                        </div>
                        <div style={{border:'none',cursor:'pointer'}}>
                            <img style={{border:'none',cursor:'pointer',maxWidth:'380px',height:'100%'}} src={BannerSeven} alt="Image 2" onClick={()=>navigate('/products?q=Harambee Stars')} />
                        </div>
                        <div style={{border:'none',cursor:'pointer'}}>
                            <img style={{border:'none',cursor:'pointer',maxWidth:'380px',height:'100%'}} src={BannerEight} alt="Image 3" onClick={()=>navigate('/products?q=Harambee Stars')}/>
                        </div>
                        <div style={{border:'none',cursor:'pointer'}}>
                            <img style={{border:'none',cursor:'pointer',maxWidth:'380px',height:'100%'}} src={BannerNine} alt="Image 4" onClick={()=>navigate('/products?q=Harambee Stars')}/>
                        </div>
                        <div style={{border:'none',cursor:'pointer'}}>
                            <img style={{border:'none',cursor:'pointer',maxWidth:'380px',height:'100%'}} src={BannerTen} alt="Image 5" onClick={()=>navigate('/products?q=Harambee Stars')}/>
                        </div>
                        <div style={{border:'none',cursor:'pointer'}}>
                            <img style={{border:'none',cursor:'pointer',maxWidth:'380px',height:'100%'}} src={BannerEleven} alt="Image 6" onClick={()=>navigate('/products?q=Harambee Stars')}/>
                        </div>
                    </Slider>
                </Box>
            </Box>
        </Box>
    );
}

export default BannerPage;
