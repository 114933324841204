import { Box, Button, Grid, Typography, MenuItem, TextField, Divider, Modal, Checkbox, FormControl, LinearProgress, FormHelperText, List, ListItemButton, ListItemIcon, ListItemText, Select, InputLabel, Autocomplete } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HttpRequest } from "../HttpRequest";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Add, AddCircle, ArrowBackIos, Circle, EditOutlined, HelpOutline, Info, InfoOutlined } from "@mui/icons-material";
import CustomInputLabel from "../common/CustomInputLabel";
import { SuccessAlert } from "../common/snackbar/success";
import { ErrorAlert } from "../common/snackbar/error";
import CustomTable from "../common/CustomTable";
import CustomSearchInput from "../common/CustomSearchInput";
import CustomSelect from "../common/customSelectInput";
import DeleteIcon from '@mui/icons-material/Delete';
import warningIcon from '../../images/warning-remove-icn.svg';
import imageUploadPlaceholder from '../../images/img-holder-icn.svg';
import generateImageUrl from '../common/generateImageUrl';
import debounce from "lodash.debounce";
import secureLocalStorage from "react-secure-storage";
const businessNumber = secureLocalStorage.getItem("businessId");

const itemsModal = {
    borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: "130px", p: '61px 22px 165px 63px', border: '1px #707070', m: '55px 104px 6.5px 118px'
}
const deleteServiceModal = {
    borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: "130px", p: '61px 22px 165px 63px', border: '1px #707070', m: '55px 104px 6.5px 118px'
}

const PackagingFee = () => {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const navigate = useNavigate();
    const [showAddPackage, setShowAddPackage] = useState(false);
    const [showEditServiceModal, setShowEditServiceModal] = useState(false);
    const [serviceId, setServiceId] = useState("");
    const [services, setServices] = useState([]);
    const [serviceBoxes, setServiceBoxes] = useState([{ serviceName: '', cost: '' }]);
    const [serviceData, setServiceData] = useState({
        serviceName: '',
        selectionType: '',
        items: []
    });
    const [openItemsModal, setOpenItemsModal] = useState(false)
    const [openDeleteService, setOpenDeleteService] = useState(false)
    const [items, setItems] = useState([]);
    const [serviceToDelete, setServiceToDelete] = useState({});
    const [search, setSearch] = useState('');
    const [iconError, setIconError] = useState(false);
    const [icon, setIcon] = useState("");
    const [uploading, setUploading] = useState(false);
    const [imagePreview, setImagePreview] = useState(imageUploadPlaceholder);
    const [nameError, setNameError] = useState(false);
    const [noOfItemsError, setNoOfItemsError] = useState(false);
    const [name, setName] = useState("");
    const [noOfItems, setNoOfItems] = useState("");
    const [amount, setAmount] = useState("");
    const [amountError, setAmountError] = useState(false);
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize });
    const [productOptions, setProductOptions] = useState([]);
    const [products, setProducts] = useState([]);
    const [productId, setProductId] = useState('');
    const [variationId, setVariationId] = useState('');

    const handleOpenItemsModal = () => { setOpenItemsModal(true) }
    const handleCloseItemsModal = () => { setOpenItemsModal(false) }
    const handleOpenDeleteService = () => { setOpenDeleteService(true) }
    const handleCloseDeleteService = () => { setOpenDeleteService(false) }
    const handleIconUpload = async (event) => {
        setUploading(true);
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const file = URL.createObjectURL(selectedFile);
            setImagePreview(file);

            try {
                const generatedUrl = await generateImageUrl(selectedFile);
                console.log('Generated url', generatedUrl);
                setIcon(generatedUrl);
                console.log('Icon image set', generatedUrl);
            } catch (error) {
                console.error('Error generating image URL:', error);
            } finally {
                setUploading(false);
            }
        } else {
            setUploading(false);
        }
    };


    const handleRowClick = (event, params) => {
        event.stopPropagation();
        console.log('params', params.row);
        navigate(`/zed_ecommerce/additionalServices/${params.row._id}/edit`);
    }
    const handleSubmit = () => {
        let isValid = true;
        setNameError(false);
        setNoOfItemsError(false);
        setIconError(false);
        setAmountError(false);

        if (name === "") {
            setNameError(true);
            isValid = false;
        }
        if (noOfItems === "") {
            setNoOfItemsError(true)
            isValid = false;
        }
        if (icon === "") {
            setIconError(true);
            isValid = false;
        }
        if (amount === "") {
            setAmountError(true);
            isValid = false;
        }

        if (isValid) {
            HttpRequest({
                method: "POST",
                url: `/api/v1/ecommerce/create_category`,
                body: {
                    name: name,
                    noOfItems: noOfItems,
                    amount: amount,
                    image: icon,
                    productId:productId,
                    variationId:variationId
                },
            }).then((data) => {
                if (data.status === 200) {
                    setSuccessShow({ state: true, message: "Package fee created successfully!" })
                    setShowAddPackage(false);
                } else {
                    setErrorShow({ state: true, message: 'Failed to create package fee!' });
                }
            }).catch((error) => {
                console.log('Create package response error', error);
                setErrorShow({ state: true, message: 'Failed to create package fee!' })
            });
        }
    }
    const columns = [
        {
            field: 'name', headerName: 'Name', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Name </Box>) },
            renderCell: (params) => {
                return (<Box onClick={(event) => handleRowClick(event, params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography sx={{ fontWeight: '600', textTransform: 'capitalize' }}>{params.row.serviceName}</Typography>
                        </Grid>
                    </Grid>
                </Box>)
            }
        },
        {
            field: 'amount', headerName: 'Amount', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}> Amount   </Box>) },
            renderCell: (params) => {
                return (<Box onClick={(event) => handleRowClick(event, params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
                    {params.row.selectionType}
                </Box>)
            }
        },
        {
            field: 'max_items', headerName: 'Max No. Of Items', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}> Max No. Of Items  </Box>) },
            renderCell: (params) => {
                const onClickShowItems = (event) => {
                    event.stopPropagation();
                    handleOpenItemsModal();
                }
                console.log('Service items', params?.row?.items);
                setItems(params?.row?.items);
                const itemNames = params?.row?.items.map(item => item.itemName).join(', ');

                return (
                    <Box onClick={(event) => handleRowClick(event, params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500", display: 'flex', border: 'none' }}>
                        <Typography sx={{ marginRight: '10px' }}>{itemNames}</Typography>
                        <Info />
                    </Box>
                );
            }
        },
        {
            field: 'Image', headerName: 'Image', flex: 2,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}> Image  </Box>) },
            renderCell: (params) => {
                const onClickShowItems = (event) => {
                    event.stopPropagation();
                    handleOpenItemsModal();
                }
                console.log('Service items', params?.row?.items);
                setItems(params?.row?.items);
                const itemNames = params?.row?.items.map(item => item.itemName).join(', ');

                return (
                    <Box onClick={(event) => handleRowClick(event, params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500", display: 'flex', border: 'none' }}>
                        <Typography sx={{ marginRight: '10px' }}>{itemNames}</Typography>
                        <Info />
                    </Box>
                );
            }
        },
        {
            field: 'action', headerName: 'Action', flex: 0.5,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}> Action  </Box>) },
            renderCell: (params) => {
                const onClickEdit = (event) => {
                    event.stopPropagation();
                    console.log('params', params.row);
                    navigate(`/zed_ecommerce/additionalServices/${params.row._id}/edit`);
                }
                const onClickDelete = (event) => {
                    event.stopPropagation();
                    setServiceToDelete(params.row);
                    handleOpenDeleteService();
                }
                return (
                    <Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
                        <EditOutlined onClick={onClickEdit} sx={{ color: '#032541', marginRight: '30px' }} />
                        <DeleteIcon onClick={onClickDelete} sx={{ color: '#DC3545' }} />
                    </Box>
                )
            }
        },
    ]

    const fetchPackageFees = () => {
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/get_additional_services?searchValue=${search}`
        }).then((data) => {
            console.log(data.response?.data, ' data.response.data')
            setServices(data.response?.data)
        }).catch((error) => {
            console.log('Error fetching items');
        });
    }

    useEffect(() => {
        fetchPackageFees()
    }, [search])

    const getProducts = (searchTerm) => {
        setPageState((old) => ({ ...old, isLoading: true }));
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/get_products?businessNumber=${businessNumber}&page=${pageState.page}&limit=${dataGridPageSize}&search=${searchTerm??''.trim()}`
        }).then((data) => {
            if (data.status === 200) {
                const options = data?.response?.data?.map(product => ({
                    label: product?.productName,
                    _id: product?._id,
                    variations: product?.variations,
                    productName:product?.productName
                }));
                setProductOptions(options);
            }
        }).catch((error) => {
            console.log('Error fetching products', error);
            setPageState((old) => ({ ...old, isLoading: false }));
        }).finally(() => {
            setPageState((old) => ({ ...old, isLoading: false }));
        });
    };
     const debouncedGetProducts = useCallback(debounce((searchTerm) => {
        getProducts(searchTerm);
    }, 300), [pageState.page]);

    useEffect(() => {
        getProducts(); 
    }, []); 

    useEffect(() => {
        if (search.trim()) {
            debouncedGetProducts(search);
        } else {
            getProducts(); 
        }
    }, [search, debouncedGetProducts]);

    const handleInputChange = (event, value) => {
        setSearch(value || ''); 
    };
    
    const handleAddBox = () => {
        setServiceBoxes([...serviceBoxes, { serviceName: '', cost: '', customizable: false }]);
    };
    const handleDeleteBox = (indexToRemove) => {
        setServiceBoxes(serviceBoxes.filter((_, index) => index !== indexToRemove));
    };

    const handleFormData = (e) => {
        setServiceData({ ...serviceData, [e.target.name]: e.target.value });
    }
    const handleSelectionChange = (event, value) => {
        if (value) {
            setProductId(value._id);
            setName(value?.productName)
            if (value.variations && value.variations.length > 0) {
                setVariationId(value.variations[0]._id);
            } else {
                setVariationId('');
            }
        } else {
            setProductId('');
            setVariationId('');
        }
    };


    return (
        <div style={{ marginTop: '4%' }}>

            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                <Box sx={{ flexDirection: "column", ml: '10px' }}>
                    <Box variant="h4" sx={{ display: 'flex', }}>
                        {showAddPackage ? (
                            <>
                                <ArrowBackIos onClick={() => { setShowAddPackage(false); setShowEditServiceModal(false) }} sx={{ width: '35px', height: '35px', margin: '0px 1px 4px 0', objectFit: 'contain' }} />
                            </>
                        ) : ''}
                        <Typography sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600", fontFamily: 'Poppins' }}> {showAddPackage ? 'Add' : ''} Packaging Fee</Typography>
                    </Box>
                    <Box>
                        <Typography variant="h4" sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal", fontFamily: 'Poppins' }}   >
                            {showAddPackage ? 'Add a Packaging fee.' : 'View and manage your Packaging.'}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    {showAddPackage ? (
                        <Button variant="contained" sx={{ opacity: '0.9', margin: '35px 20px 34px 295px', backgroundColor: "#b4bcc4", color: "#707070", textTransform: "none", borderRadius: "4px", padding: "12.5px 37px 12.5px 38px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#b4bcc4", color: "#707070" } }} onClick={() => { setShowAddPackage(false); setShowEditServiceModal(false) }} >
                            <Typography sx={{ color: '#707070', fontWeight: '550' }}>Cancel</Typography>
                        </Button>
                    ) : (
                        <Button variant="contained" sx={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#032541", color: "#fff" } }}
                            onClick={() => setShowAddPackage(true)} >
                            <AddCircleOutlineIcon />   Add Package
                        </Button>
                    )}
                </Box>
            </Box>
            {showAddPackage ? null :
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ marginLeft: "10px" }}>
                        <CustomSearchInput placeholder={"Search"}  name="search" value={search} onChange={(event) => setSearch(event.target.value)} />
                    </Box>
                </Box>
            }

            {showAddPackage ? <>
                <Box style={{ width: "800px", marginTop: "20px" }}>
                    <Box sx={{ justifyContent: 'space-between', alignItems: 'left' }}>
                        <Box>
                            <Autocomplete disablePortal id="combo-box-demo" options={productOptions} onChange={handleSelectionChange}   onInputChange={handleInputChange} sx={{ width: 300 }} renderInput={(params) => <TextField {...params} label="Select Product" />} />
                            {/* <TextField error={nameError} helperText={nameError ? 'Please enter package name.' : ''} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: '14px', color: '#032541', textAlign: 'left', fontWeight: 'normal' } }} label="Service Name" value={name} name="serviceName" required onChange={(e) => setName(e.target.value)} style={{ width: "100%", maxWidth: "405px", margin: "0px 0 0", borderRadius: '4px', backgroundColor: "#fff", border: 'solid 1px #e0e0e0' }} placeholder="Enter Package Name" /> */}
                        </Box>
                        <Box>
                            <TextField error={noOfItemsError} helperText={noOfItemsError ? 'Please enter no. of items.' : ''} type="number" InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: '14px', color: '#032541', textAlign: 'left', fontWeight: 'normal' } }} label="No. Of Items" value={noOfItems} name="noOfItems" required onChange={(e) => setNoOfItems(e.target.value)} style={{ width: "100%", maxWidth: "405px", margin: "12px 0 0" }} placeholder="Enter maximum number of items" />
                        </Box>
                        <Box>
                            <TextField type="number" error={amountError} helperText={amountError ? 'Please enter amount.' : ''} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: '14px', color: '#032541', textAlign: 'left', fontWeight: 'normal' } }} label="Amount" value={amount} name="amount" required onChange={(e) => setAmount(e.target.value)} style={{ width: "100%", maxWidth: "405px", margin: "12px 0 0" }} placeholder="Enter amount" />
                        </Box>
                        <FormControl>
                            <Typography sx={{ margin: '15px 165px 7px 0', fontFamily: 'Poppins', fontWeight: 'normal', fontSize: '10px', color: '#707070' }}>Upload Image</Typography>
                            <label htmlFor="upload-photo" style={{ border: iconError ? 'solid 1px red' : '', width: '400px' }}>
                                <input style={{ display: 'none' }} id="upload-photo" name="upload-photo" type="file" onChange={handleIconUpload} accept=".png, .jpeg, .jpg" />
                                <Box sx={{ display: 'flex', width: '100%', maxWidth: '405px', height: '100%', maxHeight: '10.8px 575px 18.2px 0', margin: "10.8px 575px 18.2px 0" }}>
                                    <Box sx={{ width: "170px", height: "85px", borderRadius: "6px", border: "solid 1px #e0e0e0", backgroundColor: "#fff", alignItems: 'center', margin: '0 0 9.8px', padding: '3px 3px 4px', justifyContent: 'center' }}>
                                        {!icon ?
                                            <Box sx={{ width: "164px", height: "83px", borderRadius: "5px", backgroundColor: "#f5f5f5", margin: "1px 1px 2px 1px", textAlign: "center", alignContent: "center", }}>
                                                <img src={imagePreview} alt="" style={{ width: "100%", height: "100%", maxWidth: '32.5px', maxHeight: '32.5px', objectFit: "contain", alignItems: "center", textAlign: "center" }} />
                                            </Box>
                                            :
                                            <img src={imagePreview} alt="" style={{ width: "100%", height: "100%", objectFit: "contain", alignItems: "center", textAlign: "center" }} />
                                        }
                                        <AddCircle sx={{ width: '29.5px', height: '29.5px', margin: '-15.2px 5.5px 0 70px', objectFit: 'contain' }} />
                                    </Box>
                                    <Box sx={{ width: '100%', height: '100%', maxWidth: '200px', maxHeight: '82px', margin: '0 0 17.8px 35px' }}>
                                        <Box sx={{ display: 'flex', mt: '1px' }}>
                                            <Circle sx={{ width: '10px', height: '10px', margin: '3px 5px 2px 0', color: '#f5f5f5' }} />
                                            <Typography sx={{ fontSize: '10px', fontFamily: 'Poppins', margin: '0 0 0 5px', color: '#707070', textWrap: 'nowrap' }}> Accepted file formats:  <span style={{ color: '#000' }}>.png or .jpg</span></Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', mt: '15px' }}>
                                            <Circle sx={{ width: '10px', height: '10px', margin: '3px 5px 2px 0', color: '#f5f5f5' }} />
                                            <Typography sx={{ fontSize: '10px', fontFamily: 'Poppins', margin: '0 0 0 5px', color: '#707070', textWrap: 'nowrap' }}> Recommended size: 350px by 180px</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', mt: '15px' }}>
                                            <Circle sx={{ width: '10px', height: '10px', margin: '3px 5px 2px 0', color: '#f5f5f5' }} />
                                            <Typography sx={{ fontSize: '10px', fontFamily: 'Poppins', margin: '0 0 0 5px', color: '#707070', textWrap: 'nowrap' }}> Max. File size: 1MB</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                {uploading &&
                                    <Box>
                                        <LinearProgress sx={{ marginTop: '0%' }} /> <Typography>Uploading File... </Typography>
                                    </Box>
                                }
                            </label>
                            <FormHelperText sx={{ color: 'red' }}>{iconError ? 'Package image is is required.' : ''}</FormHelperText>
                        </FormControl>

                    </Box>
                    <Button onClick={handleSubmit} variant='contained' sx={{ width: '125px', height: '45px', margin: '0px 0.5px 36.5px 265px', backgroundColor: name !== "" && noOfItems !== "" && icon !== "" ? "#032541" : "#b4bcc4", textTransform: 'capitalize', "&:hover": { backgroundColor: name !== "" && noOfItems !== "" && icon !== "" ? "#032541" : "#b4bcc4", } }}>
                        Add
                    </Button>
                </Box>
            </> : ''}
            {showEditServiceModal ? (
                <Box style={{ width: "800px", marginTop: "20px" }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left' }}>
                        <Box sx={{ width: "50%" }}>
                            <CustomInputLabel label="Service Name" />
                            <TextField
                                label="Service Name"
                                value={serviceData.serviceName}
                                name="serviceName"
                                onChange={handleFormData}
                                style={{ width: "100%" }}
                                placeholder="Enter Service Name"
                            />
                        </Box>

                    </Box>
                    <Typography variant="h4" sx={{ color: "#032541", fontSize: "20px", fontWeight: "600", marginTop: "20px" }}>
                        Service Items
                    </Typography>
                    {serviceBoxes.map((box, index) => (
                        <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left', marginBottom: '10px' }}>
                            <Box sx={{ width: "50%" }}>
                                <CustomInputLabel label="Service Name" />
                                <TextField
                                    value={box.itemName}
                                    onChange={(e) => {
                                        const updatedBoxes = [...serviceBoxes];
                                        updatedBoxes[index].serviceName = e.target.value;
                                        setServiceBoxes(updatedBoxes);
                                    }}
                                    style={{ width: "100%" }}
                                    placeholder="Enter Service Name"
                                />
                            </Box>
                            <Box sx={{ width: "50%", marginLeft: "10px" }}>
                                <CustomInputLabel label="Cost" />
                                <TextField
                                    type="number"
                                    value={box.itemPrice}
                                    onChange={(e) => {
                                        const updatedBoxes = [...serviceBoxes];
                                        updatedBoxes[index].cost = e.target.value;
                                        setServiceBoxes(updatedBoxes);
                                    }}
                                    style={{ width: "100%" }}
                                    placeholder="Enter Cost"
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end', marginTop: "20px", marginLeft: "20px" }}>
                                <Typography variant="h4" sx={{ color: "#dc3545", fontSize: "30px", cursor: 'pointer' }} onClick={() => handleDeleteBox(index)}>
                                    ✕
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: "end" }}>
                        <Typography variant="h4" sx={{ color: "#032541", fontSize: "14px", cursor: 'pointer' }} onClick={handleAddBox}>
                            + Add Another
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: "20px" }}>
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ maxWidth: "150px", backgroundColor: "#032541", color: "#fff", textTransform: "none" }}
                        >
                            Update
                        </Button>
                    </Box>
                </Box>
            ) : null}
            {showAddPackage || showEditServiceModal ? null :
                <Box style={{ marginRight: "15px" }}>
                    <CustomTable columns={columns} data={services} />
                </Box>
            }
            <Modal open={openItemsModal} onClose={handleCloseItemsModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                <Box sx={itemsModal}>
                    <Box sx={{ padding: "10px" }}>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ margin: '0 0 0 39px' }}>
                                <Typography sx={{ textWrap: 'nowrap', height: '23px', margin: '0 134px 30px 1px', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: "#032541" }}>
                                    Additional Service Items
                                </Typography>
                                {items?.map((item) => (
                                    <Box sx={{ display: 'flex', }}>
                                        <Box sx={{ display: 'flex', marginRight: '20px' }}>
                                            <Typography sx={{ margin: '1px 10px 1px 0', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 550, textAlign: 'left', color: '#000' }}>Name:</Typography>
                                            <Typography sx={{ margin: '1px 0px 1px 0', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 'normal', textAlign: 'right', color: '#707070' }}>{item.itemName}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex' }}>
                                            <Typography sx={{ margin: '1px 10px 1px 0', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 550, textAlign: 'left', color: '#000' }}>Price:</Typography>
                                            <Typography sx={{ margin: '1px 0px 1px 0', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 'normal', textAlign: 'right', color: '#707070' }}>KES {item.itemPrice}</Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <Modal open={openDeleteService} onClose={handleCloseDeleteService} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                <Box sx={deleteServiceModal}>
                    <Box sx={{ padding: "10px" }}>
                        <Box sx={{ display: 'flex' }}>
                            <img src={warningIcon} alt="" style={{ width: '70px', height: '70px', margin: '12px 0 32px 0', objectFit: 'contain' }} />
                            <Box sx={{ width: '300px', height: '104px', margin: '0 0 0 39px' }}>
                                <Typography sx={{ textWrap: 'nowrap', height: '23px', width: '231px', margin: '0 134px 10px 1px', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: "#032541" }}>
                                    Delete {serviceToDelete.serviceName}?
                                </Typography>
                                <Typography sx={{ width: '255px', height: '41px', margin: '10px 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: '#707070', padding: '11px 66px 0 0px' }}>
                                    Additional service titled ‘Customization’ and its associated services will be deleted.
                                    <br />
                                    <br />
                                    This action cannot be undone/
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: 'center' }}>
                            <Button size='large' variant="outlined" sx={{ backgroundColor: "#fff", border: 'solid 1px #002543', color: "#6e7074", textTransform: "none", borderRadius: "4px", padding: "13px 38px 12px 37px", margin: '65px 16px 0 53px', "&:hover": { backgroundColor: "#fff" } }} onClick={handleCloseDeleteService} >Cancel</Button>
                            <Button size='large' variant="contained" sx={{ backgroundColor: "#dc3545", color: "#fff", textTransform: "none", borderRadius: "4px", padding: "12.5px 38.4px 12.5px 40.6px", margin: '65px 56px 0 16px' }} onClick={() => { handleSubmit(serviceToDelete._id) }}>Delete</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default PackagingFee