import { Grid, Typography } from "@mui/material"
import { CustomerProfileSidebar } from "./profileSidebar";
import SearchNavBar from "../searchBar";
import BannerPage from "../banner";
import Navbar from "../navbar";

const CustomerProfile = () => {
    const isLargeGrid = window.innerWidth > 768;
    return (
        <Grid container justifyContent={"center"} display={'flex'} direction={'column'} width={'100%'} height={'100%'} style={{ fontFamily: "Poppins" }}>
            <Grid container sx={{ paddingLeft: isLargeGrid ? '13%' : '5%', m: '2% 7.5% 0% 6.5%' }}>
                <Grid item display={'flex'} alignContent={'flex-start'}>
                    <Typography sx={{fontFamily:'Poppins',fontSize:'16px',fontWeight:'bold',color:'#343434'}}>My Profile</Typography>
                </Grid>
            </Grid>
            <Grid container sx={{ paddingLeft: isLargeGrid ? '13%' : '2%', ml:isLargeGrid ? '6.5%':'2%' }}>
                <CustomerProfileSidebar />
            </Grid>
        </Grid>
    )
}

export { CustomerProfile };