import { Grid, Box, Typography, TextField, FormControl, styled, Button, InputAdornment, IconButton, InputLabel, OutlinedInput, FormHelperText } from '@mui/material'
import logoImage from "../../../images/eastwestLogo.svg"
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../footer';
import { HttpRequest } from '../../HttpRequest';
import { SuccessAlert } from '../../common/snackbar/success';
import { ErrorAlert } from '../../common/snackbar/error';
import { logInUser } from '../../../features/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';

const Login = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [buttonState, setButtonState] = useState(false);
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const stateCart = useSelector((state) => state.cart);
    let cart = stateCart?.cart;

    const handleClickShowPassword = () => {
        setShowPassword(true);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const validateEmail = (email) => {
        let newEMail = email.trim();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(newEMail);
    };

    const changeButtonState = () => {
        if (email !== "" && password !== "") {
            setButtonState(true);
        } else {
            setButtonState(false);
        }
    }

    useEffect(() => {
        changeButtonState();
    }, [email, password])

    const handlePasswordChange = () => {
        console.log(email, password);
        HttpRequest({
            method: 'POST',
            url: '/posLoginVersion2',
            body: {
                "userPin": password,
                "email": email,
                "fcmToken": "cLyPjpl0TGSBb-HNCXe-ia:APA91bGRikYPEZvFG5DiLmg0ciO4WQpqAOSyBYiSSU0I2R7YFyqnpNV9Jy6LML_zs9uu1JrRRAtUUC1as45Owbx0Xb5txiQQWlgKw7VJOK2o_en4ZE0dB49kOKeLjhlpnuz8lWNwAELd",
                "deviceId": "WEB"
            }
        }).then((response) => {
            console.log(response);
            if (response.status === 200) {
                console.log(response.response.group);
                let data = response.response;
                secureLocalStorage.setItem("token", data?.token);
                secureLocalStorage.setItem("group", data?.group);
                secureLocalStorage.setItem("zedAccountingId", data?.zedAccountingId);
                secureLocalStorage.setItem("email", data?.email);
                secureLocalStorage.setItem("state", data?.state);
                secureLocalStorage.setItem("businessID", data?.defaultBusinessId);
                secureLocalStorage.setItem("username", data?.username);
                secureLocalStorage.setItem("businessName", data?.businessName);
                secureLocalStorage.setItem("businessId", data?.businessNumber);
                secureLocalStorage.setItem("userId", data?.userId);
                secureLocalStorage.setItem("BranchID", data?.branchId);
                secureLocalStorage.setItem("customerId", data?.customerId);
                secureLocalStorage.setItem("businessCategory", data?.businessCategory);
                secureLocalStorage.setItem('localCurrency', data?.localCurrency)
                secureLocalStorage.setItem('customerClassification', data?.customerClassification)
                secureLocalStorage.setItem("affiliatedPartner", data?.partnerName)
                dispatch(logInUser({ isLoggedIn: true }));

                switch (response.response.group) {
                    case 'Customer':
                        setSuccessShow({ state: true, message: 'Login Successful' });
                        if (cart?.length > 0) {
                            navigate('/checkout')
                        } else {
                            navigate('/')
                        }
                        break;
                    case 'merchant':
                        setSuccessShow({ state: true, message: 'Login Successful' });
                        navigate('/zed_ecommerce/dashboard')
                        break;
                    case 'Accountant':
                        setSuccessShow({ state: true, message: 'Login Successful' });
                        navigate('/zed_ecommerce/dashboard')
                        break;
                    case 'admin':
                        setSuccessShow({ state: true, message: 'Login Successful' });
                        navigate('/zed_ecommerce/dashboard')
                        break;
                    default:
                        setErrorShow({ state: true, message: 'User  not allowed!' })
                        break;

                }
            } else if (response.status === 400) {
                setErrorShow({ state: true, message: 'User not found, please provide correct phone or email and pin' })
            } else if (response.status === 505) {
                setErrorShow({ state: true, message: 'Invalid Pin' })
            } else if (response.status === 507) {
                setErrorShow({ state: true, message: 'Account Suspended for Numerous Login Attempts' })
            } else if (response.status === 405) {
                setErrorShow({ state: true, message: 'User Does not Exists!' })
            }

        }).catch((error) => {
            console.log(error);

        })
    }

    return (
        <Grid container spacing={2} sx={{ minHeight: 'calc(100vh - 64px - 64px)' }} row>
            <Grid item xs={12}>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            </Grid>
            <Grid item xs={12}>
                <Box display={'flex'} py={1} flexDirection={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                    <img onClick={() => navigate('/')} src={logoImage} alt='' style={{ width: '159px', height: '98.5px', margin: { lg: '79px 640px 30.5px 640px', sm: '' }, objectFit: 'contain', cursor: 'pointer' }} />
                    <Typography sx={{ width: '105px', fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 2, letterSpacing: 'normal', textAlign: 'left', color: '#02b3ad' }}>
                        Welcome!
                    </Typography>
                    <Typography sx={{ width: '316px', height: '41px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'center', color: '#898989' }}>
                    Login to your account and enjoy unbeatable offers and products.
                    </Typography>
                </Box>
                <Box sx={{ maxWidth: "350px", marginLeft: 'auto', marginRight: "auto", }} component={'div'} display={'flex'} py={1} flexDirection={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                    <Grid container spacing={2} width={'100%'}>
                        <Grid item lg={12} width={'100%'}>
                            <FormControl sx={{width: '100%', maxWidth: { xs: '340px', sm: '340px', md: '350px',lg:'350px',xl:'350px' }, height: "100%", maxHeight: '48px', margin: '2px 0 10px 2px', borderRadius: '5px', border: '1px #bfbfbf', backgroundColor: '#fff', fontFamily: 'Poppins' }}>
                                {email && !validateEmail(email) && (
                                    <FormHelperText error>Enter a valid email</FormHelperText>
                                )}
                                <TextField value={email} required id='email' label='Email Address' sx={{ }} onChange={(e) => setEmail(e.target.value)} InputProps={{style:{fontSize:'12px',fontFamily:'Poppins',color:'#707070'}}}/>
                            </FormControl>
                        </Grid>
                        <Grid item lg={12} width={'100%'}>
                            <FormControl sx={{ width: '100%', maxWidth: { xs: '340px', sm: '340px', md: '350px' }, height: "100%", maxHeight: '48px', margin: '2px 0 10px 2px', borderRadius: '5px', border: '1px #bfbfbf', backgroundColor: '#fff', fontFamily: 'Poppins' }}>
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput onChange={e => setPassword(e.target.value)} value={password} placeholder="Enter your Password"
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                    InputProps={{style:{fontSize:'12px',fontFamily:'Poppins',color:'#707070'}}}
                                />
                            </FormControl>
                            <Typography onClick={()=>navigate('/password/forgot')} sx={{ margin: '16px 0 20px px', fontFamily: 'Poppins', fontSize: '12px', fontWeight: 500, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.42, letterSpacing: 'normal', color: '#02b3ad',textWrap:'nowrap',textAlign:'end',cursor:'pointer' }}>
                                Forgot your Password?
                            </Typography>
                            {/* <Typography sx={{ margin: '20px 130px 17px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', textAlign: 'center', color: '#898989' }}>
                                    Or sign in with
                                </Typography>
                                <Box>
                                    <Button variant='outlined' sx={{ width: '150px', height: '50px', margin: '0 13px 0 0', padding: '13px 20px 12.2px', objectFit: 'contain', borderRadius: '10px', border: 'solid 1px #e0e0e0', backgroundColor: '#fff' }}>
                                        <img src={facebookIcon} alt='' style={{ width: '25px', height: '24.8px', margin: '0 5px 0 0', objectFit: 'contain' }} />
                                        <Typography sx={{ margin: ' 2px 0 2.8px 17px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', color: '#343434', textTransform: 'capitalize' }}>Facebook</Typography>
                                    </Button>
                                    <Button variant='outlined' sx={{ width: '150px', height: '50px', margin: '0 13px 0 0', padding: '13px 20px 12.2px', objectFit: 'contain', borderRadius: '10px', border: 'solid 1px #e0e0e0', backgroundColor: '#fff' }}>
                                        <img src={googleIcon} alt='' style={{ width: '25px', height: '24.8px', margin: '0 5px 0 0', objectFit: 'contain' }} />
                                        <Typography sx={{ margin: ' 2px 0 2.8px 17px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', color: '#343434', textTransform: 'capitalize' }}>Google</Typography>
                                    </Button>

                                </Box> */}
                            <Box>
                                <Button variant='contained' sx={{ width: '313px', height: '50px', margin: '31px 18px 14px 19px', padding: '15px 137px 15px 138px', objectFit: 'contain', opacity: email ==='' || password === '' ? '0.5' : '1', borderRadius: '5px', backgroundColor: '#02b3ad', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'bold', color: '#fff', textTransform: 'capitalize', "&:hover": { backgroundColor: '#02b3ad' } }} onClick={handlePasswordChange}>Login</Button>
                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', textAlign: 'center', color: '#171819' }}>
                                    Don’t have an account? <Link to='/register' style={{ textDecoration: 'none', color: '#02b3ad' }}>Sign Up </Link>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}


export default Login;