import { Box, Button, Grid, Typography, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HttpRequest } from "../HttpRequest";
import { ArrowBackIos, EditOutlined } from "@mui/icons-material";
import CustomInputLabel from "../common/CustomInputLabel";
import { SuccessAlert } from "../common/snackbar/success";
import { ErrorAlert } from "../common/snackbar/error";
import CustomTable from "../common/CustomTable";
import CustomSearchInput from "../common/CustomSearchInput";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const deletePickupModal = {
  borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: "130px", p: '61px 22px 165px 63px', border: '1px #707070', m: '55px 104px 6.5px 118px'
}
const PickupRegions = () => {
  const [successShow, setSuccessShow] = useState({ state: false, message: "" })
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })
  const [showAddPickupModal, setShowAddPickupModal] = useState(false);
  const [showEditPickupModal, setShowEditPickupModal] = useState(false)
  const [pickUpLocations, setPickUpLocations] = useState([])
  const [pickUp,setPickUp] = useState({});
  const [pickData, setPickData] = useState({
    locationName: "",
    locationDescription: "",
  });
  const [openDeletePickup, setOpenDeletePickup] = useState(false)
  const handleOpenDeletePickup = () => { setOpenDeletePickup(true) }
  const handleCloseDeleteShippingRegion = () => { setOpenDeletePickup(false) }

  const handleFormData = (e) => {
    const { name, value } = e.target
    setPickData({ ...pickData, [name]: value })
  }
  const handleRowClick = (event, params) => {
    event.stopPropagation();
    console.log('params', params.row);
    setPickData({
      locationName: params.row.locationName,
      locationDescription: params.row.locationDescription,
    })
    setPickUp(params.row);
    setShowEditPickupModal(true);
  }

  const columns = [
    {
      field: 'locationName', headerName: 'Pickup Name', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Pickup Name </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Grid container spacing={2}>
            <Grid item>
              {params.row.locationName}
            </Grid>
          </Grid>
        </Box>)
      }
    },
    {
      field: 'locationDescription', headerName: 'Description', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Description   </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          {params.row.locationDescription}
        </Box>)
      }
    },
    {
      field: 'action', headerName: 'Action', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Action  </Box>) },
      renderCell: (params) => {
        const onClickDelete = (event) => {
          event.stopPropagation();
          // setShippingRegion(params.row);
          // handleOpenDeleteShippingRegion(true);
        }
        return (
          <Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500", mt: '30px' }}>
            <EditOutlined onClick={(event)=>handleRowClick(event,params)} sx={{ color: '#032541', marginRight: '10px' }} />
            <DeleteIcon onClick={onClickDelete} sx={{ color: '#DC3545' }} />
          </Box>
        )
      }
    },
  ]

  const getPickUPLocation = () => {
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/get_pickup_locations`
    }).then((data) => {
      console.log(data.response?.data, ' data.response.data')
      setPickUpLocations(data.response?.data)
    }).catch((error) => {
      console.log('Error fetching items');
    });
  }

  useEffect(() => {
    getPickUPLocation()
  }, [])

  const navigate = useNavigate();

  const handlePickSubmit = () => {
    if (!pickData.locationName) {
      setErrorShow({ state: true, message: "Location name is required" });
      return;
    }
    if (!pickData.locationDescription) {
      setErrorShow({ state: true, message: "Location description is required" });
      return;
    }
    HttpRequest({
      method: 'POST',
      url: `/api/v1/ecommerce/add_pickpoint_locations`,
      body: pickData

    }).then((data) => {
      if (data?.status === 200) {
        setSuccessShow({ state: true, message: "Pickup added successfully" });
        setShowAddPickupModal(false)
        getPickUPLocation()
        setPickData({
          locationName: "",
          locationDescription: "",
        })
      } else {
        console.log(data, ' data.response.data ')
        console.log(data.response?.data, ' data.response.data ')

        setErrorShow({ state: true, message: data.response?.data?.message });
      }
    }).catch((error) => {
      setErrorShow({ state: true, message: "Error adding pickup" });
    })
  }
  const handlePickUpdateSubmit = () => {
    if (!pickData.locationName) {
      setErrorShow({ state: true, message: "Location name is required" });
      return;
    }
    if (!pickData.locationDescription) {
      setErrorShow({ state: true, message: "Location description is required" });
      return;
    }
   
    HttpRequest({
      method: 'POST',
      url: `/api/v1/ecommerce/edit_pickup_locations/${pickUp._id}`,
      body: pickData

    }).then((data) => {
      if (data?.status === 200) {
        setSuccessShow({ state: true, message: "Pickup updated successfully" });
        setShowEditPickupModal(false)
        getPickUPLocation()
        setPickData({
          locationName: "",
          locationDescription: "",
        })
      } else {
        console.log(data, ' data.response.data ')
        console.log(data.response?.data, ' data.response.data ')

        setErrorShow({ state: true, message: data.response?.data?.message });
      }
    }).catch((error) => {
      setErrorShow({ state: true, message: "Error updating pickup" });
    })
  }
  return (
    <div style={{ marginTop: '4%' }}>
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
        <Box sx={{ flexDirection: "column" }}>
          <Box>
            {showAddPickupModal ? (
              <Box variant="h4" sx={{ display: 'flex' }}>
                <ArrowBackIos sx={{ width: '35px', height: '35px', objectFit: 'contain' }} onClick={() => { setShowAddPickupModal(false) }} />
                <Typography sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600" }}>
                  Add Pickup
                </Typography>
              </Box>
            ) : showEditPickupModal ? (
              <Box variant="h4" sx={{ display: 'flex' }}>
                <ArrowBackIos sx={{ width: '35px', height: '35px', objectFit: 'contain' }} onClick={() => { setShowEditPickupModal(false) }} />
                <Typography sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600" }}>
                  Edit Pickup
                </Typography>
              </Box>
            ) : (
              <Typography variant="h4" sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600" }}>
                Pickups
              </Typography>
            )}
          </Box>
          <Box>
            <Typography variant="h4" sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal", }}   >
              View and manage your Pickups.
            </Typography>
          </Box>
        </Box>
        {showAddPickupModal || showEditPickupModal ? null :
          <Box style={{ marginRight: "15px" }}>

            <Button variant="contained" sx={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#032541", color: "#fff" } }} onClick={() => setShowAddPickupModal(true)}>
              Add Pickup
            </Button>
          </Box>
        }
      </Box>
      {showAddPickupModal || showEditPickupModal ? null :
        <Box sx={{ display: 'flex' }}>
          <Box sx={{}}>
            <CustomSearchInput placeholder={"Search pickup name ...."} name="search" value={""} />
          </Box>
        </Box>
      }
      {showAddPickupModal ?
        <>
          <Box sx={{ width: "500px", }}>
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              <CustomInputLabel label="Location  Name" placeholder="Enter Location Name " />
              <TextField label="Location Name" value={pickData?.locationName} name="locationName" onChange={handleFormData} InputProps={{ sx: { width: "490px", height: '50px', fontSize: "0.875rem", "& fieldset": { borderColor: "#bec5d1", }, }, }} placeholder="Nairobi - CBD" />
            </Box>
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              <CustomInputLabel label="Description" placeholder="Enter  Description*" />
              <TextField name={"locationDescription"} value={pickData.locationDescription} onChange={handleFormData} placeholder={"Service Description"} variant="outlined" margin="dense" sx={{ marginBottom: 2 }} multiline minRows={2} InputProps={{ sx: { width: "490px", height: '70px', fontSize: "0.875rem", "& fieldset": { borderColor: "#bec5d1", }, }, }} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignContent: "end" }}>
              <Button variant="contained" sx={{ backgroundColor: "#fff", color: "#dc3545", textTransform: "none", borderRadius: "5px", marginRight: "10px", border: "1px solid #dc3545", padding: "10.4px 30px 9.6px 30px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#fff", color: "#dc3545" } }} onClick={() => setShowAddPickupModal(false)}>
                Cancel
              </Button>
              <Button variant="contained" sx={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#032541", color: "#fff" } }} onClick={handlePickSubmit}>
                Add Pickup
              </Button>
            </Box>
          </Box>
        </> : showEditPickupModal ?
          <>
            <Box sx={{ width: "500px", }}>
              <Box sx={{ width: "100%", marginTop: "20px" }}>
                <CustomInputLabel label="Location  Name" placeholder="Enter Location Name " />
                <TextField label="Location Name" value={pickData?.locationName} name="locationName" onChange={handleFormData} InputProps={{ sx: { width: "490px", height: '50px', fontSize: "0.875rem", "& fieldset": { borderColor: "#bec5d1", }, }, }} placeholder="Nairobi - CBD" />
              </Box>
              <Box sx={{ width: "100%", marginTop: "20px" }}>
                <CustomInputLabel label="Description" placeholder="Enter  Description*" />
                <TextField name={"locationDescription"} value={pickData.locationDescription} onChange={handleFormData} placeholder={"Service Description"} variant="outlined" margin="dense" sx={{ marginBottom: 2 }} multiline minRows={2} InputProps={{ sx: { width: "490px", height: '70px', fontSize: "0.875rem", "& fieldset": { borderColor: "#bec5d1", }, }, }} />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end", alignContent: "end" }}>
                <Button variant="contained" sx={{ backgroundColor: "#fff", color: "#dc3545", textTransform: "none", borderRadius: "5px", marginRight: "10px", border: "1px solid #dc3545", padding: "10.4px 30px 9.6px 30px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#fff", color: "#dc3545" } }} onClick={() => setShowEditPickupModal(false)}>
                  Cancel
                </Button>
                <Button variant="contained" sx={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#032541", color: "#fff" } }} onClick={handlePickUpdateSubmit}>
                  Update
                </Button>
              </Box>
            </Box>
          </> :
          <Box style={{ marginRight: "15px" }}>
            <CustomTable columns={columns} data={pickUpLocations} />
          </Box>
      }


    </div>
  )
}

export default PickupRegions