import { Avatar, Box, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import NoProfile from '../../../images/profile_picture.svg'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PlaceIcon from '@mui/icons-material/Place';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import LogoutIcon from '@mui/icons-material/Logout';
import { ProfileDetails } from "./profileDetails";
import { HttpRequest } from "../../HttpRequest";
import { CustomerAdressDetails } from "./addressDetails";
import { MyCustomerOrders } from "./ordersCustomer";
import { logoutUser } from "../../../features/userSlice";
import { useDispatch } from "react-redux";
import { AccountCircle, EditOffOutlined, EditRounded, ShoppingCartCheckoutOutlined } from "@mui/icons-material";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const CustomerProfileSidebar = () => {
    const [activeTab, setActiveTab] = useState('orders');
    const [customerDetails, setCustomerDetails] = useState();
    const customerId = secureLocalStorage.getItem('customerId');
    const zedUrl = process.env.REACT_APP_ZED_URL;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function getCustomerDetails() {
        try {
            HttpRequest({
                url: `/api/get_customer_by_customer_id?customerId=${customerId}`,
                method: 'GET',
            }).then((response) => {
                if (response?.status === 200) {
                    setCustomerDetails(response?.response?.data);
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getCustomerDetails();
    }, [customerId]);


    console.log(customerDetails, 'customerDetails');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    }

    const tabs = [
        {
            label: 'My Orders',
            value: 'orders',
            icon: ShoppingBasketIcon,
        },
        {
            label: 'Personal Data',
            value: 'personalData',
            icon: AccountCircleIcon,
        },
        {
            label: 'Delivery Addresses',
            value: 'addresses',
            icon: PlaceIcon,
        },
    ]

    const handleLogout = () => {
        secureLocalStorage.clear();
        dispatch(logoutUser());
    }

    return (
        <Grid container width={'100%'} sx={{ fontFamily: "Poppins" }}>
            <Grid item width={'100%'} spacing={2}>
                <Grid container spacing={2} gap={2}>
                    <Grid item lg={3} md={4} sm={12} xs={12} >
                        <Box sx={{ width: '100%', maxWidth: '334px', padding: '40.9px 15px 27px 6px', height: '100%', maxHeight: '582px', borderRadius: '15px', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', backgroundColor: '#fff', margin: '20px 16px 96px 3px' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: "column", alignItems: 'center', width: '100%', maxWidth: '222px', height: '100%', maxHeight: '150.1px', margin: '0 81px 0px 25px' }}>
                                <AccountCircle sx={{ width: '126.7px', height: '131px', margin: '0 95.3px 22px 0', objectFit: 'contain', fontSize: '10px', color: '#707070', borderTop: 'none', borderLeft: '5px solid #02b3ad', borderBottom: 'none', borderRight: 'none', borderRadius: '50%' }} />
                                <Box sx={{ backgroundColor: '#02b3ad', borderRadius: '50%', justifyContent: 'center', alignItems: 'center', m: '-50px 0px 0px -10px' }}>
                                    <EditRounded sx={{ width: '20px', height: '20px', color: '#fff', m: '4px 4px 0px 4px' }} />
                                </Box>
                            </Box>
                            <Box sx={{ margin: '22px 0 0 20px' }}>
                                <Typography sx={{ margin: '0 92px 1px 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, textAlign: 'left', color: '#000', textWrap: 'nowrap' }}>{customerDetails?.firstName} {customerDetails?.lastName}</Typography>
                                <Typography sx={{ margin: '1px 0 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', color: '#898989' }}>{customerDetails?.email}</Typography>
                            </Box>
                            <Box>
                                {tabs.map((tab, index) => (
                                    <Box key={index} onClick={() => handleTabChange(tab.value)} sx={{ padding: '15px 14px 15px 15px', alignItems: 'center', display: "flex", margin: '12px 0 10px', cursor: 'pointer', borderRadius: '10px', backgroundColor: activeTab === tab.value ? '#effffe' : '#fff', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', width: '100%', maxWidth: "303px", height: '100%', maxHeight: '48px' }}>
                                        {tab.icon && <tab.icon sx={{ marginRight: '10px', color: activeTab === tab.value ? '#02b3ad' : '#898989' }} />}
                                        <Typography sx={{ color: activeTab === tab.value ? '#02b3ad' : '#898989', margin: '2px 0 2px 24px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: activeTab === tab.value ? 600 : 'normal', textAlign: 'left' }}>{tab.label}</Typography>
                                    </Box>
                                ))}
                            </Box>
                            <Box component={'div'} onClick={() => navigate('/products')} sx={{ padding: '3px 14px 3px 15px', alignItems: 'center', display: "flex", margin: '12px 0 10px', cursor: 'pointer', borderRadius: '10px', backgroundColor: '#fff', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', width: '100%', maxWidth: "303px", height: '100%', maxHeight: '48px' }}>
                                <ShoppingCartCheckoutOutlined sx={{ color: '#898989', marginRight: '10px', }} />
                                <Typography sx={{ color: '#898989', margin: '2px 0 2px 24px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', textAlign: 'left' }}>Continue Shopping</Typography>
                            </Box>
                            <Box component={'div'} onClick={handleLogout} sx={{  padding: '3px 14px 3px 15px', alignItems: 'center', display: "flex", margin: '12px 0 10px', cursor: 'pointer', borderRadius: '10px', backgroundColor: '#fff', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', width: '100%', maxWidth: "303px", height: '100%', maxHeight: '48px' }}>
                                <LogoutIcon sx={{ color: '#e20613',marginRight: '10px', }} />
                                <Typography variant="p" sx={{ color: '#e20613', margin: '2px 0 2px 24px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', textAlign: 'left' }}>Logout</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={8} md={7} sm={12} xs={12}>
                        <Box component={Paper} display={'flex'} sx={{ width: '100%',maxWidth:{xs:'350px',sm:'684px'},height:'100%',maxHeight:'582px',margin:{xs:'0px',sm:'20px 23px 0 16px'},padding:'32px 27.9px 62px 30px',borderRadius:'15px',boxShadow:'0 5px 30px 0 rgba(0, 0, 0, 0.05)',backgroundColor:'#fff' }}>
                            {activeTab === 'orders' && <MyCustomerOrders customerDetails={customerDetails} />}
                            {activeTab === 'personalData' && <ProfileDetails customerDetails={customerDetails} />}
                            {activeTab === 'addresses' && <CustomerAdressDetails customerDetails={customerDetails} />}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    )
}
export { CustomerProfileSidebar }

