import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import productIcon from "../images/product-icn.svg";
import ordersIcon from "../images/bills-icon.svg";
import customersIcon from "../images/users-icon.svg";
import salesIcon from "../images/sales-icn.svg";
import { HttpRequest } from './HttpRequest';
import Chart from "chart.js/auto";
import { Bar } from 'react-chartjs-2';
import { ListAlt, ShowChart, Visibility } from '@mui/icons-material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import CustomTable from './common/CustomTable';
import CurrencyFormatter from './common/currencyFormatter';
import { useNavigate } from 'react-router-dom';
const token = process.env.REACT_APP_TOKEN;
const Dashboard = () => {
  const navigate = useNavigate();

  const [totalProducts, setTotalProducts] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [year, setYear] = useState(new Date().getFullYear());
  const [salesData, setSalesData] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [transactionsLoading, setTransactionsLoading] = useState(true);

  const formatDate = (value) => {
    const dateString = value;
    const date = new Date(dateString);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  const columns = [
    {
      field: 'orderNumber', headerName: 'order Number', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Order Number  </Box>) },
      renderCell: (params) => { return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}> {params.row.orderNumber}  </Box>) }
    },
    {
      field: 'createdAt', headerName: 'Date', flex: 1,

      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Date  </Box>) },
      renderCell: (params) => {
        return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          {formatDate(params.row.createdAt)}
        </Box>)
      }
    },
    {
      field: 'customerName', headerName: 'Customer Name', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Customer Name  </Box>) },
      renderCell: (params) => { return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>    {params.row.customerName}  </Box>) }
    },
    {
      field: 'customerAddress', headerName: 'Delivery Address', flex: 3,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Delivery Address  </Box>) },
      renderCell: (params) => { return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>     {params?.row?.customerAddress?.address}, {params?.row?.customerAddress?.apartment},  {params?.row?.customerAddress?.city} </Box>) }
    },
    {
      field: 'customerAddress', headerName: 'Delivery Address', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Delivery Address  </Box>) },
      renderCell: (params) => { return (<Box  style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>  
        {typeof params?.row?.customerAddress === 'object' && params?.row?.customerAddress !== null ? (
          <>
            { params?.row?.customerAddress?.address && <div>{ params?.row?.customerAddress?.address}</div>}
            { params?.row?.customerAddress?.apartment && <div>{ params?.row?.customerAddress?.apartment}</div>}
            { params?.row?.customerAddress?.city && <div>{ params?.row?.customerAddress?.city}</div>}
          </>
        ) : (
         params?.row?.customerAddress
        )}
         </Box>) }
    },
    {
      field: 'amount', headerName: 'Amount', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Amount  </Box>) },
      renderCell: (params) => {
        return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          {/* {params.row.amount} */}
          {CurrencyFormatter(params.row.amount, 'KES')}
        </Box>)
      }
    },
    {
      field: 'paymentStatus', headerName: 'Payment Status', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Payment Status  </Box>) },
      renderCell: (params) => { return (<Box style={{ cursor: 'pointer', color: getStatusColor(params), fontSize: "15px", fontWeight: "600", textTransform: 'capitalize' }}> {params.row.status}  </Box>) }
    },
    {
      field: 'status', headerName: 'Order Status', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Order Status  </Box>) },
      renderCell: (params) => {
        return (
          <Box style={{ cursor: 'pointer', color: getOrderStatusColor(params), fontSize: "16px", fontWeight: "600", textAlign: 'left' }}>
            {params.row?.orderStatus ?? 'Pending'}
          </Box>
        )
      }
    },
    {
      field: 'action', headerName: 'Action', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}>   </Box>) },
      renderCell: (params) => {
        return (
          <Box style={{ cursor: 'pointer', color: '#707070', fontSize: "15px", fontWeight: "500", marginTop: '10px' }}>
            <Visibility onClick={() => { navigate(`/zed_ecommerce/orders/${params.row._id}/details`) }} />
          </Box>)
      }
    },
  ]
  function getStatusColor(params) {
    const statusColors = {
      'paid': '#17ae7b',
      'unpaid': '#dc3545',
    };
    return statusColors[params.row.status] || '#dc3545';
  }
  function getOrderStatusColor(params) {
    const statusColors = {
      'Received': '#707070',
      'Delivered': '#17ae7b',
      'Shipped': '#000000',
      'Processing': '#f79009'
    };
    return statusColors[params.row?.orderStatus] || '#dc3545';
  }
  const fetchStats = async () => {
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/zed_ecommerce_summary`,
      body: null,
    }).then((data) => {
      if (data.status === 200) {
        console.log('stats',data.response);
        setTotalCustomers(data.response.totalCustomers);
        setTotalOrders(data.response.totalOrders);
        setTotalSales(data.response.totalRevenue);
        setTotalProducts(data.response.totalproducts);
      }
    }).catch((error) => {
      console.error(error, 'error');
    })
  }

  const salesActivity = async () => {
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/zed_ecommerce_sales_activity?year=${year}`,
      body: null,
    }).then((data) => {
      if (data.status === 200) {
        let status = data.response;
        const formattedData = formatSalesData(data.response.data);
        setSalesData(formattedData);
      }
    }).catch((error) => {
    })
  }

  const fetchOrders = async () => {
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/orders?page=1&limit=5`,
      body: null
    }).then((data) => {
      if (data.status === 200) {
        let status = data.response;
        setTransactions(data.response.data);
      }
    }).catch((error) => {
    }).finally(() => {
      setTransactionsLoading(false);
    });
  }
  const salesActivityLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const salesActvityData = {
    labels: salesActivityLabels,
    datasets: [
      {
        label: "Sales Activity",
        backgroundColor: "rgba(23, 174, 123, 0.45)",
        borderColor: "rgba(23, 174, 123, 0.45)",
        borderRadius: 4,
        barThickness: 15,
        data: salesData,
      },
    ],
  };

  const formatSalesData = (data) => {
    const formattedData = salesActivityLabels.map((month) => {
      const matchingData = data.find((item) => item.month === month);
      return matchingData ? matchingData.totalSales : 0;
    });
    return formattedData;
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  }

  useEffect(() => {
    fetchStats();
    fetchOrders();
  }, [])
  useEffect(() => {
    salesActivity();
  }, [year])

  return (
    <Box component="main" sx={{ margin: '2% 0 0 22px', padding: '25px 34px 136px 5px' }}>
      <Typography sx={{ width: '136px', height: '20px', margin: '9px 9px 5px 0', fontFamily: 'Poppins', fontSize: '28px', fontWeight: 600, lineHeight: 1.18, letterSpacing: 'normal', textAlign: 'left', fontStyle: 'normal', fontStretch: 'normal' }}>
        Dashboard
      </Typography>
      <Grid container spacing={2} columns={1} sx={{ mt: '2%', marginLeft: '0.5%' }}>

        <Grid item sx={{ width: '100%',maxWidth:'366px', height: 110, margin: '20px 15px 15px 0', padding:15, boxShadow: '3px 3px 20px 0 rgba(145, 158, 171, 0.16)', background: '#fff' }} onClick={() => navigate("/zed_ecommerce/products")}>
          <Box sx={{ display: 'flex',justifyContent:'space-between',alignItems:'center',gap:'80%' }}>
            <Typography sx={{flexDirection:'column', textWrap: 'nowrap', fontFamily: 'Poppins', fontSize: 16, WebkitTextStroke: '1px rgba(0, 0, 0, 0)', fontWeight: 'normal', fontStretch: 'normal', textAlign: 'left', color: '#212b36', fontStyle: 'normal' }}>Total Products</Typography>
            <img src={productIcon} alt='' style={{ objectFit: 'contain', marginLeft: 'auto'}} />
          </Box>
          <Typography sx={{ margin: '57px 13px 0 0', fontFamily: 'Poppins', fontSize: 20, fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.2, textAlign: 'left', color: '#5e0ac4' }}>
            {totalProducts.toLocaleString()}
          </Typography>
        </Grid>
        <Grid item sx={{width: '100%',maxWidth:'366px', height: 110, margin: '20px 15px 25px 0', padding: 15, boxShadow: '3px 3px 20px 0 rgba(145, 158, 171, 0.16)', background: '#fff' }} onClick={() => navigate("/zed_ecommerce/orders")}>
          <Box sx={{ display: 'flex',justifyContent:'space-between',alignItems:'center',gap:'80%'  }}>
            <Typography sx={{flexDirection:'column', margin: '2px 20px 28px 0', textWrap: 'nowrap', fontFamily: 'Poppins', fontSize: 16, WebkitTextStroke: '1px rgba(0, 0, 0, 0)', fontWeight: 'normal', fontStretch: 'normal', textAlign: 'left', color: '#212b36', fontStyle: 'normal' }}>Total Orders</Typography>
            <img src={ordersIcon} alt='' style={{ objectFit: 'contain', marginTop: '-7%' }} />
          </Box>
          <Typography sx={{ margin: '27px 173px 0 0', fontFamily: 'Poppins', fontSize: 20, fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.2, textAlign: 'left', color: '#3da2f8' }}>
            {totalOrders.toLocaleString()}
          </Typography>
        </Grid>
        <Grid item sx={{width: '100%',maxWidth:'366px', height: 110, margin: '20px 15px 25px 0', padding: 15, boxShadow: '3px 3px 20px 0 rgba(145, 158, 171, 0.16)', background: '#fff' }} onClick={() => navigate("/zed_ecommerce/customers")}>
          <Box sx={{ display: 'flex',justifyContent:'space-between',alignItems:'center',gap:'60%'  }}>
            <Typography sx={{flexDirection:'column', margin: '2px 20px 28px 0', textWrap: 'nowrap', fontFamily: 'Poppins', fontSize: 16, WebkitTextStroke: '1px rgba(0, 0, 0, 0)', fontWeight: 'normal', fontStretch: 'normal', textAlign: 'left', color: '#212b36', fontStyle: 'normal' }}>Total Customers</Typography>
            <img src={customersIcon} alt='' style={{ objectFit: 'contain', marginTop: '-7%' }} />
          </Box>
          <Typography sx={{ margin: '27px 173px 0 0', fontFamily: 'Poppins', fontSize: 20, fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.2, textAlign: 'left', color: '#f79009' }}>
            {totalCustomers.toLocaleString()}
          </Typography>
        </Grid>
        <Grid item sx={{width: '100%',maxWidth:'366px', height: 110, margin: '20px 15px 25px 0', padding: 15, boxShadow: '3px 3px 20px 0 rgba(145, 158, 171, 0.16)', background: '#fff' }} onClick={() => navigate("/zed_ecommerce/reports")}>
          <Box sx={{ display: 'flex',justifyContent:'space-between',alignItems:'center',gap:'80%'  }}>
            <Typography sx={{flexDirection:'column', margin: '2px 20px 28px 0', textWrap: 'nowrap', fontFamily: 'Poppins', fontSize: 16, WebkitTextStroke: '1px rgba(0, 0, 0, 0)', fontWeight: 'normal', fontStretch: 'normal', textAlign: 'left', color: '#212b36', fontStyle: 'normal' }}>Total Sales</Typography>
            <img src={salesIcon} alt='' style={{ objectFit: 'contain', marginTop: '-7%' }} />
          </Box>
          <Typography sx={{ margin: '27px 173px 0 0', fontFamily: 'Poppins', textWrap: 'nowrap', fontSize: 20, fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 1.2, textAlign: 'left', color: '#17ae7b', pb: '10%' }}>
            Kshs {totalSales.toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Typography>
        </Grid>

      </Grid>
      <Grid container columns={1} sx={{ mt: '1', marginLeft: '0.5%' }}>
        <Grid item lg={12} sx={{ width: '100%', height: '20%', margin: '5px 0', padding: '20px 20px 31px 14px', borderRadius: 1, boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff' }}>
          <Typography sx={{ display: 'flex' }}>
            <ShowChart sx={{ width: '40px', height: '40px', margin: '1px ', padding: '1px', borderRadius: '4px', backgroundColor: '#e7f7f1', color: '#17AE7B' }} />
            <Typography sx={{ margin: '13px 800px 40px 15px', fontFamily: 'Poppins', fontSize: 18, fontWeight: 600, textAlign: 'left', color: '#032541' }}>Sales Activity</Typography>
            <FormControl sx={{ width: 95, height: 55, margin: '0 0 33px 400px', padding: '13px 16px 12px 15px', borderRadius: '4px', backgroundColor: 'rgba(0, 0, 0, 0.03)' }}>
              <Select
                id="select-year"
                value={year}
                label={year}
                onChange={handleYearChange} >
                <MenuItem value={2024}>2024</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
              </Select>
            </FormControl>
          </Typography>
          <Bar data={salesActvityData} height={100} />
        </Grid>
        <Grid item lg={12} sx={{ width: '100%', height: '20%', margin: '5px 0', padding: '20px 20px 31px 14px', borderRadius: 1, boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff' }}>
          <Typography sx={{ display: 'flex' }}>
            <ListAlt sx={{ width: '40px', height: '40px', margin: '1px ', padding: '1px', borderRadius: '4px', backgroundColor: 'rgba(61, 162, 248, 0.1)', color: '#3da2f8' }} />
            <Typography sx={{ margin: '13px 800px 40px 15px', fontFamily: 'Poppins', fontSize: 18, fontWeight: 600, textAlign: 'left', color: '#032541' }}>Recent Orders</Typography>
          </Typography>
          <CustomTable columns={columns} data={transactions} sx={{
            [`.${gridClasses.cell}.Received`]: {
              color: '#707070'
            },
            [`.${gridClasses.cell}.Processing`]: {
              color: '#f79009',
            },
            [`.${gridClasses.cell}.Shipped`]: {
              color: '#000000',
            },
            [`.${gridClasses.cell}.Delivered`]: {
              color: '#17ae7b',
            }
          }} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;

