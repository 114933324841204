import { Navigate, Outlet } from 'react-router-dom';
import { Box, Grid } from '@mui/material'
import { useEffect, useState } from 'react';
import { HttpRequest } from '../HttpRequest';
import Header from '../customer/header';
import Footer from '../customer/footer';
import TopNavBar from '../customer/topNavBar';
import SearchNavBar from '../customer/searchBar';
import Navbar from '../customer/navbar';
import secureLocalStorage from 'react-secure-storage';

const BusinessRoutes = () => {
  const [tokenGenerated, setTokenGenerated] = useState(false);

  useEffect(() => {
    const getBusinessToken = async () => {
      const businessNumber = localStorage.getItem('businessId');
      if (!businessNumber) {
        const currentDomain = window.location.origin;
        HttpRequest({
          method: 'GET',
          url: `/api/v1/ecommerce/getzedecommerce-domain-details?subdomain=${currentDomain}`,
        }).then(async (data) => {
          if (data.status === 200) {
            localStorage.setItem('businessId',data.response.businessNumber);
            secureLocalStorage.setItem('businessId',data.response.businessNumber);
            setTokenGenerated(true);
          }
        }).catch((error) => {
          console.log('Error fetching business token', error);
        });
      } else {
        setTokenGenerated(true);
      }
    };

    getBusinessToken();
  }, []);

  return (
    <Box component={'div'} width={'100%'} sx={{ minHeight: '100vh' }}>
      {tokenGenerated ?
        <Grid container display={'flex'} direction={'column'} height={'100%'} spacing={2}>
          <Grid xs={12}>
            <Box py={2}>
              <TopNavBar sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }} />
            </Box>
            <Box className="container">
              <SearchNavBar />
              <Navbar />
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box>
              <Outlet />
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box mt={'100px'}>
              <Footer />
            </Box>
          </Grid>
        </Grid> : <Outlet />}
    </Box>
  );
};

export default BusinessRoutes;
