import { Box, Button, Grid, Typography, MenuItem, TextField, Divider, Modal, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Checkbox, Autocomplete, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HttpRequest } from "../HttpRequest";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Add, AddCircleOutline, ArrowBackIos, CheckBox } from "@mui/icons-material";
import CustomInputLabel from "../common/CustomInputLabel";
import { useDispatch, useSelector } from "react-redux";
import { setProductTypeData, setProductCategory, setProductName, setProductDescription, setProductIsPackage, setMaxNoOfItems, setVariations, setFeaturedImage, setThumbnailImage, setAdditionalImages, setPricingData, setTagsData, setAdditionalServicesData } from "../../features/productSlice";
import CloseIcon from "@mui/icons-material/Close";
import PricingComponent from "./PricingComponent";
import imagePlaceholder from "../../images/img-holder-icn.svg";
import { SuccessAlert } from "../common/snackbar/success";
import { ErrorAlert } from "../common/snackbar/error";
import generateImageUrl from "../common/generateImageUrl";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import secureLocalStorage from "react-secure-storage";

const AddProductsmodal = {
  borderRadius: "10px",
  backgroundColor: "#fff",
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  height: "390px",
  p: 2,
};
const EditProduct = (props) => {
  const { productTypeData, productName, productCategory, productDescription, productIsPackage, maxNoOfItems, featuredImage, thumbnail, additionalImages, variations, tagsData, additionalServicesData
  } = useSelector((state) => state.product);
  const dispatch = useDispatch();

  const [productTypeOptions, setProductTypeOptions] = useState([
    {
      id: 1,
      label: "Simple Product",
      value: "simple_product",
      description: "Product variations shares same stock and pricing",
    },
    {
      id: 2,
      label: "Variable Product",
      value: "variable_product",
      description: "Each product variations has its own stock and pricing",
    },
  ]);
  const { additionalServicesEnabled, ecommerceUrlEnabled } = useSelector((store) => store.configs);
  const [categories, setCategories] = useState([]);
  const additionalImagesItems = [0, 1, 2, 3];
  const [productType, setProductType] = useState(productTypeData ? productTypeData : "simple_product");
  const [tags, setTags] = useState([]);
  const [additionalServices, setAdditionalServices] = useState([]);
  const navigate = useNavigate();
  const [servicesEnabled, setServicesEnabled] = useState(additionalServicesEnabled);
  const [product, setProduct] = useState({});
  const { productId } = useParams();
  const [categorySearch, setCategorySearch] = useState('');
  const [limit, setLimit] = useState(10);
  const handleproductTypeOptionsChange = (value) => {
    setProductType(value);
  };

  const [openVariantAdd, setOpenVariantAdd] = useState(false);
  const handleOPenVariantAdd = () => {
    setOpenVariantAdd(true);
  };
  const handleCloseVariantAdd = () => {
    setOpenVariantAdd(false);
  };
  const [uploading, setUploading] = useState('');
  const [displayBars, setDisplayBars] = useState("productDetails");

  const activeClassBar = {
    height: "45px",
    backgroundColor: "#fafafa",
    width: "100%",
    color: "#0000",
    cursor: "pointer",
  };
  const inactiveClassBar = {
    height: "45px",
    backgroundColor: "#fff",
    width: "100%",
    color: "#707070",
    cursor: "pointer",
  };

  const activeClassText = {
    color: "#000",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    paddingTop: "10px",
  };
  const inactiveClassText = {
    color: "#707070",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    paddingTop: "10px",
  };

  const handleChangeBars = (value) => {
    console.log("handleChangeBars", value);
    setDisplayBars(value);
  };
  const [successShow, setSuccessShow] = useState({ state: false, message: "" })
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })

  const [variationName, setVariationName] = useState("");
  const [variationValues, setVariationValues] = useState(" ");
  const [variationNameError, setVariationNameError] = useState(false);
  const [variationNameHelperText, setVariationHelperText] = useState('');

  const [variationData, setVariationData] = useState(variations);
  const [productFeaturedImage, setProductFeaturedImage] = useState("");
  const [additionalProductImages, setAdditionalProductImages] = useState([]);
  const [selectedTags, setSelectedTags] = useState(tagsData);
  const [selectedAdditionalServices, setSelectedAdditionalServices] = useState(Array.isArray(additionalServicesData) ? additionalServicesData : []);
  const [variationId, setVariationId] = useState('');

  const handleChange = (event) => {
    const { name, checked, value } = event.target;
    if (checked) {
      const newTag = { tagId: value, tagName: name };
      console.log('selectedAdditionalServicesta', selectedTags);
      const newSelectedTags = [...selectedTags, newTag];
      setSelectedTags(newSelectedTags);
      setFormData((prevFormData) => ({ ...prevFormData, tags: newSelectedTags }));
      dispatch(setTagsData(newSelectedTags));
    } else {
      const newSelectedTags = selectedTags.filter((tag) => tag.tagId !== value);
      setSelectedTags(newSelectedTags);
      setFormData((prevFormData) => ({ ...prevFormData, tags: newSelectedTags }));
      dispatch(setTagsData(newSelectedTags));
    }
  };
  const handleChangeAdditionalServices = (event) => {
    const { name, checked, value } = event.target;
    console.log('target values', event.target.name);
    if (checked) {
      const newService = { serviceId: value, serviceName: name };
      console.log('selectedAdditionalServices', selectedAdditionalServices);
      const newSelectedServices = [...selectedAdditionalServices, newService];
      setSelectedAdditionalServices(newSelectedServices);
      setFormData((prevFormData) => ({ ...prevFormData, additionalServices: newSelectedServices }));
      dispatch(setAdditionalServicesData(newSelectedServices));
    } else {
      const newSelectedServices = selectedAdditionalServices?.filter((service) => service.serviceId !== value);
      setSelectedAdditionalServices(newSelectedServices);
      setFormData((prevFormData) => ({ ...prevFormData, additionalServices: newSelectedServices }));
      dispatch(setAdditionalServicesData(newSelectedServices));
    }
  };

  const handleChangeVariationName = (e) => {
    console.log('Variant name ', e.target.value);
    const { value } = e.target;
    if (value.trim() !== '' && value.includes(' ')) {
      setErrorShow({ state: true, message: 'Variant name cannot contain spaces!' });
      setVariationHelperText('Variant name cannot contain spaces!');
      setVariationNameError(true);
    } else {
      setVariationName(value);
    }
  }
  const handleVariations = () => {
    const checkVariationName = variationData.findIndex(
      (item) => item && item.variationName === variationName
    );
    if (checkVariationName === -1) {
      const newVariationData = [
        ...variationData,
        {
          variationName: variationName.trim(),
          variationValues: variationValues.split(","),
        },
      ];
      setVariationData(newVariationData);
      setFormData({ ...formData, variations: newVariationData });
      dispatch(setVariations(newVariationData));
      secureLocalStorage.setItem("variationData", JSON.stringify(newVariationData));
    } else {
      const updatedVariationData = variationData.map((item) => {
        if (item.variationName === variationName) {
          return { ...item, variationValues: variationValues.split(",") };
        }
        return item;
      });
      setVariationData(updatedVariationData);
      setFormData({ ...formData, variations: updatedVariationData });
      dispatch(setVariations(updatedVariationData));
      secureLocalStorage.setItem(
        "variationData",
        JSON.stringify(updatedVariationData)
      );
    }
    setVariationName("");
    setVariationValues("");
    handleCloseVariantAdd();
  };

  const receivingPricingData = (data) => {
    setFormData({ ...formData, pricing: data });
  };

  const [formData, setFormData] = useState({
    productType: productTypeData ? productTypeData : " ",
    productName: productName ? productName : " ",
    productCategory: productCategory ? productCategory : " ",
    productDescription: productDescription ? productDescription : " ",
    isPackage: productIsPackage ? productIsPackage : false,
    noOfItemsInPackage: maxNoOfItems ? maxNoOfItems : 0,
    featuredImage: featuredImage ? featuredImage : " ",
    thumbailImagePath: thumbnail ? thumbnail : '',
    additionalImages: additionalImages ? additionalImages : [],
    variations: variations ? variations : [],
    productCategoryId: "",
    pricing: [],
    tags: tagsData ? tagsData : [],
    additionalServices: additionalServicesData ? additionalServicesData : []
  });

  const handleFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === 'productCategory') {
      const filteredCategory = categories.find(item => item.categoryName === e.target.value);
      setFormData({ ...formData, productCategoryId: filteredCategory?._id, productCategory: e.target.value })
    }
  };
  const findCategoryByName = (categories, categoryName) => {
    for (const category of categories) {
      if (category.categoryName === categoryName) {
        return category;
      }

      if (category.subCategories && category.subCategories.length > 0) {
        const foundInChildren = findCategoryByName(category.subCategories, categoryName);
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }

    return null;
  };
  const handleCategorySearch = (e) => {
    const searchValue = e.target.value;
    console.log(searchValue, 'searchValue');
    const filteredCategory = findCategoryByName(categories, searchValue);

    if (filteredCategory) {
      console.log("Filtered Category:", filteredCategory);
      setFormData({
        ...formData,
        productCategoryId: filteredCategory._id,
        productCategory: searchValue
      });
    } else {
      console.log("Category not found");
    }
  };

  const [pricingObj, setpricingObj] = useState({
    pricing: {
      initialStock: "",
      runningLowLevel: "",
      normalPrice: "",
      discountedPrice: "",
      buyingPrice: ""
    }
  })

  const handlePrincingNewObject = () => {
    let newPricing = pricingObj.pricing
    newPricing = { ...newPricing, variationKey: "default" }

    formData.pricing = [{ ...newPricing }]

    setFormData({ ...formData, pricing: [newPricing] })
    let newPricingData = [newPricing]
    dispatch(setPricingData(newPricingData))
  }
  const handleVariationChange = () => {
    const defaultvariations = [{
      "variationName": "default",
      "variationValues": [
        "default"
      ]

    }]
    setFormData({ ...formData, variations: defaultvariations })
  }

  useEffect(() => {
    handlePrincingNewObject()
    if (productType === "simple_product") {
      handleVariationChange()
    }
  }, [pricingObj])

  console.log("pricingObj", pricingObj)
  const [buttonState, setButtonState] = useState(false);

  const nextButtonState = () => {
    if (displayBars === "productDetails") {
      console.log("formData", formData);
      if (
        formData.productName !== "" &&
        formData.productCategory !== "" &&
        formData.productDescription !== ""
      ) {
        setButtonState(false);
      } else {
        console.log(
          "formData =====================================>>>>>",
          formData
        );
        setButtonState(true);
      }
    } else if (displayBars === "productImage") {
      if (
        formData.featuredImage !== ""
        //&& formData.additionalImages.length !== 0
      ) {
        setButtonState(false);
      } else {
        setButtonState(true);
      }
    } else if (displayBars === "productVariations") {
      console.log("formData.variations", formData.variations);
      if (formData.variations.length === 0) {
        setButtonState(true);
      } else {
        setButtonState(false);
      }
    } else if (displayBars === "productPricing") {
      if (productType === "simple_product") {
        if (
          formData.pricing.initialStock !== "" &&
          formData.pricing.runningLowLevel !== "" &&
          formData.pricing.normalPrice !== "" &&
          formData.pricing.discountedPrice !== ""
        ) {
          setButtonState(false);
        } else {
          setButtonState(true);
        }
      } else if (productType === "variable_product") {
        if (formData.pricing.length > 0) {
          setButtonState(false);
        } else {
          setButtonState(true);
        }
      }
    }
  };
  const renderMenuItems = (items, level = 1) => {
    const menuItems = [];
    items.forEach((item) => {
      menuItems.push(
        <MenuItem disabled={item.subCategories.length > 0 ? true : false} key={item._id} value={item.categoryName} style={{ paddingLeft: level * 20, width: '98px', height: '20px', margin: '14px 21px 15px 22px', fontFamily: 'Poppins', fontSize: '16px', textAlign: 'left', color: '#707070', fontWeight: level < 2 ? 550 : 'normal', "&:hover": { backgroundColor: '#fff' } }}>
          {item.categoryName}
        </MenuItem>
      );
      if (item.subCategories && item.subCategories.length > 0) {
        const subMenuItems = renderMenuItems(item.subCategories, level + 1);
        menuItems.push(...subMenuItems);
      }
    });
    return menuItems;
  };
  const handleSubmit = async () => {
    console.log('FORMDATA====================>>>><<<>>>', formData)
    const { variations, pricing, ...updatedFormData } = formData;
    HttpRequest({
      method: "PUT",
      url: `/api/updateProduct/${variationId}`,
      body: updatedFormData,
    }).then((data) => {
      if (data.status === 202) {
        setSuccessShow({ state: true, message: "Product updated successfully!" });
        dispatch(setProductTypeData(''));
        dispatch(setProductCategory(''));
        dispatch(setProductName(''));
        dispatch(setProductIsPackage(false));
        dispatch(setMaxNoOfItems(0));
        dispatch(setVariations([]));
        dispatch(setTagsData([]));
        dispatch(setProductDescription())
        dispatch(setAdditionalServicesData([]));
        dispatch(setFeaturedImage(''));
        dispatch(setThumbnailImage(''));
        dispatch(setAdditionalImages([]));
        setTimeout(() => {
          navigate('/zed_ecommerce/products')
        }, 3000);

      } else {
        const message = data.response.message;
        setErrorShow({ state: true, message });
      }
    }).catch((error) => {
      console.log('Create product response error', error);
      setErrorShow({ state: true, message: 'Failed to update product!' })
    });
  };
  const fetchBusinessVariations = () => {
    const businessNumber = secureLocalStorage.getItem("businessId");
    HttpRequest({
      method: "GET",
      url: `/api/v1/ecommerce/get_business_variations?businessNumber=${businessNumber}`,
    }).then((data) => {
      console.log('fetch business variations response', data);
      if (data.status === 200) {
        const businessVariationData = data.response.data.productsVariations;
        let newVariationData = [];

        // Then, add data to newVariationData
        businessVariationData.forEach((variation) => {
          newVariationData.push({
            variationName: variation.variationName.trim(),
            variationValues: variation.variationValues,
          });
        });

        setVariationData(businessVariationData);
        setVariationData(businessVariationData);
        setFormData({ ...formData, variations: businessVariationData });
        dispatch(setVariations(businessVariationData));
      } else {
        console.log('Error fetching business variations');
      }
    }).catch((error) => {
      console.log('Error fetching business variations', error);
    });
  };
  const fetchBusinessTags = () => {
    const businessNumber = secureLocalStorage.getItem("businessId");
    HttpRequest({
      method: "GET",
      url: `/api/v1/ecommerce/business/getTag?businessNumber=${businessNumber}`,
    }).then((data) => {
      if (data.status === 200) {
        setTags(data.response.data);
      } else {
        console.log('Error fetching business tags');
      }
    }).catch((error) => {
      console.log('Error fetching business tags', error);
    });
  };
  const fetchAdditionalServices = () => {
    HttpRequest({
      method: "GET",
      url: `/api/v1/ecommerce/get_additional_services`,
    }).then((data) => {
      console.log('fetch additional services', data);
      if (data.status === 200) {
        console.log(' additional service fetched successfully', data.response.data)
        setAdditionalServices(data.response.data);
      } else {
        console.log('Error fetching business  additional service');
      }
    }).catch((error) => {
      console.log('Error fetching business  additional service', error);
    });
  };
  const fetchProductDetails = () => {
    const businessNumber = secureLocalStorage.getItem("businessId");
    HttpRequest({
      method: "GET",
      url: `/api/v1/ecommerce/get_product/${productId}?businessNumber=${businessNumber}`,
    }).then((data) => {
      console.log('fetch additional product details', data);
      if (data.status === 200) {
        console.log('additional service fetched successfully', data.response.data)
        const productDetails = data?.response?.data;
        setProduct(productDetails);
        getCategories();

        const filteredCategory = categories.find(item => item.categoryName === productDetails?.productCategory);
       
        setFormData({
          ...formData,
          productType: productDetails?.productType,
          productName: productDetails?.productName,
          productCategory: productDetails?.productCategory,
          isPackage: productDetails?.isPackage,
          noOfItemsInPackage: productDetails?.noOfItemsInPackage,
          productDescription: productDetails?.productDescription,
          featuredImage: productDetails?.featuredImage,
          thumbailImagePath: productDetails?.thumbailImagePath,
          additionalImages: productDetails?.additionalImages,
          variations: productDetails?.variations,
          productCategoryId: filteredCategory?._id,
          pricing: productDetails?.pricing,
          tags: productDetails?.tags,
          additionalServices: productDetails?.additionalServices
        })
        setAdditionalProductImages(productDetails?.additionalImages);
        // setAdditionalImagesItems(productDetails?.additionalImages);
        setSelectedTags(productDetails?.tags);
        setSelectedAdditionalServices(productDetails?.additionalServices);
        setProductType(productDetails?.productType);
        setVariationId(productDetails?.pricing[0]?._id)
      } else {
        console.log('Error fetching business  additional service');
      }
    }).catch((error) => {
      console.log('Error fetching business  additional service', error);
    });
  };
  useEffect(() => {
    nextButtonState();
  }, [formData, displayBars]);

  const handleNext = () => {
    console.log("handleNext", displayBars);
    if (displayBars === "productDetails") {
      setDisplayBars("productImage");
      dispatch(setProductTypeData(formData?.productType));
      dispatch(setProductName(formData?.productName));
      dispatch(setProductCategory(formData?.productCategory));
      dispatch(setProductDescription(formData?.productDescription));
      dispatch(setProductIsPackage(formData?.isPackage));
      dispatch(setMaxNoOfItems(formData?.noOfItemsInPackage));
    } else if (displayBars === "productImage") {
      setDisplayBars("tags");
    } else if (displayBars === "tags") {
      if (servicesEnabled) {
        setDisplayBars("additionalServices")
      } else {
        handleSubmit();
      }
    } else if (displayBars === "additionalServices") {
      handleSubmit();
    }
  };

  const removeVariation = (variationName, variationValue) => {
    const updatedVariationData = variationData
      .map((item) => {
        if (item.variationName === variationName) {
          const newValue = item.variationValues.filter(
            (value) => value !== variationValue
          );
          return {
            ...item,
            variationValues: newValue.length > 0 ? newValue : null,
          };
        }
        return item;
      })
      .filter((item) => item && item.variationValues !== null); // Filter out items with null variationValues

    setVariationData(updatedVariationData);
    setFormData({ ...formData, variations: updatedVariationData });
    dispatch(setVariations(updatedVariationData));
    secureLocalStorage.setItem("variationData", JSON.stringify(updatedVariationData));
  };

  console.log("formData===<<>>>>>", formData);

  const handleFeaturedImage = async (event) => {
    setUploading('5');
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      try {
        const file = URL.createObjectURL(selectedFile);
        setProductFeaturedImage(file);
        const { imageUrl, thumbnailUrl } = await generateImageUrl(selectedFile);

        dispatch(setFeaturedImage(imageUrl));
        dispatch(setThumbnailImage(thumbnailUrl));
        setFormData({ ...formData, featuredImage: imageUrl, thumbailImagePath: thumbnailUrl });

        setUploading(null);
      } catch (error) {
        console.error('Error uploading image:', error);
        setUploading(null);
      }
    } else {
      setUploading(null);
    }
  };

  const handleAdditionalImages = async (event, index) => {
    setUploading(index);
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      try {
        const fileUrl = URL.createObjectURL(selectedFile);

        const { imageUrl, thumbnailUrl } = await generateImageUrl(selectedFile);

        const updatedImages = [...additionalProductImages];
        updatedImages[index] = { id: index, image: imageUrl };

        setAdditionalProductImages(updatedImages);
        setFormData(prevFormData => ({
          ...prevFormData,
          additionalImages: updatedImages,
        }));

        setUploading(null);
      } catch (error) {
        console.error('Error uploading image:', error);
        setUploading(null);
      }
    } else {
      setUploading(null);
    }
  };

  useEffect(() => {
    additionalImagesItems.map((item, index) =>
      console.log("Testing console", additionalProductImages[index])
    );
    // Dispatch action here if needed
    dispatch(setAdditionalImages(additionalProductImages));
  }, [additionalProductImages, dispatch]);

  const getCategories = () => {
    const businessNumber = secureLocalStorage.getItem("businessId");
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/get_categories?businessNumber=${businessNumber}&categoryName=${categorySearch}&limit=${limit}`
    }).then((data) => {
      console.log('Fetch categories response', data.response.data);
      // setCategories(data.response.data);
      const options = [];
      let rawCategories = data.response.data;
      const addCategoryWithLevel = (category, level = 0) => {
        options.push({
          label: category.categoryName,
          value: category.categoryName,
          level,
          id:category?._id,
          disabled: category.subCategories.length > 0
        });
        category.subCategories.forEach(subCategory => {
          addCategoryWithLevel(subCategory, level + 1);
        });
      };
      
      const filteredCategory = rawCategories.find(item => item.categoryName === product?.productCategory);
      if(filteredCategory)
        {
          setFormData({
            ...formData,
            productCategoryId: filteredCategory?._id,
          });
        }
      rawCategories.forEach(category => addCategoryWithLevel(category));
      //   setCategories(options);
      setCategories(prevCategories => data?.response?.data?.length > 0 ? options : [...prevCategories, ...options]);
      setLimit(data?.response?.count);
    }).catch((error) => {
      console.log('Error fetching categories', error);
    });
  }
  useEffect(() => {
    fetchBusinessVariations();
    fetchBusinessTags();
    fetchAdditionalServices();
    fetchProductDetails();
  }, [])
  useEffect(() => {
    getCategories();
  }, [categorySearch]);
  return (
    <div>
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      <div style={{ marginTop: "4%" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
          <Box sx={{ flexDirection: "column" }}>
            <Box>
              <Typography variant="h4" sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600", }}>
                {" "}
                <ArrowBackIos className="" onClick={() => navigate(-1)} /> Edit Product
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal", }}   >
                Update your product details.
              </Typography>
            </Box>
          </Box>
          <Box style={{ marginRight: "15px" }}>
            <Button variant="contained" sx={{ backgroundColor: "#f5f6f7", color: "#6e7074", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", width: "125px", height: "40px", fontWeight: "600", opacity: "0.99", "&:hover": { backgroundColor: "#f5f6f7", color: "#6e7074" } }} onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}></Box>
        <Grid item width={"70%"} style={{ margin: "15px 0px 0px 0px" }}>
          <Typography sx={{ margin: '20px 709px 15px 2px', fontFamily: 'Poppins', fontSize: '16px', textAlign: 'left', color: '#000', fontWeight: 600 }}>What type of product are you adding?</Typography>
          <TextField style={{ width: "50%" }} value={productType} id="outlined-select-meals" select label="Product Type" helperText="" name="paymentType" onChange={(event) => setProductType(event.target.value)}>
            {productTypeOptions?.map((prod, index) => (
              <div key={prod.id} value={prod.value} style={{ padding: "10px 10px 10.5px" }} onClick={() => handleproductTypeOptionsChange(prod.value)}>
                <div onClick={() => handleproductTypeOptionsChange(prod.value)}>
                  <Typography variant="body1" style={{ color: "#000", fontSize: "17px", fontWeight: "600" }} onClick={() => handleproductTypeOptionsChange(prod.value)}>
                    {prod.label}
                  </Typography>
                </div>
                <div style={{ color: "#707070", paddingBottom: "10px" }} onClick={() => handleproductTypeOptionsChange(prod.value)}>
                  {prod.description}
                </div>
                <Divider />
              </div>
            ))}
          </TextField>
        </Grid>
        {productType && <Box>
          <Typography variant="h4" sx={{ color: "#000", fontSize: "18px", fontStyle: "normal", fontWeight: "600", marginTop: "20px" }}>
            {productType === "simple_product" ? "Simple Product Details" : "Variable Product Details"}
          </Typography>
        </Box>
        }
        {productType && <Box sx={{ display: "flex", justifyContent: "center", maxWidth: displayBars === "productImage" ? "980px" : "800px", marginTop: "20px" }}>
          <Box style={{ width: "30%", borderTop: "1px solid #e0e0e0", height: "100%" }}>
            <Box style={displayBars === "productDetails" ? activeClassBar : inactiveClassBar} onClick={() => handleChangeBars("productDetails")}>
              <Typography variant="h4" sx={displayBars === "productDetails" ? activeClassText : inactiveClassText}>
                Product Details
              </Typography>
            </Box>
            <Divider />
            <Box style={displayBars === "productImage" ? activeClassBar : inactiveClassBar} sx={{ paddingTop: "10px" }} onClick={() => handleChangeBars("productImage")}>
              <Typography variant="h4" sx={displayBars === "productImage" ? activeClassText : inactiveClassText}>
                Images
              </Typography>
            </Box>
            <Divider />
            {/* <Box style={displayBars === "productVariations" ? activeClassBar : inactiveClassBar} sx={{ paddingTop: "10px", display: productType === 'simple_product' ? 'none' : '' }} onClick={() => handleChangeBars("productVariations")}>
              <Typography variant="h4" sx={displayBars === "productVariations" ? activeClassText : inactiveClassText}>
                Variations
              </Typography>
            </Box>
            <Divider /> */}
            {/* <Box style={displayBars === "productPricing" ? activeClassBar : inactiveClassBar} sx={{ paddingTop: "10px" }} onClick={() => handleChangeBars("productPricing")}>
              <Typography variant="h4" sx={displayBars === "productPricing" ? activeClassText : inactiveClassText}>
                Pricing & Stock
              </Typography>
            </Box> */}
            <Divider />
            <Box style={displayBars === "tags" ? activeClassBar : inactiveClassBar} sx={{ paddingTop: "10px" }} onClick={() => handleChangeBars("tags")}>
              <Typography variant="h4" sx={displayBars === "tags" ? activeClassText : inactiveClassText}>
                Tags
              </Typography>
            </Box>
            <Divider />
            {servicesEnabled === true && <Box style={displayBars === "additionalServices" ? activeClassBar : inactiveClassBar} sx={{ paddingTop: "10px" }} onClick={() => handleChangeBars("additionalServices")}>
              <Typography variant="h4" sx={displayBars === "additionalServices" ? activeClassText : inactiveClassText}>
                Additional Services
              </Typography>
            </Box>}
            <Divider />
          </Box>
          <Box style={{ width: "70%", borderTop: "1px solid #e0e0e0", borderRight: "1px solid #e0e0e0", height: "100%", borderBottom: "1px solid #e0e0e0", borderLeft: "1px solid #e0e0e0", paddingBottom: "40px" }}>
            {displayBars === "productDetails" ? (
              <Box sx={{ padding: "0px 20px" }}>
                <Typography variant="h4" sx={{ color: "#000", fontSize: "16px", fontStyle: "normal", fontWeight: "600", marginTop: "20px" }}>
                  Product Details
                </Typography>
                <Box sx={{ marginTop: "25px" }}>
                  <TextField label="Product Name" value={formData?.productName} name="productName" onChange={handleFormData} style={{ width: "100%" }} />
                </Box>
                <Box sx={{ marginTop: "25px" }}>
    
                  <Autocomplete
                    style={{ width: "100%" }}
                    id="outlined-select-meals"
                    options={categories}
                    getOptionDisabled={(option) => option.disabled}
                    getOptionLabel={(option) => option.label}
                    value={formData?.productCategory ? { label: formData.productCategory } : null}
                    onInputChange={(event, newInputValue) => {
                      setCategorySearch(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setFormData(prevFormData => ({
                          ...prevFormData,
                          productCategory: newValue.label,
                          productCategoryId: newValue?.id
                        }));
                      } else {
                        setFormData(prevFormData => ({
                          ...prevFormData,
                          productCategory: '',
                          productCategoryId: ''
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Product Category"
                        helperText=""
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        {...props}
                        sx={{ ml: option.level * 2 }}
                      >
                        {option.label}
                      </Box>
                    )}
                  />
                  <Box sx={{ marginTop: "15px" }}>
                    <TextField label="Product Description" value={formData?.productDescription} name="productDescription" onChange={handleFormData} style={{ width: "100%" }} multiline minRows={3} />
                  </Box>
                  <Box sx={{ marginTop: "25px", display: 'flex' }}>
                    <Checkbox style={{ color: "#b4bcc4", margin: '10px 6px 37px 0px', }} checked={formData?.isPackage} value={formData?.isPackage} onChange={(e) => {
                      setFormData({ ...formData, isPackage: !formData?.isPackage });
                    }} />
                    <Typography sx={{ margin: '20px 6px 37px 12px', fontSize: '16px', color: '#707070', fontFamily: 'Poppins' }}>Is a Package</Typography>
                  </Box>
                  {formData?.isPackage && <Box >
                    <TextField type="number" label="No. of Items per Package" value={formData?.noOfItemsInPackage} name="noOfItemsInPackage" onChange={handleFormData} style={{ width: "100%" }} />
                  </Box>}
                </Box>
              </Box>
            ) : displayBars === "productImage" ? (
              <Box sx={{ padding: "0px 20px", minHeight: "250px" }}>
                <Box sx={{ display: "flex", marginTop: "20px" }}>
                  <Typography sx={{ width: "114px", height: "20px", margin: "0 226px 10px 0", fontFamily: "Poppins", fontSize: "14px", fontWeight: 600, color: "#000", textAlign: "left", lineHeight: "normal", fontStyle: "normal", letterSpacing: "normal", fontStretch: "normal" }}>
                    Featured Image
                  </Typography>
                  <Typography sx={{ width: "152px", height: "20px", margin: "0 81px 10px 22px", fontFamily: "Poppins", fontSize: "14px", fontWeight: 600, color: "#000", textAlign: "left", lineHeight: "normal", fontStyle: "normal", letterSpacing: "normal", fontStretch: "normal" }}>
                    Upload Other Images
                  </Typography>
                </Box>
                <Grid container>
                  <Grid item lg={6}>
                    <label htmlFor="featured-image">
                      <input style={{ display: "none" }} id="featured-image" name="featured-image" type="file" onChange={handleFeaturedImage} accept=".png, .jpeg, .jpg" />
                      <Box sx={{ width: "320px", height: "320px", borderRadius: "12px", border: "solid 1px #e0e0e0", backgroundColor: "#fff" }}>
                        <Box sx={{ width: "300px", height: "300px", borderRadius: "5px", backgroundColor: "#f5f5f5", margin: "10px", textAlign: "center", alignContent: "center" }}>
                          {featuredImage || product?.featuredImage ? <>
                            <img src={formData?.featuredImage} alt="" style={{ width: "100%", height: "100%", maxHeight: '250px', maxWidth: '250px', objectFit: "contain", alignItems: "center", textAlign: "center" }} />
                          </> :
                            <>
                              <img src={imagePlaceholder} alt="" style={{ width: "60.6px", height: "60.6px", objectFit: "contain", alignItems: "center", textAlign: "center" }} />
                              <Typography sx={{ width: "196px", height: "17px", margin: "21.4px 0 9px 50px", fontFamily: "Poppins", fontSize: "14px", color: "#000", fontWeight: 500 }}>
                                Click to upload or drag and drop
                              </Typography>
                              <Typography sx={{ width: "196px", height: "17px", margin: "11.4px 0 2px 50px", fontFamily: "Poppins", fontSize: "12px", color: "#707070", fontWeight: "normal" }}>
                                PNG, JPG or WebP
                              </Typography>
                              <Typography sx={{ width: "196px", height: "17px", margin: "4px 0 9px 50px", fontFamily: "Poppins", fontSize: "12px", color: "#707070", fontWeight: "normal" }}>
                                Recommended size (1000px x 1000px)
                              </Typography>
                            </>
                          }
                          {uploading === '5' &&
                            <Box>
                              <LinearProgress sx={{ marginTop: '0%' }} /> <Typography>Uploading File... </Typography>
                            </Box>
                          }
                        </Box>
                      </Box>
                    </label>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography sx={{ width: "225px", height: "35px", margin: "10px 10px 25px 20px", fontFamily: "Poppins", fontSize: "14px", fontWeight: "normal", fontStretch: "normal", color: "#707070", textAlign: "left", letterSpacing: "normal", lineHeight: "normal" }}>
                      Recommended size (1000px x 1000px) PNG, JPG or JEPG
                    </Typography>
                    <Grid container spacing={2}>
                      {additionalImagesItems.map((item, index) => (
                        <Grid key={index} item sx={{ display: "flex" }} lg={6}>
                          <label htmlFor={`other-images-${index}`}>
                            <input style={{ display: "none" }} id={`other-images-${index}`} name={`other-images-${index}`} type="file" onChange={(event) => handleAdditionalImages(event, index)} />
                            <Box sx={{ width: "110px", height: "auto", margin: "5px 28px 10px 20px", padding: "11px", borderRadius: "8px", border: "solid 1px #e0e0e0", backgroundColor: "#fff" }}>
                              <Box sx={{ width: "70px", height: "88px", padding: "7.3px 20px 40px 20px", borderRadius: "5px", backgroundColor: "#f5f5f5", alignContent: "center", textAlign: "center" }}>
                                {!additionalProductImages.length > 0 && <Add sx={{ width: "26.8px", height: "26.8px", margin: "1px 0 0px 46.1px", color: "#E0E0E0" }} />}
                                <img src={additionalProductImages.length > 0 && additionalProductImages[index] ? additionalProductImages[index].image : imagePlaceholder} alt="" style={{ width: additionalProductImages.length > 0 && additionalProductImages[index] ? '100%' : '48.2px', height: additionalProductImages.length > 0 && additionalProductImages[index] ? '100%' : '48.2px', objectFit: "contain", margin: "15px 15px 0px 0" }} />
                                {uploading === index &&
                                  <Box>
                                    <LinearProgress sx={{ marginTop: '0%' }} /> <Typography>Uploading... </Typography>
                                  </Box>
                                }
                              </Box>
                            </Box>
                          </label>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            ) : displayBars === "productVariations" ? (
              <Box sx={{ padding: "0px 20px", minHeight: "250px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h4" sx={{ color: "#000", fontSize: "16px", fontStyle: "normal", fontWeight: "600", marginTop: "20px" }}>
                    Product Variations
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }} onClick={handleOPenVariantAdd}>
                    <Typography variant="h4" sx={{ color: "#032541", fontSize: "14px", fontStyle: "normal", fontWeight: "600" }}>
                      <AddCircleOutlineIcon />
                    </Typography>
                    <Typography variant="h4" sx={{ color: "#032541", fontSize: "14px", fontStyle: "normal", fontWeight: "600", marginTop: "5px" }}>
                      Add Variations
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ marginTop: "25px" }}>
                  <Typography variant="h6" sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "600" }}>
                    Create different variations such as size, color, etc.
                  </Typography>
                  {variations.length > 0 && (
                    <Box sx={{ marginTop: "25px", display: "flex" }}>
                      <Box>
                        {variations.map((item, index) => (
                          <>
                            <Box key={index} sx={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                              <span style={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography variant="h6" sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "600", paddingTop: "10px" }}>
                                  Name:
                                </Typography>
                                <Typography variant="h6" sx={{ color: "#032541", fontSize: "14px", fontStyle: "normal", fontWeight: "700", paddingTop: "10px" }}>
                                  {item?.variationName}{" "}
                                </Typography>
                              </span>
                            </Box>
                            <Box sx={{ display: "flex", marginTop: "20px", flexWrap: 'wrap' }}>
                              <Typography variant="h6" sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "600", paddingTop: "10px" }}>
                                Values:
                              </Typography>
                              {item?.variationValues?.map((value, index) => (
                                <Box key={index} sx={{ minwidth: "57px", height: "34px", backgroundColor: "#efefef", borderRadius: "5px", padding: "5px 10px 5px 10px", margin: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  <Typography variant="h6" sx={{ color: "#707070", fontSize: "16px", fontStyle: "normal", fontWeight: "600" }}>
                                    <CloseIcon sx={{ fontSize: "14px", color: "#707070", cursor: "pointer" }} onClick={() => removeVariation(item?.variationName, value)} />
                                    {value}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                          </>
                        ))}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            ) : displayBars === "productPricing" ? (
              <Box sx={{ padding: "0px 20px", minHeight: "250px" }}>
                <Typography variant="h4" sx={{ color: "#000", fontSize: "16px", fontStyle: "normal", fontWeight: "600", marginTop: "20px" }}>
                  Pricing & Stock
                </Typography>
                <Typography variant="h6" sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "600", marginTop: "20px" }}>
                  {productType === "simple_product" ? "Set the pricing and stock of the product" : "Set the price and stock for each product variation."}
                </Typography>
                {productType === "simple_product" ? (
                  <>
                    <Box sx={{ marginTop: "25px", display: "flex", justifyContent: "space-between" }}>
                      <TextField label="Initial Stock" value={pricingObj.pricing.initialStock} name="initialStock" onChange={(e) => setpricingObj({ ...pricingObj, pricing: { ...pricingObj.pricing, initialStock: e.target.value } })} style={{ width: "100%", marginRight: "10px" }} />
                      <TextField label="Running Low Level" value={pricingObj.pricing.runningLowLevel} name="runningLowLevel" onChange={(e) => setpricingObj({ ...pricingObj, pricing: { ...pricingObj.pricing, runningLowLevel: e.target.value } })} style={{ width: "100%" }} />
                    </Box>
                    <Box sx={{ marginTop: "25px", display: "flex", justifyContent: "space-between" }}>
                      <TextField label="Normal Price" value={pricingObj.pricing.normalPrice} name="normalPrice" onChange={(e) => setpricingObj({ ...pricingObj, pricing: { ...pricingObj.pricing, normalPrice: e.target.value } })} style={{ width: "100%", marginRight: "10px" }} />
                      <TextField label="Discounted  Price" value={pricingObj.pricing.discountedPrice} name="discountedPrice" onChange={(e) => setpricingObj({ ...pricingObj, pricing: { ...pricingObj.pricing, discountedPrice: e.target.value } })} style={{ width: "100%" }} />
                    </Box >
                    <Box sx={{ marginTop: "20px" }}>
                      <TextField label="Buy Price" value={pricingObj.pricing.buyingPrice} name="buyingPrice" onChange={(e) => setpricingObj({ ...pricingObj, pricing: { ...pricingObj.pricing, buyingPrice: e.target.value } })} style={{ width: '100%', marginRight: "10px" }} />

                    </Box>
                  </>
                ) : productType === "variable_product" ? (
                  variationData.length === 0 ? (
                    <Box sx={{ marginTop: "25px" }}>
                      <Typography variant="h6" sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "600" }}>
                        Please add variations to set pricing and stock
                      </Typography>
                    </Box>
                  ) : (
                    <PricingComponent variationData={variationData} receivingPricingData={receivingPricingData} />
                  )
                ) : null}
              </Box>
            ) : displayBars === "tags" ? (
              <Box sx={{ padding: "16px 14.5px 7px 17px", minHeight: "250px", margin: '19px 25px 25px 25px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ flexDirection: 'column' }}>
                    <Typography sx={{ margin: '0 200px 10px 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 600, color: '#000', textAlign: 'left' }}>Tags</Typography>
                    <Typography sx={{ margin: '10px 82px 10px 0', fontFamily: 'Poppins', fontSize: '14px', textAlign: 'left', color: '#707070', textWrap: 'nowrap' }}>Select tags to this product</Typography>
                  </Box>
                  <Box sx={{ display: 'flex' }} onClick={() => { navigate('/zed_ecommerce/tags') }}>
                    <AddCircleOutline sx={{ width: '18.6px', height: '18.6px', color: '#032541' }} />
                    <Typography sx={{ margin: '0 0 0 6px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, color: '#032541', textWrap: 'nowrap' }}>Add Tags</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {
                    tags?.map((tag) => (
                      <FormControlLabel key={tag?._id} control={<Checkbox checked={selectedTags?.some(selectedTag => selectedTag?.tagId === tag?._id)} value={tag?._id} onChange={handleChange} inputProps={{ 'aria-label': 'controlled', backgroundColor: '#032541', textWrap: 'nowrap' }} />} name={tag?.tagName} label={tag?.tagName} />
                    )
                    )}
                </Box>
              </Box>
            ) : displayBars === "additionalServices" ? (
              <Box sx={{ padding: "16px 14.5px 7px 17px", minHeight: "250px", margin: '19px 25px 25px 25px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ flexDirection: 'column' }}>
                    <Typography sx={{ margin: '0 200px 10px 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 600, color: '#000', textAlign: 'left', textWrap: 'nowrap' }}>Additional Services</Typography>
                    <Typography sx={{ margin: '10px 82px 10px 0', fontFamily: 'Poppins', fontSize: '14px', textAlign: 'left', color: '#707070', textWrap: 'nowrap' }}>Select additional services to this product</Typography>
                  </Box>
                  <Box sx={{ display: 'flex' }} onClick={() => { navigate('/zed_ecommerce/additionalServices') }}>
                    <AddCircleOutline sx={{ width: '18.6px', height: '18.6px', color: '#032541' }} />
                    <Typography sx={{ margin: '0 0 0 6px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, color: '#032541', textWrap: 'nowrap' }}>Add Services</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {
                    additionalServices?.map((service) => (
                      <Box sx={{}}>
                        <Accordion sx={{ boxShadow: 'none', width: '100%' }}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ width: '100%' }} />} aria-controls="panel1-content" id="panel1-header">
                            <FormControlLabel key={service._id} control={<Checkbox checked={selectedAdditionalServices?.some(selectedService => selectedService.serviceId === service._id)} value={service._id} onChange={handleChangeAdditionalServices} inputProps={{ 'aria-label': 'controlled', backgroundColor: '#032541', flexDirection: 'column' }} />} name={service.serviceName} label={service.serviceName} />
                          </AccordionSummary>
                          {service?.items?.map((serviceItem) => (
                            <AccordionDetails sx={{ display: 'flex', width: '100%', }}>
                              <Typography>{serviceItem?.itemName}</Typography>
                              <Typography>+ {serviceItem?.itemPrice}</Typography>
                            </AccordionDetails>
                          ))}
                        </Accordion>
                      </Box>
                    )
                    )}
                </Box>
              </Box>
            ) : (
              <Box sx={{ padding: "0px 20px", minHeight: "250px" }}>
                Product Details
              </Box>
            )}
          </Box>
        </Box>
        }
        {productType && <Box style={{ width: "800px", height: "390px", display: "flex", justifyContent: "flex-end" }}>
          <Button disabled={buttonState} variant="contained" sx={{ backgroundColor: !buttonState ? "#032541" : "#707070", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", width: "125px", height: "40px", marginTop: "20px", "&:hover": { backgroundColor: !buttonState ? "#032541" : "#707070" } }} onClick={handleNext}>
            Next
          </Button>
        </Box>
        }
      </div>
      {/* Modals */}
      <Modal open={openVariantAdd} onClose={handleCloseVariantAdd} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
        <Box sx={AddProductsmodal}>
          <Box sx={{ padding: "10px" }}>
            <Box>
              <Typography variant="h4" sx={{ color: "#032541", fontSize: "20px", fontStyle: "normal", fontWeight: "600" }}>
                Add Variations
              </Typography>
            </Box>
            <Box sx={{ marginTop: "20px" }}>
              <CustomInputLabel label={"Variation Name"} name={"variationName"} required={true} />
              <Typography variant="h5" sx={{ color: "#707070", fontSize: "12px", fontStyle: "normal", fontWeight: "600" }}>
                Name is the attribute of the variation e.g Size
              </Typography>
              <TextField helperText={variationNameHelperText} error={variationNameError} label="Variation Name" value={variationName} name="variationName" onChange={handleChangeVariationName} style={{ width: "100%" }} />
            </Box>
            <Box sx={{ marginTop: "20px" }}>
              <CustomInputLabel label={"variationValues"} name={"variationValues"} required={true} />
              <Typography variant="h5" sx={{ color: "#707070", fontSize: "12px", fontStyle: "normal", fontWeight: "600" }}>
                Values are different attributes in the variation e.g Medium
              </Typography>
              <TextField label="variation Values" value={variationValues} name="variationValues" onChange={(e) => setVariationValues(e.target.value)} style={{ width: "100%" }} />
              <Box sx={{ marginTop: "5px", display: "flex", justifyContent: "flex-end" }}>
                <Typography variant="h5" sx={{ color: "#707070", fontSize: "12px", fontStyle: "normal", fontWeight: "600" }}>
                  Separate multiple values with a comma “,” e.g Medium, Large
                </Typography>
              </Box>
            </Box>
            <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" sx={{ backgroundColor: "#f5f6f7", color: "#6e7074", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", width: "125px", height: "40px", marginRight: "10px", "&:hover": { backgroundColor: "#f5f6f7" } }} onClick={handleCloseVariantAdd}>
                Cancel
              </Button>
              <Button variant="contained" sx={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", width: "125px", height: "40px", "&:hover": { backgroundColor: "#032541" } }} onClick={handleVariations}>
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default EditProduct;
