import { AppBar, Toolbar, Typography, Box, Button, Grid } from "@mui/material";
import zedLogo from "../../images/dashlogo.png";
import { makeStyles } from "@mui/styles";
import { Link, useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { useState } from "react";
import SidebarList from "./sidebarList";
import profileIcon from "../../images/profile.svg";
import notificationsIcon from "../../images/notification.svg";
import logoutIcon from "../../images/logout.svg";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../features/userSlice";
import secureLocalStorage from "react-secure-storage";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        fontFamily: "Poppins",
    },
    content: {
        flexGrow: 1,
        padding: '24px',
    },
    palette: {
        mode: 'dark',
        primary: {
          main: '#1976d2',
        },
      },
}));

const drawerWidth = 270;

const openedMixin = (theme) => ({
    width: drawerWidth,
    // background: `url(${cardBG})`,
    backgroundSize: 'cover',
    backgroundPosition: 'cover',
    backgroundRepeat: "no-repeat",
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    // background: `url(${cardBG})`,
    backgroundSize: 'cover',
    backgroundPosition: 'cover',
    backgroundRepeat: "no-repeat",
    // boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
    // backgroundSize: "100% 100%",
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "left",
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export default function AdminSideBar({ children }) {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const location = useLocation();
    const dispatch = useDispatch();
    const zedUrl = process.env.REACT_APP_ZED_URL; 
    const {businessName} =  useSelector((store) => store.configs);
    const handleLogout = () => {
        secureLocalStorage.clear();
        dispatch(logoutUser());
        window.location.href = zedUrl;
    }
    return (
        <div className={classes.root} style={{ backgroundColor: '#fff' }}>
            <AppBar component="nav" sx={{ width: '85%', boxShadow: 'none', height: '10px', margin: '0 0 100px 220px', padding: '0px 34px 10px 15px',backgroundColor:'#fff' }}>
                <Toolbar sx={{ margin: '10px 1px 20px 0', height: '3px', padding: '10px 0 1px', backgroundColor: '#fff' }}>
                    <Grid sx={{ flexGrow: 1, display: 'flex' }}>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ display: { xs: 'none', sm: 'block' }, color: '#dc3545', fontFamily: 'Poppins', fontSize: 16, fontWeight: 600, margin: '9px 20px 5px 0',fontStretch:'normal',fontStyle:'normal',lineHeight:'normal',letterSpacing:'normal',textAlign:'left'}}
                        >
                            {businessName}
                        </Typography>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ display: { xs: 'none', sm: 'block' }, color: '#000', fontFamily: 'Poppins', fontSize: 14, fontWeight: 550, margin: '11px 74px 6px 0px',fontStretch:'normal',fontStyle:'normal',lineHeight:'normal',letterSpacing:'normal',textAlign:'left' }}
                        >
                            eCommerce Admin
                        </Typography>
                    </Grid>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <img src={profileIcon} alt="" style={{ objectFit: 'contain',margin:'0 17px 0 17px'}} />
                        <img src={notificationsIcon} alt="logo" style={{ objectFit: "contain",margin:'0 17px' }} />
                        <img onClick={()=>{handleLogout()}} src={logoutIcon} alt="logo" style={{ objectFit: "contain",margin:'0 0 0 17px',backgroundColor:'#fff' }} />
                    </Box>
                </Toolbar>
            </AppBar>
          
            <Drawer position={"fixed"} variant="permanent" open={open} sx={{ backgroundColor: '#032541' }} color="primary">
                <DrawerHeader style={{ justifyContent: "flex-start", alignItems: "flex-start", backgroundColor: '#032541' }}>
                    <Link to={"/zed_ecommerce/dashboard"} style={{ marginLeft: 10, marginTop: 10 }}>
                        <img src={zedLogo} alt="logo" style={{ objectFit: "contain" }} />
                    </Link>
                </DrawerHeader>
                <SidebarList open={open} sx={{ backgroundColor: '#032541' }} />
            </Drawer>
            <main className={classes.content}  >
                {children}
            </main>
        </div>
    )

}