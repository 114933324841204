import React, { useState, useEffect } from 'react';
import { Box, TextField, InputAdornment, Button, IconButton, Typography, Paper, Popover, Stack, Grid, ListItem, ListItemButton, ListItemText, List, Divider, Drawer, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Person2Icon from '@mui/icons-material/Person2';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HttpRequest } from '../HttpRequest';
import logoImage from '../../images/eastwestLogo.svg';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import secureLocalStorage from 'react-secure-storage';

const drawerWidth = 240;
const navItems = [{
  name: 'Home',
  url: '/',
},
{
  name: 'Products',
  url: '/products',
},
{
  name: 'Contact Us',
  url: '/contacts',
},
{
  name: 'Blogs',
  url: '/blogs',
},
{
  name: 'FAQs',
  url: '/faqs',
}];

const SearchNavBar = (props) => {
  const stateCart = useSelector((state) => state.cart);
  let cart = stateCart.cart;
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  let { isLoggedIn } = useSelector((store) => store?.user);
  let firstName = secureLocalStorage.getItem('username');
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleProfileRedirect = () => {
    if (isLoggedIn) {
      navigate('/zed-ecommerce/customer-profile');
    } else {
      navigate('/login');
    }
  }
  const getCategories = () => {
    setLoading(true);
    let businessNumber = secureLocalStorage.getItem("businessId");
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/get_categories?businessNumber=${businessNumber}`
    }).then(async (data) => {
      console.log('Fetch categories response', data.response.data);
      const filteredCategories = data.response.data.filter(category => category.subCategories.length > 0);
      console.log('filtered categories', filteredCategories);
      let subcategories = [];
      filteredCategories.forEach((item) => {
        subcategories = subcategories.concat(item.subCategories);
      });
      setCategories(subcategories);
    }).catch((error) => {
      console.log('Error fetching products', error);
    }).finally(() => {
      setLoading(false);
    });
  };
  
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', justifyContent: 'center' }}>
      <Grid container spacing={2} sx={{ alignItems: 'center' }}>
        <Grid item xs={6} sx={{ justifyContent: 'center' }}>
          <Box sx={{ maxWidth: '99.1px', ml: '10%',cursor:'pointer' }} onClick={()=>navigate('/')}>
            <img src={logoImage} alt="logo" style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ display: { sm: 'block' } }}>
            <CloseIcon size='large' sx={{ width: '100%', objectFit: 'contain' }} />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <Typography variant="h6" sx={{ ml: 2, textAlign: 'left', fontWeight: 500 }}>
        Menu
      </Typography>
      <List>
        {navItems?.map((item) => (
          <ListItem onClick={() => navigate(item.url)} key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: 'left' }}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Typography variant="h6" sx={{ ml: 2, textAlign: 'left', fontWeight: 500 }}>
        Categories
      </Typography>
      <List>
        {categories?.map((item) => (
          <ListItem sx={{cursor:'pointer'}}  onClick={() => { handleCategoryClick(item?.categoryName)}} key={item?._id} disablePadding>
            <ListItemButton sx={{ textAlign: 'left' }}>
              <ListItemText primary={item?.categoryName} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );
  const SearchBarMobile = () => {
    return (
      <Grid container item xs={12} sm={12} md={6} lg={6} xl={6} spacing={2} sx={{ alignItems: 'center', justifyContent: 'center', display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', lg: 'none' }, width: '100%' }}>
        <Box >
          <TextField
            name="search"
            value={searchQuery}
            onInput={(e) => { setSearchQuery(e.target.value); setIsFocused(true) }}
            placeholder="Search …"
            variant="outlined"
            margin="dense"
            autoFocus={isFocused}
            // onBlur={setIsFocused(false)}

            sx={{ width:'100%', maxWidth: {xs:'100%',sm:'712px'},ml:'1%',mr:'1%',pr:'10%' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    edge="end"
                    style={{ width: '100%' }}
                    onClick={() => { handleSearchClick(searchQuery); }}
                  >
                    <SearchIcon sx={{ color: '#02b3ad' }} />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <Stack
            sx={{
              position: 'absolute',
              zIndex: 10,
              maxWidth: {xs:'330px',sm:'700px'},
              width: '100%'
            }}
          >
            <Paper>
              {searchResult.map((item) => (
                <Box onClick={() => { handleSearchClick(item.productName); }} key={item.id}
                  sx={{
                    textAlign: 'left',
                    p: 1,
                  }}
                >
                  <Typography>{item.productName}</Typography>
                </Box>
              ))}
            </Paper>
          </Stack>
        </Box>
      </Grid>
    );
  }

  const handleSearchClick = (query) => {
    setSearchResult([]);
    //handleDrawerToggle();
    navigate(`/products?q=${query}`)
  }
  const handleCategoryClick = (query) => {
    setSearchResult([]);
    handleDrawerToggle();
    navigate(`/products?categoryName=${query}`)
  }
  const fetchProducts = async () => {
    const businessNumber = secureLocalStorage.getItem('businessId');
    try {
      const data = await HttpRequest({
        method: 'GET',
        url: `/api/v1/ecommerce/get_products?businessNumber=${businessNumber}&search=${searchQuery}`,
      });
      setSearchResult(data.response.data);
      setOpen(true);
    } catch (error) {
      console.log('Error fetching products', error);
    }
  };

  useEffect(() => {
    if (searchQuery.trim() !== '') {
      fetchProducts();
    } else {
      setSearchResult([]);
    }
  }, [searchQuery]);
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Grid container spacing={2} sx={{ display: 'flex', m: '1% 0 1% 0', alignItems: 'center', justifyContent: 'space-between',width:'100%' }}>
      <Grid item xs={6} sm={4} md={2} lg={2} xl={2} sx={{ flexDirection: { sm: 'column', xs: 'column' } }}>
        <Box sx={{ maxWidth: '99.1px',cursor:"pointer" }} onClick={()=>navigate('/')}>
          <img src={logoImage} alt="logo" style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
        </Box>
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
        <Box>
          <OutlinedInput
            name="search"
            value={searchQuery}
            onInput={(e) => setSearchQuery(e.target.value)}
            placeholder="What do you need?…"
            variant="outlined"
            margin="dense"
            autoFocus={isFocused}
            onFocus={handleFocus}
            fullWidth
            sx={{paddingRight:'0px',fontFamily:'Poppins',fontSize:'14px',color:'#707070',fontWeight:'normal'}}
            endAdornment={(
              <InputAdornment position="end">
                <Button
                  edge="end"
                  style={{ backgroundColor: '#e0f4f3', height: 'auto', padding: '14px 16px 14px 15px', width: '51px' }}
                  onClick={() => { handleSearchClick(searchQuery) }}
                >
                  <SearchIcon sx={{ color: '#02b3ad' }} />
                </Button>
              </InputAdornment>
            )}

          />
          <Stack
            sx={{
              position: 'absolute',
              zIndex: 10,
              width: '650px'
            }}
          >
            <Paper>
              {searchResult.map((item) => (
                <Box onClick={() => { handleSearchClick(item.productName) }} key={item.id}
                  sx={{
                    textAlign: 'left',
                    p: 2,
                  }}
                >
                  <Typography>{item.productName}</Typography>
                </Box>
              ))}
            </Paper>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
        <Box onClick={handleProfileRedirect} sx={{cursor:'pointer', display: 'flex', width: '100%', maxWidth: '130px', height: '100%', maxHeight: '48px', borderRadius: '4px', border: 'solid 1px #e0f4f3', justifyContent: 'center', alignItems: 'center' }}>
          <IconButton size="medium">
            <Person2Icon sx={{ fontSize: '1.7rem', color: '#18AFAA','&:hover':{backgroundColor:'#fff'} }} />
          </IconButton>
          <Typography sx={{ fontSize: '14px', fontWeight: 'normal', textAlign: 'left', color: '#02b3ad', display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>{isLoggedIn ? `Hi , ${firstName}` : 'Account'}</Typography>
        </Box>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
        <Box onClick={() => navigate('/cart')} sx={{ display: 'flex', width: '100%', maxWidth: '130px', height: '100%', maxHeight: '48px', borderRadius: '4px', border: 'solid 1px #e0f4f3', justifyContent: 'center', alignItems: 'center' }}>
          <IconButton size="large" sx={{ '& .badge-container': { position: 'absolute', top: '5px', right: '0px', width: '24px', height: '24px', borderRadius: '50%', backgroundColor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', fontWeight: 'bold', fontSize: '12px', border: 'solid 1px #02b3ad' } }}>
            <ShoppingCartIcon sx={{ fontSize: '1.7rem', color: '#18AFAA' }} />
            <div className="badge-container">
              <Typography component="sup" style={{ color: '#02b3ad', fontWeight: 'bold', padding: '10px 0px', fontSize: '14px' }}>{cart?.length}</Typography>
            </div>
          </IconButton>
          <Typography sx={{ fontSize: '14px', fontWeight: 500, textAlign: 'left', color: '#02b3ad', display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>Cart</Typography>
        </Box>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }}>
        <IconButton size="large" color="inherit" aria-label="menu" sx={{ width: '100%', maxWidth: '130px', height: '100%', maxHeight: '48px', borderRadius: '4px', justifyContent: 'center', alignItems: 'center' }} onClick={handleDrawerToggle}>
          <MenuIcon sx={{ color: '#18AFAA' }} />
          <nav>
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
            >
              {drawer}
            </Drawer>
          </nav>
        </IconButton>
      </Grid>
      <SearchBarMobile />
    </Grid>
  );
};

export default SearchNavBar;
