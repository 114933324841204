import React, { useEffect, useRef, useState } from 'react';
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import ProductItem from './productItem';
import { useNavigate } from 'react-router-dom';
import { HttpRequest } from '../HttpRequest';
import imagePlaceholder from '../../images/img-holder-icn.svg';
import Carousel from 'react-multi-carousel';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Link } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';
import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import Slider from 'react-slick';
import CurrencyFormatter from '../common/currencyFormatter';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const productsByCategory = {};

const Products = () => {
    const navigate = useNavigate();
    const categories = Object.keys(productsByCategory);
    const [tokenGenerated, setTokenGenerated] = useState(false);
    const [categoryProducts, setCategoryProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    const sliderRefs = useRef([]);

    const getProducts = async () => {
        setLoading(true);
        let businessNumber = localStorage.getItem("businessId");

        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/grouped_products?businessNumber=${businessNumber}`
        }).then(async (data) => {
            console.log('Fetch products response', data?.response.data);

            const groupedData = data?.response.data.reduce((acc, category) => {
                const products = category?.product.map((product) => ({
                    ...product,
                }));

                const subcategoryProducts = category?.subcategory && category?.subcategory?.length > 0
                    ? category?.subcategory?.flatMap((subcategory) =>
                        subcategory?.product.map((product) => ({
                            ...product,
                        }))
                    )
                    : [];

                const categoryProducts = [...products, ...subcategoryProducts];

                if (categoryProducts.length > 0) {
                    acc.push({
                        categoryName: category?.categoryName,
                        products: categoryProducts,
                    });
                }

                return acc;
            }, []);

            const specificCategoryName = 'Harambee Stars';
            const specificCategoryIndex = groupedData.findIndex(category => category?.categoryName === specificCategoryName);

            if (specificCategoryIndex !== -1) {
                const [specificCategory] = groupedData.splice(specificCategoryIndex, 1);
                groupedData.unshift(specificCategory);
            }

            setCategoryProducts(groupedData);
        }).catch((error) => {
            console.log('Error fetching products', error);
        }).finally(() => {
            setLoading(false);
        });
    };


    const getBusinessToken = async () => {
        const businessNumber = localStorage.getItem('businessId');
        if (!businessNumber) {
            const currentDomain = window.location.origin;
            HttpRequest({
                method: 'GET',
                url: `/api/v1/ecommerce/getzedecommerce-domain-details?subdomain=${currentDomain}`,
            }).then(async (data) => {
                if (data.status === 200) {
                    localStorage.setItem('businessId', data?.response.businessNumber);
                    setTokenGenerated(true);
                }
            }).catch((error) => {
                console.log('Error fetching business token', error);
            });
        } else {
            setTokenGenerated(true);
        }
    };

    useEffect(() => {
        if (!tokenGenerated) {
            getBusinessToken();
        } else {
            getProducts();
        }
    }, [tokenGenerated]);

    const getOptimizedImageUrl = (url) => {
        return url.replace(/\.jpg|\.png/i, '.webp');
    };
    const checkPreorder = (pricingObject) => {
        const now = new Date();
      
        const hasValidPreOrder = pricingObject?.some(item => 
          item.isPreOrderEnabled && new Date(item.preOrderdateEnd) > now && item?.preOrderStockLeft>0
        );
        return hasValidPreOrder;
      };
    const buttonStyle = {
        float: 'right',
        backgroundColor: '#cacaca',
        color: '#fff',
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '20px',
        height: '20px',
        marginRight: '10px',
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {loading && (
                    <Box sx={{ display: 'flex', width: '100%', textAlign: 'center', alignContent: 'center', verticalAlign: 'middle', maxWidth: '774px', margin: '10% 50%' }}>
                        <CircularProgress />
                    </Box>
                )}
            </Grid>
            <Grid item xs={12}>
                {!loading && categoryProducts?.map((categoryProduct, index) => {
                    const productCount = categoryProduct?.products?.length || 0;

                    const settings = {
                        dots: false,
                        infinite: productCount > 1,
                        speed: 500,
                        slidesToShow: productCount < 4 ? productCount : 4,
                        slidesToScroll: productCount < 4 ? productCount : 4,
                        initialSlide: 0,
                        swipeToSlide: true,
                        focusOnSelect: true,
                        centerMode: false,
                        responsive: [
                            {
                                breakpoint: 1920,
                                settings: {
                                    slidesToShow: productCount < 4 ? productCount : 4,
                                    slidesToScroll: productCount < 4 ? productCount : 4,
                                    infinite: productCount > 1,
                                    dots: false,
                                }
                            },
                            {
                                breakpoint: 1280,
                                settings: {
                                    slidesToShow: productCount < 3 ? productCount : 3,
                                    slidesToScroll: productCount < 3 ? productCount : 3,
                                    infinite: productCount > 1,
                                    dots: false,
                                }
                            },
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: productCount < 2 ? productCount : 2,
                                    slidesToScroll: productCount < 2 ? productCount : 2,
                                    infinite: productCount > 1,
                                    dots: false,
                                }
                            },
                            {
                                breakpoint: 600,
                                settings: {
                                    slidesToShow: productCount < 2 ? productCount : 2,
                                    slidesToScroll: productCount < 2 ? productCount : 2,
                                    initialSlide: 2
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]
                    };

                    return (
                        <Grid container spacing={2} sx={{ paddingRight: '1%' }} key={categoryProduct.categoryName}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', padding: '1%', margin: { xs: '7%', sm: '5%', lg: '0px' } }}>
                                <Typography variant='h2' sx={{ color: '#707070', fontSize: '16px', fontWeight: '500', flexDirection: 'column', fontFamily: 'Poppins' }}>{categoryProduct.categoryName}</Typography>
                                <Box sx={{ fontSize: '14px', fontWeight: '500', textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ color: "#707070", cursor: "pointer", fontSize: '14px', fontWeight: 'normal', mr: '15%', textWrap: 'nowrap', verticalAlign: 'middle' }} onClick={() => navigate("/products")}>Show All</Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                        <ChevronLeftRounded sx={buttonStyle} onClick={() => sliderRefs.current[index].slickPrev()} />
                                        <ChevronRightRounded sx={buttonStyle} onClick={() => sliderRefs.current[index].slickNext()} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Slider ref={el => (sliderRefs.current[index] = el)} {...settings}>
                                    {categoryProduct?.products.map((product) => (
                                        <Box onClick={() => { navigate(`/product/${product._id}`) }} key={product.id} sx={{ width: '100%', height: '100%', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', backgroundColor: '#fff', borderRadius: '15px', maxWidth: '221px', maxHeight: '339px', padding: { xs: '10%', md: categoryProduct?.products?.length < 4 ? '3%' : '10%' }, margin: { xs: '5% 10% 5% 10%', md: categoryProduct?.products?.length < 4 ? '2%' : '5% 10% 5% 10%' }, alignContent: 'center', minHeight: '300px', cursor: 'pointer' }}>
                                            {checkPreorder(product?.pricing) && <Box sx={{ width: '100%', maxWidth: '113px', height: '100%', maxHeight: '20px', margin: '0 0 0px 92px', padding: '7px 11px 6px 12px', borderRadius: '3px', backgroundColor: '#02b3ad', alignItems: 'center', verticalAlign: 'middle' }}>
                                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 500, color: '#fff', textWrap: 'nowrap' }}>Pre-Order Now</Typography>
                                            </Box>}
                                            <Box sx={{ minHeight: '200px', maxWidth: '200px', maxHeight: '200px', padding: '2%', borderRadius: '15px', backgroundColor: '#fff', alignItems: 'center', textAlign: 'center', height: '100%', width: '100%' }}>
                                                <LazyLoadImage
                                                    src={product?.featuredImage}
                                                    alt="product"
                                                    effect="blur"
                                                    placeholderSrc={imagePlaceholder}
                                                    style={{ width: '100%', height: '100%', objectFit: 'contain', maxWidth: { xs: '157px', sm: '157px', md: '157px', lg: '157px', xl: '157px' }, maxHeight: '196px', minHeight: '100%' }}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', textAlign: 'left', color: '#343434', fontWeight: 'normal', margin: '0 0 10px' }}>{product.productName}</Typography>
                                                {product?.pricing[0]?.discountedPrice && product?.pricing[0]?.discountedPrice < product.pricing[0].normalPrice && (
                                                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '9px', color: '#ef0b0b', textAlign: 'left', fontWeight: 'normal', textDecoration: 'line-through' }}>
                                                        {CurrencyFormatter(product?.pricing && product.pricing.length > 0 ? product.pricing[0].normalPrice : 0)}
                                                    </Typography>
                                                )}
                                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Typography sx={{ fontFamily: 'Poppins', fontWeight: 'bold', color: '#000', fontSize: '13px' }}>
                                                        {CurrencyFormatter(product?.pricing && product.pricing.length > 0 ? product.pricing[0].discountedPrice : product?.productPrice)}
                                                    </Typography>
                                                    <Link to={`/product/${product._id}`} style={{ textDecoration: 'none' }}>
                                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                                            <RemoveRedEyeIcon style={{ fontSize: "20px", color: "#02b3ad" }} />
                                                            <Typography sx={{ fontSize: "10px", color: "#707070" }}>View Details</Typography>
                                                        </span>
                                                    </Link>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))}
                                </Slider>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};

export default Products;
