import { Box, Button, Grid, Typography, TextField, Modal, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HttpRequest } from "../HttpRequest";
import { ArrowBackIos, Circle, EditOutlined } from "@mui/icons-material";
import CustomInputLabel from "../common/CustomInputLabel";
import { SuccessAlert } from "../common/snackbar/success";
import { ErrorAlert } from "../common/snackbar/error";
import CustomTable from "../common/CustomTable";
import CustomSearchInput from "../common/CustomSearchInput";
import warningIcon from '../../images/warning-remove-icn.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from "@mui/x-data-grid";

const deleteShippingRegionModal = {
  borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: "130px", p: '61px 22px 165px 63px', border: '1px #707070', m: '55px 104px 6.5px 118px'
}
const ShippingRegion = () => {
  const [search, setSearch] = useState('');
  const [successShow, setSuccessShow] = useState({ state: false, message: "" })
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })
  const [showModalAddRegion, setshowModalAddRegion] = useState(false)
  const [showModalEditRegion, setShowModalEditRegion] = useState(false)
  const [shippingRegion, setShippingRegion] = useState({})
  const [dataGridPageSize, setPageSize] = useState(10);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
  const [shippingRegionData, setShippingRegionData] = useState({
    regionName: "",
    regionDescription: "",
    shippingCost: "",
  })
  const [openDeleteShippingRegion, setOpenDeleteShippingRegion] = useState(false)
  const handleOpenDeleteShippingRegion = () => { setOpenDeleteShippingRegion(true) }
  const handleCloseDeleteShippingRegion = () => { setOpenDeleteShippingRegion(false) }

  const handleFormData = (e) => {
    const { name, value } = e.target
    setShippingRegionData({ ...shippingRegionData, [name]: value })
  }
  const handleRowClick = (event, params) => {
    event.stopPropagation();
    console.log('params', params?.row);
    setShippingRegionData({
      regionName: params?.row?.regionName,
      regionDescription: params?.row?.regionDescription,
      shippingCost: params?.row?.shippingCost,
    })
    setShippingRegion(params?.row);
    setShowModalEditRegion(true);
  }
  const columns = [
    {
      field: 'regionName', headerName: 'Region Name', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Region Name </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Grid container spacing={2}>
            <Grid item>
              {params?.row?.regionName}
            </Grid>
          </Grid>
        </Box>)
      }
    },
    {
      field: 'regionDescription', headerName: 'Description', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Description   </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          {params?.row?.regionDescription}
        </Box>)
      }
    },
    {
      field: 'shippingCost', headerName: 'Shipping Cost', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Shipping Cost   </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          {params?.row?.shippingCost}
        </Box>)
      }
    },
    {
      field: 'action', headerName: 'Action', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Action  </Box>) },
      renderCell: (params) => {
        const onClickDelete = (event) => {
          event.stopPropagation();
          setShippingRegion(params?.row);
          handleOpenDeleteShippingRegion(true);
        }
        return (
          <Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500", mt: '30px' }}>
            <EditOutlined onClick={(event)=>handleRowClick(event,params)} sx={{ color: '#032541', marginRight: '10px' }} />
            <DeleteIcon onClick={onClickDelete} sx={{ color: '#DC3545' }} />
          </Box>
        )
      }
    },
  ]

  const getShippingRegion = () => {
    setPageState((old) => ({ ...old, isLoading: true }));
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/get_shipping_regions?searchValue=${search}`
    }).then((data) => {
      console.log(data.response?.data, ' data.response.data')
      setPageState({
        ...pageState,
        data: data?.response?.data,
        total: data?.response?.count,
        isLoading: false
      })
    }).catch((error) => {
      console.log('Error fetching items');
    });
  }

  const navigate = useNavigate();

  const handleRegionSubmit = () => {
    if (!shippingRegionData.regionName) {
      setErrorShow({ state: true, message: "Location name is required" });
      return;
    }
    if (!shippingRegionData.regionDescription) {
      setErrorShow({ state: true, message: "Location description is required" });
      return;
    }
    if (!shippingRegionData.shippingCost) {
      setErrorShow({ state: true, message: "Location description is required" });
      return;
    }
    HttpRequest({
      method: 'POST',
      url: `/api/v1/ecommerce/add_shipping_regions`,
      body: shippingRegionData

    }).then((data) => {
      if (data?.status === 200) {
        console.log(data, ' data.response.data ')
        setSuccessShow({ state: true, message: "Shipping Region added successfully" });
        setshowModalAddRegion(false)
        getShippingRegion()
        setShippingRegionData({
          regionName: "",
          regionDescription: "",
          shippingCost: "",
        })
      } else {
        console.log(data, ' data.response.data ')
        console.log(data.response?.data, ' data.response.data ')

        setErrorShow({ state: true, message: data.response?.data?.message });
      }
    }).catch((error) => {
      setErrorShow({ state: true, message: "Error adding shipping Region" });
    })
  }
  const handleRegionUpdateSubmit = () => {
    if (!shippingRegionData.regionName) {
      setErrorShow({ state: true, message: "Location name is required" });
      return;
    }
    if (!shippingRegionData.regionDescription) {
      setErrorShow({ state: true, message: "Location description is required" });
      return;
    }
    if (!shippingRegionData.shippingCost) {
      setErrorShow({ state: true, message: "Location description is required" });
      return;
    }
    HttpRequest({
      method: 'POST',
      url: `/api/v1/ecommerce/edit_shipping_regions/${shippingRegion._id}`,
      body: shippingRegionData

    }).then((data) => {
      if (data?.status === 200) {
        console.log(data, ' data.response.data ')
        setSuccessShow({ state: true, message: "Shipping Region updated successfully" });
        setShowModalEditRegion(false)
        getShippingRegion()
        setShippingRegionData({
          regionName: "",
          regionDescription: "",
          shippingCost: "",
        })
      } else {
        console.log(data, ' data.response.data ')
        console.log(data.response?.data, ' data.response.data ')

        setErrorShow({ state: true, message: data.response?.data?.message });
      }
    }).catch((error) => {
      setErrorShow({ state: true, message: "Error updating shipping Region" });
    })
  }
  const handleDeleteShippingRegion = (id) => {
    handleCloseDeleteShippingRegion();
    HttpRequest({
      method: 'DELETE',
      url: `/api/v1/ecommerce/delete_shipping_regions/${id}`,
    }).then((data) => {
      if (data?.status === 200) {
        setSuccessShow({ state: true, message: "Shipping deleted successfully" });
        setShippingRegion({});
        setShippingRegionData({
          regionName: "",
          regionDescription: "",
          shippingCost: "",
        })
      } else {
        console.log(data, ' data.response.data ')
        console.log(data.response?.data, ' data.response.data ')
        setErrorShow({ state: true, message: data.response?.data?.message });
      }
    }).catch((error) => {
      setErrorShow({ state: true, message: "Error deleting shipping region" });
    })
  }
  useEffect(() => {
    getShippingRegion()
  }, [search])
  return (
    <div style={{ marginTop: '4%' }}>
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
        <Box sx={{ flexDirection: "column" }}>
          <Box>
            {showModalAddRegion ? (
              <Box variant="h4" sx={{ display: 'flex' }}>
                <ArrowBackIos sx={{ width: '35px', height: '35px', objectFit: 'contain' }} onClick={() => { setshowModalAddRegion(false) }} />
                <Typography sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600" }}>
                  Add Shipping Region
                </Typography>
              </Box>
            ) : showModalEditRegion ? (
              <Box variant="h4" sx={{ display: 'flex' }}>
                <ArrowBackIos sx={{ width: '35px', height: '35px', objectFit: 'contain' }} onClick={() => { setShowModalEditRegion(false) }} />
                <Typography sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600" }}>
                  Edit Shipping Region
                </Typography>
              </Box>
            ) : (
              <Typography variant="h4" sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600" }}>
                Shipping Regions
              </Typography>
            )}
          </Box>
          <Box>
            <Typography variant="h4" sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal", }}   >
              View and manage your Shipping Regions
            </Typography>
          </Box>
        </Box>
        {showModalAddRegion || showModalEditRegion ? null :
          <Box style={{ marginRight: "15px" }}>
            <Button variant="contained" sx={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#032541", color: "#fff" } }} onClick={() => setshowModalAddRegion(true)}>
              Add Region
            </Button>
          </Box>
        }
      </Box>
      {showModalAddRegion || showModalEditRegion ? null :
        <Box sx={{ display: 'flex' }}>
          <Box sx={{}}>
            <CustomSearchInput placeholder={"Search region name ...."} name="search" value={search} onChange={(e) => setSearch(e.target.value)} />
          </Box>
        </Box>
      }
      {showModalAddRegion ?
        <>
          <Box sx={{ width: "500px", }}>
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              <CustomInputLabel label="Region  Name" placeholder="Enter Location Name " />
              <TextField label="Region Name" value={shippingRegionData?.regionName} name="regionName" onChange={handleFormData} InputProps={{ sx: { width: "490px", height: '50px', fontSize: "0.875rem", "& fieldset": { borderColor: "#bec5d1", }, }, }} placeholder="Nairobi - Region" />
            </Box>
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              <CustomInputLabel label="shipping Amount" placeholder="Enter  cost*" />
              <TextField label="shipping Cost Aount" type="number" value={shippingRegionData?.shippingCost} name="shippingCost" onChange={handleFormData} InputProps={{ sx: { width: "490px", height: '50px', fontSize: "0.875rem", "& fieldset": { borderColor: "#bec5d1", }, }, }} placeholder="Nairobi - CBD" />
            </Box>
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              <CustomInputLabel label="Description" placeholder="Enter  Description*" />
              <TextField name={"regionDescription"} value={shippingRegionData.regionDescription} onChange={handleFormData} placeholder={"Description"} variant="outlined" margin="dense" sx={{ marginBottom: 2 }} multiline minRows={2} InputProps={{ sx: { width: "490px", height: '70px', fontSize: "0.875rem", "& fieldset": { borderColor: "#bec5d1", }, }, }} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignContent: "end" }}>
              <Button variant="contained" sx={{ backgroundColor: "#fff", color: "#dc3545", textTransform: "none", borderRadius: "5px", marginRight: "10px", border: "1px solid #dc3545", padding: "10.4px 30px 9.6px 30px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#fff", color: "#dc3545" } }} onClick={() => setshowModalAddRegion(false)}>
                Cancel
              </Button>
              <Button variant="contained" sx={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#032541", color: "#fff" } }} onClick={handleRegionSubmit}>
                Add Region
              </Button>
            </Box>
          </Box>
        </>
        : showModalEditRegion ?
          <>
            <Box sx={{ width: "500px", }}>
              <Box sx={{ width: "100%", marginTop: "20px" }}>
                <CustomInputLabel label="Region  Name" placeholder="Enter Location Name " />
                <TextField label="Region Name" value={shippingRegionData?.regionName} name="regionName" onChange={handleFormData} InputProps={{ sx: { width: "490px", height: '50px', fontSize: "0.875rem", "& fieldset": { borderColor: "#bec5d1", }, }, }} placeholder="Nairobi - Region" />
              </Box>
              <Box sx={{ width: "100%", marginTop: "20px" }}>
                <CustomInputLabel label="shipping Amount" placeholder="Enter  cost*" />
                <TextField label="shipping Cost Aount" type="number" value={shippingRegionData?.shippingCost} name="shippingCost" onChange={handleFormData} InputProps={{ sx: { width: "490px", height: '50px', fontSize: "0.875rem", "& fieldset": { borderColor: "#bec5d1", }, }, }} placeholder="Nairobi - CBD" />
              </Box>
              <Box sx={{ width: "100%", marginTop: "20px" }}>
                <CustomInputLabel label="Description" placeholder="Enter  Description*" />
                <TextField name={"regionDescription"} value={shippingRegionData.regionDescription} onChange={handleFormData} placeholder={"Description"} variant="outlined" margin="dense" sx={{ marginBottom: 2 }} multiline minRows={2} InputProps={{ sx: { width: "490px", height: '70px', fontSize: "0.875rem", "& fieldset": { borderColor: "#bec5d1", }, }, }} />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end", alignContent: "end" }}>
                <Button variant="contained" sx={{ backgroundColor: "#fff", color: "#dc3545", textTransform: "none", borderRadius: "5px", marginRight: "10px", border: "1px solid #dc3545", padding: "10.4px 30px 9.6px 30px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#fff", color: "#dc3545" } }} onClick={() => setshowModalAddRegion(false)}>
                  Cancel
                </Button>
                <Button variant="contained" sx={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#032541", color: "#fff" } }} onClick={handleRegionUpdateSubmit}>
                  Update
                </Button>
              </Box>
            </Box>
          </> :
          <Box style={{ marginRight: "15px" }}>
            <DataGrid
              sx={{ height: '500px', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
              rowCount={pageState.total}
              pagination
              loading={pageState.isLoading}
              page={pageState.page - 1}
              pageSizeOptions={[1, 10, 20, 50, 100]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: dataGridPageSize,
                    page: pageState.page - 1,
                  },
                },
              }}
              pageSize={dataGridPageSize}
              onPaginationModelChange={(data) => {
                const { pageSize, page } = data;
                setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                setPageSize(pageSize)
              }}
              paginationMode="server"
              columns={columns}
              rows={pageState.data}
              getRowId={(row) => row._id}
            />
          </Box>
      }
      <Modal open={openDeleteShippingRegion} onClose={handleCloseDeleteShippingRegion} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
        <Box sx={deleteShippingRegionModal}>
          <Box sx={{ padding: "10px" }}>
            <Box sx={{ display: 'flex' }}>
              <img src={warningIcon} alt="" style={{ width: '70px', height: '70px', margin: '12px 0 32px 0', objectFit: 'contain' }} />
              <Box sx={{ width: '300px', height: '104px', margin: '0 0 0 39px' }}>
                <Typography sx={{ textWrap: 'nowrap', height: '23px', width: '231px', margin: '0 134px 10px 1px', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: "#032541" }}>
                  Delete Shipping Region?
                </Typography>
                <Typography sx={{ height: '41px', margin: '10px 10px 10px 0px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: '#707070', padding: '11px 66px 0 0px' }}>
                  Selected shipping region will be deleted. This action cannot be undone.
                </Typography>
                <Box sx={{ display: 'flex', marginTop: '20px', padding: '10px' }}>
                  <Circle sx={{ width: '10px', height: '10px', margin: '1px 1px 1px 0', color: '#0c253f' }} />
                  <Typography sx={{ textWrap: 'nowrap', width: '121px', height: '20px', margin: "0 0 0 8px", fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: '#032541' }}>
                    {shippingRegion.regionName}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: 'center' }}>
              <Button size='large' variant="outlined" sx={{ backgroundColor: "#fff", border: 'solid 1px #002543', color: "#6e7074", textTransform: "none", borderRadius: "4px", padding: "13px 38px 12px 37px", margin: '65px 16px 0 53px', "&:hover": { backgroundColor: "#fff" } }} onClick={handleCloseDeleteShippingRegion} >Cancel</Button>
              <Button size='large' variant="contained" sx={{ backgroundColor: "#dc3545", color: "#fff", textTransform: "none", borderRadius: "4px", padding: "12.5px 38.4px 12.5px 40.6px", margin: '65px 56px 0 16px', "&:hover": { backgroundColor: "#dc3545" } }} onClick={() => { handleDeleteShippingRegion(shippingRegion._id) }}>Delete</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default ShippingRegion