 import { Box, Grid } from '@mui/material'
import React from 'react'
import "./index.css"
import TopNavBar from './topNavBar';
import SearchNavBar from './searchBar';
import Navbar from './navbar';
import BannerPage from './banner';
import Products from './products';
import Footer from './footer';

const HomePage = () => {

    return (
        <Grid width={'100%'} flexDirection={'column'} sx={{ minHeight: '100vh'}}>
            {/* <Box>
                <TopNavBar />
            </Box> */}
            <Box className="container">
                {/* Search abr */}
                {/* <SearchNavBar />
                {/* Navbar */}
                {/* <Navbar />  */}
                {/* Banner */}
                <BannerPage />

                {/* Products */}
                <Products />
            </Box>
            {/* <Box component={'div'} sx={{ marginTop: 'auto' ,bottom:0 , marginBottom:0, height:'100%', width:'100%' }}>
                <Footer />
            </Box> */}
        </Grid>
    )
}

export default HomePage