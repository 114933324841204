import { Download } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import exportFromJSON from "export-from-json";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React from "react";
import DateFormatter from "./dateFormatter";

const ExportData = ({ data, tableColumn, title }) => {
    const timestamp = new Date();
    const fileName = `${title}_${DateFormatter(timestamp)}`;

    const ExportXLXS = () => {
        const excelFileName = fileName;
        const exportType = "xls";
        exportFromJSON({ data, excelFileName, exportType });
    };

    const ExportCSV = () => {
        const csvFileName = fileName;
        const exportType = "csv";
        exportFromJSON({ data, csvFileName, exportType });
    };

    const exportPDF = () => {
        const doc = new jsPDF();
        const tableRows = [];

        data.forEach(item => {
            const itemData = tableColumn.map(col => item[col.field]);
            tableRows.push(itemData);
        });

        doc.autoTable({
            head: [tableColumn.map(col => col.headerName)],
            body: tableRows,
        });

        doc.text(title, 16, 10);

        const pdfFileName = `${fileName}.pdf`;
        doc.save(pdfFileName);
    };



    return (
        <Box sx={{ display: 'flex' }}>
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                        <Button variant='contained' sx={{ margin: '0px 0.5px 36.5px 40px', backgroundColor: '#032541', textTransform: 'capitalize', textWrap: 'nowrap', "&:hover": { backgroundColor: '#032541' } }} {...bindTrigger(popupState)} startIcon={<Download />}>
                            Export
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                            <MenuItem onClick={() => { ExportCSV(); popupState.close(); }}>CSV</MenuItem>
                            <MenuItem onClick={() => { ExportXLXS(); popupState.close(); }}>Excel</MenuItem>
                            <MenuItem onClick={() => { exportPDF(); popupState.close() }}>PDF</MenuItem>
                        </Menu>
                    </React.Fragment>
                )}
            </PopupState>
        </Box>
    );
};

export default ExportData;
