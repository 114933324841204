import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import {thunk} from 'redux-thunk';
import productSlice from "../features/productSlice";
import { cartReducer } from "../features/cartSlice";
import userSlice from "../features/userSlice";
import configsSlice from "../features/configsSlice";
import forgotPasswordSlice from "../features/forgotPassword";

const rootReducer = combineReducers({
    product: productSlice,
    cart:cartReducer,
    user:userSlice,
    configs:configsSlice,
    forgotPassword:forgotPasswordSlice
});

const reduxPersistConfig = {
    key: 'root',
    storage: storageSession,
    blacklist:[], // reducer names to ignore
};

const persistedReducer = persistReducer(reduxPersistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware:(getDefaultMiddleware)=> [thunk],

});

export const persistedStore = persistStore(store);
