import secureLocalStorage from "react-secure-storage";

export default function generateImageUrl(selectedFile) {
  return new Promise((resolve, reject) => {
    try {
      const base_url = process.env.REACT_APP_BASE_URL;
      const url = `/api/v1/ecommerce/generate_file_system_url`;
      const finalUrl = `${base_url}${url}`;
      let token = secureLocalStorage.getItem('token');

      const formData = new FormData();
      formData.append("file", selectedFile);

      const xhr = new XMLHttpRequest();
      xhr.open("POST", finalUrl);
      xhr.setRequestHeader("X-Authorization", token);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          try {
            if (xhr.status === 200) {
              const response = JSON.parse(xhr.response);
              const imageUrl = response.data;
              const thumbnailUrl = response.thumbnailurl;
              console.log('Response data image', response.data);
              resolve({ imageUrl, thumbnailUrl }); // Resolve the Promise with an object containing both URLs
            } else {
              console.error("XHR request failed with status:", xhr.status);
              const response = JSON.parse(xhr.response);
              console.log(response, "response");
              reject(new Error("XHR request failed")); // Reject the Promise on error
            }
          } catch (error) {
            // Handle JSON parsing error or non-JSON response
            console.error("Failed to parse response as JSON:", error);
            const errorMessage = extractErrorMessage(xhr.responseText);
            reject(new Error(`Unexpected response format: ${errorMessage}`));
          }
        }
      };

      xhr.send(formData);
    } catch (error) {
      console.error(`Error in generateImageUrl function:`, error.message);
      reject(error); // Reject the Promise on exception
    }
  });
}

function extractErrorMessage(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const pre = doc.querySelector('pre');
  return pre ? pre.textContent : 'Unknown error';
}
