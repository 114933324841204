import { Box, Typography, Button, CircularProgress, TextField, Alert, Checkbox, RadioGroup, Radio, FormControl, FormControlLabel, Grid, Divider, IconButton, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from '../header'
import Footer from '../footer'
import { HttpRequest } from '../../HttpRequest';
import { useNavigate, useParams } from 'react-router-dom';
import { AddCircle, CheckBox, KeyboardArrowDown, KeyboardArrowUp, RemoveCircle, ShoppingCart } from '@mui/icons-material';
import { addToCart, setPackage } from '../../../features/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { SuccessAlert } from '../../common/snackbar/success';
import { ErrorAlert } from '../../common/snackbar/error';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import useBestPackage from '../../common/packages';
import secureLocalStorage from 'react-secure-storage';
import noImagePlaceholder from '../../../images/No-Image-Placeholder.png'


const businessNumber = secureLocalStorage.getItem("businessId");
const customerId = secureLocalStorage.getItem('customerId');

const ProductPage = () => {
  const stateCart = useSelector((state) => state.cart);
  let cart = stateCart.cart;

  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({});
  const { productId } = useParams();
  const [count, setCount] = useState(0);
  const [variations, setVariations] = useState({});
  const [variationKey, setVariationKey] = useState('');
  const [variationId, setVariationId] = useState('');
  const dispatch = useDispatch();
  const [price, setPrice] = useState(0);
  const [normalPrice, setNormalPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [successShow, setSuccessShow] = useState({ state: false, message: "" })
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })
  const [stock, setStock] = useState(0);
  const [selectedAdditionalServices, setSelectedAdditionalServices] = useState([]);
  const [additionalServicesAmount, setAdditionalServicesAmount] = useState(0);
  const [checkedItems, setCheckedItems] = useState({});
  const [customValues, setCustomValues] = useState({});
  const [customError, setCustomError] = useState(false);
  const [activeImage, setActiveImage] = useState('');
  const [variationKeyError, setVariationKeyError] = useState(false);
  const navigate = useNavigate();
  const { fetchAndDetermineBestPackage } = useBestPackage();
  const [paymentMode, setPaymentMode] = useState('preOrderPayMethod');
  const [isPreOrderEnabled, setIsPreOrderEnabled] = useState(false);
  const [endDate, setEndDate] = useState(null);

  const handleIncrementCart = () => {
    console.log(stock, 'handleIncrementCart', count);
    if (stock < count + 1) {
      setErrorShow({ state: true, message: "The selected quantity exceeds available stock." });
      return;
    }
    if (variationKey.trim() === '') {
      setVariationKeyError(true);
      setErrorShow({ state: true, message: "Please select variations." });
    } else {
      const variations = new Set(variationKey.split(',').map(item => item.trim()));
      const matchedPricing = product?.pricing?.some((pricing) => {
        const pricingVariations = new Set(pricing?.variationKey?.split(',').map(item => item.trim()));
        return variations.size === pricingVariations.size && [...variations].every(variation => pricingVariations.has(variation));
      });
      if (matchedPricing === undefined) {
        setErrorShow({ state: true, message: "Please select all available variations." });
      } else {
        if (stock > 0) {
          setCount((prevCount) => prevCount + 1);
        } else {
          setErrorShow({ state: true, message: "Product currently out of stock" });
        }
      }
    }
  };

  const handleDecrementCart = () => {
    if (count > 0) {
      setCount((prevCount) => prevCount - 1);
    }
  };

  const handleAddToCart = async () => {
    if (stock < count) {
      setErrorShow({ state: true, message: "The selected quantity exceeds available stock." });
      return;
    }
    console.log('Added to cart');
    setCustomError(false);
    console.log(selectedAdditionalServices, 'selectedAdditionalServices');
    const hasEmptyCustomValues = selectedAdditionalServices.some(service => {
      return service.items.some(item => {
        return item?.isCustomizableValue?.trim() === '';
      });
    });
    if (hasEmptyCustomValues) {
      setCustomError(true);
      setErrorShow({ state: true, message: "Please enter customization values." });
      return;
    }

    const isAnyEmpty = selectedAdditionalServices?.items?.some(service =>
      service.isCustomizableValue.trim() === ''
    );

    if (isAnyEmpty) {
      setErrorShow({ state: true, message: "Please fill all fields" });
      return;
    }
    const payload = {
      id: product._id,
      image: product.featuredImage,
      name: product.productName,
      price: price,
      variations: variations,
      quantity: count,
      variationId: variationId,
      additionalServices: selectedAdditionalServices,
      customizationAmount: additionalServicesAmount,
      isPreOrder: isPreOrderEnabled,
      remainingStock: stock
    };
 
     let selectedPackage = await fetchAndDetermineBestPackage(count);
     console.log(selectedPackage,'selectedPackage');
     if(selectedPackage!==null) {
      dispatch(addToCart(payload));
      postCartItem(payload);
      setSuccessShow({ state: true, message: "Product added to cart." });
     }else {
      setErrorShow({ state: true, message: "No suitable package found." });
     }
   
  }
  const postCartItem = (payload) => {
    return HttpRequest({
      method: 'POST',
      url: `/api/v1/ecommerce/savecartitem?businessNumber=${businessNumber}`,
      body: {
        "cartItem": {
          ...payload,
          "businessNo": businessNumber,
          "userId": customerId,
        }
      }
    }).then(async (data) => {
      console.log('Post add cart item error', data);
    }).catch((error) => {
      console.log('Error post cart item', error);
    })
  }
  const handleVariations = (type, variation) => {
    // setVariations((prevVariations) => {
    //   const updatedVariations = { ...prevVariations, [type]: variation };
    //   const variationsArray = Object.values(updatedVariations);
    //   const generatedVariationKey = variationsArray.join(',');
    //   setVariationKey(generatedVariationKey);
    //   getPricingForVariationKey(generatedVariationKey, product.pricing);
    //   return updatedVariations;
    // });
    setVariations(prevVariations => {
      const updatedVariations = { ...prevVariations, [type]: variation };
      const variationsArray = Object.values(updatedVariations);
      const generatedVariationKey = variationsArray.join(',');
      setVariationKey(generatedVariationKey);
      getPricingForVariationKey(generatedVariationKey, product.pricing);
      return updatedVariations;
    });
  };

  const isOutOfStock = (currentVariations, type, variationValue) => {
    const updatedVariations = { ...currentVariations, [type]: variationValue };
    const variationsArray = Object.values(updatedVariations);
    const generatedVariationKey = variationsArray.join(',');
    const matchedPricing = product.pricing.find(pricing => {
      const pricingVariations = new Set(pricing.variationKey.split(',').map(item => item.trim()));
      return variationsArray.length === pricingVariations.size && variationsArray.every(variation => pricingVariations.has(variation));
    });
    return matchedPricing ? matchedPricing.availableStock <= 0 && !isPreOrderEnabled : false;
  };

  const getPricingForVariationKey = (variationKey, pricingData) => {
    const variations = new Set(variationKey?.split(',').map(item => item.trim()));
    const matchedPricing = pricingData?.find((pricing) => {
      const pricingVariations = new Set(pricing?.variationKey?.split(',').map(item => item.trim()));
      return variations.size === pricingVariations.size && [...variations].every(variation => pricingVariations.has(variation));
    });
    setVariationId(matchedPricing?._id);
    setPrice(matchedPricing ? matchedPricing.discountedPrice + additionalServicesAmount : price);
    setNormalPrice(matchedPricing ? matchedPricing?.normalPrice + additionalServicesAmount : normalPrice);
    setDiscountedPrice(matchedPricing ? matchedPricing?.discountedPrice + additionalServicesAmount : discountedPrice);
    setStock(matchedPricing ? matchedPricing?.availableStock : 0);
    if (matchedPricing) {

      const preOrderdateEnd = matchedPricing?.preOrderdateEnd;
      const currentDate = new Date();
      const preOrderEndDate = new Date(preOrderdateEnd);

      const isPreOrderActive = currentDate <= preOrderEndDate;

      if (matchedPricing?.isPreOrderEnabled && matchedPricing?.preOrderStockLeft > 0 && isPreOrderActive) {
        setPaymentMode(matchedPricing?.preOrderPayMethod);
        setIsPreOrderEnabled(matchedPricing?.isPreOrderEnabled);
        setStock(matchedPricing ? matchedPricing?.preOrderStockLeft : 0);
        setEndDate(matchedPricing?.preOrderdateEnd);
      } else {
        setIsPreOrderEnabled(false);
      }
    }
  };

  const getProduct = () => {
    setLoading(true);
    const businessNumber = secureLocalStorage.getItem("businessId");
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/get_product/${productId}?businessNumber=${businessNumber}`
    }).then(async (data) => {
      console.log('Fetch product details response', data.response.data);
      let productDetails = data?.response?.data;

      setProduct(productDetails);
      setPrice(productDetails?.pricing && productDetails?.pricing?.length > 0 ? productDetails?.pricing[0]?.discountedPrice : productDetails?.productPrice ?? 0);
      setNormalPrice(productDetails?.pricing && productDetails?.pricing?.length > 0 ? productDetails?.pricing[0]?.normalPrice : productDetails?.productPrice ?? 0);
      setDiscountedPrice(productDetails?.pricing && productDetails?.pricing?.length > 0 ? productDetails?.pricing[0]?.discountedPrice : productDetails?.productPrice ?? 0)
      setStock(productDetails?.pricing && productDetails?.pricing?.length > 0 ? productDetails?.pricing[0]?.availableStock : 0);
      setActiveImage(productDetails?.featuredImage ?? noImagePlaceholder);
      if (productDetails?.variations && productDetails?.variations[0]?.variationName === 'default') {
        setVariationKey(productDetails?.variations[0]?.variationName);
        setVariations({ ...variations, [productDetails?.variations[0]?.variationName]: variations[0]?.variationValues });
        setVariationId(productDetails?.pricing[0]?._id);
        setStock(productDetails?.pricing && productDetails.pricing.length > 0 ? productDetails.pricing[0].availableStock : 0);
      }
      setIsPreOrderEnabled(checkPreorder(productDetails?.pricing));
      setEndDate(productDetails?.pricing[0]?.preOrderdateEnd);

    }).catch((error) => {
      console.log('Error fetching products', error);
    }).finally(() => {
      setLoading(false);
    });
  };
  const checkVariationSelected = (variation) => {
    console.log('Variation key', variationKey);
    if (variationKey === null || variationKey === undefined) {
      return false;
    }
    console.log('Variation key', variationKey);
    const variationArray = variationKey?.split(',').map(value => value.trim());
    const variationSelected = variationArray.includes(variation);
    if (variationSelected) {
      return true;
    } else {
      return false;
    }
  }
  function toSentenceCase(text) {
    return text.toLowerCase().split('. ').map(sentence => {
      return sentence.charAt(0).toUpperCase() + sentence.slice(1);
    }).join('. ');
  }
  const handleSelectAdditionalServicesMultiple = (service, item, isChecked) => {
    setSelectedAdditionalServices(prevState => {
      const serviceIndex = prevState.findIndex(prevService => prevService.serviceId === service.serviceId);
      if (isChecked) {
        if (serviceIndex > -1) {
          const updatedServices = [...prevState];
          updatedServices[serviceIndex] = {
            ...updatedServices[serviceIndex],
            items: [
              ...updatedServices[serviceIndex].items,
              { itemId: item._id, itemName: item.itemName, itemPrice: item.itemPrice }
            ]
          };
          return updatedServices;
        } else {
          return [
            ...prevState,
            {
              serviceId: service.serviceId,
              items: [{ itemId: item._id, itemName: item.itemName, itemPrice: item.itemPrice }]
            }
          ];
        }
      } else {
        if (serviceIndex > -1) {
          const updatedServices = [...prevState];
          updatedServices[serviceIndex] = {
            ...updatedServices[serviceIndex],
            items: updatedServices[serviceIndex].items.filter(serviceItem => serviceItem.itemId !== item._id)
          };
          if (updatedServices[serviceIndex].items.length === 0) {
            updatedServices.splice(serviceIndex, 1);
          }
          return updatedServices;
        }
      }
      return prevState;
    });
  };
  const [selectedItem, setSelectedItem] = useState(null);

  const handleSelectAdditionalServicesSingle = (service, item) => {
    setSelectedAdditionalServices(prevState => {
      const updatedServices = prevState.map(prevService => {
        if (prevService.serviceId === service.serviceId) {
          return {
            ...prevService,
            items: [{ itemId: item._id, itemName: item.itemName, itemPrice: item.itemPrice }]
          };
        }
        return prevService;
      });

      if (!updatedServices.some(prevService => prevService.serviceId === service.serviceId)) {
        updatedServices.push({
          serviceId: service.serviceId,
          items: [{ itemId: item._id, itemName: item.itemName, itemPrice: item.itemPrice }]
        });
      }
      return updatedServices;
    });
  };

  const calculateAdditionServiceAmount = () => {

    const totalPriceSum = selectedAdditionalServices.reduce((total, service) => {
      return total + service.items.reduce((subtotal, item) => subtotal + item.itemPrice, 0);
    }, 0);
    console.log(totalPriceSum, 'totalPriceSum', selectedAdditionalServices)
    return totalPriceSum;
  }

  const handleCustomizeClick = (serviceId, itemId, itemName) => {
    setCustomError(false);
    const inputValue = document.getElementById(`custom-value-${itemId}`).value;
    setCustomValues(prevState => ({
      ...prevState,
      [itemId]: inputValue
    }));

    setSelectedAdditionalServices(prevState => {
      return prevState.map(service => {
        if (service.serviceId === serviceId) {
          return {
            ...service,
            items: service.items.map(item => {
              if (item.itemId === itemId) {
                return {
                  ...item,
                  isCustomizableValue: inputValue
                };
              }
              return item;
            })
          };
        }
        return service;
      });
    });
  };

  const [showAllServices, setShowAllServices] = useState({});

  const handleToggleShowAllItems = (serviceId) => {
    setShowAllServices((prev) => ({
      ...prev,
      [serviceId]: !prev[serviceId],
    }));
  };

  const checkPreorder = (pricingObject) => {
    const now = new Date();

    const hasValidPreOrder = pricingObject?.some(item =>
      item.isPreOrderEnabled && new Date(item.preOrderdateEnd) > now && item?.preOrderStockLeft > 0
    );

    return hasValidPreOrder;
  };

  useEffect(() => {
    let newAmount = calculateAdditionServiceAmount();
    console.log(newAmount, 'newAmount');
    setAdditionalServicesAmount(newAmount);
  }, [selectedAdditionalServices]);
  useEffect(() => {
    getProduct();
  }, [productId])


  const CountdownTimer = ({ targetDate }) => {
    const calculateTimeLeft = () => {
      const difference = new Date(targetDate) - new Date();
      let timeLeft = {};

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        };
      } else {
        timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
      }

      return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);

      return () => clearInterval(timer);
    }, []);

    return (
      <Typography> {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s</Typography>
    );
  };


  return (
    <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Grid item xs={12} style={{position:'fixed',zIndex:20,width:'100%'}}>
        <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
        <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      </Grid>
      {loading && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box sx={{ display: 'flex', width: '100%', textAlign: 'center', alignContent: 'center', verticalAlign: 'middle', maxWidth: '774px', margin: '10% 50%' }}>
          <CircularProgress />
        </Box>
      </Grid>}
      {!loading &&
        <Grid container item spacing={2} xs={12} sx={{ justifyContent: "center", marginTop: "40px", alignContent: 'center', }}>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2} sx={{ width: "100%", minWidth: { md: '500px' }, height: 'auto' }}>
            <Box sx={{ padding: '5%', width: "100%", display: 'flex', maxHeight: "400px", maxWidth: { xs: '100%', md: '400px' }, borderRadius: "15px", transition: 'transform 0.3s ease, opacity 0.3s ease', '&:hover': { transform: 'scale(1.01)', opacity: 0.9 }, boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', backgroundColor: '#fff', ml: '1%' }}>
              <img src={activeImage ?? noImagePlaceholder} alt='' style={{ width: '100%', maxWidth: '600px', maxHeight: '400px', height: "100%", objectFit: 'contain' }} />
              {isPreOrderEnabled && <Box sx={{ width: '100%', maxWidth: '113px', height: '100%', maxHeight: '20px', margin: { xs: '4px 20px 24px -135px', md: '4px 0 24px -135px' }, padding: '7px 11px 6px 12px', borderRadius: '3px', backgroundColor: '#02b3ad', alignItems: 'center', verticalAlign: 'middle' }}>
                <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 500, color: '#fff', textWrap: 'nowrap', textAlign: 'center' }}>Pre-Order Now</Typography>
              </Box>}
            </Box>
            <Grid container spacing={2} sx={{ width: '100%', maxWidth: { xs: '100%', md: '400px' }, mb: '20%', }}>
              {product?.additionalImages?.map((itemImage) => (
                <Grid item xs={3} sm={3} md={6} sx={{ mt: '40px', '&:hover': { transform: 'scale(1.05)' }, maxHeight: { xs: '100px', sm: '100px', md: '300px' }, maxWidth: { xs: '100px', sm: '300px', md: '300px' } }}>
                  <Box sx={{ border: itemImage?.image === activeImage ? 'solid 1px #02b3ad' : 'none', margin: '10px 5px 4px 2px', padding: '10%', borderRadius: '5px', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', backgroundColor: '#fff' }}>
                    <img src={itemImage?.image} alt='' style={{ width: '100%', height: '100%', objectFit: 'contain', cursor: 'pointer', transition: 'transform 0.3s ease, opacity 0.3s ease' }} onClick={() => setActiveImage(itemImage?.image)} onMouseEnter={() => setActiveImage(itemImage?.image)} />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={10} xl={10} sx={{ width: "100%", flexWrap: "wrap", maxWidth: { lg: '684px' }, ml: { xs: '10%', sm: '10%', md: '1%', lg: '1%', xl: '1%' } }}>
            <Typography sx={{ margin: { lg: '24px 436px 18px 50px' }, fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'bold', textAlign: 'left', color: '#000', textWrap: '' }}>
              {product?.productName}
            </Typography>
            <Typography sx={{ margin: { lg: '18px 807px 10px 50px' }, fontFamily: 'Poppins', fontSize: '14px', textAlign: 'left', color: '#000' }}>
              Description
            </Typography>
            <Typography sx={{ margin: { lg: '10px 203px 10px 50px' }, fontFamily: 'Poppins', fontSize: '14px', textAlign: 'left', color: '#707070' }}>
              {product?.productDescription}
            </Typography>
            {product?.variations?.map((variant) => (
              <Box sx={{ marginLeft: { lg: '50px' }, mr: '1px' }}>
                {variant?.variationName !== 'default' &&
                  <Typography sx={{ margin: { lg: '10px 133px 10px 0px' }, fontFamily: 'Poppins', fontSize: '14px', textAlign: 'left', color: '#000', fontWeight: 'normal' }}>
                    Select {toSentenceCase(variant?.variationName)}
                  </Typography>
                }
                {variant && variant.variationName?.toLowerCase() === 'gender' && variant?.variationName !== 'default' &&
                  variant.variationValues.map((variationValue, key) => (
                    <Tooltip sx={{ color: '#ef0b0b' }} placement="bottom" arrow key={key} title={isOutOfStock(variations, variant.variationName, variationValue) ? 'This variation is out of stock' : ''}>
                      <span>
                        <Button disabled={isOutOfStock(variations, variant.variationName, variationValue)} onClick={() => handleVariations(variant.variationName, variationValue)} variant='container' sx={{ fontWeight: 'normal', width: '100%', maxWidth: '104.2px', height: '48px', margin: '10.5px 10px 10px 0px', padding: '13.5px 37.7px 14.5px 36.5px', borderRadius: '5px', backgroundColor: checkVariationSelected(variationValue) === true ? '#02b3ad' : '#fff', color: checkVariationSelected(variationValue) === true ? '#fff' : '#707070', textTransform: 'capitalize', fontSize: '14px', border: checkVariationSelected(variationValue) === true ? 'none' : 'solid 1px #bfbfbf', "&:hover": { backgroundColor: checkVariationSelected(variationValue) === true ? '#02b3ad' : '#fff', border: checkVariationSelected(variationValue) === true ? 'none' : 'solid 1px #bfbfbf', } }}>
                          {toSentenceCase(variationValue)}
                        </Button>
                      </span>
                    </Tooltip>

                  ))}
                {variant && variant.variationName.toLowerCase() === 'size' && variant?.variationName !== 'default' &&
                  variant.variationValues.map((variationValue, key) => (
                    <Tooltip sx={{ color: '#ef0b0b' }} placement="bottom" arrow key={key} title={isOutOfStock(variations, variant.variationName, variationValue) ? 'This variation is out of stock' : ''}>
                      <span>
                        <Button disabled={isOutOfStock(variations, variant.variationName, variationValue)} onClick={() => handleVariations(variant.variationName, variationValue)} variant='outlined' sx={{ fontWeight: 'normal', width: 'auto', height: '48px', margin: '10.5px 10px 10px 0px', padding: '13.5px 19.5px 14.5px', borderRadius: '5px', backgroundColor: checkVariationSelected(variationValue) === true ? '#02b3ad' : '#fff', color: checkVariationSelected(variationValue) === true ? '#fff' : '#707070', fontSize: '14px', textTransform: 'capitalize', border: checkVariationSelected(variationValue) === true ? 'none' : 'solid 1px #bfbfbf', "&:hover": { backgroundColor: checkVariationSelected(variationValue) === true ? '#02b3ad' : '#fff', border: checkVariationSelected(variationValue) === true ? 'none' : 'solid 1px #bfbfbf', } }}>
                          {toSentenceCase(variationValue)}
                        </Button>
                      </span>
                    </Tooltip>
                  ))}
                {variant && variant.variationName?.toLowerCase() === 'color' && variant?.variationName !== 'default' &&
                  variant.variationValues.map((variationValue, key) => (
                    <Tooltip sx={{ color: '#ef0b0b' }} placement="bottom" arrow key={key} title={isOutOfStock(variations, variant.variationName, variationValue) ? 'This variation is out of stock' : ''}>
                      <span>
                        <Button disabled={isOutOfStock(variations, variant.variationName, variationValue)} onClick={() => handleVariations(variant.variationName, variationValue)} variant='contained' sx={{ fontWeight: 'normal', width: '37px', height: '38px', margin: '10.5px 10px 10px 0px', borderRadius: '5px', backgroundColor: `${variationValue}`, border: `solid 3px #${variationValue}`, "&:hover": { backgroundColor: `${variationValue}`, border: checkVariationSelected(variationValue) === true ? `solid 3px #${variationValue}` : 'none' } }}>
                          {checkVariationSelected(variationValue) === true && <CheckBox sx={{ color: '#fff', display: 'block' }} />}
                        </Button>
                      </span>
                    </Tooltip>
                  ))}
                {variant && variant.variationName?.toLowerCase() === 'Brand' && variant?.variationName !== 'default' &&
                  variant.variationValues.map((variationValue, key) => (
                    <Tooltip sx={{ color: '#ef0b0b' }} placement="bottom" arrow key={key} title={isOutOfStock(variations, variant.variationName, variationValue) ? 'This variation is out of stock' : ''}>
                      <span>
                        <Button disabled={isOutOfStock(variations, variant.variationName, variationValue)} onClick={() => handleVariations(variant.variationName, variationValue)} variant='outlined' sx={{ fontWeight: 'normal', width: 'auto', height: '48px', margin: '10.5px 10px 10px 0px', padding: '13.5px 19.5px 14.5px', borderRadius: '5px', backgroundColor: checkVariationSelected(variationValue) === true ? '#02b3ad' : '#fff', color: checkVariationSelected(variationValue) === true ? '#fff' : '#707070', fontSize: '14px', textTransform: 'capitalize', border: checkVariationSelected(variationValue) === true ? 'none' : 'solid 1px #bfbfbf', "&:hover": { backgroundColor: checkVariationSelected(variationValue) === true ? '#02b3ad' : '#fff', border: checkVariationSelected(variationValue) === true ? 'none' : 'solid 1px #bfbfbf', } }}>
                          {toSentenceCase(variationValue)}
                        </Button>
                      </span>
                    </Tooltip>
                  ))
                }
                {
                  variant && variant.variationName?.toLowerCase() !== 'brand' && variant.variationName?.toLowerCase() !== 'color' && variant.variationName?.toLowerCase() !== 'size' && variant.variationName?.toLowerCase() !== 'size' && variant.variationName?.toLowerCase() !== 'gender' && variant.variationName?.toLowerCase() !== 'color' && variant?.variationName?.toLowerCase() !== 'default' &&
                  variant.variationValues.map((variationValue, key) => (
                    <Tooltip sx={{ color: '#ef0b0b' }} placement="bottom" arrow key={key} title={isOutOfStock(variations, variant.variationName, variationValue) ? 'This variation is out of stock' : ''}>
                      <span>
                        <Button disabled={isOutOfStock(variations, variant.variationName, variationValue)} onClick={() => handleVariations(variant.variationName, variationValue)} variant='outlined' sx={{ fontWeight: 'normal', width: 'auto', height: '48px', margin: '10.5px 10px 10px 0px', padding: '13.5px 19.5px 14.5px', borderRadius: '5px', backgroundColor: checkVariationSelected(variationValue) === true ? '#02b3ad' : '#fff', color: checkVariationSelected(variationValue) === true ? '#fff' : '#707070', fontSize: '14px', textTransform: 'capitalize', border: checkVariationSelected(variationValue) === true ? 'none' : 'solid 1px #bfbfbf', "&:hover": { backgroundColor: checkVariationSelected(variationValue) === true ? '#02b3ad' : '#fff', border: checkVariationSelected(variationValue) === true ? 'none' : 'solid 1px #bfbfbf', } }}>
                          {toSentenceCase(variationValue)}
                        </Button>
                      </span>
                    </Tooltip>

                  ))
                }
              </Box >
            ))}
            <Box sx={{ padding: '1% 0', mt: '1%' }}>
              {product?.additionalServices?.map((service) => (
                <Box key={service.serviceId} className="filter-brand" sx={{ marginLeft: { lg: '50px' } }}>
                  <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontSize: '14px', textAlign: 'left', color: '#000', fontWeight: 'normal' }}>
                    {toSentenceCase(service.serviceName)}
                  </Typography>
                  {service?.selectionType === 'multiple' ? (
                    <Box sx={{ display: "flex", marginTop: "10px", flexDirection: "column", justifyContent: 'start' }}>
                      {(showAllServices[service.serviceId] ? service?.items : service?.items.slice(0, 2)).map((item) => (
                        <Box key={item.itemName} sx={{ display: 'flex' }}>
                          <Checkbox
                            sx={{
                              color: "#b2b4bb",
                              '&.Mui-checked': { color: '#17ae7b' },
                              backgroundColor: '#fff'
                            }}
                            checked={checkedItems[item.itemName] || false}
                            value={item.itemName}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setCheckedItems(prevState => ({
                                ...prevState,
                                [item.itemName]: isChecked,
                              }));
                              handleSelectAdditionalServicesMultiple(service, item, isChecked);
                              if (isChecked) {
                                handleCustomizeClick(service?.serviceId, item?._id, item?.itemName);
                              }
                            }}
                          />
                          <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ color: "#000", fontSize: "14px", fontWeight: "normal", margin: '10px 0 0px 0px' }}>{item.itemName}</Typography>
                            <Typography sx={{ fontWeight: 600, color: '#000000', margin: '10px 0 0px 0px' }}>+ Ksh {item.itemPrice}</Typography>
                            <TextField
                              error={false}
                              id={`custom-value-${item._id}`}
                              size="small"
                              sx={{ width: '100%', maxWidth: '150px', margin: '2.5px 10px', borderRadius: '5px', backgroundColor: '#fff', display: checkedItems[item.itemName] === true ? 'block' : 'none' }}
                              placeholder={'Enter ' + item.itemName}
                              onChange={() => handleCustomizeClick(service?.serviceId, item?._id, item?.itemName)}
                            />
                          </Box>
                        </Box>
                      ))}
                      {service?.items?.length > 2 && <Box onClick={() => handleToggleShowAllItems(service.serviceId)} sx={{ color: '#808285', fontFamily: 'Poppins', fontSize: '14px', textTransform: 'capitalize', textAlign: 'center', m: '0 20px 20px 10px' }}>
                        {showAllServices[service.serviceId] ? (
                          <Box sx={{ display: 'flex' }}>
                            Show Less <KeyboardArrowUp sx={{ width: '100%', maxWidth: '20px', height: '100%', maxHeight: '20px', margin: '1px 0 1px 5px' }} />
                          </Box>
                        ) : (
                          <Box sx={{ display: 'flex' }}>
                            Show More <KeyboardArrowDown sx={{ width: '100%', maxWidth: '20px', height: '100%', maxHeight: '20px', margin: '1px 0 1px 5px' }} />
                          </Box>
                        )}
                      </Box>}
                    </Box>
                  ) : (
                    <Box style={{ display: "flex", marginTop: "10px", flexDirection: "column" }}>
                      <RadioGroup
                        column
                        name={service?.serviceName}
                        value={selectedItem ? selectedItem.itemName : ''}
                      >
                        {(showAllServices[service.serviceId] ? service?.items : service?.items.slice(0, 2)).map((item) => (
                          <Box sx={{ display: 'flex' }} key={item._id}>
                            <FormControlLabel
                              value={item.itemName}
                              control={
                                <Radio
                                  inputProps={{ style: { color: '#02b3ad' } }}
                                  sx={{ color: '#b2b4bb', '&.Mui-checked': { color: '#02b3ad' } }}
                                  checked={selectedItem ? selectedItem.itemName === item.itemName : false}
                                  onClick={() => {
                                    if (selectedItem && selectedItem.itemName === item.itemName) {
                                      setSelectedItem(null);
                                      setSelectedAdditionalServices(prevState => prevState.filter(prevService => prevService.serviceId !== service.serviceId));
                                    } else {
                                      setSelectedItem(item);
                                      handleSelectAdditionalServicesSingle(service, item);
                                    }
                                  }}
                                />
                              }
                              sx={{ textWrap: 'nowrap', objectFit: 'contain', color: '#032541', fontSize: '16px', fontFamily: 'Poppins' }}
                            />
                            <Typography sx={{ color: "#000", fontSize: "16px", fontWeight: "normal", margin: '10px 0 0px 0px' }}>{item.itemName}</Typography>
                            <Typography sx={{ fontWeight: 600, color: '#000000', margin: '10px 0 0px 0px' }}>+ Ksh {item.itemPrice}</Typography>
                          </Box>
                        ))}
                      </RadioGroup>
                      {service?.items?.length > 2 && <Box onClick={() => handleToggleShowAllItems(service.serviceId)} sx={{ color: '#808285', fontFamily: 'Poppins', fontSize: '14px', textTransform: 'capitalize', textAlign: 'center', m: '0 20px 20px 0px' }}>
                        {showAllServices[service.serviceId] ? (
                          <Box sx={{ display: 'flex' }}>
                            Show Less <KeyboardArrowUp sx={{ width: '100%', maxWidth: '20px', height: '100%', maxHeight: '20px', margin: '1px 0 1px 5px' }} />
                          </Box>
                        ) : (
                          <Box sx={{ display: 'flex' }}>
                            Show More <KeyboardArrowDown sx={{ width: '100%', maxWidth: '20px', height: '100%', maxHeight: '20px', margin: '1px 0 1px 5px' }} />
                          </Box>
                        )}
                      </Box>}
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
            <Divider sx={{ width: '100%', maxWidth: '509px', height: '1px', margin: { lg: '29.5px 38px 29.5px 50px' } }} />
            {
              isPreOrderEnabled && <Box>
                <Typography sx={{ margin: { md: '19.5px 70px 20px 51px' }, fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, color: '#000' }}>Payment Mode</Typography>
                <RadioGroup column name={0} value={''} >
                  <Box sx={{ display: 'flex', ml: { md: '50px' } }} key={1}>
                    <FormControlLabel
                      value={0}
                      control={
                        <Radio
                          inputProps={{ style: { color: '#02b3ad' } }}
                          sx={{ color: '#b2b4bb', '&.Mui-checked': { color: '#02b3ad' } }}
                          checked={true}
                          onClick={() => {

                          }}
                        />
                      }
                      sx={{ textWrap: 'nowrap', objectFit: 'contain', color: '#032541', fontSize: '16px', fontFamily: 'Poppins' }}
                    />
                    <Typography sx={{ color: "#000", fontSize: "16px", fontWeight: "normal", margin: '10px 0 0px 0px' }}>{paymentMode === 'onPlaceOrder' ? 'On Placing Order' : 'On Delivery'}</Typography>
                  </Box>
                </RadioGroup>
                <Divider sx={{ width: '100%', maxWidth: '509px', height: '1px', margin: { lg: '29.5px 38px 29.5px 50px' } }} />
              </Box>
            }
            <Box sx={{ display: 'flex', margin: { lg: '0px 50px' } }}>
              <Typography sx={{ margin: '0 11px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 550, lineHeight: 2.57, textAlign: 'left', color: '#032541' }}>SKU:</Typography>
              <Typography sx={{ margin: '0 11px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'left', color: '#707070' }}>{product?.productCode}</Typography>
            </Box>
            <Box sx={{ display: 'flex', margin: { lg: '0px 50px' } }}>
              <Typography sx={{ margin: '0 11px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 550, lineHeight: 2.57, textAlign: 'left', color: '#032541' }}>Category:</Typography>
              <Typography sx={{ margin: '0 11px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'left', color: '#707070' }}>{product?.productCategory}</Typography>
            </Box>
            <Box sx={{ display: 'flex', margin: { lg: '0px 50px' } }}>
              <Typography sx={{ margin: '0 11px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 550, lineHeight: 2.57, textAlign: 'left', color: '#032541' }}>Tags:</Typography>
              <Typography sx={{ margin: '0 11px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'left', color: '#707070' }}>{product?.tags?.map(tag => tag.tagName).join(', ')}</Typography>
            </Box>
            <Box sx={{ display: 'flex', margin: { lg: '0px 50px' } }}>
              <Typography sx={{ margin: '0 11px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 550, lineHeight: 2.57, textAlign: 'left', color: '#032541' }}>Availabity:</Typography>
              <Typography sx={{ margin: '0 11px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'left', color: '#02b3ad' }}>{isPreOrderEnabled ? 'Pre-Order' : (stock > 0 ? 'In Stock' : 'Out of Stock')} </Typography>
            </Box>
            {
              isPreOrderEnabled && <Box sx={{ display: 'flex', margin: { lg: '0px 50px' }, alignItems: 'center' }}>
                <Typography sx={{ margin: '0 11px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 550, lineHeight: 2.57, textAlign: 'left', color: '#032541' }}>Time Left:</Typography>
                <Typography sx={{ margin: '0 11px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'left', color: '#02b3ad' }}><CountdownTimer targetDate={endDate} /> </Typography>
              </Box>
            }
            <Box sx={{ display: 'flex', margin: { lg: '0px 50px' } }}>
              <Typography sx={{ margin: '0 18px 0 0', fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bold', lineHeight: 2.57, textAlign: 'left', color: '#000' }}>Ksh {price + additionalServicesAmount} </Typography>
              {product && product?.pricing?.length > 0 && normalPrice > discountedPrice && <Typography sx={{ margin: '15px 0 2px 1px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', lineHeight: 'normal', textAlign: 'left', color: '#ef0b0b', textDecoration: 'line-through' }}>Ksh {product?.pricing && product?.pricing.length > 0 ? normalPrice + additionalServicesAmount : product?.productPrice}</Typography>}
              {product && product?.pricing?.length > 0 && normalPrice > discountedPrice && <Typography sx={{ margin: '20px 0 5px 17px', fontFamily: 'Poppins', fontSize: '12px', fontWeight: 'normal', lineHeight: 'normal', textAlign: 'left', color: '#02b3ad' }}>(Save Ksh {product?.pricing && product?.pricing.length > 0 ? (normalPrice - discountedPrice) : 0})</Typography>}
            </Box>
            <Grid container rowSpacing={2} columnSpacing={0} sx={{ margin: { lg: '0px 50px' }, alignItems: 'center' }}>
              <Grid item xs={12} sm={12} md={4} sx={{ display: 'flex' }}>
                <Typography sx={{ margin: '10px 0px 0px 0', padding: '8px 5px 7px 1px', borderRadius: '3px', backgroundColor: '#fff' }}>
                  <RemoveCircle onClick={() => { handleDecrementCart() }} sx={{ color: '#909090', width: '30px', height: '30px', objectFit: 'contain' }} />
                </Typography>
                <TextField disabled={stock <= 0} value={count} inputProps={{ style: { textAlign: 'center', color: '#000', fontSize: '14px', height: '15px' } }} sx={{ width: '56px', height: '15px', margin: '0px 10px 0px 10px', padding: '9.5px 6.5px 4.5px 4.5px', borderRadius: '5px', alignItems: 'center', textAlign: 'center' }} variant='outlined' />
                <Typography sx={{ margin: '10px 0px 0px 0', padding: '8px 5px 7px 5px', borderRadius: '3px', backgroundColor: '#fff' }}>
                  <AddCircle disabled={true} onClick={() => { handleIncrementCart() }} sx={{ color: '#02b3ad', width: '30px', height: '30px', objectFit: 'contain' }} />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex' }}>
                <Button disabled={count < 1 || variations.length < 1 || stock < 1} variant='contained' sx={{ width: '100%', minWidth: { md: '175px' }, maxWidth: '175px', height: '50px', margin: '10px 26px 0 1px', padding: '13px 18px 12px 19px', opacity: count < 1 || variations.length < 1 || stock < 1 ? 0.45 : 1, borderRadius: '3px', backgroundColor: count < 1 || variations.length < 1 || stock < 1 ? 'rgba(2, 179, 173, 0.45)' : '#02b3ad', border: 'none', "&.Mui-disabled": { background: "rgba(2, 179, 173, 0.45)", }, '&:hover': { backgroundColor: count < 1 || variations.length < 1 || stock < 1 ? 'rgba(2, 179, 173, 0.45)' : '#02b3ad', border: 'none' } }} onClick={handleAddToCart}>
                  <ShoppingCart sx={{ color: count <= 0 || variations.length === 0 ? '#02b3ad' : '#fff', fontWeight: 'bold', opacity: 0.9 }} />
                  <Typography sx={{ margin: ' 0 0 0 10px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, textAlign: 'left', color: count <= 0 || variations.length === 0 ? '#02b3ad' : '#fff', textTransform: 'capitalize', textWrap: 'nowrap' }}> {!isPreOrderEnabled ? 'Add to Cart' : 'Pre-Order Now'} </Typography>
                </Button>
                {cart?.length > 0 && <Box onClick={() => navigate('/cart')} sx={{ display: 'flex', width: '100%', minWidth: '150px', maxWidth: '170px', height: '100%', maxHeight: '48px', borderRadius: '4px', border: 'solid 1px #02b3ad', alignItems: 'center', margin: '10px 26px 0 8px', }}>
                  <IconButton size="large" sx={{ '& .badge-container': { position: 'absolute', top: '5px', right: '0px', width: '24px', height: '24px', borderRadius: '50%', backgroundColor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', fontWeight: 'bold', fontSize: '12px', border: 'solid 1px #02b3ad' } }}>
                    <ShoppingCartIcon sx={{ fontSize: '1.7rem', color: '#18AFAA' }} />
                    <div className="badge-container">
                      <Typography component="sup" style={{ color: '#02b3ad', fontWeight: 500, padding: '10px 0px', fontSize: '14px' }}>{cart?.length}</Typography>
                    </div>
                  </IconButton>
                  <Typography sx={{ margin: ' 0 0 0 10px', fontSize: '14px', fontWeight: 500, textAlign: 'left', color: '#02b3ad', textWrap: 'nowrap' }}>View Cart</Typography>
                </Box>}
              </Grid>
            </Grid>
          </Grid >
        </Grid >}
    </Grid >
  )
}

export default ProductPage