import { Box, Button, FormControl, Grid, TextField, Typography } from '@mui/material'
import "./index.css";
import Header from './header';
import Footer from './footer';
import { APIProvider, AdvancedMarker, Map } from '@vis.gl/react-google-maps';
import { CallOutlined, EmailOutlined, MapOutlined, PinDropOutlined } from '@mui/icons-material';
import PhoneInput from 'react-phone-input-2';
import { useState } from 'react';

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const Contacts = () => {
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [phone,setPhone] = useState('');
    const [subject,setSubject] = useState('');
    const [message,setMessage] = useState('');

    return (
        <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Grid container justifyContent="center" sx={{ width: '100%', ml: { xs: '2%', sm: '2%', md: '20%' }, mt: '2%', maxWidth: '1134px', }}>
                <Grid item xs={12} sx={{ width: '100%', maxWidth: '1034px', mb: '30px' }}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8162505869077!2d36.82170367496571!3d-1.284159998703634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f10d61a03ddc7%3A0x39b6e2de6cb0b6ea!2sSonalux%20House!5e0!3m2!1sen!2ske!4v1717062323028!5m2!1sen!2ske"
                        style={{ border: 0, width: '100%', height: '384px', borderRadius: '5px' }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </Grid>
                <Grid item xs={12} md={6} sx={{ mb: '30px' }}>
                    <Box sx={{ backgroundColor: '#fafafa', borderRadius: '10px', padding: '32px', textAlign: 'left', height: '100%',maxHeight:'479px' }}>
                        <Typography sx={{ fontFamily: 'Poppins', fontWeight: '500', fontSize: '18px', color: '#010202', mb: '16px' }}>
                            Head Office
                        </Typography>
                        <Box sx={{ display: 'flex', mb: '45px' }}>
                            <PinDropOutlined sx={{ fontSize: '70px', color: "#707070", mr: '20px' }} />
                            <Box>
                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', color: '#4b4b4b', mb: '3px' }}>Our Location</Typography>
                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', color: '#010202' }}>
                                    Moi Avenue Sonalux Building Basement Floor, Nairobi - Kenya
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', mb: '45px' }}>
                            <CallOutlined sx={{ fontSize: '70px', color: "#707070", mr: '20px' }} />
                            <Box>
                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', color: '#4b4b4b', mb: '3px' }}>Mobile Phone</Typography>
                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', color: '#010202' }}>
                                    +254700011817 or +254701028611
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', mb: '45px' }}>
                            <EmailOutlined sx={{ fontSize: '70px', color: "#707070", mr: '20px' }} />
                            <Box>
                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', color: '#4b4b4b', mb: '3px' }}>Email</Typography>
                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', color: '#010202' }}>
                                    info@eastwestfashions.com
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            onClick={() => window.open('https://www.google.com/maps/place/Sonalux+House/@-1.28416,36.824279,16z/data=!4m6!3m5!1s0x182f10d61a03ddc7:0x39b6e2de6cb0b6ea!8m2!3d-1.28416!4d36.8242786!16s%2Fg%2F11f106qx8z?hl=en&entry=ttu')}
                            sx={{ display: 'flex', alignItems: 'center', padding: '12px 20px', borderRadius: '4px', backgroundColor: '#fff', cursor: 'pointer', width: 'fit-content' }}
                        >
                            <MapOutlined sx={{ fontSize: '26px', color: '#707070', mr: '10px' }} />
                            <Typography sx={{ fontFamily: 'Poppins', fontSize: '17px', color: "#707070" }}>View Map</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ padding: '0 0 0 20px', mb: '30px', }}>
                    <Box sx={{ backgroundColor: '#fafafa', borderRadius: '10px', padding: '32px', textAlign: 'left', height: '100%',maxHeight:'479px' }}>
                        <Typography sx={{ fontFamily: 'Poppins', fontWeight: '500', fontSize: '18px', color: '#010202', mb: '16px' }}>
                            Send Us Message
                        </Typography>
                        <Typography sx={{ margin: '13px 22px 6.5px 0', fontFamily: 'Poppins', fontSize: '14px', color: '#010202' }}>
                            Drop us a quick mail for any question and we will get back to you as soon as possible.
                        </Typography>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl sx={{ width: '100%', maxWidth: { xs: '320px', sm: '340px', md: '300px' }, height: '48px', margin: '16px 1px 11px 0px', padding: '4px 2px 14px 0px', borderRadius: '5px', }}>
                                    <TextField InputProps={{ style: { fontSize: '14px', color: '#000', textAlign: 'left', fontFamily: 'Poppins' } }} value={name} onChange={(e) => {setName(e.target.value)}} placeholder="Your Name" sx={{ backgroundColor: '#fff', border: 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl sx={{ width: '100%', maxWidth: { xs: '320px', sm: '340px', md: '300px' }, height: '48px', margin: '16px 1px 11px 0px', padding: '4px 2px 14px 0px', borderRadius: '5px', }}>
                                    <TextField InputProps={{ style: { fontSize: '14px', color: '#000', textAlign: 'left', fontFamily: 'Poppins' } }} value={email} onChange={(e) => {setEmail(e.target.value) }} placeholder="Your Email Address" sx={{ backgroundColor: '#fff', border: 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl sx={{ width: '100%', maxWidth: { xs: '320px', sm: '340px', md: '300px' }, height: '48px', margin: '0px 1px 11px 0px', padding: '4px 2px 14px 0px', borderRadius: '5px', }}>
                                    <PhoneInput country={'ke'} inputStyle={{ width: '100%', maxWidth: { xs: '300px', sm: '300px', md: '300px', lg: '580px' }, height: '58px', margin: '10.5px 0 10px 0.5px', padding: '14.5px 12.5px 13.5px 52.5px', borderRadius: '5px', backgroundColor: '#fff', border: 'solid 1px #bfbfbf', borderRadius: '5px' }} placeholder='Phone Number' value={''} onChange={() => { }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl sx={{ width: '100%', maxWidth: { xs: '320px', sm: '340px', md: '300px' }, height: '48px', margin: '0px 1px 11px 0px', padding: '4px 2px 14px 0px', borderRadius: '5px', }}>
                                    <TextField InputProps={{ style: { fontSize: '14px', color: '#000', textAlign: 'left', fontFamily: 'Poppins' } }} value={''} onChange={() => { }} placeholder="Subject" sx={{ backgroundColor: '#fff', border: 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl sx={{ width: '100%', maxWidth: { xs: '620px', sm: '340px', md: '600px' }, height: '48px', margin: '0px 1px 11px 0px', padding: '4px 2px 14px 0px', borderRadius: '5px', }}>
                                    <TextField rows={4} multiline InputProps={{ style: { fontSize: '14px', color: '#000', textAlign: 'left', fontFamily: 'Poppins' } }} value={''} onChange={() => { }} placeholder="Your Message" sx={{ backgroundColor: '#fff', border: 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sx={{ justifyContent: 'end' }}>
                            <Button sx={{ width: '100%', height: '100%', maxWidth: '175px', maxHeight: '50px', margin: '70px 0 0 60px', padding: '13px 25px 13px 26px', borderRadius: '4px', backgroundColor: '#fff' }}>
                                <Typography sx={{fontFamily:'Poppins',fontSize:'17px',fontWeight:500,color:'#02b3ad',textWrap:'nowrap',textTransform:'capitalize'}}>Send Message</Typography>
                            </Button>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Contacts;