import { Navigate, Outlet } from 'react-router-dom';
import { Box, Grid } from '@mui/material'
import { useEffect, useState } from 'react';
import { HttpRequest } from '../HttpRequest';
import secureLocalStorage from 'react-secure-storage';
import Header from '../customer/header';
import TopNavBar from '../customer/topNavBar';
import SearchNavBar from '../customer/searchBar';
import Navbar from '../customer/navbar';
import Footer from '../customer/footer';

const AuthRoutes = () => {
  const [tokenGenerated, setTokenGenerated] = useState(false);

  useEffect(() => {
    const getBusinessToken = async () => {
      const businessNumber = secureLocalStorage.getItem('businessId');
      if (!businessNumber) {
        const currentDomain = window.location.origin;
        HttpRequest({
          method: 'GET',
          url: `/api/v1/ecommerce/getzedecommerce-domain-details?subdomain=${currentDomain}`,
        }).then(async (data) => {
          if (data.status === 200) {
            secureLocalStorage.setItem('businessId', data.response.businessNumber);
            setTokenGenerated(true);
          }
        }).catch((error) => {
          console.log('Error fetching business token', error);
        });
      } else {
        setTokenGenerated(true);
      }
    };

    getBusinessToken();
  }, []);

  return (
    <Box component={'div'} width={'100%'} sx={{ minHeight: '100vh' }}>
      {tokenGenerated ?
        <Grid container display={'flex'} direction={'column'} height={'100%'} spacing={2}>
          <Outlet />
          <Box mt={'auto'}>
            <Footer />
          </Box>
        </Grid>
        : <Outlet />}
    </Box>
  );
};

export default AuthRoutes;
