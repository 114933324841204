import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, FormHelperText, LinearProgress, CircularProgress, Autocomplete } from '@mui/material';
import imageUploadPlaceholder from '../../images/img-holder-icn.svg';
import backIcon from '../../images/back-icn.svg';
import { Link, useNavigate } from 'react-router-dom';
import generateImageUrl from '../common/generateImageUrl';
import { HttpRequest } from '../HttpRequest';
import { SuccessAlert } from '../common/snackbar/success';
import { ErrorAlert } from '../common/snackbar/error';
import secureLocalStorage from 'react-secure-storage';

const AddCategories = () => {

  const [parentCategories, setParentCategories] = useState([]);

  const [name, setName] = useState("");
  const [parentCategory, setParentCategory] = useState("none");
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState("");
  const [imagePreview, setImagePreview] = useState(imageUploadPlaceholder);

  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [iconError, setIconError] = useState(false);

  const [successShow, setSuccessShow] = useState({ state: false, message: "" })
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })
  const [uploading, setUploading] = useState('');
  const [categorySearch, setCategorySearch] = useState('');
  const [limit, setLimit] = useState(1000);
  const [categories, setCategories] = useState([]);

  const navigate = useNavigate();

  const handleIconUpload = async (event) => {
    setUploading(true);
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const file = URL.createObjectURL(selectedFile);
      setImagePreview(file);

      try {
        const { imageUrl, thumbnailUrl } = await generateImageUrl(selectedFile);
        setIcon(imageUrl);
      } catch (error) {
        console.error('Error generating image URL:', error);
      } finally {
        setUploading(false);
      }
    } else {
      setUploading(false);
    }
  };

  const handleSubmit = () => {
    let isValid = true;
    setNameError(false);
    setDescriptionError(false);
    setIconError(false);

    if (name === "") {
      setNameError(true);
      isValid = false;
    }
    if (description === "") {
      setDescriptionError(true)
      isValid = false;
    }
    if (icon === "") {
      setIconError(true);
      isValid = false;
    }

    if (isValid) {
      HttpRequest({
        method: "POST",
        url: `/api/v1/ecommerce/create_category`,
        body: {
          categoryName: name,
          parentCategoryName: parentCategory,
          categoryDescription: description,
          thumbnail: icon
        },
      }).then((data) => {
        console.log('Create category response success', data.response.error);
        if (data.status === 200) {
          setSuccessShow({ state: true, message: "Category created successfully!" })
          setTimeout(() => {
            navigate('/zed_ecommerce/categories')
          }, 1000);
        } else {
          const message = data.response.message;
          setErrorShow({ state: true, message });
        }
      }).catch((error) => {
        console.log('Create category response error', error);
        setErrorShow({ state: true, message: 'Failed to create category!' })
      });
    }
  }
  const getCategories = () => {
    const businessNumber = secureLocalStorage.getItem("businessId");
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/get_categories?businessNumber=${businessNumber}&categoryName=${categorySearch}&limit=${limit}`
    }).then((data) => {
      console.log('Fetch categories response', data.response.data);
      let rawCategories = data.response.data;
      const options = [];

      const addCategoryWithLevel = (category, level = 0) => {
        options.push({
          label: category.categoryName,
          value: category.categoryName,
          level,
          disabled: category.subCategories.length > 0
        });
        category.subCategories.forEach(subCategory => {
          addCategoryWithLevel(subCategory, level + 1);
        });
      };

      rawCategories.forEach(category => addCategoryWithLevel(category));

      setCategories(prevCategories => data.response.data.length > 0 ? options : [...prevCategories, ...options]);
      setLimit(data?.response?.count);
    }).catch((error) => {
      console.log('Error fetching categories', error);
    });
  }
  useEffect(() => {
    getCategories();
  }, [categorySearch]);

  const renderMenuItems = (items, level = 1) => {
    const menuItems = [];
    items.forEach((item) => {
      menuItems.push(
        <MenuItem key={item._id} value={item.categoryName} style={{ paddingLeft: level * 20, width: '98px', height: '20px', margin: '14px 21px 15px 22px', fontFamily: 'Poppins', fontSize: '16px', textAlign: 'left', color: '#707070', fontWeight: level < 2 ? 550 : 'normal', "&:hover": { backgroundColor: '#fff' } }}>
          {item.categoryName}
        </MenuItem>
      );
      if (item.subCategories && item.subCategories.length > 0) {
        const subMenuItems = renderMenuItems(item.subCategories, level + 1);
        menuItems.push(...subMenuItems);
      }
    });
    return menuItems;
  };
  const findCategoryByName = (categories, categoryName) => {
    for (const category of categories) {
      if (category.categoryName === categoryName) {
        return category;
      }

      if (category.subCategories && category.subCategories.length > 0) {
        const foundInChildren = findCategoryByName(category.subCategories, categoryName);
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }

    return null;
  };
  const handleCategorySearch = (e) => {
    const searchValue = e.target.value;

    const filteredCategory = findCategoryByName(categories, searchValue);

    if (filteredCategory) {
      setParentCategory(searchValue);
    } else {
      console.log("Category not found");
    }
  };
  return (
    <Box component="main" sx={{ margin: '3% 0 0 22px', padding: '0px 34px 136px 5px' }}>
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      <Grid container spacing={2} columns={2}>
        <Grid item sx={{ display: 'flex' }} lg={12}>
          <Typography sx={{ width: '136px', fontFamily: 'Poppins', height: '20px', textWrap: 'nowrap', margin: '30px 823px 1px 0', fontSize: '28px', fontWeight: 600, display: 'flex' }}>
            <Link to='/zed_ecommerce/categories' style={{ textDecoration: 'none' }}>  <img src={backIcon} alt='' style={{ width: '20px', height: '30px', margin: '3px 10px 2px 0', objectFit: 'contain' }} /></Link>  Add Product Category
          </Typography>
        </Grid>
        <Grid item sx={{ display: 'flex' }} lg={12}>
          <Typography sx={{ margin: '10px 675px 19.5px 0', fontFamily: 'Poppins', fontSize: '16px', textWrap: 'nowrap', fontWeight: 'normal', color: '#707070' }}>
            Add category details and set a thumbnail image.
          </Typography>
          <Button onClick={() => { navigate('/zed_ecommerce/categories') }} variant='contained' sx={{ width: '125px', height: '45px', margin: '35px 0 2.5px 424.5px', backgroundColor: '#F4F5F6', textTransform: 'capitalize', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 800, color: '#707070', padding: '12.5px 37px 12.5px 38px', boxShadow: 'none', "&:hover": { backgroundColor: '#F4F5F6', boxShadow: 'none' } }}>
            Cancel
          </Button>
        </Grid>
        <Grid item lg={12}>
          <Divider sx={{ width: '25%', height: '1px', margin: '-39.5px 0 19.5px 0.5px', backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sx={{ display: 'flex' }} lg={12}>
          <form>
            <FormControl>
              <TextField helperText={nameError ? 'Category name is required.' : ''} error={nameError} value={name} onChange={(e) => setName(e.target.value)} label='Category Name' variant='outlined' sx={{ width: '390px' }} inputProps={{ width: '390px', height: '54px', margin: '12px 0 0', padding: '17px 278px 17px 15px', borderRadius: '4px', border: 'solid 1px #e0e0e0', backgroundColor: '#fff' }} />
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: '35px' }} >

              {/* <Select value={parentCategory} onChange={handleChangeParentCategory} label="Select Category" sx={{ width: '390px', height: '54px', margin: '12px 0 0', padding: '17px 15px', borderRadius: '4px', backgroundColor: '#fff', border: 'solid 1px #e0e0e0', "&:hover": { backgroundColor: '#fff' } }}>
                <MenuItem value="" label="None" sx={{ fontFamily: 'Poppins', marginTop: '10px', fontSize: '16px', fontWeight: 600, textAlign: 'left', color: '#707070', "&:hover": { backgroundColor: '#fff' } }}>None</MenuItem>
                {parentCategories.map((category) => {
                  const menuItem = (
                    <MenuItem key={category._id} value={category.categoryName} sx={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: 600, textAlign: 'left', color: '#707070', "&:hover": { backgroundColor: '#fff' } }}>
                      {category.categoryName}
                    </MenuItem>
                  );
                  const subcategoryMenuItems = renderMenuItems(category.subCategories);
                  return [menuItem, ...subcategoryMenuItems];
                })}
              </Select> */}
              <Autocomplete
                style={{ width: "100%", maxWidth: '390px' }}
                id="outlined-select-meals"
                options={categories}
                value={parentCategory}
                onInputChange={(event, newInputValue) => {
                  setCategorySearch(newInputValue);
                }}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setParentCategory(newValue.label);
                    handleCategorySearch(event);
                  } else {
                    setParentCategory('');
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Product Category"
                    helperText=""
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                    sx={{ ml: option.level * 2, fontWeight: (2 - option.level) * 200, fontFamily: 'Poppins' }}
                  >
                    {option.label}
                  </Box>
                )}
              />
              <FormHelperText> To set a category as parent category, select <b>None</b></FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: '35px' }}>
              <TextField helperText={descriptionError ? 'Category description is required.' : ''} error={descriptionError} value={description} onChange={(e) => setDescription(e.target.value)} label='Category Description' variant='outlined' multiline rows={4} sx={{ width: '390px' }} inputProps={{ margin: '35px 0 0', padding: '15px 242px 35px 15px', borderRadius: '4px', border: 'solid 1px #e0e0e0', backgroundColor: '#fff' }} />
            </FormControl>
            <FormControl >
              <Typography sx={{ margin: '15px 165px 7px 0', fontFamily: 'Poppins', fontWeight: 600, fontSize: '16px', color: '#000' }}>Thumbnail/Icon</Typography>
              <label htmlFor="upload-photo" style={{ border: iconError ? 'solid 1px red' : '', width: '400px' }}>
                <input style={{ display: 'none' }}
                  id="upload-photo"
                  name="upload-photo"
                  type="file" onChange={handleIconUpload} accept=".png, .jpeg, .jpg" />

                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ width: "320px", height: "220px", borderRadius: "12px", border: "solid 1px #e0e0e0", backgroundColor: "#fff", alignItems: 'center' }}>
                    <Box sx={{ width: "200px", height: "200px", borderRadius: "5px", backgroundColor: "#f5f5f5", margin: "10px", textAlign: "center", alignContent: "center" }}>
                      <img src={imagePreview} alt="" style={{ width: "100%", height: "100%", maxHeight: '150px', maxWidth: '150px', objectFit: "contain", alignItems: "center", textAlign: "center" }} />
                    </Box>
                  </Box>
                  <Typography sx={{ width: '226px', height: '35px', textWrap: 'nowrap', textAlign: 'center', margin: '25px 669px 46.5px 15px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', color: '#707070', textAlign: 'left', fontWeight: 'normal', fontStretch: 'normal' }}>
                    Recommended Size  <br />(1000px x 1000px)  <br />
                    PNG, JPG or JEPG
                  </Typography>
                </Box>
                {uploading &&
                  <Box>
                    <LinearProgress sx={{ marginTop: '0%' }} /> <Typography>Uploading File... </Typography>
                  </Box>
                }
              </label>
              <FormHelperText sx={{ color: 'red' }}>{iconError ? 'Category Thumbnail/Icon is required.' : ''}</FormHelperText>
            </FormControl>
            <Divider sx={{ width: '25%', height: '1px', margin: '19.5px 0 19.5px 0.5px', backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />
            <Button onClick={handleSubmit} variant='contained' sx={{ width: '125px', height: '45px', margin: '0px 0.5px 36.5px 265px', backgroundColor: name !== "" && description !== "" && icon !== "" ? "#032541" : "#b4bcc4", textTransform: 'capitalize', "&:hover": { backgroundColor: name !== "" && description !== "" && icon !== "" ? "#032541" : "#b4bcc4", } }}>
              Add
            </Button>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddCategories;