import { Box, Button, Grid, Tab, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomSearchInput from '../common/CustomSearchInput'
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import { HttpRequest } from '../HttpRequest';
import CurrencyFormatter from '../common/currencyFormatter';
import { Visibility } from '@mui/icons-material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import CustomSelect from '../common/customSelectInput';
import { CustomizedDate } from '../common/customDate';
import {startOfToday,startOfYesterday, endOfToday, endOfYesterday, subDays, startOfMonth, endOfMonth, subMonths, format} from 'date-fns';
import ExportData from '../common/exportData';

const getLastMonthRange = (date) => {
  const previousMonth = subMonths(date, 1);
  const start = startOfMonth(previousMonth);
  const end = endOfMonth(previousMonth);
  return { start, end };
};


const AntTabs = styled(TabList)({ borderBottom: "none", "& .MuiTabs-indicator": { backgroundColor: "#fff" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none", maxWidth: 140, [theme.breakpoints.up("sm")]: { minWidth: 0 },
  fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1), width: '100%',
  fontSize: "18px", background: '#f5f6f7', borderRadius: '5px', fontStretch: "normal", fontStyle: "normal",
  letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","),
  "&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#fff", backgroundColor: '#032541', borderBottom: '', fontWeight: 600 },
  "&.Mui-focusVisible": { backgroundColor: "none" },
}));
const Orders = () => {
  const [search, setSearch] = useState('')
  const [tabValue, setTabValue] = useState('all');
  const [dataGridPageSize, setPageSize] = useState(10);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
  const [paymentStatus, setPaymentStatus] = useState('paid');
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [orderType, setOrderType] = useState('');
  const navigate = useNavigate();
  const [dataToExport, setDataToExport] = useState([]);
  const [tableColumns, setTableColumns] = useState([
    {
      field: 'orderNumber',
      headerName: 'Order Number',
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
    },
    {
      field: 'status',
      headerName: 'Payment Status',
    },
    {
      field: 'date',
      headerName: 'Date',
    },
    {
      field: 'orderStatus',
      headerName: 'Order Status',
    },
    {
      field: 'itemsCount',
      headerName: 'No. of Items',
    },
    {
      field: 'amount',
      headerName: 'Amount',
    },
    {
      field: 'customerAddress',
      headerName: 'Customer Address',
    },
  ]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '8px', mr: '20px' }}>
        <ExportData data={dataToExport} tableColumn={tableColumns} title={`Orders Export`} />
      </GridToolbarContainer>
    );
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const formatDate = (value) => {
    const dateString = value;
    const date = new Date(dateString);

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    return `${month} ${day}, ${year} ${time}`;
  };
  const handleRowClick = (event, rowId) => {
    event.stopPropagation();
    navigate(`/zed_ecommerce/orders/${rowId}/details`)
  }

  const columns = [
    {
      field: 'orderNumber', headerName: 'order Number', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Order Number  </Box>) },
      renderCell: (params) => {
        return (
          <Box onClick={(event) => handleRowClick(event, params?.row?._id)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }} > {params?.row?.orderNumber}  </Box>)
      }
    },
    {
      field: 'createdAt', headerName: 'Date', flex: 1.5,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Date  </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event) => handleRowClick(event, params?.row?._id)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          {formatDate(params?.row?.createdAt)}
        </Box>)
      }
    },
    {
      field: 'customerName', headerName: 'Customer Name', flex: 1.5,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Customer Name  </Box>) },
      renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?._id)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>{params?.row?.customerName}  </Box>) }
    },
    {
      field: 'customerAddress', headerName: 'Delivery Address', flex: 2,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Delivery Address  </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event) => handleRowClick(event, params?.row?._id)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          {typeof params?.row?.customerAddress === 'object' && params?.row?.customerAddress !== null ? (
            <>
              {params?.row?.customerAddress?.address && <div>{params?.row?.customerAddress?.address}</div>}
              {params?.row?.customerAddress?.apartment && <div>{params?.row?.customerAddress?.apartment}</div>}
              {params?.row?.customerAddress?.city && <div>{params?.row?.customerAddress?.city}</div>}
            </>
          ) : (
            params?.row?.customerAddress
          )}
        </Box>)
      }
    },
    {
      field: 'itemsCount', headerName: 'Items', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Items  </Box>) },
      renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?._id)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>    {params?.row?.itemsCount}  </Box>) }
    },
    {
      field: 'amount', headerName: 'Amount', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Amount  </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event) => handleRowClick(event, params?.row?._id)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          {CurrencyFormatter(params?.row?.amount, 'KES')}
        </Box>)
      }
    },
    {
      field: 'paymentStatus', headerName: 'Payment Status', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Payment Status  </Box>) },
      renderCell: (params) => { return (<Box onClick={(event) => handleRowClick(event, params?.row?._id)} style={{ cursor: 'pointer', color: getStatusColor(params), fontSize: "15px", fontWeight: "600", textTransform: 'capitalize' }}> {params?.row?.status}  </Box>) }
    },
    {
      field: 'status', headerName: 'Order Status', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Order Status  </Box>) },
      renderCell: (params) => {
        return (
          <Box onClick={(event) => handleRowClick(event, params?.row?._id)} style={{ cursor: 'pointer', color: getOrderStatusColor(params), fontSize: "16px", fontWeight: "600", textAlign: 'left' }}>
            {params.row?.orderStatus ?? 'Pending'}
          </Box>
        )
      }
    },
    {
      field: 'action', headerName: 'Action', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}>   </Box>) },
      renderCell: (params) => {
        return (
          <Box onClick={(event) => handleRowClick(event, params?.row?._id)} style={{ cursor: 'pointer', color: '#707070', fontSize: "15px", fontWeight: "500", marginTop: '10px' }}>
            <Visibility onClick={() => { navigate(`/zed_ecommerce/orders/${params?.row?._id}/details`) }} />
          </Box>)
      }
    },
  ]
  function getStatusColor(params) {
    const statusColors = {
      'paid': '#17ae7b',
      'unpaid': '#dc3545',
    };
    return statusColors[params?.row?.status] || '#dc3545';
  }
  function getOrderStatusColor(params) {
    const statusColors = {
      'Received': '#707070',
      'Delivered': '#17ae7b',
      'Shipped': '#000000',
      'Processing': '#f79009'
    };
    return statusColors[params.row?.orderStatus] || '#dc3545';
  }
  const sanitizeSearchQuery = (query) => {
    return query.replace(/\D/g, '');
  };
  const getOrders = () => {
    setPageState((old) => ({ ...old, isLoading: true }));
  
    let status = tabValue === 'all' ? '' : tabValue;
    const url = `/api/v1/ecommerce/orders?page=${pageState.page}&limit=${pageState.pageSize}&orderStatus=${status}&searchValue=${sanitizeSearchQuery(search)}&status=${paymentStatus}&startDate=${from ?? ''}&endDate=${to ?? ''}&deliveryMethod=${deliveryMethod}&isPreOrder=${orderType}`;
  
    HttpRequest({
      method: 'GET',
      url: url,
    }).then((data) => {
      console.log(data.response?.data, 'data.response.data', status);
      setPageState({
        ...pageState,
        data: data?.response?.data,
        total: data?.response?.count,
        isLoading: false
      });
      getDataToExport(data?.response?.count, status, search, paymentStatus, from, to, deliveryMethod, orderType);
    }).catch((error) => {
      console.log('Error', error);
    });
  };
  
  const getDataToExport = (limit, status, search, paymentStatus, from, to, deliveryMethod, orderType) => {
    const url = `/api/v1/ecommerce/orders?page=${pageState.page}&limit=${limit}&orderStatus=${status}&searchValue=${sanitizeSearchQuery(search)}&status=${paymentStatus}&startDate=${from ?? ''}&endDate=${to ?? ''}&deliveryMethod=${deliveryMethod}&isPreOrder=${orderType}`;
    return HttpRequest({
      method: 'GET',
      url: url
    }).then((data) => {
      const filteredOrders = data?.response?.data?.map(({ _id, userId, customerAddress, createdAt, ...rest }) => ({
        ...rest,
        date: formatDate(createdAt),
        customerAddress: `${customerAddress?.address ?? ''}, ${customerAddress?.city ?? 'N/A'}, ${customerAddress?.apartment ?? 'N/A'}, ${customerAddress?.county ?? 'N/A'}, ${customerAddress?.postalCode ?? 'N/A'}`
      }));
      console.log('filteredOrders', filteredOrders);
      setDataToExport(filteredOrders);
      return filteredOrders; 
    }).catch((error) => {
      console.log('Error in getDataToExport', error);
      throw error;
    });
  };
  

  const [range, setRange] = useState('');
  const status = [
    { id: 2, label: 'Paid', value: 'paid' },
    { id: 3, label: 'Unpaid', value: 'unpaid' },
    { id: 4, label: 'Partially Paid', value: 'partial' },
  ];
  const methods = [
    { id: 3, label: 'Delivery', value: 'ship' },
    { id: 4, label: 'Pickup', value: 'pickup' },
  ];
  const types = [
    { id: 3, label: 'Orders', value: false },
    { id: 4, label: 'Pre-Orders', value: true },
  ];
  const ranges = [
    { id: 1, label: 'Select Date', value: '' },
    { id: 2, label: 'Today', value: 'today' },
    { id: 3, label: 'Yesterday', value: 'yesterday' },
    { id: 4, label: 'Last 7 Days', value: 'oneWeek' },
    { id: 5, label: 'This Month', value: 'thisMonth' },
    { id: 6, label: 'Last Month', value: 'lastMonth' },
    { id: 7, label: 'Custom Range', value: 'customDate' },
  ];
  useEffect(() => {
    getOrders()
  }, [tabValue, search, dataGridPageSize, pageState.page, paymentStatus, to, from, deliveryMethod, orderType])
  useEffect(() => {
    const updateDates = (range) => {
      const today = new Date();
      let fromDate = null;
      let toDate = null;

      switch (range) {
        case 'today':
          fromDate = format(startOfToday(), 'MM/dd/yyyy');
          toDate = format(endOfToday(), 'MM/dd/yyyy');
          break;
        case 'yesterday':
          fromDate = format(startOfYesterday(), 'MM/dd/yyyy');
          toDate = format(endOfYesterday(), 'MM/dd/yyyy');
          break;
        case 'oneWeek':
          fromDate = format(subDays(today, 7), 'MM/dd/yyyy');
          toDate = format(endOfToday(), 'MM/dd/yyyy');
          break;
        case 'thisMonth':
          fromDate = format(startOfMonth(today), 'MM/dd/yyyy');
          toDate = format(endOfMonth(today), 'MM/dd/yyyy');
          break;
        case 'lastMonth':
          ({ start: fromDate, end: toDate } = getLastMonthRange(today));
          fromDate = format(fromDate, 'MM/dd/yyyy');
          toDate = format(toDate, 'MM/dd/yyyy');
          break;
        default:
          fromDate = null;
          toDate = null;
          break;
      }

      setFrom(fromDate);
      setTo(toDate);
    };

    updateDates(range);
  }, [range]);


  return (
    <div style={{ marginTop: '4%' }}>
      <Box>
        <Typography variant='h4' sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600" }}>Orders</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '20px' }}>
        <CustomSearchInput placeholder={"Search Orders Id"} name="search" sm={"sm"} value={search} onChange={(e) => setSearch(e.target.value)} />
        <Box sx={{ mr: '1%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <CustomSelect sx={{ mr: '10px', mt: '8px' }} value={orderType} placeholder='Order Type' options={types} sm={"xs"} onChange={(e) => setOrderType(e.target.value)} />
          <CustomSelect sx={{ mr: '10px', mt: '8px' }} value={deliveryMethod} placeholder='Delivery Method' options={methods} sm={"xl"} onChange={(e) => setDeliveryMethod(e.target.value)} />
          <CustomSelect sx={{ mr: '10px', mt: '8px' }} value={paymentStatus} options={status} sm={"xs"} onChange={(e) => setPaymentStatus(e.target.value)} />
          <Box sx={{ mr: '2%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <CustomSelect
              sx={{ mr: '10px', mt: '8px' }}
              value={range}
              options={ranges}
              sm={"sm"}
              onChange={(e) => setRange(e.target.value)}
            />
            {range === 'customDate' && (
              <CustomizedDate setDateFrom={setFrom} setDateTo={setTo} style={{ display: "flex", alignItems: "center" }} />
            )}
          </Box>
        </Box>
      </Box>
      <Box>
        <TabContext value={tabValue}>
          <AntTabs value={tabValue} onChange={handleChange} sx={{ display: 'flex' }}>
            <AntTab label="All" value="all" />
            <AntTab label="Received" value="Received" style={{ marginLeft: '20px' }} />
            <AntTab label="Processing" value="Processing" style={{ marginLeft: "20px" }} />
            <AntTab label="Shipped" value="Shipped" style={{ marginLeft: "20px" }} />
            <AntTab label="Delivered" value="Delivered" style={{ marginLeft: "20px" }} />
            <AntTab label="Pending" value="Pending" style={{ marginLeft: "20px" }} />
          </AntTabs>
          <TabPanel value="all" style={{ marginLeft: '-30px' }}>
            <Grid item>
              <DataGrid
                sx={{ minHeight: '500px', height: 'auto', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                rowCount={pageState.total}
                pagination
                loading={pageState.isLoading}
                page={pageState.page - 1}
                pageSizeOptions={[1, 10, 20, 50, 100]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: dataGridPageSize,
                      page: pageState.page - 1,
                    },
                  },
                }}
                pageSize={dataGridPageSize}
                onPaginationModelChange={(data) => {
                  const { pageSize, page } = data;
                  setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                  setPageSize(pageSize)
                }}
                paginationMode="server"
                columns={columns}
                rows={pageState.data}
                getRowId={(row) => row._id}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </Grid>
          </TabPanel>
          <TabPanel value="Received" style={{ marginLeft: '-30px' }}>
            <Grid item>
              <DataGrid
                sx={{ minHeight: '500px', height: 'auto', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                rowCount={pageState.total}
                pagination
                loading={pageState.isLoading}
                page={pageState.page - 1}
                pageSizeOptions={[1, 10, 20, 50, 100]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: dataGridPageSize,
                      page: pageState.page - 1,
                    },
                  },
                }}
                pageSize={dataGridPageSize}
                onPaginationModelChange={(data) => {
                  const { pageSize, page } = data;
                  setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                  setPageSize(pageSize)
                }}
                paginationMode="server"
                columns={columns}
                rows={pageState.data}
                getRowId={(row) => row._id}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </Grid>
          </TabPanel>
          <TabPanel value="Processing" style={{ marginLeft: "-30px" }}>
            <Grid item>
              <DataGrid
                sx={{ minHeight: '500px', height: 'auto', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                rowCount={pageState.total}
                pagination
                loading={pageState.isLoading}
                page={pageState.page - 1}
                pageSizeOptions={[1, 10, 20, 50, 100]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: dataGridPageSize,
                      page: pageState.page - 1,
                    },
                  },
                }}
                pageSize={dataGridPageSize}
                onPaginationModelChange={(data) => {
                  const { pageSize, page } = data;
                  setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                  setPageSize(pageSize)
                }}
                paginationMode="server"
                columns={columns}
                rows={pageState.data}
                getRowId={(row) => row._id}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </Grid>
          </TabPanel>
          <TabPanel value="Shipped" style={{ marginLeft: "-30px" }}>
            <Grid item>
              <DataGrid
                sx={{ minHeight: '500px', height: 'auto', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                rowCount={pageState.total}
                pagination
                loading={pageState.isLoading}
                page={pageState.page - 1}
                pageSizeOptions={[1, 10, 20, 50, 100]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: dataGridPageSize,
                      page: pageState.page - 1,
                    },
                  },
                }}
                pageSize={dataGridPageSize}
                onPaginationModelChange={(data) => {
                  const { pageSize, page } = data;
                  setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                  setPageSize(pageSize)
                }}
                paginationMode="server"
                columns={columns}
                rows={pageState.data}
                getRowId={(row) => row._id}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </Grid>
          </TabPanel>
          <TabPanel value="Delivered" style={{ marginLeft: "-30px" }}>
            <Grid item>
              <DataGrid
                sx={{ minHeight: '500px', height: 'auto', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                rowCount={pageState.total}
                pagination
                loading={pageState.isLoading}
                page={pageState.page - 1}
                pageSizeOptions={[1, 10, 20, 50, 100]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: dataGridPageSize,
                      page: pageState.page - 1,
                    },
                  },
                }}
                pageSize={dataGridPageSize}
                onPaginationModelChange={(data) => {
                  const { pageSize, page } = data;
                  setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                  setPageSize(pageSize)
                }}
                paginationMode="server"
                columns={columns}
                rows={pageState.data}
                getRowId={(row) => row._id}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </Grid>
          </TabPanel>
          <TabPanel value="Pending" style={{ marginLeft: "-30px" }}>
            <Grid item>
              <DataGrid
                sx={{ minHeight: '500px', height: 'auto', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                rowCount={pageState.total}
                pagination
                loading={pageState.isLoading}
                page={pageState.page - 1}
                pageSizeOptions={[1, 10, 20, 50, 100]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: dataGridPageSize,
                      page: pageState.page - 1,
                    },
                  },
                }}
                pageSize={dataGridPageSize}
                onPaginationModelChange={(data) => {
                  const { pageSize, page } = data;
                  setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                  setPageSize(pageSize)
                }}
                paginationMode="server"
                columns={columns}
                rows={pageState.data}
                getRowId={(row) => row._id}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </Grid>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  )
}

export default Orders