import { ArrowBackIos, CancelOutlined, CheckCircle, CircleOutlined, MoreVert } from "@mui/icons-material";
import { Box, Button, CircularProgress, Divider, FormControl, FormControlLabel, FormHelperText, Grid, Modal, Radio, RadioGroup, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { HttpRequest } from "../../HttpRequest";
import { useEffect, useState } from "react";
import OrderDetails from "../../Orders/OrderDetails";
import DateFormatter from "../../common/dateFormatter";
import CurrencyFormatter from "../../common/currencyFormatter";
import imagePlaceHolder from "../../../images/No-Image-Placeholder.png";
import secureLocalStorage from "react-secure-storage";
import cardIcon from "../../../images/card.png";
import mpesaIcon from "../../../images/mpesa.png";
import PhoneInput from "react-phone-input-2";
import { SuccessAlert } from "../../common/snackbar/success";
import { ErrorAlert } from "../../common/snackbar/error";
import { useDispatch } from "react-redux";
import loadingIcon from "../../../images/loading.svg";
import capitalizeFirstLetter from "../../common/capitalize";

const base_url = process.env.REACT_APP_WEB_SOCKET_URL;

const CustomerOrderDetails = () => {
    const orderProcessingModal = {
        borderRadius: "12px", backgroundColor: "#fff", position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', width: "260px", height: "176px", p: { xs: '49px 50px 80px', sm: '49px 50px 80px', md: '91px 101px 90px', lg: '91px 101px 90px' }, border: '1px #707070', m: { lg: '36px 227px 7px 51px' }, boxShadow: ' 3px 0 20px 0 rgba(0, 0, 0, 0.16)', textAlign: 'center'
    }
    const { orderId } = useParams();
    const [order, setOrder] = useState({});
    const [loading, setLoading] = useState(false);
    const [orderPaymentDetails, setOrderPaymentDetails] = useState({});
    const [customer, setCustomer] = useState({});
    const [retryPayment, setRetryPayment] = useState(false);

    const [bankPaybillStatus, setBankPaybillStatus] = useState(false);
    const [mpesaStatus, setMpesaStatus] = useState(false);
    const [payCardStatus, setPayCardStatus] = useState(false);

    const [paymentMethod, setPaymentMethod] = useState('');
    const [mpesaPhoneNumber, setMpesaPhoneNumber] = useState('');
    const [mobileMoneyNumber, setMobileMoneyNumber] = useState('');
    const [paymentMethodError, setPaymentMethodError] = useState(false);

    const [mpesaPhoneNumberError, setMpesaPhoneNumberError] = useState(false);
    const [mobileMoneyNumberError, setMobileMoneyNumberError] = useState(false);

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [openOrderProcessing, setOpenOrderProcessing] = useState(false);
    const handleOpenOrderProcessing = () => { setOpenOrderProcessing(true) }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getOrderDetails = () => {
        setLoading(true);
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/getzedecommerce-order-byid?orderId=${orderId}`,
        }).then((data) => {
            if (data.status === 200) {
                console.log('orderDetails', data.response);
                setOrder(data.response.data);
            }
        }).catch((error) => {
            console.error(error, 'error');
        }).finally(() => {
            setLoading(false);
        })
    }
    function getOrderStatusColor(orderStatus) {
        const statusColors = {
            'Received': '#707070',
            'Delivered': '#17ae7b',
            'Shipped': '#000000',
            'Processing': '#f79009'
        };
        return statusColors[orderStatus] || '#707070';
    }


    const getOrderPaymentDetails = () => {
        HttpRequest({
            method: 'POST',
            url: `/api/order_payment_status`,
            body: {
                "pushyTransactionId": orderId,
                "businessNo": secureLocalStorage.getItem("businessId"),
            }
        }).then(async (data) => {
            setOrderPaymentDetails(data.response.order);

        }).catch((error) => {
            console.log('Error fetching categories');
        });
    }
    const getCustomerDetails = () => {
        HttpRequest({
            method: 'GET',
            url: `/api/get_customer_by_customer_id?customerId=${order?.customerId}`
        }).then((data) => {
            console.log('Fetch customer profile', data.response.data);
            setCustomer(data.response.data);
        }).catch((error) => {
            console.log(error);
        });
    }
    const checkPaymentStatus = async () => {
        await HttpRequest({
            method: 'GET',
            url: `/api/get_payment_methods_with_status`
        }).then((data) => {
            console.log('Registration response ', data.response);
            const mpesaStatus = data.response.data.find(method => method.name === "Mpesa")?.status;
            const cardStatus = data.response.data.find(method => method.name === "Card")?.status;
            const cashStatus = data.response.data.find(method => method.name === "Cash")?.status;
            const paybillStatus = data.response.data.find(method => method.name === "Banks")?.paymentOptions[0].kcb[0]?.status
            const bankStatus = data.response.data.find(method => method.name === "Banks")?.paymentOptions[0].kcb[2]?.status;
            const momoPayStatus = data.response?.data?.find(method => method?.name === 'MTN Momo')?.status
            setMpesaStatus(mpesaStatus)
            //  setCardStatus(cardStatus)
            setBankPaybillStatus(paybillStatus)
            setPayCardStatus(cardStatus)
            // setBankStatus(bankStatus)
            // setMomoStatus(momoPayStatus)
            //  setCashStatus(cashStatus)
        }).catch((error) => {
            console.log('Error fetching payment methods', error);
        });
    }

    const handleRetryPayment = () => {
        if (paymentMethod === '') {
            setErrorShow({ state: true, message: 'Please select payment method.' });
            return;
        }
        if (paymentMethod === 'mpesa') {
            if (mpesaPhoneNumber === '') {
                setErrorShow({ state: true, message: 'Phone number is required.' });
                return;
            }
        }
        if (paymentMethod === 'kcbpaybill') {
            if (mobileMoneyNumber === '') {
                setErrorShow({ state: true, message: 'Phone number is required.' });
                return;
            }
        }
        setOpenOrderProcessing(true);
        const payload = {
            amount: order.transamount,
            phone: paymentMethod === 'mpesa' ? mpesaPhoneNumber : mobileMoneyNumber,
            businessId: order.businessId,
            orderIds: [
                order._id
            ],
            type: 'order',
            paymentChanel: 'Web',
            discount: 0
        }
        HttpRequest({
            method: 'POST',
            url: paymentMethod === 'mpesa' ? `/api/pushstk` : paymentMethod === 'kcbpaybill' ? `/api/v1/payments/initiate_kcb_stk_push` : paymentMethod === 'card' ? `/api/v1/payments/card/pay` : '',
            body: payload
        }).then((data) => {
            console.log('send stk response ', data.response.data.stkOrderId);
            if (data.status === 200) {
                let orderId
                let statusPayload = {
                    pushyTransactionId: paymentMethod === 'card' ? data?.response?.data?.data?._id : data.response.data.stkOrderId,
                    businessNo: order.businessId
                }
                console.log(data?.response?.data?.data?.invoiceInformation, 'data?.data')
                const paymentLink = data?.response?.data?.data?.invoiceInformation?.paymentLink;
                if (paymentMethod === 'mpesa' || paymentMethod == 'kcbpaybill') {
                    orderId = data?.response.data?.stkOrderId;
                }
                console.log(orderId, 'orderId');
                if (paymentMethod === 'card') {
                    orderId = data?.response?.data?.data.invoiceInformation?.invoiceNumber;
                }
                if (paymentLink) {
                    const cacheBustedLink = `${paymentLink}?t=${new Date().getTime()}`;
                    setTimeout(() => {
                        window.open(cacheBustedLink);
                    }, 3000);

                } else {
                    console.error("Payment link is invalid or not provided.");
                }
                const checkStatusTimer = setTimeout(() => {
                    HttpRequest({
                        method: 'POST',
                        url: `/api/order_payment_status`,
                        body: statusPayload
                    }).then((data) => {

                        if (data?.status === 200) {
                            setSuccessShow({ state: true, message: 'Order processed successfully.' })

                        } else {
                            setErrorShow({ state: true, message: 'Failed to pay for the order. Please try again.' });
                        }
                    }).catch((error) => {
                        console.log('Error creating order', error);
                    }).finally(() => {
                        setOpenOrderProcessing(false);
                    });
                }, 300000);

                const socket = new WebSocket(base_url + '/notification/' + orderId);
                socket.addEventListener('message', function (event) {

                    const data = JSON.parse(event.data);
                    console.log('Websocket data ', event.data);
                    if (data?.status === "OK") {
                        setSuccessShow({ state: true, message: data?.message });
                    } else if (data?.status === "SUCCESS") {
                        setOpenOrderProcessing(false);
                        setSuccessShow({ state: true, message: "Your order payment has been processed successfully." });
                        getOrderDetails();
                        getOrderPaymentDetails();

                    } else {
                        setErrorShow({ state: true, message: data.statusMessage });
                        socket.close();
                        setOpenOrderProcessing(false);
                    }
                    clearTimeout(checkStatusTimer);
                });

            } else {
                setErrorShow({ state: true, message: 'Failed to process order payments.' });
            }
        }).catch((error) => {
            console.log('Error creating order', error);
        });
    }
    useEffect(() => {
        checkPaymentStatus()
        getOrderDetails();
    }, []);
    useEffect(() => {
        getCustomerDetails();
        if (order?._id !== '') {
            getOrderPaymentDetails();
        }
    }, [order]);

    return (
        <Grid container spacing={2} sx={{ mb: '20%', mt: '1%' }}>
            <Grid item xs={12}>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            </Grid>
            <Grid item xs={12} sx={{ ml: { xs: '5%', xl: '20%' } }}>
                <Box sx={{ display: 'flex', margin: '0px 56px 19px 0px' }}>
                    <ArrowBackIos onClick={() => navigate('/zed-ecommerce/customer-profile')} sx={{ width: '24px', height: '24px', margin: '0 2px 0 0', objectFit: 'contain', color: '#71717A' }} />
                    <Typography sx={{ margin: '1px 0 0 2px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'bold', color: '#343434' }}>Order Details</Typography>
                </Box>
            </Grid>
            {loading && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box sx={{ display: 'flex', width: '100%', textAlign: 'center', alignContent: 'center', verticalAlign: 'middle', maxWidth: '774px', margin: '10% 50%' }}>
                    <CircularProgress />
                </Box>
            </Grid>}
            {!loading && <Grid container spacing={2} sx={{ ml: {lg:'3%', xl: '19%' } }}>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
                    <Box sx={{ width: '100%', maxWidth: '684px', height: '100%', maxHeight: '109px', margin: '19px 16px 10px 23px', padding: '28px 31px', borderRadius: '15px', boxShadow: '-0 5px 30px 0 rgba(0, 0, 0, 0.05)', backgroundColor: '#fff' }}>
                        <Box sx={{ width: '100%', maxWidth: { xs: '322px', sm: '622px' }, height: '100%', maxHeight: '53px' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography sx={{ margin: '4px 31px 9px 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, color: '#000' }}>Order#{order?.orderNumber}</Typography>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ margin: '0 5px 13px 21px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', color: '#898989' }}>Status:</Typography>
                                    <Typography sx={{ margin: '0 0 13px 5px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, textAlign: 'right', color: getOrderStatusColor(order?.orderStatus) }}>Order {order?.orderStatus}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography sx={{ margin: '9px 24px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', color: '#898989' }}>Ordered on {DateFormatter(order?.transtime)}</Typography>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ margin: '9px 0 0 24px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, color: '#343434', textAlign: 'right' }}>Total Amt: {CurrencyFormatter(order?.transamount)}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%', maxWidth: {xs:'430px',sm:'684px'}, height: '100%',maxHeight:'819px', margin: '10px 16px 50% 23px', padding: '20px 29px 20px', borderRadius: '5px', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', backgroundColor: '#fff' }}>
                        {order?.items?.map((item) => (
                            <Box sx={{ width: '100%', maxWidth: '624px', height: '100%', maxHeight: {xs:'150px',sm:'95px'}, margin: '0 0 38px 2px' }}>
                                <Box sx={{ display: 'flex', width: '100%', maxWidth: '624px', height: '100%', maxHeight: {xs:'auto',sm:'95px'}, margin: '0 0 28px 2px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4} sm={3} md={3}>
                                            <Box sx={{ width: '100%', maxWidth: '100px', height: '100%', maxHeight: '95px', margin: '0 46px 0 0', padding: '13px 0px 0px 13px', border: 'solid 0.5px #c9c6c6', borderRadius: '15px', alignItems: 'center' }}>
                                                <img src={item?.productImage ?? imagePlaceHolder} style={{ width: '80px', height: '76px', objectFit: 'contain' }} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Box sx={{ width: '100%', maxWidth: '303.4px', height: '100%', maxHeight: '72px', margin: '0 7.6px 0 0' }}>
                                                <Typography sx={{ margin: '0 0 8px 0.4px', fontSize: '14px', fontWeight: 500, color: '#000', fontFamily: 'Poppins' }}>{item?.itemName}</Typography>
                                                <Box sx={{ display: {xs:'block',sm:'flex'} }}>
                                                    <Typography sx={{ margin: '0 9.7px 0 0', fontSize: '14px', fontWeight: 500, lineHeight:{xs:1,sm:2.57}, color: '#032541' }}>Variations:</Typography>
                                                    <Typography sx={{ margin:{xs:'0px',sm: '0 0 0 9.7px'}, fontSize: '14px', fontWeight: 'normal', lineHeight: {xs:1.5,sm:2.57}, color: '#707070', textWrap: 'nowrap' }}>{item?.variationValues}</Typography>
                                                </Box>
                                                <Box sx={{ display: {xs:'block',sm:'flex'} }}>
                                                    <Typography sx={{ margin: '0 9.7px 0 0', fontSize: '14px', fontWeight: 500, lineHeight: {xs:1.5,sm:2.57}, color: '#032541' }}>Category:</Typography>
                                                    <Typography sx={{ margin:{xs:'0px',sm: '0 0 0 9.7px'}, fontSize: '14px', fontWeight: 'normal', lineHeight: {xs:1.5,sm:2.57}, color: '#707070', }}>{item?.itemCategory}</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <Box>
                                                <Typography sx={{ margin:{sm:'28px 0 0 70.6px'}, fontSize: '14px', fontWeight: 500, textAlign: {xs:'left',sm:'right'}, color: '#000' }}>{CurrencyFormatter(item?.itemAmount)}</Typography>
                                                <Typography sx={{ margin:{sm:'28px 0 0 70.6px'}, fontSize: '14px', fontWeight: 'normal', textAlign: {xs:'left',sm:'right'}, color: '#898989' }}>Qty:{item?.itemCount}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider sx={{ width: '100%', maxWidth: {xs:'330px',sm:'684px'}, height: '1px', margin: '10px 15.5px 12px 2.5px', backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />
                            </Box>
                        ))}
                        <Box>
                            <Typography sx={{ margin: '44px 12px 24px 0', fontSize: '14px', fontWeight: '600', color: '#000' }}>Order Summary</Typography>
                            <Box sx={{ display: {xs:'block',sm:'flex'}, justifyContent: {xs:'start',sm:'space-between'}, width: '100%', maxWidth: '626px', height: '100%', maxHeight: '176px', margin: '24px 0 0' }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#707070' }}>Subtotal:</Typography>
                                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#000', textAlign: {xs:'left',sm:'right'} }}>{CurrencyFormatter(order?.orderAmountExclDelivery)}</Typography>
                            </Box>
                            <Box sx={{ display: {xs:'block',sm:'flex'}, justifyContent: {xs:'start',sm:'space-between'}, width: '100%', maxWidth: '626px', height: '100%', maxHeight: '176px', margin: '24px 0 0' }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#707070' }}>Items:</Typography>
                                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#000', textAlign: {xs:'left',sm:'right'} }}>{order?.items?.reduce((sum, item) => sum + item?.itemCount, 0)} Items</Typography>
                            </Box>
                            <Box sx={{ display: {xs:'block',sm:'flex'}, justifyContent: {xs:'start',sm:'space-between'}, width: '100%', maxWidth: '626px', height: '100%', maxHeight: '176px', margin: '24px 0 0' }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#707070' }}>Delivery/Shipping cost:</Typography>
                                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#000', textAlign: {xs:'left',sm:'right'} }}>{CurrencyFormatter(order?.shippingAmount)}</Typography>
                            </Box>
                            <Box sx={{ display: {xs:'block',sm:'flex'}, justifyContent: {xs:'start',sm:'space-between'}, width: '100%', maxWidth: '626px', height: '100%', maxHeight: '176px', margin: '24px 0 0' }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#707070' }}>Total (tax incl.):</Typography>
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: '#02b3ad', textAlign: {xs:'left',sm:'right'} }}>{CurrencyFormatter(order?.transamount)}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={6}>
                    <Box sx={{ width: '100%',maxWidth:{xs:'350px',sm:'700px',md:'334px'}, height: '100%', maxHeight: '620px', margin: '19px 23px 10px 15.5px', padding: '24px 19px 25px 24px', borderRadius: '15px', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', backgroundColor: '#fff' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography sx={{ margin: '0 0px 21px 0', fontSize: '14px', fontWeight: 600, color: '#000', textWrap: 'nowrap' }}>Track History</Typography>
                            <Typography sx={{ margin: '1px 0px 21px 0px', fontSize: '13px', fontWeight: 'bold', textAlign: 'right', color: '#ee6e0d' }}>Last Updated {DateFormatter(order?.updatedAt)}</Typography>
                        </Box>
                        {order?.status === 'paid' ?
                            <Box sx={{ width: '100%', height: '100%', maxHeight: '215px', margin: '21px 0 0' }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                        <CheckCircle sx={{ color: '#0eb565', width: '25px', height: '25px', margin: '5px 20px 0px 0' }} />
                                        <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#0bb564' }} />
                                        <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#0bb564' }} />
                                        <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#0bb564' }} />
                                        <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 0px 10.8px', backgroundColor: '#0bb564' }} />
                                    </Box>
                                    <Box>
                                        <Typography sx={{ margin: '0 11px 0px 0', fontSize: '13px', color: '#205a41' }}>Order Placed</Typography>
                                        <Typography sx={{ fontSize: '12px', color: '#898989', textWrap: '' }}>Your order was placed on {DateFormatter(order?.transtime)}</Typography>
                                    </Box>
                                </Box>
                                {order?.orderStatus === 'Pending' ?
                                    <Box sx={{ display: 'flex' }}>
                                        <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                            <CircleOutlined sx={{ color: '#343434', width: '25px', height: '25px', margin: '5px 20px 0px 0' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 0px 10.8px', backgroundColor: '#e0e0e0' }} />
                                        </Box>
                                        <Box>
                                            <Typography sx={{ margin: '0 11px 0px 0', fontSize: '13px', color: '#205a41', fontWeight: 'bold' }}>Order Confirmed</Typography>
                                            <Typography sx={{ fontSize: '12px', color: '#343434', fontWeight: 'normal' }}>Your order is awaiting confirmation.</Typography>
                                        </Box>
                                    </Box> : order?.orderStatus === 'Processing' || order?.orderStatus === 'Received' || order?.orderStatus === 'Shipped' || order?.orderStatus === 'Delivered' ?
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <CheckCircle sx={{ color: '#0eb565', width: '25px', height: '25px', margin: '5px 20px 0px 0' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#0bb564' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#0bb564' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#0bb564' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 0px 10.8px', backgroundColor: '#0bb564' }} />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ margin: '0 11px 0px 0', fontSize: '13px', color: '#205a41' }}>Order Confirmed</Typography>
                                                <Typography sx={{ fontSize: '12px', color: '#898989' }}>Your order has been confirmed.</Typography>
                                            </Box>
                                        </Box> :
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <CircleOutlined sx={{ color: '#989898', width: '25px', height: '25px', margin: '5px 20px 0px 0' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 0px 10.8px', backgroundColor: '#e0e0e0' }} />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ margin: '0 11px 0px 0', fontSize: '13px', color: '#898989' }}>Order Confirmed</Typography>
                                                <Typography sx={{ fontSize: '12px', color: '#cacaca' }}>Your order has been confirmed.</Typography>
                                            </Box>
                                        </Box>
                                }
                                {order?.orderStatus === 'Received' ?
                                    <Box sx={{ display: 'flex' }}>
                                        <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                            <CircleOutlined sx={{ color: '#343434', width: '25px', height: '25px', margin: '5px 20px 0px 0' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 0px 10.8px', backgroundColor: '#e0e0e0' }} />
                                        </Box>
                                        <Box>
                                            <Typography sx={{ margin: '0 11px 0px 0', fontSize: '13px', color: '#205a41', fontWeight: 'bold' }}>Order Processed</Typography>
                                            <Typography sx={{ fontSize: '12px', color: '#343434', fontWeight: 'normal' }}>Your order is awaiting processing.</Typography>
                                        </Box>
                                    </Box> : order?.orderStatus === 'Processing' || order?.orderStatus === 'Shipped' || order?.orderStatus === 'Delivered' ?
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <CheckCircle sx={{ color: '#0eb565', width: '25px', height: '25px', margin: '5px 20px 0px 0' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#0bb564' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#0bb564' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#0bb564' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 0px 10.8px', backgroundColor: '#0bb564' }} />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ margin: '0 11px 0px 0', fontSize: '13px', color: '#205a41' }}>Order Processed</Typography>
                                                <Typography sx={{ fontSize: '12px', color: '#898989' }}>Your order has been processed.</Typography>
                                            </Box>
                                        </Box> :
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <CircleOutlined sx={{ color: '#989898', width: '25px', height: '25px', margin: '5px 20px 0px 0' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 0px 10.8px', backgroundColor: '#e0e0e0' }} />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ margin: '0 11px 0px 0', fontSize: '13px', color: '#898989' }}>Order Processed</Typography>
                                                <Typography sx={{ fontSize: '12px', color: '#cacaca' }}>Your order has been processed.</Typography>
                                            </Box>
                                        </Box>
                                }
                                {order?.orderStatus === 'Processing' ?
                                    <Box sx={{ display: 'flex' }}>
                                        <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                            <CircleOutlined sx={{ color: '#343434', width: '25px', height: '25px', margin: '5px 20px 0px 0' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 0px 10.8px', backgroundColor: '#e0e0e0' }} />
                                        </Box>
                                        <Box>
                                            <Typography sx={{ margin: '0 11px 0px 0', fontSize: '13px', color: '#205a41', fontWeight: 'bold' }}>Ready To Be Shipped</Typography>
                                            <Typography sx={{ fontSize: '12px', color: '#343434', fontWeight: 'normal' }}>Your order is awaiting to be shipped.</Typography>
                                        </Box>
                                    </Box> : order?.orderStatus === 'Shipped' || order?.orderStatus === 'Delivered' ?
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <CheckCircle sx={{ color: '#0eb565', width: '25px', height: '25px', margin: '5px 20px 0px 0' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#0bb564' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#0bb564' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#0bb564' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 0px 10.8px', backgroundColor: '#0bb564' }} />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ margin: '0 11px 0px 0', fontSize: '13px', color: '#205a41' }}>Ready To Be Shipped</Typography>
                                                <Typography sx={{ fontSize: '12px', color: '#898989' }}>Your order is awaiting to be shipped.</Typography>
                                            </Box>
                                        </Box> :
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <CircleOutlined sx={{ color: '#989898', width: '25px', height: '25px', margin: '5px 20px 0px 0' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                                <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 0px 10.8px', backgroundColor: '#e0e0e0' }} />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ margin: '0 11px 0px 0', fontSize: '13px', color: '#898989' }}>Ready To Be Shipped</Typography>
                                                <Typography sx={{ fontSize: '12px', color: '#cacaca' }}>Your order is awaiting to be shipped.</Typography>
                                            </Box>
                                        </Box>
                                }
                                {order?.orderStatus === 'Shipped' || order?.orderStatus === 'Delivered' ?
                                    <Box sx={{ display: 'flex' }}>
                                        <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                            <CheckCircle sx={{ color: '#0eb565', width: '25px', height: '25px', margin: '5px 20px 0px 0' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#0bb564' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#0bb564' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#0bb564' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 0px 10.8px', backgroundColor: '#0bb564' }} />
                                        </Box>
                                        <Box>
                                            <Typography sx={{ margin: '0 11px 0px 0', fontSize: '13px', color: '#205a41' }}>Out For Delivery</Typography>
                                            <Typography sx={{ fontSize: '12px', color: '#898989' }}>Your order is on its way.</Typography>
                                        </Box>
                                    </Box> :
                                    <Box sx={{ display: 'flex' }}>
                                        <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                            <CircleOutlined sx={{ color: '#989898', width: '25px', height: '25px', margin: '5px 20px 0px 0' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 6px 10.8px', backgroundColor: '#e0e0e0' }} />
                                            <Typography sx={{ width: '2.5px', height: '2.5px', margin: '-1.5px 26.8px 0px 10.8px', backgroundColor: '#e0e0e0' }} />
                                        </Box>
                                        <Box>
                                            <Typography sx={{ margin: '0 11px 0px 0', fontSize: '13px', color: '#898989' }}>Out For Delivery</Typography>
                                            <Typography sx={{ fontSize: '12px', color: '#cacaca' }}>Your order is on its way.</Typography>
                                        </Box>
                                    </Box>
                                }
                                {order?.orderStatus === 'Shipped' ?
                                    <Box sx={{ display: 'flex' }}>
                                        <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                            <CircleOutlined sx={{ color: '#343434', width: '25px', height: '25px', margin: '5px 20px 0px 0' }} />
                                        </Box>
                                        <Box>
                                            <Typography sx={{ margin: '0 11px 0px 0', fontSize: '13px', color: '#205a41', fontWeight: 'bold' }}>Delivered</Typography>
                                            <Typography sx={{ fontSize: '12px', color: '#343434', fontWeight: 'normal' }}>Your order has been delivered.</Typography>
                                        </Box>
                                    </Box> : order?.orderStatus === 'Delivered' ?
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <CheckCircle sx={{ color: '#0eb565', width: '25px', height: '25px', margin: '5px 20px 0px 0' }} />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ margin: '0 11px 0px 0', fontSize: '13px', color: '#205a41' }}>Delivered</Typography>
                                                <Typography sx={{ fontSize: '12px', color: '#898989' }}>Your order has been delivered.</Typography>
                                            </Box>
                                        </Box> :
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <CircleOutlined sx={{ color: '#989898', width: '25px', height: '25px', margin: '5px 20px 0px 0' }} />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ margin: '0 11px 0px 0', fontSize: '13px', color: '#898989' }}>Delivered</Typography>
                                                <Typography sx={{ fontSize: '12px', color: '#cacaca' }}>Your order has been delivered.</Typography>
                                            </Box>
                                        </Box>
                                }
                            </Box> :
                            <Box sx={{ width: '100%',  height: '100%', maxHeight: '215px', margin: '21px 0 0' }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                        <CancelOutlined sx={{ color: '#ef0b0b', width: '25px', height: '25px', margin: '5px 20px 0px 0' }} />
                                    </Box>
                                    <Box>
                                        <Typography sx={{ margin: '0 11px 0px 0', fontSize: '13px', color: '#ef0b0b' }}>Payment Failed</Typography>
                                        <Typography sx={{ fontSize: '12px', color: '#898989', textWrap: '' }}>Your order payment was unsuccessful</Typography>
                                        {!retryPayment && <Button onClick={() => setRetryPayment(true)} variant='contained' sx={{ justifyContent: 'center', textAlign: 'center', width: '100%', maxWidth: { xs: '135px', sm: '140px', md: '121px', lg: '131px' }, height: '50px', margin: { xs: '10px 1px% 0 0', sm: '10px 10% 0 0', md: '10px 10% 0 0', lg: '20px 6% 0 0' }, padding: '15px 16.9px 15px 15px', objectFit: 'contain', opacity: 1, borderRadius: '5px', backgroundColor: '#02b3ad', '&:hover': { backgroundColor: '#02b3ad' } }}>
                                            <Typography sx={{ textWrap: 'nowrap', fontFamily: 'Poppins', fontSize: '14px', textTransform: 'capitalize', fontWeight: 500, textAlign: 'center', color: '#fff', border: 'none' }}>Retry Payment</Typography>
                                        </Button>}
                                    </Box>
                                </Box>
                                {retryPayment && <Grid container item xs={12}>
                                    <Grid item xs={12}>
                                        <Typography sx={{ margin: '21px 121px 16px 0px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, textAlign: 'left', color: '#343434' }}>
                                            Payment  Method
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ width: '100%', maxWidth: { xs: '320px', sm: '320px', md: '300px', lg: '635px' }, height: 'auto', margin: '0px 5px 0px 0px', padding: '10px 20px', borderRadius: '10px', boxShadow: 'none', backgroundColor: '#fff', display: mpesaStatus === true ? 'block' : 'none' }}>
                                        <FormControl fullWidth>
                                            <RadioGroup name="payment-method" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} sx={{ border: paymentMethodError ? 'solid 1px red' : '' }}>
                                                <Box display={{ display: 'flex', }}>
                                                    <FormControlLabel value="mpesa" control={<Radio inputProps={{ style: { color: 'red', textWrap: 'nowrap' } }} sx={{ textWrap: 'nowrap', color: '#02b3ad', '&.Mui-checked': { color: '#02b3ad', } }} />} label='' sx={{ textWrap: 'nowrap', width: '24px', height: '24px', margin: '15px 0 2px 10px', objectFit: 'contain', color: '#032541', fontSize: '14px', fontFamily: 'Poppins' }} />
                                                    <img src={mpesaIcon} alt="" style={{ width: '63px', height: '23px', margin: '16px 306px 16px 15px', objectFit: 'contain' }} />
                                                </Box>
                                            </RadioGroup>
                                            {paymentMethod === 'mpesa' && <Typography sx={{ margin: '10px 0 0 15px', fontFamily: 'Poppins', fontSize: '14px', lineHeight: 1.43, textAlign: 'left', color: '#707070', textWrap: 'wrap' }}>You will be redirected to Mpesa to complete the payment.</Typography>}

                                            {paymentMethod === 'mpesa' && <FormHelperText sx={{ fontFamily: 'Poppins', color: 'red', display: paymentMethodError ? '' : 'none' }}>Please select payment method</FormHelperText>}
                                        </FormControl>
                                        {paymentMethod === 'mpesa' && <FormControl sx={{ width: '100%', maxWidth: { xs: '270px', sm: '270px', md: '300px', lg: '580px' }, height: '48px', margin: '10.5px 0 10px 0.5px', padding: '14.5px 12.5px 13.5px 12.5px', borderRadius: '5px', }}>
                                            <PhoneInput country={'ke'} inputStyle={{ width: '100%', maxWidth: { xs: '300px', sm: '300px', md: '300px', lg: '580px' }, height: '58px', margin: '10.5px 0 10px 0.5px', padding: '14.5px 12.5px 13.5px 52.5px', borderRadius: '5px', backgroundColor: '#fff', border: mpesaPhoneNumberError ? 'solid 1px red' : 'solid 1px #bfbfbf', borderRadius: '5px' }} value={mpesaPhoneNumber} onChange={mpesaPhoneNumber => { setMpesaPhoneNumber(mpesaPhoneNumber) }} />
                                            <FormHelperText sx={{ fontFamily: 'Poppins', color: 'red', display: mpesaPhoneNumberError ? '' : 'none' }}>Please enter a valid phone number</FormHelperText>
                                        </FormControl>}
                                    </Grid>
                                    <Grid item xs={12} sx={{ width: '100%', maxWidth: { xs: '320px', sm: '320px', md: '300px', lg: '635px' }, height: 'auto', margin: '0px 5px 0px 0px', padding: '10px 20px', borderRadius: '10px', boxShadow: 'none', backgroundColor: '#fff', display: bankPaybillStatus === true ? 'block' : 'none' }}>
                                        <FormControl fullWidth>
                                            <RadioGroup name="payment-method" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} sx={{ border: paymentMethodError ? 'solid 1px red' : '' }}>
                                                <Box display={{ display: 'flex', }}>
                                                    <FormControlLabel value="kcbpaybill" control={<Radio inputProps={{ style: { color: 'red', textWrap: 'nowrap' } }} sx={{ textWrap: 'nowrap', color: '#02b3ad', '&.Mui-checked': { color: '#02b3ad', } }} />} label='Mobile Money' sx={{ textWrap: 'nowrap', width: '24px', height: '24px', margin: '15px 0 2px 10px', objectFit: 'contain', color: '#032541', fontSize: '14px', fontFamily: 'Poppins' }} />
                                                </Box>
                                            </RadioGroup>
                                            {paymentMethod === 'kcbpaybill' && <Typography sx={{ margin: '10px 0 0 15px', fontFamily: 'Poppins', fontSize: '14px', lineHeight: 1.43, textAlign: 'left', color: '#707070', textWrap: 'wrap' }}>STK push will be sent to your phone. Kindly input your M-Pesa pin.</Typography>}
                                            {paymentMethod === 'kcbpaybill' && <FormHelperText sx={{ fontFamily: 'Poppins', color: 'red', display: paymentMethodError ? '' : 'none' }}>Please select payment method</FormHelperText>}
                                        </FormControl>
                                        {paymentMethod === 'kcbpaybill' && <FormControl sx={{ width: '100%', maxWidth: { xs: '270px', sm: '300px', md: '300px', lg: '580px' }, height: '48px', margin: '10.5px 0 10px 0.5px', padding: '14.5px 12.5px 13.5px 12.5px', borderRadius: '5px', }}>
                                            <PhoneInput country={'ke'} inputStyle={{ width: '100%', maxWidth: { xs: '300px', sm: '300px', md: '300px', lg: '580px' }, height: '58px', margin: '10.5px 0 10px 0.5px', padding: '14.5px 12.5px 13.5px 52.5px', borderRadius: '5px', backgroundColor: '#fff', border: mobileMoneyNumberError ? 'solid 1px red' : 'solid 1px #bfbfbf', borderRadius: '5px' }} value={mobileMoneyNumber} onChange={mobileMoneyNumber => { setMobileMoneyNumber(mobileMoneyNumber) }} />
                                            <FormHelperText sx={{ fontFamily: 'Poppins', color: 'red', display: mobileMoneyNumberError ? '' : 'none' }}>Please enter a valid phone number</FormHelperText>
                                        </FormControl>}
                                    </Grid>
                                    <Grid item xs={12} sx={{ width: '100%', maxWidth: { xs: '320px', sm: '320px', md: '300px', lg: '635px' }, height: 'auto', margin: '0px 5px 0px 12px', padding: '10px 20px', borderRadius: '10px', boxShadow: 'none', backgroundColor: '#fff', display: payCardStatus === true ? 'block' : 'none' }}>
                                        <FormControl fullWidth>
                                            <RadioGroup name="payment-method" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} sx={{ border: paymentMethodError ? 'solid 1px red' : '' }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', margin: '15px 0' }}>
                                                    <FormControlLabel value="card" control={<Radio sx={{ color: '#02b3ad', '&.Mui-checked': { color: '#02b3ad' } }} />} sx={{ margin: '0', color: '#032541', fontSize: '14px', fontFamily: 'Poppins', }} />
                                                    <img src={cardIcon} alt="Card Icon" style={{ width: '40px', height: '40px', objectFit: 'contain', margin: '0 8px 0 10px' }} />
                                                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', color: '#032541', whiteSpace: 'nowrap', color: '#4b4b4b' }}>
                                                        Card
                                                    </Typography>
                                                </Box>
                                            </RadioGroup>
                                            {paymentMethod === 'card' && <Typography sx={{ margin: '10px 0 0 15px', fontFamily: 'Poppins', fontSize: '14px', lineHeight: 1.43, textAlign: 'left', color: '#707070', textWrap: 'wrap' }}>You will be redirected to a payment link.</Typography>}
                                            {paymentMethod === 'card' && <FormHelperText sx={{ fontFamily: 'Poppins', color: 'red', display: paymentMethodError ? '' : 'none' }}>Please select payment method</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sx={{ width: '100%', maxWidth: { xs: '320px', sm: '320px', md: '300px', lg: '635px' }, height: 'auto', margin: '0px 5px 40px 0px', padding: '10px 20px', borderRadius: '10px', backgroundColor: '#fff' }}>
                                        <Button onClick={() => handleRetryPayment()} variant='contained' sx={{ justifyContent: 'center', textAlign: 'center', width: '100%', maxWidth: { xs: '135px', sm: '140px', md: '121px', lg: '131px' }, height: '50px', margin: { xs: '10px 1px% 0 0', sm: '10px 10% 0 0', md: '10px 10% 0 0', lg: '20px 6% 0 0' }, padding: '15px 16.9px 15px 15px', objectFit: 'contain', opacity: 1, borderRadius: '5px', backgroundColor: '#02b3ad', '&:hover': { backgroundColor: '#02b3ad' } }}>
                                            <Typography sx={{ textWrap: 'nowrap', fontFamily: 'Poppins', fontSize: '14px', textTransform: 'capitalize', fontWeight: 500, textAlign: 'center', color: '#fff', border: 'none' }}>Retry Payment</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>}
                            </Box>
                        }
                    </Box>
                    <Box sx={{ width: '100%',maxWidth:{xs:'350px',sm:'700px',md:'334px'}, height: '100%', maxHeight: '410px', margin: ' 10px 203px 100% 15.5px', padding: '24px 20px 92px', borderRadius: '15px', boxShadow: ' 0 5px 30px 0 rgba(0, 0, 0, 0.05)', backgroundColor: '#fff' }}>
                        <Typography sx={{ margin: '0 144px 16px 4px', fontSize: '14px', fontWeight: 600, color: '#000' }}> Delivery Information</Typography>
                        <Box sx={{ width: '100%', maxWidth: '100%', height: '100%', maxHeight: '399px', margin: '16px 0 0' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '100%', height: '100%', maxHeight: '20px', margin: '19px 0' }}>
                                <Typography sx={{ fontSize: '14px', lineHeight: 1.43, color: '#707070', margin: '0 0px 0 0' }}>Name</Typography>
                                <Typography sx={{ margin: '0 0 0 19px', fontSize: '14px', lineHeight: 1.43, color: '#032541', textWrap: 'wrap' }}>{customer?.firstName} {customer?.lastName}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '100%', height: '100%', maxHeight: '20px', margin: '19px 0' }}>
                                <Typography sx={{ fontSize: '14px', lineHeight: 1.43, color: '#707070', margin: '0 0px 0 0' }}>Email</Typography>
                                <Typography sx={{ margin: '0 0 0 19px', fontSize: '14px', lineHeight: 1.43, color: '#032541', textWrap: 'wrap' }}>{customer?.email}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '100%', height: '100%', maxHeight: '20px', margin: '19px 0' }}>
                                <Typography sx={{ fontSize: '14px', lineHeight: 1.43, color: '#707070', margin: '0 0px 0 0' }}>Phone</Typography>
                                <Typography sx={{ margin: '0 0 0 19px', fontSize: '14px', lineHeight: 1.43, color: '#032541', textWrap: 'wrap' }}>{customer?.phone}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '100%', height: '100%', maxHeight: '20px', margin: '19px 0' }}>
                                <Typography sx={{ fontSize: '14px', lineHeight: 1.43, color: '#707070', margin: '0 0px 0 0' }}>Delivery Mode</Typography>
                                <Typography sx={{ margin: '0 0 0 19px', fontSize: '14px', lineHeight: 1.43, color: '#032541', textWrap: 'wrap' }}>{capitalizeFirstLetter(order?.deliveryMethod)}</Typography>
                            </Box>
                            {order?.deliveryMethod === 'pickup' && <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '100%', height: '100%', maxHeight: '20px', margin: '19px 0' }}>
                                <Typography sx={{ fontSize: '14px', lineHeight: 1.43, color: '#707070', margin: '0 0px 0 0' }}>Pickup Location</Typography>
                                <Typography sx={{ margin: '0 0 0 19px', fontSize: '14px', lineHeight: 1.43, color: '#032541', textWrap: 'wrap' }}>{order?.pickUpLocationDetails?.locationName}</Typography>
                            </Box>}
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '100%', height: '100%', maxHeight: '20px', margin: '19px 0' }}>
                                <Typography sx={{ fontSize: '14px', lineHeight: 1.43, color: '#707070', margin: '0 0px 0 0' }}>Payment Method</Typography>
                                <Typography sx={{ margin: '0 0 0 19px', fontSize: '14px', lineHeight: 1.43, color: '#032541', textWrap: 'wrap' }}>{order?.paymentMethod}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '100%', height: '100%', maxHeight: '20px', margin: '19px 0' }}>
                                <Typography sx={{ fontSize: '14px', lineHeight: 1.43, color: '#707070', margin: '0 0px 0 0' }}>Shipping Amount</Typography>
                                <Typography sx={{ margin: '0 0 0 19px', fontSize: '14px', lineHeight: 1.43, color: '#032541', textWrap: 'wrap' }}>{CurrencyFormatter(order?.shippingAmount)}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '100%', height: '100%', maxHeight: '20px', margin: '19px 0' }}>
                                <Typography sx={{ fontSize: '14px', lineHeight: 1.43, color: '#707070', margin: '0 0px 0 0' }}>Transaction Date</Typography>
                                <Typography sx={{ margin: '0 0 0 19px', fontSize: '14px', lineHeight: 1.43, color: '#032541', textWrap: 'wrap' }}>{DateFormatter(order?.transtime)}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>}
            <Modal open={openOrderProcessing} onClose={handleOpenOrderProcessing} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                <Box sx={orderProcessingModal}>
                    <img src={loadingIcon} alt=" " style={{ width: '100%', maxWidth: '125px', height: '100%', maxHeight: '125px', margin: '0 33px 9px 32px', objectFit: 'contain', animation: 'App-logo-spin 2s linear infinite', }} />
                    <Typography sx={{ width: '190px', height: '41px', margin: '9px 33px 100px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'center', color: '#343434' }}>
                        {paymentMethod === 'card' ? 'You will be redirected to a payment link.' : 'Please check your phone for STK prompt.'}
                    </Typography>
                </Box>
            </Modal>
        </Grid>
    );
}

export default CustomerOrderDetails;