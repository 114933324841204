import { ArrowBackIos } from "@mui/icons-material";
import { Box, Button, CircularProgress, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HttpRequest } from "../HttpRequest";
import imagePlaceHolder from './../../images/img-placeholder.svg';


const CustomerOrders = () => {
    const navigate = useNavigate();
    const [customer, setCustomer] = useState([]);
    const { customerId } = useParams();
    const [orderCount, setOrderCount] = useState(0);
    const [orderAmount, setOrderAmount] = useState(0);
    const [latestOrder, setLatestOrder] = useState({});

    const getCustomerDetails = () => {
        HttpRequest({
            method: 'GET',
            url: `/api/get_customer_by_customer_id?customerId=${customerId}`
        }).then((data) => {
            console.log('Fetch customer profile', data.response.data);
            setCustomer(data.response.data);
        }).catch((error) => {
            console.log('Error fetching customer profile');
        });
    }
    const getCustomerOrders = () => {
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/getzedecommercecustomerorder?customerId=${customerId}`
        }).then((data) => {
            console.log('Fetch customer orders', data.response.data);
            const ordersData = data.response.data;
            setOrderCount(ordersData.length);
            let totalAmount = 0;
            ordersData.forEach(order => {
                totalAmount += order.amount;
            });
            setOrderAmount(totalAmount);
            ordersData.sort((a, b) => b.orderNumber - a.orderNumber);
            const lastPlacedOrder = ordersData[0];
            setLatestOrder(lastPlacedOrder);

        }).catch((error) => {
            console.log('Error fetching customer orders');
        });
    }
    useEffect(() => {
        getCustomerDetails();
        getCustomerOrders();
    }, [])
    const CustomerComponent = ({ createdAt }) => {
        const createdDate = new Date(createdAt);
        const timeDiff = new Date() - createdDate;
        const years = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365));
        if (years < 1) {
            const months = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30));
            if (months < 1) {
                const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
                return (
                    <Box sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal" }}>
                        Customer for {days} {days === 1 ? 'day' : 'days'}
                    </Box>
                );
            } else {
                return (
                    <Box sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal" }}>
                        Customer for {months} {months === 1 ? 'month' : 'months'}
                    </Box>
                );
            }
        } else {
            return (
                <Box sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal" }}>
                    Customer for {years} {years === 1 ? 'year' : 'years'}
                </Box>
            );
        }
    }
    function getOrderStatusColor(status) {
        const statusColors = {
            'Received': '#707070',
            'Delivered': '#17ae7b',
            'Shipped': '#000000',
            'Processing': '#f79009'
        };
        return statusColors[status] || '#dc3545';
    }
    const formatDate = (value) => {
        const dateString = value;
        const date = new Date(dateString);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month} ${day}, ${year}`;
      };
    return (
        <div style={{ marginTop: '4%' }}>
              {customer.length===0 && <Box sx={{ display: 'flex', width: '100%', textAlign: 'center', alignContent: 'center', verticalAlign: 'middle', maxWidth: '774px', margin: '10% 50%' }}>
                <CircularProgress />
            </Box>}
            {customer.length!==0 && 
            <Box>
                <Box sx={{ display: 'flex' }}>
                    <ArrowBackIos sx={{ width: '30px', height: '50px' }} onClick={() => navigate(-1)} />
                    <Typography variant="h4" sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600", margin: '7px 15px 4px 0' }}>
                        {customer?.firstName + ' ' + customer?.lastName}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Typography variant='h4' sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal", mr: '5px' }}>{customer?.customerpreference?.city}, Kenya. </Typography>
                    <CustomerComponent createdAt={customer?.createdAt} />
                </Box>
            </Box>}
            {customer.length!==0 && <Box sx={{ display: 'flex' }}>
                <Box>
                    {orderCount == 0 && <Box sx={{ width: '684px', height: '130px', margin: '20px 16px 4px 0', padding: '24px 388px 230px 24px', borderRadius: '15px', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', backgroundColor: '#fff' }}>
                        <Typography sx={{ margin: '0 148px 26px 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 600, textAlign: 'left', color: '#000' }}>Last Order Placed</Typography>
                        <Typography sx={{ margin: '26px 0 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', color: '#707070' }}>Looks like this customer has not placed <br />
                            any order yet.</Typography>
                    </Box>}
                    {orderCount > 0 &&
                        <Box>
                            <Box sx={{ width: '1045px', height: '145px', margin: '10px 16px 10px 0', padding: '24px 31px 34px 24px;', borderRadius: '15px', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', backgroundColor: '#fff' }}>
                                <Typography sx={{ margin: '0 148px 14px 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 600, textAlign: 'left', color: '#000' }}>Order Details</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '14px 0 0' }}>
                                    <Typography sx={{ margin: '0 0px 12px 0', fontFamily: 'Poppins', fontSize: '16px', textAlign: 'left', color: '#707070', fontWeight: 500 }}>
                                        Orders for 30 days
                                    </Typography>
                                    <Typography sx={{ margin: '0 0px 12px 0', fontFamily: 'Poppins', fontSize: '16px', textAlign: 'left', color: '#707070', fontWeight: 'normal' }}>
                                        Total Amt Spent :
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography sx={{ margin: '12px 0px 1px 0', fontFamily: 'Poppins', fontSize: '16px', textAlign: 'left', color: '#205a41', fontWeight: 600 }}>
                                        {orderCount} Orders
                                    </Typography>
                                    <Typography sx={{ margin: '13px 0px 0px 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 600, textAlign: 'left', color: '#032541' }}>
                                        Ksh {orderAmount}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ width: '1045px', height: '314px', margin: '10px 16px 10px 0', padding: '24px 31px 34px 24px;', borderRadius: '15px', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', backgroundColor: '#fff' }}>
                                <Typography sx={{ margin: '0 148px 14px 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 600, textAlign: 'left', color: '#000' }}>Last Order Placed</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '14px 0 0' }}>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography sx={{ margin: '0 0px 12px 0', fontFamily: 'Poppins', fontSize: '16px', textAlign: 'left', color: '#707070', fontWeight: 500 }}>
                                            Total Items:
                                        </Typography>
                                        <Typography sx={{ margin: '0 0px 12px 0', fontFamily: 'Poppins', fontSize: '16px', textAlign: 'left', color: '#000', fontWeight: 550 }}>
                                            {latestOrder?.items?.length} Item(s)
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography sx={{ margin: '0 5px 12px 0', fontFamily: 'Poppins', fontSize: '16px', textAlign: 'left', color: '#898989', fontWeight: 500 }}>
                                            Status:
                                        </Typography>
                                        <Typography sx={{ margin: '0 0px 12px 5px', fontFamily: 'Poppins', fontSize: '16px', textAlign: 'left', color: getOrderStatusColor(latestOrder?.orderStatus), fontWeight: 600 }}>
                                            Order   {latestOrder?.orderStatus}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography sx={{ margin: '12px 0px 1px 0', fontFamily: 'Poppins', fontSize: '16px', textAlign: 'left', color: '#898989', fontWeight: 'normal' }}>
                                        Ordered on {formatDate(latestOrder?.createdAt)}
                                    </Typography>
                                    <Typography sx={{ margin: '13px 0px 0px 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 550, textAlign: 'right', color: '#343434' }}>
                                        Total Amt Spent : Ksh {latestOrder?.amount}
                                    </Typography>
                                </Box>
                                <Box sx={{ width: '100%', height: '50px', margin: '28px 2px 0 1px', padding: '6px 273px 5.1px 0', border: 'solid 1px #fff', backgroundColor: '#fff' }}>
                                    <Divider />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                        <img src={imagePlaceHolder} style={{ width: '34.2px', height: '38.9px', objectFit: 'contain' }} />
                                        <Typography onClick={() => { navigate(`/zed_ecommerce/orders/${latestOrder?._id}/details`) }} sx={{ margin: '9px 198px 9.9px 43px', fontSize: '16px', fontFamily: 'Poppins', color: '#707070', textDecoration: 'underline' }}>{latestOrder?.orderNumber}</Typography>
                                        <Typography sx={{ margin: '9px 0 9.9px 198px', fontSize: '16px', fontFamily: 'Poppins', color: '#707070' }}>*{latestOrder?.items?.length}</Typography>
                                        <Typography sx={{ margin: '14px 0 15px 427px', fontSize: '16px', fontFamily: 'Poppins', color: '#707070' }}>Ksh {latestOrder?.amount}</Typography>
                                    </Box>
                                    <Divider />
                                </Box>
                                <Button onClick={()=>navigate(`/zed_ecommerce/customer/${customerId}/orders`)} variant="outlined" sx={{ width: '127px', height: '40px', margin: '24px 0 0 88%', padding: '10px 10px 10px 11px', borderRadius: '4px', backgroundColor: '#032541' }} >
                                    <Typography sx={{fontFamily:'Poppins',fontSize:'14px',fontWeight:600,color:'#fff',textTransform:'capitalize',textWrap:'nowrap'}}>View All Orders</Typography>
                                </Button>
                            </Box>
                        </Box>
                    }
                </Box>
                <Box sx={{ width: '405px', height: '305px', margin: '20px 0 0 16px', padding: '24px 20px 45px', borderRadius: '15px', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', backgroundColor: '#fff' }}>
                    <Typography sx={{ margin: '0 148px 26px 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 600, textAlign: 'left', color: '#000' }}>Customer Details</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ flexDirection: 'column', fontFamily: 'Poppins', fontSize: '16px', color: '#707070', lineHeight: 1.43 }}>Name</Typography>
                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', color: '#032541', lineHeight: 1.43 }}>{customer.firstName + ' ' + customer.lastName}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '20px' }}>
                        <Typography sx={{ flexDirection: 'column', fontFamily: 'Poppins', fontSize: '16px', color: '#707070', lineHeight: 1.43 }}>Email</Typography>
                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', color: '#032541', lineHeight: 1.43 }}>{customer.email}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '20px' }}>
                        <Typography sx={{ flexDirection: 'column', fontFamily: 'Poppins', fontSize: '16px', color: '#707070', lineHeight: 1.43 }}>Phone No.</Typography>
                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', color: '#032541', lineHeight: 1.43 }}>{customer.phone}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '20px' }}>
                        <Typography sx={{ flexDirection: 'column', fontFamily: 'Poppins', fontSize: '16px', color: '#707070', lineHeight: 1.43 }}>Address</Typography>
                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', color: '#032541', lineHeight: 1.43 }}>{customer?.customerpreference?.address}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '20px' }}>
                        <Typography sx={{ flexDirection: 'column', fontFamily: 'Poppins', fontSize: '16px', color: '#707070', lineHeight: 1.43 }}>City</Typography>
                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', color: '#032541', lineHeight: 1.43 }}>{customer?.customerpreference?.city}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '20px' }}>
                        <Typography sx={{ flexDirection: 'column', fontFamily: 'Poppins', fontSize: '16px', color: '#707070', lineHeight: 1.43 }}>County</Typography>
                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', color: '#032541', lineHeight: 1.43 }}>{customer?.customerpreference?.county}</Typography>
                    </Box>
                </Box>
            </Box>}
        </div>
    )
}
export default CustomerOrders;