import { Box, Button, Grid, Typography, TextField, Modal, Paper, Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HttpRequest } from "../HttpRequest";
import { ArrowBackIos, EditOutlined } from "@mui/icons-material";
import { SuccessAlert } from "../common/snackbar/success";
import { ErrorAlert } from "../common/snackbar/error";
import CustomSearchInput from "../common/CustomSearchInput";
import warningIcon from '../../images/warning-remove-icn.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import CustomSelect from "../common/customSelectInput";
import secureLocalStorage from "react-secure-storage";
import { format, startOfToday } from "date-fns";
import dayjs from "dayjs";

const deleteShippingRegionModal = {
    borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: "130px", p: '61px 22px 165px 63px', border: '1px #707070', m: '55px 104px 6.5px 118px'
}
const PreOrders = () => {
    const [search, setSearch] = useState('');
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [showModalAddPreorder, setshowModalAddPreorder] = useState(false)
    const [showModalEditPreorder, setShowModalEditPreorder] = useState(false)
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const [openDeleteShippingRegion, setOpenDeleteShippingRegion] = useState(false);
    const [endDate, setEndDate] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [methods, setMethods] = useState([]);
    const businessNumber = secureLocalStorage.getItem("businessId");
    const [searchProduct, setSearchProduct] = useState('');
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({
        productId: "",
        productName: "",
        productImage: "",
        productVariations: []
    });
    const [pricingIds, setPricingIds] = useState([]);
    const [stockValues, setStockValues] = useState({});
    const [preOrderStatus, setPreOrderStatus] = useState(true);
    const [preOrderId, setPreOrderId] = useState('');
    const [action,setAction] = useState('added');

    const handleOpenDeleteShippingRegion = () => { setOpenDeleteShippingRegion(true) }
    const handleCloseDeleteShippingRegion = () => { setOpenDeleteShippingRegion(false) }

    const handleRowClick = (event, params) => {
        event.stopPropagation();
        setPreOrderId(params?.row?._id);
        const initialDeadline = dayjs(params?.row?.deadline);
        setEndDate(initialDeadline);
        setPaymentMethod(params?.row?.preOrderPaymentMethod);
        setPreOrderStatus(true);
        setPricingIds([params?.row?.variationKeyId]);
        setShowModalEditPreorder(true);
        setSearchProduct(params?.row?.productName);
        handleStockChange(params?.row?.variationKeyId, params?.row?.preOrderStock);
        setAction('updated');
    }
    const columns = [
        {
            field: 'ID', headerName: 'ID', flex: 0.5,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> ID </Box>) },
            renderCell: (params) => {
                return (<Box onClick={(event) => handleRowClick(event, params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
                    <Grid container spacing={2}>
                        <Grid item>
                            {params?.row?.index}
                        </Grid>
                    </Grid>
                </Box>)
            }
        },
        {
            field: 'productName', headerName: 'Product Name', flex: 2,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Product Name   </Box>) },
            renderCell: (params) => {
                return (<Box onClick={(event) => handleRowClick(event, params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
                    {params?.row?.productName}
                </Box>)
            }
        },
        {
            field: 'variationKey', headerName: 'Variance', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Variance  </Box>) },
            renderCell: (params) => {
                return (<Box onClick={(event) => handleRowClick(event, params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
                    {params?.row?.variationKey}
                </Box>)
            }
        },
        {
            field: 'deadline', headerName: 'Deadline', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Deadline  </Box>) },
            renderCell: (params) => {
                return (<Box onClick={(event) => handleRowClick(event, params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
                    {formatDate(params?.row?.deadline)}
                </Box>)
            }
        },
        {
            field: 'paymentMode', headerName: 'Payment Mode', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Payment Mode  </Box>) },
            renderCell: (params) => {
                return (<Box onClick={(event) => handleRowClick(event, params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
                    {params?.row?.preOrderPaymentMethod == 'onDelivery' ? 'On Delivery' : 'On Placing Order'}
                </Box>)
            }
        },
        {
            field: 'action', headerName: 'Action', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Action  </Box>) },
            renderCell: (params) => {
                const onClickDelete = (event) => {
                    event.stopPropagation();
                    setPreOrderStatus(false);
                    setPricingIds([params?.row?.variationKeyId]);
                    setSearchProduct(params?.row?.productName);
                    setOpenDeleteShippingRegion(true);
                    setPreOrderId(params?.row?._id);
                    const initialDeadline = dayjs(params?.row?.deadline);
                    setEndDate(initialDeadline);
                    setPaymentMethod(params?.row?.preOrderPaymentMethod);
                    setSearchProduct(params?.row?.productName);
                    setAction('deleted');
                }
                return (
                    <Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500", mt: '30px' }}>
                        <EditOutlined onClick={(event) => handleRowClick(event, params)} sx={{ color: '#032541', marginRight: '10px' }} />
                        <DeleteIcon onClick={onClickDelete} sx={{ color: '#DC3545' }} />
                    </Box>
                )
            }
        },
    ]
    const formatDate = (value) => {
        const dateString = value;
        const date = new Date(dateString);

        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${month} ${day}, ${year}`;
    };
    const getPreOrders = () => {
        setPageState((old) => ({ ...old, isLoading: true }));
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/getproductswithpreorder?page=${pageState.page}&limit=${pageState.pageSize}&searchValue=${search}`
        }).then((data) => {
            setPageState((old) => ({
                ...old,
                isLoading: false,
                total: data.response.count,
                data: data.response.data.map((row, index) => ({
                    ...row,
                    index: index + 1 + (pageState.page - 1) * dataGridPageSize
                }))
            }));
        }).catch((error) => {
            console.log('Error fetching items');
        });
    }
    const createRequestBody = (pricingIds, endDate, preOrderStatus, stockValues, paymentMethod) => {
        const preOrderData = pricingIds.map(pricingId => ({
            "preOrderdateAvaliable": format(startOfToday(), 'yyyy-MM-dd'),
            "preOrderdateEnd": gridFormatDate(endDate),
            "isPreOrderEnabled": preOrderStatus,
            "pricingId": pricingId,
            "preOrderStock": stockValues[pricingId] || 0,
            "preOrderPayMethod": paymentMethod
        }));

        return preOrderData;
    };
    const validateStockValues = (stockValues) => {
        console.log('stock values', stockValues);
        if (Object.keys(stockValues).length !== pricingIds.length) {
            console.log("Number of pricingIds does not match number of stockValues entries");
            return false;
        }

        if (Object.keys(stockValues).length === 0) {
            console.log('stock values ARE EMPTY', stockValues);
            return false;
        }

        return Object.values(stockValues).every(stock => {
            const parsedNumber = parseFloat(stock);
            return !isNaN(parsedNumber) && parsedNumber > 0;
        });
    };



    const handlePreorderSubmit = () => {
        if (preOrderStatus) {
            if (!selectedProduct?.productId) {
                setErrorShow({ state: true, message: "Please select a product!" });
                return;
            }
            if (!pricingIds?.length > 0) {
                setErrorShow({ state: true, message: "Please select a variation!" });
                return;
            }
            if (!endDate) {
                setErrorShow({ state: true, message: "Deadline is required!" });
                return;
            }
            if (!paymentMethod) {
                setErrorShow({ state: true, message: "Payment method is required!" });
                return;
            }
            if (!validateStockValues(stockValues)) {
                setErrorShow({ state: true, message: "Available stock must be greater than 0!" });
                return;
            }
        }

        const preOrderdata = createRequestBody(pricingIds, endDate, preOrderStatus, stockValues, paymentMethod);
        HttpRequest({
            method: 'POST',
            url: `/api/v1/ecommerce/enable-preorder`,
            body: {
                preOrderdata
            }

        }).then((data) => {
            if (data?.status === 200) {
                setSuccessShow({ state: true, message: `Pre-Order ${action} successfully` });
                setShowModalEditPreorder(false)
                getPreOrders();
                setPaymentMethod('');
                setPreOrderStatus(true);
                setStockValues({});
                setEndDate(null);
                setSelectedProduct({
                    productId: "",
                    productName: "",
                    productImage: "",
                    productVariations: []
                });
                setPricingIds([]);
                setshowModalAddPreorder(false);
                setAction('added');
            } else {
                setErrorShow({ state: true, message: data.response?.data?.message });
            }
        }).catch((error) => {
            setErrorShow({ state: true, message: `Error ${action} Pre-Order` });
        })
    }
    useEffect(() => {
        getPreOrders();
    }, [search, dataGridPageSize, pageState.page])

    const getProducts = () => {
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/get_products?businessNumber=${businessNumber}&search=${searchProduct.trim()}`
        }).then((data) => {
            if (data.status === 200) {
                setProducts(data.response.data);
                if (preOrderId) {
                    let product = data?.response?.data[0];
                    console.log(product);
                    if (product) {
                        setSelectedProduct({
                            productId: product?._id,
                            productName: product?.productName,
                            productImage: product?.featuredImage,
                            productVariations: product?.pricing
                        });
                        setProducts([]);
                        setSearchProduct('');
                    }
                } else {
                    console.log('no preorder')
                }
            }
        }).catch((error) => {
            console.log('Error fetching products', error);
        });
    };
    const getPaymentMethods = () => {
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/getpreorderspaymethods`
        }).then((data) => {
            if (data.status === 200) {
                setMethods(data.response.data);
            }
        }).catch((error) => {
            console.log('Error fetching methods', error);
        });
    };


    useEffect(() => {
        if (searchProduct.trim() !== '') {
            getProducts();
        } else {
            setProducts([]);
        }
    }, [businessNumber, searchProduct]);
    useEffect(() => {
        getPaymentMethods();
    }, [])
    const getHighlightedText = (text, highlight) => {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return parts.map((part, index) => (
            <span key={index} style={part.toLowerCase() === highlight.toLowerCase() ? { color: '#032541' } : {}}>
                {part}
            </span>
        ));
    };

    const gridFormatDate = (inputDate) => {
        const yyyy = new Date(inputDate)?.getFullYear();
        let mm = new Date(inputDate)?.getMonth() + 1;
        let dd = new Date(inputDate)?.getDate();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        const formattedToday = yyyy + "-" + mm + "-" + dd;

        return formattedToday;
    };

    const handleCheckboxChange = (pricingId) => {
        setPricingIds((prev) => {
            if (prev.includes(pricingId)) {
                const newPricingIds = prev.filter((id) => id !== pricingId);
                const newStockValues = { ...stockValues };
                delete newStockValues[pricingId];
                setStockValues(newStockValues);
                return newPricingIds;
            } else {
                return [...prev, pricingId];
            }
        });
    };

    const handleStockChange = (pricingId, stockValue) => {
        if(stockValue !== undefined && stockValue >= 0) {
            setStockValues((prev) => ({
                ...prev,
                [pricingId]: stockValue,
            }));
        }
    };
    useEffect(() => {
        console.log(stockValues);
    }, [stockValues])
    return (
        <div style={{ marginTop: '4%' }}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                <Box sx={{ flexDirection: "column" }}>
                    <Box>
                        {showModalAddPreorder ? (
                            <Box variant="h4" sx={{ display: 'flex' }}>
                                <ArrowBackIos sx={{ width: '35px', height: '35px', objectFit: 'contain', margin: '20px 1px 25px 2px' }} onClick={() => { setshowModalAddPreorder(false) }} />
                                <Typography sx={{ color: "#000", fontSize: "28px", fontStyle: "normal", fontWeight: "600", lineHeight: 1.18, textAlign: 'left', margin: '20px 21px 25px 2px', fontFamily: 'Poppins' }}>
                                    Add Pre - Order Item
                                </Typography>
                            </Box>
                        ) : showModalEditPreorder ? (
                            <Box variant="h4" sx={{ display: 'flex' }}>
                                <ArrowBackIos sx={{ width: '35px', height: '35px', objectFit: 'contain', margin: '20px 1px 25px 2px' }} onClick={() => { setShowModalEditPreorder(false) }} />
                                <Typography sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600", lineHeight: 1.18, textAlign: 'left', margin: '20px 21px 25px 2px' }}>
                                    Edit Pre-Order
                                </Typography>
                            </Box>
                        ) : (
                            <Typography sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600", lineHeight: 1.18, textAlign: 'left', margin: '20px 21px 25px 2px' }}>
                                Pre-Orders
                            </Typography>
                        )}
                    </Box>
                </Box>
                {showModalAddPreorder || showModalEditPreorder ? null :
                    <Box style={{ marginRight: "15px" }}>
                        <Button variant="contained" sx={{ margin: '20px 8px 24px 563px', backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#032541", color: "#fff" } }} onClick={() => setshowModalAddPreorder(true)}>
                            <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: '500', lineHeight: 2, textAlign: 'center', color: '#fff' }}>Add Pre-Order Item</Typography>
                        </Button>
                    </Box>
                }
            </Box>
            {showModalAddPreorder || showModalEditPreorder ? null :
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{}}>
                        <CustomSearchInput placeholder={"Search Product"} name="search" value={search} onChange={(e) => setSearch(e.target.value)} />
                    </Box>
                </Box>
            }
            {showModalAddPreorder || showModalEditPreorder ?
                <>
                    <Box sx={{ width: "500px", }}>
                        <Box>
                            <Typography sx={{ margin: '0 26px 20px 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', color: '#707070', textAlign: 'left' }}>Select product to activate pre-ordering.</Typography>
                        </Box>
                        <Box sx={{}}>
                            <CustomSearchInput placeholder={"Search Product"} name="search" value={searchProduct} onChange={(e) => setSearchProduct(e.target.value)} />
                            {products?.length > 0 && <Paper sx={{ width: '371px', height: '199px', margin: '5px 33px 0 0', padding: '10px 65px 19px 15px', borderRadius: '4px', border: 'solid 1px #d8dee2', backgroundColor: '#fff', overflow: 'auto' }}>
                                <Box sx={{ width: '100%', maxWidth: '126px', height: 'auto', padding: '3px 106px 17px 0', fontSize: '14px' }}>
                                    {products?.map((product) => (
                                        <Typography onClick={() => {
                                            setSelectedProduct({
                                                productId: product?._id,
                                                productName: product?.productName,
                                                productImage: product?.featuredImage,
                                                productVariations: product?.pricing
                                            })
                                            setSearchProduct('');
                                            setProducts([]);
                                        }} sx={{ fontFamily: 'Poppins', fontSize: '14px', lineHeight: 2.14, textAlign: 'left', color: '#9c9ea4', fontWeight: 'normal', textWrap: 'nowrap', cursor: 'pointer' }}>
                                            {getHighlightedText(product?.productName, searchProduct)}
                                        </Typography>
                                    ))}
                                </Box>
                            </Paper>}
                        </Box>
                        {selectedProduct?.productId && <Box>
                            {selectedProduct?.productId && <Box sx={{ display: 'flex', width: '100%', maxWidth: '534px', height: '100%', maxHeight: 'auto', margin: '20px 20px 15px 0', alignItems: 'center' }}>
                                <img src={selectedProduct?.productImage} style={{ width: '57px', height: '57px', margin: '0 14px 0 0', objectFit: 'contain', borderRadius: '5px' }} />
                                <Typography sx={{ margin: '18px 0 11px 14px', fontFamily: 'Poppins', fontSize: '20px', fontWeight: '600', lineHeight: 2.05, textAlign: 'left', color: '#000' }}>{selectedProduct?.productName}</Typography>
                            </Box>}
                            {selectedProduct?.productVariations?.length > 0 && (
                                <Box>
                                    <Typography sx={{ margin: '15px 92px 15px 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 600, lineHeight: 1.79, textAlign: 'left', color: '#032541' }}>
                                        Product Variations
                                    </Typography>
                                    {selectedProduct?.productVariations?.map((variant) => (
                                        <Box key={variant._id} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '405px', height: '100%', maxHeight: '45px', margin: '15px 69px 10px 0', padding: '13px 31px 12px 15px', borderRadius: '4px', border: ' solid 1px #d8dee2', backgroundColor: '#fff' }}>
                                            <Box sx={{ display: 'flex' }}>
                                                <Checkbox
                                                    checked={pricingIds.includes(variant._id)}
                                                    value={variant?._id}
                                                    sx={{
                                                        width: '24px', height: '24px', border: '#b4bcc4', color: 'b4bcc4', '&.Mui-checked': {
                                                            color: '#032541',
                                                        },
                                                    }}
                                                    onChange={() => handleCheckboxChange(variant._id)}
                                                />
                                                <Typography sx={{ margin: '0 0 0 11px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, lineHeight: 1.71, textAlign: 'left', color: '#707070' }}>
                                                    {variant?.variationKey}
                                                </Typography>
                                            </Box>
                                            {pricingIds.includes(variant._id) && (
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <TextField
                                                        type="number"
                                                        value={stockValues[variant._id] || 0}
                                                        inputProps={{ style: { textAlign: 'center', color: stockValues[variant._id] > 0 ? '#17ae7b' : '#dc3545', fontSize: '14px', fontWeight: 500 } }}
                                                        sx={{
                                                            width: '56px', height: 'auto', margin: '0px 1px 0px 10px', alignItems: 'center', textAlign: 'center', '& .MuiInputBase-root': {
                                                                border: 'none',
                                                            },
                                                            '& .MuiInput-underline:before': {
                                                                borderBottom: 'none',
                                                            },
                                                            '& .MuiInput-underline:after': {
                                                                borderBottom: 'none',
                                                            },
                                                            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                                                borderBottom: 'none',
                                                            },
                                                        }}
                                                        onChange={(e) => handleStockChange(variant._id, e.target.value)}
                                                        variant="standard"
                                                    />
                                                    <Typography sx={{ margin: '1px 0 2px 11px', fontFamily: 'Poppins', fontSize: '12px', fontWeight: 500, lineHeight: 1.33, color: '#000', textAlign: 'right' }}>
                                                        Stock Available
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                            )}
                            {selectedProduct?.productName && <Box sx={{ width: "100%", maxWidth: '405px', marginTop: "20px" }}>
                                <DatePicker sx={{ margin: '12px 0 0', width: '455px', borderRadius: '4px', border: 'solid 1px #e0e0e0', backgroundColor: '#fff' }} label="Deadline" value={endDate ? endDate : null} onChange={(newValue) => setEndDate(newValue)} renderInput={(params) => <TextField {...params} style={{ margin: "0px 15px 0px 15px" }} />} />
                            </Box>}
                            {selectedProduct?.productName && <Box sx={{ margin: '12px 0 0' }}>
                                <CustomSelect value={paymentMethod} options={methods} placeholder={"Payment Method"} sm={""} onChange={(e) => setPaymentMethod(e.target.value)} />
                            </Box>}
                            {selectedProduct?.productName && <Box sx={{ display: "flex", justifyContent: "flex-end", alignContent: "end" }}>
                                <Button variant="contained" sx={{ backgroundColor: !selectedProduct?.productId || !pricingIds.length > 0 || !endDate || !paymentMethod ? "#b4bcc4" : "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#032541", color: "#fff" }, margin: '35px 48px 0 92px' }} onClick={handlePreorderSubmit}>
                                    Add Pre-Order Item
                                </Button>
                            </Box>}
                        </Box>}

                    </Box>
                </>
                :
                <Box style={{ marginRight: "15px" }}>
                    <DataGrid
                        sx={{ minHeight: '500px', height: 'auto', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                        rowCount={pageState.total}
                        pagination
                        loading={pageState.isLoading}
                        page={pageState.page - 1}
                        pageSizeOptions={[1, 10, 20, 50, 100]}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: dataGridPageSize,
                                    page: pageState.page - 1,
                                },
                            },
                        }}
                        pageSize={dataGridPageSize}
                        onPaginationModelChange={(data) => {
                            const { pageSize, page } = data;
                            setPageState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                            setPageSize(pageSize)
                        }}
                        paginationMode="server"
                        columns={columns}
                        rows={pageState.data}
                        getRowId={(row) => row._id}
                    />
                </Box>
            }
            <Modal open={openDeleteShippingRegion} onClose={handleCloseDeleteShippingRegion} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                <Box sx={deleteShippingRegionModal}>
                    <Box sx={{ padding: "10px" }}>
                        <Box sx={{ display: 'flex' }}>
                            <img src={warningIcon} alt="" style={{ width: '70px', height: '70px', margin: '12px 0 32px 0', objectFit: 'contain' }} />
                            <Box sx={{ width: '300px', height: '104px', margin: '0 0 0 39px' }}>
                                <Typography sx={{ textWrap: 'nowrap', height: '23px', width: '231px', margin: '0 134px 10px 1px', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: "#032541" }}>
                                    Delete Pre-Order?
                                </Typography>
                                <Typography sx={{ height: '41px', margin: '10px 10px 10px 0px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: '#707070', padding: '11px 66px 0 0px' }}>
                                    Are you sure you want to delete the
                                    pre-order?
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: 'center' }}>
                            <Button size='large' variant="outlined" sx={{ backgroundColor: "#fff", border: 'solid 1px #002543', color: "#6e7074", textTransform: "none", borderRadius: "4px", padding: "13px 38px 12px 37px", margin: '65px 16px 0 53px', "&:hover": { backgroundColor: "#fff" } }} onClick={handleCloseDeleteShippingRegion} >Cancel</Button>
                            <Button size='large' variant="contained" sx={{ backgroundColor: "#dc3545", color: "#fff", textTransform: "none", borderRadius: "4px", padding: "12.5px 38.4px 12.5px 40.6px", margin: '65px 56px 0 16px', "&:hover": { backgroundColor: "#dc3545" } }} onClick={() => { handlePreorderSubmit(); handleCloseDeleteShippingRegion(); }}>Delete</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default PreOrders;