import { Box, Button, Grid, Typography, MenuItem, TextField, Divider, Modal, Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HttpRequest } from "../HttpRequest";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Add, ArrowBackIos, Circle, EditOutlined, HelpOutline, Info, InfoOutlined } from "@mui/icons-material";
import CustomInputLabel from "../common/CustomInputLabel";
import { SuccessAlert } from "../common/snackbar/success";
import { ErrorAlert } from "../common/snackbar/error";
import CustomTable from "../common/CustomTable";
import CustomSearchInput from "../common/CustomSearchInput";
import CustomSelect from "../common/customSelectInput";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import warningIcon from '../../images/warning-remove-icn.svg';

const itemsModal = {
    borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: "130px", p: '61px 22px 165px 63px', border: '1px #707070', m: '55px 104px 6.5px 118px'
}
const deleteServiceModal = {
    borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: "130px", p: '61px 22px 165px 63px', border: '1px #707070', m: '55px 104px 6.5px 118px'
}

const AdditionalServices = () => {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const navigate = useNavigate();
    const [showAddServiceModal, setShowAddServiceModal] = useState(false);
    const [showEditServiceModal, setShowEditServiceModal] = useState(false);
    const [serviceId, setServiceId] = useState("");
    const [services, setServices] = useState([]);
    const [serviceBoxes, setServiceBoxes] = useState([{ serviceName: '', cost: '' }]);
    const [serviceData, setServiceData] = useState({
        serviceName: '',
        selectionType: '',
        items: []
    });
    const [openItemsModal, setOpenItemsModal] = useState(false)
    const [openDeleteService, setOpenDeleteService] = useState(false)
    const [items, setItems] = useState([]);
    const [serviceToDelete, setServiceToDelete] = useState({});
    const [search,setSearch] = useState('');

    const handleOpenItemsModal = () => { setOpenItemsModal(true) }
    const handleCloseItemsModal = () => { setOpenItemsModal(false) }
    const handleOpenDeleteService = () => { setOpenDeleteService(true) }
    const handleCloseDeleteService = () => { setOpenDeleteService(false) }

    const handleRowClick = (event, params) => {
        event.stopPropagation();
        console.log('params', params.row);
        navigate(`/zed_ecommerce/additionalServices/${params.row._id}/edit`);
    }
    const columns = [
        {
            field: 'serviceName', headerName: 'Service Name', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Service Name </Box>) },
            renderCell: (params) => {
                return (<Box onClick={(event)=>handleRowClick(event,params)}  style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography sx={{ fontWeight: '600', textTransform: 'capitalize' }}>{params.row.serviceName}</Typography>
                        </Grid>
                    </Grid>
                </Box>)
            }
        },
        {
            field: 'selection Type', headerName: 'selection Type', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Selection Type   </Box>) },
            renderCell: (params) => {
                return (<Box onClick={(event)=>handleRowClick(event,params)}  style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
                    {params.row.selectionType}
                </Box>)
            }
        },
        {
            field: 'items', headerName: 'Items', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Items  </Box>) },
            renderCell: (params) => {
                const onClickShowItems = (event) => {
                    event.stopPropagation();
                    handleOpenItemsModal();
                }
                console.log('Service items', params?.row?.items);
                setItems(params?.row?.items);
                const itemNames = params?.row?.items.map(item => item.itemName).join(', ');

                return (
                    <Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500", display: 'flex', border: 'none' }}>
                        <Typography sx={{ marginRight: '10px' }}>{itemNames}</Typography>
                        <Info />
                    </Box>
                );
            }
        },
        {
            field: 'action', headerName: 'Action', flex: 1,
            renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Action  </Box>) },
            renderCell: (params) => {
                const onClickEdit = (event) => {
                    event.stopPropagation();
                    console.log('params', params.row);
                    navigate(`/zed_ecommerce/additionalServices/${params.row._id}/edit`);
                }
                const onClickDelete = (event) => {
                    event.stopPropagation();
                    setServiceToDelete(params.row);
                    handleOpenDeleteService();
                }
                return (
                    <Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
                        <EditOutlined onClick={onClickEdit} sx={{ color: '#032541', marginRight: '10px' }} />
                        <DeleteIcon onClick={onClickDelete} sx={{ color: '#DC3545' }} />
                    </Box>
                )
            }
        },
    ]

    const Services = () => {
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/get_additional_services?searchValue=${search}`
        }).then((data) => {
            console.log(data.response?.data, ' data.response.data')
            setServices(data.response?.data)
        }).catch((error) => {
            console.log('Error fetching items');
        });
    }

    useEffect(() => {
        Services()
    }, [search])

    const selectOptions = [
        { id: 1, label: 'Multiple', value: 'multiple' },
        { id: 2, label: 'Single', value: 'single' },
    ]

    const handleAddBox = () => {
        setServiceBoxes([...serviceBoxes, { serviceName: '', cost: '', customizable: false }]);
    };
    const handleDeleteBox = (indexToRemove) => {
        setServiceBoxes(serviceBoxes.filter((_, index) => index !== indexToRemove));
    };

    const handleFormData = (e) => {
        setServiceData({ ...serviceData, [e.target.name]: e.target.value });
    }
    const handleUpdateServiceSubmit = () => {
        const isAnyEmpty = serviceBoxes.some(box => box.serviceName === '' || box.cost === '');
        if (isAnyEmpty) {
            setErrorShow({ state: true, message: "Please fill all fields" });
            return;
        }
        if (serviceData.serviceName === '' || serviceData.selectionType === '') {
            setErrorShow({ state: true, message: "Please fill all fields" });
            return;
        }

        const updatedServiceData = {
            ...serviceData,
            items: serviceBoxes.map((box) => ({
                itemName: box.serviceName,
                itemPrice: box.cost
            }))
        };
        console.log(updatedServiceData, "service data to be submitted");

        HttpRequest({
            method: 'POST',
            url: `/api/v1/ecommerce/edit_additional_services/${serviceId}`,
            body: updatedServiceData,
        }).then((data) => {
            console.log('response raw', data);
            if (data?.status === 200) {
                setSuccessShow({ state: true, message: "Service updated successfully" });
                setShowAddServiceModal(false);
                setServiceData({
                    serviceName: '',
                    selectionType: '',
                    items: []
                });
                setServiceBoxes([{ serviceName: '', cost: '' }]);
                Services()
            } else {
                console.log(data, ' data.response.data ')
                console.log(data.response?.data, ' data.response.data ')

                setErrorShow({ state: true, message: data.response?.data?.message });
            }
        }).catch((error) => {
            console.log(error, 'error updating');
            setErrorShow({ state: true, message: "Error updating service" });
        })
    }
    const handleServiceSubmit = () => {
        const isAnyEmpty = serviceBoxes.some(box => box.serviceName === '' || box.cost === '');
        if (isAnyEmpty) {
            setErrorShow({ state: true, message: "Please fill all fields" });
            return;
        }
        if (serviceData.serviceName === '' || serviceData.selectionType === '') {
            setErrorShow({ state: true, message: "Please fill all fields" });
            return;
        }

        const items = serviceBoxes.map(box => ({ itemName: box.serviceName, itemPrice: box.cost, isCustomizable: box?.customizable }));
        const data = { ...serviceData, items };
        console.log(data, "service data");

        HttpRequest({
            method: 'POST',
            url: `/api/v1/ecommerce/add_additional_services`,
            body: data,

        }).then((data) => {
            if (data?.status === 200) {
                setSuccessShow({ state: true, message: "Service added successfully" });
                setShowAddServiceModal(false);
                setServiceData({
                    serviceName: '',
                    selectionType: '',
                    items: []
                });
                setServiceBoxes([{ serviceName: '', cost: '' }]);
                Services()
            } else {
                setErrorShow({ state: true, message: data.response?.data?.message });
            }
        }).catch((error) => {
            setErrorShow({ state: true, message: "Error adding service" });
        })

    }
    const handleDeleteService = (id) => {
        handleCloseDeleteService();
        HttpRequest({
            method: 'DELETE',
            url: `/api/v1/ecommerce/delete_additional_services/${id}`,
        }).then((data) => {
            if (data?.status === 200) {
                setSuccessShow({ state: true, message: "Service deleted successfully" });
                setServiceToDelete({});
                Services()
            } else {
                console.log(data, ' data.response.data ')
                console.log(data.response?.data, ' data.response.data ')
                setErrorShow({ state: true, message: data.response?.data?.message });
            }
        }).catch((error) => {
            setErrorShow({ state: true, message: "Error deleting service" });
        })
    }

    return (
        <div style={{ marginTop: '4%' }}>

            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                <Box sx={{ flexDirection: "column" }}>
                    <Box variant="h4" sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600", display: 'flex' }}>
                        {showAddServiceModal ? (
                            <>
                                <ArrowBackIos onClick={() => { setShowAddServiceModal(false); setShowEditServiceModal(false) }} sx={{ width: '35px', height: '35px', margin: '0px 1px 4px 0', objectFit: 'contain' }} />
                                {showAddServiceModal ? 'Add' : ''}
                            </>
                        ) : ''}  Additional Services
                    </Box>
                    <Box>
                        <Typography variant="h4" sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal", }}   >
                            Additional services allow your customers customise the final product before delivery.
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    {showAddServiceModal ? (
                        <Button variant="contained" sx={{ opacity: '0.9', margin: '35px 20px 34px 295px', backgroundColor: "#b4bcc4", color: "#707070", textTransform: "none", borderRadius: "4px", padding: "12.5px 37px 12.5px 38px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#b4bcc4", color: "#707070" } }} onClick={() => { setShowAddServiceModal(false); setShowEditServiceModal(false) }} >
                            <Typography sx={{ color: '#707070', fontWeight: '550' }}>Cancel</Typography>
                        </Button>
                    ) : (
                        <Button variant="contained" sx={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#032541", color: "#fff" } }}
                            onClick={() => setShowAddServiceModal(true)} >
                            <AddCircleOutlineIcon />   Add AdditionalServices
                        </Button>
                    )}
                </Box>
            </Box>
            {showAddServiceModal ? null :
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ marginLeft: "10px" }}>
                        <CustomSearchInput placeholder={"Search service name ...."} name="search" value={search}  onChange={(event)=>setSearch(event.target.value)} />
                    </Box>
                    <Box sx={{ marginLeft: "10px" }}>
                        <CustomSelect value="Select Type" options={[]} placeholder={"Select Type"} sm={"sm"} />
                    </Box>
                </Box>
            }

            {showAddServiceModal ? <>
                <Box style={{ width: "800px", marginTop: "20px" }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left' }}>
                        <Box sx={{ width: "50%" }}>
                            <CustomInputLabel label="ServiceName Name" placeholder="Enter Service Name" />
                            <TextField label="Service Name" value={serviceData?.serviceName} name="serviceName" onChange={handleFormData} style={{ width: "100%" }} placeholder="Enter Service Name" />
                        </Box>
                        <Box sx={{ width: "50%", marginLeft: "10px", marginTop: "-6px" }}>
                            <CustomInputLabel label="Select Type" placeholder="Enter Select Type" />
                            <CustomSelect value={serviceData?.selectionType} name="selectionType" options={selectOptions} onChange={handleFormData} placeholder={"Select Type"} sm={"sm"} />
                        </Box>
                    </Box>
                    <Typography variant="h4" sx={{ color: "#032541", fontSize: "20px", fontStyle: "normal", fontWeight: "600", marginTop: "20px" }}>Service Items</Typography>
                    {serviceBoxes.map((box, index) => (
                        <Box>
                            <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left', marginBottom: '10px' }}>
                                <Box sx={{ width: "50%" }}>
                                    <CustomInputLabel label="Service Name" placeholder="Enter Service Name" />
                                    <TextField label="Service" value={box.serviceName} name={`serviceName-${index}`} onChange={(e) => {
                                        const updatedBoxes = [...serviceBoxes];
                                        updatedBoxes[index].serviceName = e.target.value;
                                        setServiceBoxes(updatedBoxes);
                                    }} style={{ width: "100%" }} placeholder="Service Name" />
                                </Box>
                                <Box sx={{ width: "50%", marginLeft: "10px", marginTop: "" }}>
                                    <CustomInputLabel label="Cost" placeholder="Enter Cost" />
                                    <TextField type="number" label="Service cost" value={box.cost} name={`cost-${index}`} onChange={(e) => {
                                        const updatedBoxes = [...serviceBoxes];
                                        updatedBoxes[index].cost = e.target.value;
                                        setServiceBoxes(updatedBoxes);
                                    }} style={{ width: "100%" }} />
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: "end", marginTop: "20px", marginLeft: "20px" }}>
                                    <Typography variant="h4" sx={{ color: "#dc3545", fontSize: "30px", fontStyle: "normal", fontWeight: "600", marginTop: "20px", cursor: 'pointer' }} onClick={() => handleDeleteBox(index)}>
                                        <DeleteIcon sx={{ fontSize: "30px" }} />
                                    </Typography>
                                </Box>
                            </Box>
                            <Box style={{ display: "flex", }}>
                                <Checkbox style={{ color: "#b4bcc4", margin: '10px 6px 37px 0px', }} checked={box?.customizable} value={box?.customizable} onChange={(e) => {
                                    const updatedBoxes = [...serviceBoxes];
                                    updatedBoxes[index].customizable = !box?.customizable;
                                    setServiceBoxes(updatedBoxes);
                                }} />
                                <Typography sx={{ margin: '20px 6px 37px 12px', fontSize: '16px', color: '#707070', fontFamily: 'Poppins' }}>Customizable</Typography>
                                <HelpOutline sx={{ color: "#b4bcc4", margin: '20px 6px 37px 12px', }} />
                            </Box>
                        </Box>
                    ))}

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: "end" }}>

                        <Typography variant="h4" sx={{ color: "#032541", fontSize: "14px", fontStyle: "normal", fontWeight: "600", marginTop: "20px", cursor: 'pointer' }} onClick={handleAddBox}>
                            <AddCircleOutlineIcon sx={{ fontSize: "14px" }} />   Add Another
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: "end" }}>

                        <Button variant="contained" sx={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "10.4px 18px 9.6px 19px", height: "40px", fontWeight: "600", marginTop: "20px", display: "flex", justifyContent: "flex-end" }} onClick={handleServiceSubmit}>
                            Add Additional Services
                        </Button>
                    </Box>
                </Box>
            </> : ''}
            {showEditServiceModal ? (
                <Box style={{ width: "800px", marginTop: "20px" }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left' }}>
                        <Box sx={{ width: "50%" }}>
                            <CustomInputLabel label="Service Name" />
                            <TextField
                                label="Service Name"
                                value={serviceData.serviceName}
                                name="serviceName"
                                onChange={handleFormData}
                                style={{ width: "100%" }}
                                placeholder="Enter Service Name"
                            />
                        </Box>
                        <Box sx={{ width: "50%", marginLeft: "10px", marginTop: "-6px" }}>
                            <CustomInputLabel label="Select Type" />
                            <CustomSelect
                                value={serviceData.selectionType}
                                name="selectionType"
                                options={selectOptions}
                                onChange={handleFormData}
                                placeholder="Select Type"
                            />
                        </Box>
                    </Box>
                    <Typography variant="h4" sx={{ color: "#032541", fontSize: "20px", fontWeight: "600", marginTop: "20px" }}>
                        Service Items
                    </Typography>
                    {serviceBoxes.map((box, index) => (
                        <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left', marginBottom: '10px' }}>
                            <Box sx={{ width: "50%" }}>
                                <CustomInputLabel label="Service Name" />
                                <TextField
                                    value={box.itemName}
                                    onChange={(e) => {
                                        const updatedBoxes = [...serviceBoxes];
                                        updatedBoxes[index].serviceName = e.target.value;
                                        setServiceBoxes(updatedBoxes);
                                    }}
                                    style={{ width: "100%" }}
                                    placeholder="Enter Service Name"
                                />
                            </Box>
                            <Box sx={{ width: "50%", marginLeft: "10px" }}>
                                <CustomInputLabel label="Cost" />
                                <TextField
                                    type="number"
                                    value={box.itemPrice}
                                    onChange={(e) => {
                                        const updatedBoxes = [...serviceBoxes];
                                        updatedBoxes[index].cost = e.target.value;
                                        setServiceBoxes(updatedBoxes);
                                    }}
                                    style={{ width: "100%" }}
                                    placeholder="Enter Cost"
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end', marginTop: "20px", marginLeft: "20px" }}>
                                <Typography variant="h4" sx={{ color: "#dc3545", fontSize: "30px", cursor: 'pointer' }} onClick={() => handleDeleteBox(index)}>
                                    ✕
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: "end" }}>
                        <Typography variant="h4" sx={{ color: "#032541", fontSize: "14px", cursor: 'pointer' }} onClick={handleAddBox}>
                            + Add Another
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: "20px" }}>
                        <Button
                            variant="contained"
                            onClick={handleUpdateServiceSubmit}
                            sx={{ maxWidth: "150px", backgroundColor: "#032541", color: "#fff", textTransform: "none" }}
                        >
                            Update
                        </Button>
                    </Box>
                </Box>
            ) : null}
            {showAddServiceModal || showEditServiceModal ? null :
                <Box style={{ marginRight: "15px" }}>
                    <CustomTable columns={columns} data={services} />
                </Box>
            }
            <Modal open={openItemsModal} onClose={handleCloseItemsModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                <Box sx={itemsModal}>
                    <Box sx={{ padding: "10px" }}>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ margin: '0 0 0 39px' }}>
                                <Typography sx={{ textWrap: 'nowrap', height: '23px', margin: '0 134px 30px 1px', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: "#032541" }}>
                                    Additional Service Items
                                </Typography>
                                {items?.map((item) => (
                                    <Box sx={{ display: 'flex', }}>
                                        <Box sx={{ display: 'flex', marginRight: '20px' }}>
                                            <Typography sx={{ margin: '1px 10px 1px 0', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 550, textAlign: 'left', color: '#000' }}>Name:</Typography>
                                            <Typography sx={{ margin: '1px 0px 1px 0', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 'normal', textAlign: 'right', color: '#707070' }}>{item.itemName}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex' }}>
                                            <Typography sx={{ margin: '1px 10px 1px 0', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 550, textAlign: 'left', color: '#000' }}>Price:</Typography>
                                            <Typography sx={{ margin: '1px 0px 1px 0', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 'normal', textAlign: 'right', color: '#707070' }}>KES {item.itemPrice}</Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            <Modal open={openDeleteService} onClose={handleCloseDeleteService} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                <Box sx={deleteServiceModal}>
                    <Box sx={{ padding: "10px" }}>
                        <Box sx={{ display: 'flex' }}>
                            <img src={warningIcon} alt="" style={{ width: '70px', height: '70px', margin: '12px 0 32px 0', objectFit: 'contain' }} />
                            <Box sx={{ width: '300px', height: '104px', margin: '0 0 0 39px' }}>
                                <Typography sx={{ textWrap: 'nowrap', height: '23px', width: '231px', margin: '0 134px 10px 1px', fontFamily: 'Poppins', fontSize: '18px', fontWeight: 600, fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: "#032541" }}>
                                    Delete {serviceToDelete.serviceName}?
                                </Typography>
                                <Typography sx={{ width: '255px', height: '41px', margin: '10px 0', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'left', color: '#707070', padding: '11px 66px 0 0px' }}>
                                    Additional service titled ‘Customization’ and its associated services will be deleted.
                                    <br />
                                    <br />
                                    This action cannot be undone/
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: 'center' }}>
                            <Button size='large' variant="outlined" sx={{ backgroundColor: "#fff", border: 'solid 1px #002543', color: "#6e7074", textTransform: "none", borderRadius: "4px", padding: "13px 38px 12px 37px", margin: '65px 16px 0 53px', "&:hover": { backgroundColor: "#fff" } }} onClick={handleCloseDeleteService} >Cancel</Button>
                            <Button size='large' variant="contained" sx={{ backgroundColor: "#dc3545", color: "#fff", textTransform: "none", borderRadius: "4px", padding: "12.5px 38.4px 12.5px 40.6px", margin: '65px 56px 0 16px' }} onClick={() => { handleDeleteService(serviceToDelete._id) }}>Delete</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default AdditionalServices