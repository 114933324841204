import { Box, Button, Grid, Typography, MenuItem, TextField, Divider, Modal, Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HttpRequest } from "../HttpRequest";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CustomInputLabel from "../common/CustomInputLabel";
import { SuccessAlert } from "../common/snackbar/success";
import { ErrorAlert } from "../common/snackbar/error";
import CustomSelect from "../common/customSelectInput";
import DeleteIcon from '@mui/icons-material/Delete';
import { ArrowBackIos, HelpOutline } from "@mui/icons-material";

const itemsModal = {
    borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: "130px", p: '61px 22px 165px 63px', border: '1px #707070', m: '55px 104px 6.5px 118px'
}

const EditAdditionalServices = () => {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const { serviceId } = useParams();
    const [services, setServices] = useState([]);
    const [serviceBoxes, setServiceBoxes] = useState([{ serviceName: '', cost: '' }]);
    const [serviceData, setServiceData] = useState({
        serviceName: '',
        selectionType: '',
        items: []
    });
    const navigate = useNavigate();

    const Services = () => {
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/get_additional_services`
        }).then((data) => {
            console.log(data.response?.data, ' data.response.data', serviceId)
            const services = data.response?.data;
            const filteredData = services.find(item => item._id === serviceId);
            if (filteredData) {
                setServiceData({
                    serviceName: filteredData.serviceName,
                    selectionType: filteredData.selectionType,
                    items: filteredData.items || []
                });
                setServiceBoxes(filteredData.items);
            }
            console.log(serviceData, 'serviceDatad')
        }).catch((error) => {
            console.log('Error fetching items');
        });
    }

    useEffect(() => {
        Services()
    }, [serviceId])

    const selectOptions = [
        { id: 1, label: 'Multiple', value: 'multiple' },
        { id: 2, label: 'Single', value: 'single' },
    ]

    const handleAddBox = () => {
        setServiceBoxes([...serviceBoxes, { serviceName: '', cost: '' }]);
    };
    const handleDeleteBox = (indexToRemove) => {
        setServiceBoxes(serviceBoxes.filter((_, index) => index !== indexToRemove));
    };

    const handleFormData = (e) => {
        setServiceData({ ...serviceData, [e.target.name]: e.target.value });
    }
    const handleServiceSubmit = () => {
        const isAnyEmpty = serviceBoxes.some(box => box.itemName === '' || box.itemPrice === '');
        if (isAnyEmpty) {
            setErrorShow({ state: true, message: "Please fill all fields" });
            return;
        }
        if (serviceData.serviceName === '' || serviceData.selectionType === '') {
            setErrorShow({ state: true, message: "Please fill all fields" });
            return;
        }
        console.log('New service box', serviceBoxes);
        const updatedServiceData = {
            ...serviceData,
            items: serviceBoxes.map((box) => ({
                itemName: box.itemName,
                itemPrice: box.itemPrice,
                isCustomizable: box?.isCustomizable
            }))
        };
        console.log(updatedServiceData, "service data to be submitted");

        HttpRequest({
            method: 'POST',
            url: `/api/v1/ecommerce/edit_additional_services/${serviceId}`,
            body: updatedServiceData,
        }).then((data) => {
            console.log('response raw', data);
            if (data?.status === 200) {
                setSuccessShow({ state: true, message: "Service updated successfully" });
                setServiceData({
                    serviceName: '',
                    selectionType: '',
                    items: []
                });
                setServiceBoxes([{ serviceName: '', cost: '' }]);
                Services()
                setTimeout(() => {
                    navigate('/zed_ecommerce/additionalServices');
                }, 2000);
            } else {
                console.log(data, ' data.response.data ')
                console.log(data.response?.data, ' data.response.data ')

                setErrorShow({ state: true, message: data.response?.data?.message });
            }
        }).catch((error) => {
            console.log(error, 'error updating');
            setErrorShow({ state: true, message: "Error updating service" });
        })
    }

    return (
        <div style={{ marginTop: '4%' }}>

            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                <Box sx={{ flexDirection: "column" }}>

                    <Box variant="h4" sx={{ display: 'flex' }}>
                        <ArrowBackIos sx={{ width: '35px', height: '35px', objectFit: 'contain' }} onClick={() => { navigate('/zed_ecommerce/additionalServices') }} />
                        <Typography sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600" }}>
                            Edit Additional Services
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h4" sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal", }}   >
                            Additional services allow your customers customise the final product before delivery.
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Button variant="contained" sx={{ opacity: '0.9', margin: '35px 20px 34px 295px', backgroundColor: "#b4bcc4", color: "#707070", textTransform: "none", borderRadius: "4px", padding: "12.5px 37px 12.5px 38px", height: "40px", fontWeight: "600", "&:hover": { backgroundColor: "#b4bcc4", color: "#707070" } }} onClick={() => navigate(-1)} >
                        <Typography sx={{ color: '#707070', fontWeight: '550' }}>Cancel</Typography>
                    </Button>
                </Box>
            </Box>
            <Box style={{ width: "800px", marginTop: "20px" }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left' }}>
                    <Box sx={{ width: "50%" }}>
                        <CustomInputLabel label="ServiceName Name" placeholder="Enter ServiceName Name" />
                        <TextField label="Service Name" value={serviceData?.serviceName} name="serviceName" onChange={handleFormData} style={{ width: "100%" }} placeholder="Enter Service Name" />
                    </Box>
                    <Box sx={{ width: "50%", marginLeft: "10px", marginTop: "-6px" }}>
                        <CustomInputLabel label="Select Type" placeholder="Enter Select Type" />
                        <CustomSelect value={serviceData?.selectionType} name="selectionType" options={selectOptions} onChange={handleFormData} placeholder={"Select Type"} sm={"sm"} />
                    </Box>
                </Box>
                <Typography variant="h4" sx={{ color: "#032541", fontSize: "20px", fontStyle: "normal", fontWeight: "600", marginTop: "20px" }}>Service Items</Typography>
                {serviceBoxes?.map((box, index) => (
                    <Box>
                        <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left', marginBottom: '10px' }}>
                            <Box sx={{ width: "50%" }}>
                                <CustomInputLabel label="Service Name" placeholder="Enter Service Name" />
                                <TextField value={box.itemName} name={`serviceName-${index}`} onChange={(e) => {
                                    const updatedBoxes = [...serviceBoxes];
                                    updatedBoxes[index].itemName = e.target.value;
                                    setServiceBoxes(updatedBoxes);
                                }} style={{ width: "100%" }} placeholder="Service Name" />
                            </Box>
                            <Box sx={{ width: "50%", marginLeft: "10px", marginTop: "" }}>
                                <CustomInputLabel label="Cost" placeholder="Enter Cost" />
                                <TextField type="number" label="Service cost" value={box.itemPrice} name={`cost-${index}`} onChange={(e) => {
                                    const updatedBoxes = [...serviceBoxes];
                                    updatedBoxes[index].itemPrice = e.target.value;
                                    setServiceBoxes(updatedBoxes);
                                }} style={{ width: "100%" }} />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: "end", marginTop: "20px", marginLeft: "20px" }}>
                                <Typography variant="h4" sx={{ color: "#dc3545", fontSize: "30px", fontStyle: "normal", fontWeight: "600", marginTop: "20px", cursor: 'pointer' }} onClick={() => handleDeleteBox(index)}>
                                    <DeleteIcon sx={{ fontSize: "30px" }} />
                                </Typography>
                            </Box>
                        </Box>
                        <Box style={{ display: "flex", }}>
                            <Checkbox style={{ color: "#b4bcc4", margin: '10px 6px 37px 0px', }} checked={box?.isCustomizable === true ? 'checked' : ''} value={box?.isCustomizable} onChange={(e) => {
                                console.log('isCustomizable value before', box);
                                const updatedBoxes = [...serviceBoxes];
                                updatedBoxes[index].isCustomizable = !box?.isCustomizable;
                                console.log('isCustomizable value after', box);
                                setServiceBoxes(updatedBoxes);
                            }} />
                            <Typography sx={{ margin: '20px 6px 37px 12px', fontSize: '16px', color: '#707070', fontFamily: 'Poppins' }}>Customizable</Typography>
                            <HelpOutline sx={{ color: "#b4bcc4", margin: '20px 6px 37px 12px', }} />
                        </Box>
                    </Box>
                ))}

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: "end" }}>

                    <Typography variant="h4" sx={{ color: "#032541", fontSize: "14px", fontStyle: "normal", fontWeight: "600", marginTop: "20px", cursor: 'pointer' }} onClick={handleAddBox}>
                        <AddCircleOutlineIcon sx={{ fontSize: "14px" }} />   Add Another
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: "end" }}>

                    <Button variant="contained" sx={{ backgroundColor: "#032541", color: "#fff", textTransform: "none", borderRadius: "5px", padding: "12.5px 48.7px 12.5px 49.3px", height: "45px", fontWeight: "600", marginTop: "20px", display: "flex", justifyContent: "flex-end" }} onClick={handleServiceSubmit}>
                        Update
                    </Button>
                </Box>
            </Box>
        </div>
    )
}

export default EditAdditionalServices