import { Box, Button, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputAdornment, MenuItem, Modal, OutlinedInput, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import CustomerHeader from "../Orders/customerHeader";
import { Link, useNavigate } from "react-router-dom";
import { Circle, Hail, KeyboardArrowDown, KeyboardArrowUp, LocalShipping, TransferWithinAStation } from "@mui/icons-material";
import mpesaIcon from "../../images/mpesa.png";
import { useEffect, useState } from "react";
import { HttpRequest } from "../HttpRequest";
import { useDispatch, useSelector } from "react-redux";
import { SuccessAlert } from "../common/snackbar/success";
import { ErrorAlert } from "../common/snackbar/error";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import loadingIcon from "../../images/loading.svg";
import { clearCart } from "../../features/cartSlice";
import cardIcon from "../../images/card.png";
import secureLocalStorage from "react-secure-storage";

const base_url = process.env.REACT_APP_WEB_SOCKET_URL;

const Checkout = () => {

    const orderProcessingModal = {
        borderRadius: "12px", backgroundColor: "#fff", position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)', width: "260px", height: "176px", p: { xs: '49px 50px 80px', sm: '49px 50px 80px', md: '91px 101px 90px', lg: '91px 101px 90px' }, border: '1px #707070', m: { lg: '36px 227px 7px 51px' }, boxShadow: ' 3px 0 20px 0 rgba(0, 0, 0, 0.16)', textAlign: 'center'
    }

    const [openOrderProcessing, setOpenOrderProcessing] = useState(false);
    const [shippingRegions, setShippingRegions] = useState([]);
    const [shippingOption, setShippingOption] = useState('ship');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [county, setCounty] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [apartment, setApartment] = useState('');
    const [shippingRegion, setShippingRegion] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [mpesaPhoneNumber, setMpesaPhoneNumber] = useState('');
    const [mobileMoneyNumber, setMobileMoneyNumber] = useState('');
    const [pickupLocation, selectPickupLocation] = useState('');
    const [pickupLocations, selectPickupLocations] = useState([]);
    const [shippingCost, setShippingCost] = useState(0);
    const [orderCreated, setOrderCreated] = useState(false);
    const [orderCreatedDetails, setOrderCreatedDetails] = useState({});

    const [addressError, setAddressError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [countyError, setCountyError] = useState(false);
    const [postalCodeError, setPostalCodeError] = useState(false);
    const [apartmentError, setApartmentError] = useState(false);
    const [shippingRegionError, setShippingRegionError] = useState(false);
    const [paymentMethodError, setPaymentMethodError] = useState(false);
    const [mpesaPhoneNumberError, setMpesaPhoneNumberError] = useState(false);
    const [mobileMoneyNumberError, setMobileMoneyNumberError] = useState(false);
    const [pickupLocationError, setPickupLocationError] = useState(false);

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    const [bankPaybillStatus, setBankPaybillStatus] = useState(false);
    const [mpesaStatus, setMpesaStatus] = useState(false);
    const [payCardStatus, setPayCardStatus] = useState(false);

    const handleOpenOrderProcessing = () => { setOpenOrderProcessing(true) }

    const [buttonStatus, setButtonStatus] = useState(true);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const stateCart = useSelector((state) => state.cart);
    let selectedPackage = stateCart?.package;
    const totalPackagePrice = selectedPackage?.price ?? 0;

    const [showAll, setShowAll] = useState({});

    const handleToggleShowAll = (itemId) => {
        setShowAll((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId]
        }));
    };

    let cart = stateCart?.cart;
    const totalPrice = cart?.reduce((total, item) => {
        const { price, quantity } = item;
        const itemTotalPrice = price * quantity;
        return total + itemTotalPrice;
    }, 0);
    const customizationAmount = cart?.reduce((total, item) => {
        const { customizationAmount, quantity } = item;
        const itemTotalPrice = customizationAmount * quantity;
        return total + itemTotalPrice;
    }, 0);
    const transformedProducts = cart?.map(product => {
        return {
            productId: product.id,
            productImage:product?.image,
            quantity: product.quantity,
            variationKeyId: product.variationId,
            additionalServices: product?.additionalServices,
            isPreOrder: product.isPreOrder
        };
    });
    let payloadToSubmit = [];
    if (selectedPackage && selectedPackage?.id) {
        const updatedProducts = [...transformedProducts, {
            productId: selectedPackage.id,
            quantity: selectedPackage.quantity,
            variationKeyId: selectedPackage.variationId,
        }];
        payloadToSubmit = updatedProducts
    } else {
        payloadToSubmit = transformedProducts;
    }


    const getShippingRegions = () => {
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/get_shipping_regions`
        }).then((data) => {
            console.log('Fetch shipping response', data.response.data);
            setShippingRegions(data.response.data);
        }).catch((error) => {
            console.log('Error fetching shipping regions');
        });
    }
    const getPickupLocations = () => {
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/get_pickup_locations`
        }).then((data) => {
            console.log('Fetch pickup location response', data.response.data);
            selectPickupLocations(data.response.data);
        }).catch((error) => {
            console.log('Error fetching pickup location');
        });
    }
    const payButtonStatus = () => {
        console.log('shippingRegion', shippingRegion, 'paymentMethod', paymentMethod, 'mpesaPhoneNumber', mpesaPhoneNumber)
        if (shippingOption === 'ship') {
            if (shippingRegion !== '' && paymentMethod !== '' && (mpesaPhoneNumber !== null || mobileMoneyNumber !== null)) {
                setButtonStatus(false);
            }
        }
        if (shippingOption === 'pickup') {
            if (pickupLocation !== '' && paymentMethod !== '' && (mpesaPhoneNumber !== null || mobileMoneyNumber !== null)) {
                setButtonStatus(false);
            }
        }
        console.log('Button status', buttonStatus);
    }
    const validateField = (field, setError, isValidFunc) => {
        const isValid = field !== '' && isValidFunc(field);
        setError(!isValid);
        return isValid;
    };

    const isValidMpesaPhoneNumber = (phoneNumber) => {
        const phoneNumberRegex = /^254\d{9}$/;
        return phoneNumberRegex.test(phoneNumber);
    };

    const validateForm = () => {
        const validationResults = {
            address: shippingOption === 'ship' ? validateField(address, setAddressError, () => true) : true,
            county: shippingOption === 'ship' ? validateField(county, setCountyError, () => true) : true,
            city: shippingOption === 'ship' ? validateField(city, setCityError, () => true) : true,
            postalCode: shippingOption === 'ship' ? validateField(postalCode, setPostalCodeError, () => true) : true,
            shippingRegion: shippingOption === 'ship' ? validateField(shippingRegion, setShippingRegionError, () => true) : true,
            pickupLocation: shippingOption === 'pickup' ? validateField(pickupLocation, setPickupLocationError, () => true) : true,
            paymentMethod: validateField(paymentMethod, setPaymentMethodError, () => true),
            mpesaPhoneNumber: paymentMethod === 'mpesa' ? validateField(mpesaPhoneNumber, setMpesaPhoneNumberError, isValidMpesaPhoneNumber) : true,
            mobileMoneyNumber: paymentMethod === 'kcbpaybill' ? validateField(mobileMoneyNumber, setMobileMoneyNumberError, () => true) : true
        };

        const isFormValid = Object.values(validationResults).every(result => result);

        return isFormValid;
    };

    const handleSubmit = () => {
        const customerId = secureLocalStorage.getItem('customerId');
        console.log('customerId', customerId);
        if (!customerId) {
            navigate('/login');
        }
        let payload = {
            products: payloadToSubmit,
            customerId: customerId,
            deliveryMethod: shippingOption,
            shippingCustomerpreference: {
                address: address,
                city: city,
                apartment: apartment,
                county: county,
                postalCode: postalCode
            }
        };

        if (shippingOption === 'ship') {
            payload.shippingRegionId = shippingRegion;
        } else if (shippingOption === 'pickup') {
            payload.pickUpLocationId = pickupLocation;
        }

        const isValid = validateForm();

        if (isValid) {
            if(orderCreated) {
                sendStkPush(orderCreatedDetails);
                setOpenOrderProcessing(true);
            }else{
                HttpRequest({
                    method: 'POST',
                    url: `/api/v1/ecommerce/create_order`,
                    body: payload
                }).then((data) => {
                    console.log('Create order response ', data.response.data);
                    if (data.status === 200) {
                        setOpenOrderProcessing(true);
                        setOrderCreated(true);
                        setOrderCreatedDetails(data?.response?.data)
                        sendStkPush(data?.response?.data);
    
                    } else {
                        setErrorShow({ state: true, message: 'Failed to place order. Please try again.' });
                    }
                }).catch((error) => {
                    console.log('Error creating order', error);
                });
            }
       
        }
    };

    const sendStkPush = (orderDetails) => {
        const payload = {
            amount: orderDetails.transamount,
            phone: paymentMethod === 'mpesa' ? mpesaPhoneNumber : mobileMoneyNumber,
            businessId: orderDetails.businessId,
            orderIds: [
                orderDetails._id
            ],
            type: 'order',
            paymentChanel: 'Web',
            discount: 0
        }

        HttpRequest({
            method: 'POST',
            url: paymentMethod === 'mpesa' ? `/api/pushstk` : paymentMethod === 'kcbpaybill' ? `/api/v1/payments/initiate_kcb_stk_push` : paymentMethod === 'card' ? `/api/v1/payments/card/pay` : '',
            body: payload
        }).then((data) => {
            console.log('send stk response ', data.response.data.stkOrderId);
            if (data.status === 200) {
                let orderId
                let statusPayload = {
                    pushyTransactionId: paymentMethod === 'card' ? data?.response?.data?.data?._id : data.response.data.stkOrderId,
                    businessNo: orderDetails.businessId
                }
                console.log(data?.response?.data?.data?.invoiceInformation, 'data?.data')
                const paymentLink = data?.response?.data?.data?.invoiceInformation?.paymentLink;
                if (paymentMethod === 'mpesa' || paymentMethod == 'kcbpaybill') {
                    orderId = data?.response.data?.stkOrderId;
                }
                console.log(orderId, 'orderId');
                if (paymentMethod === 'card') {
                    orderId = data?.response?.data?.data.invoiceInformation?.invoiceNumber;
                }
                if (paymentLink) {
                    const cacheBustedLink = `${paymentLink}?t=${new Date().getTime()}`;
                    setTimeout(() => {
                        window.open(cacheBustedLink);
                    }, 3000);

                } else {
                    console.error("Payment link is invalid or not provided.");
                }
                const checkStatusTimer = setTimeout(() => {
                    HttpRequest({
                        method: 'POST',
                        url: `/api/order_payment_status`,
                        body: statusPayload
                    }).then((data) => {

                        if (data?.status === 200) {
                            setSuccessShow({ state: true, message: 'Order processed successfully.' })

                        } else {
                            setErrorShow({ state: true, message: 'Failed to place order. Please try again.' });
                        }
                    }).catch((error) => {
                        console.log('Error creating order', error);
                    }).finally(() => {
                        setOpenOrderProcessing(false);
                    });
                }, 300000);

                const socket = new WebSocket(base_url + '/notification/' + orderId);
                socket.addEventListener('message', function (event) {

                    const data = JSON.parse(event.data);
                    console.log('Websocket data ', event.data);
                    if (data?.status === "OK") {
                        setSuccessShow({ state: true, message: data?.message });
                    } else if (data?.status === "SUCCESS") {
                        setOpenOrderProcessing(false);
                        setSuccessShow({ state: true, message: "Your order has been processed successfully." });
                        dispatch(clearCart());
                        setTimeout(() => {
                            navigate(`/order/customer/${orderDetails.customerId}/${orderDetails._id}`)
                        }, 3000);

                    } else {
                        setErrorShow({ state: true, message: data.statusMessage });
                        socket.close();
                        setOpenOrderProcessing(false);
                    }
                    clearTimeout(checkStatusTimer);
                });

            } else {
                setErrorShow({ state: true, message: 'Failed to process order payments.' });
            }
        }).catch((error) => {
            console.log('Error creating order', error);
        });
    }

    const handleShippingRegion = (event) => {
        setShippingRegion(event.target.value);
        const filteredShippingRegion = shippingRegions.find(item => item._id === event.target.value);
        setShippingCost(filteredShippingRegion ? filteredShippingRegion.shippingCost : 0);
    }
    const getCustomerProfile = () => {
        let customerId = secureLocalStorage.getItem("customerId") ?? secureLocalStorage.getItem("userId")
        HttpRequest({
            method: 'GET',
            url: `/api/get_customer_by_customer_id?customerId=${customerId}`
        }).then((data) => {
            console.log('Fetch customer profile', data.response.data);
            let customerData = data?.response?.data;
            setFirstName(customerData?.firstName);
            setLastName(customerData?.lastName);
            setEmail(customerData?.email);
            setPhone(customerData?.phone);
            setAddress(customerData?.customerpreference?.address);
            setCity(customerData?.customerpreference?.city);
            setCounty(customerData?.customerpreference?.county);
            setPostalCode(customerData?.customerpreference?.postalCode);
            setApartment(customerData?.customerpreference?.apartment);

        }).catch((error) => {
            console.log('Error fetching shipping regions');
        });
    }
    const checkPaymentStatus = async () => {
        await HttpRequest({
            method: 'GET',
            url: `/api/get_payment_methods_with_status`
        }).then((data) => {
            console.log('Registration response ', data.response);
            const mpesaStatus = data.response.data.find(method => method.name === "Mpesa")?.status;
            const cardStatus = data.response.data.find(method => method.name === "Card")?.status;
            const cashStatus = data.response.data.find(method => method.name === "Cash")?.status;
            const paybillStatus = data.response.data.find(method => method.name === "Banks")?.paymentOptions[0].kcb[0]?.status
            const bankStatus = data.response.data.find(method => method.name === "Banks")?.paymentOptions[0].kcb[2]?.status;
            const momoPayStatus = data.response?.data?.find(method => method?.name === 'MTN Momo')?.status
            setMpesaStatus(mpesaStatus)
            //  setCardStatus(cardStatus)
            setBankPaybillStatus(paybillStatus)
            setPayCardStatus(cardStatus)
            // setBankStatus(bankStatus)
            // setMomoStatus(momoPayStatus)
            //  setCashStatus(cashStatus)
        }).catch((error) => {
            console.log('Error fetching payment methods', error);
        });
    }
    useEffect(() => {
        getShippingRegions();
        getPickupLocations();
        getCustomerProfile();
        checkPaymentStatus();
    }, [])
    useEffect(() => {
        payButtonStatus();
    }, [shippingRegion, paymentMethod, mpesaPhoneNumber])

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            </Grid>

            <Grid container item xs={12} sx={{ justifyContent: 'center', m: { xs: '1% 0% 1% 10%', sm: '1% 0% 1% 10%', md: '1% 10% 1% 10%', lg: '1% 19% 10% 19%', xl: '1% 10% 10% 19%' } }}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mr: '16%' }}>
                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, textAlign: 'left', color: '#343434', textWrap: 'nowrap', }}>Delivery Method</Typography>
                    <Link to='/cart' style={{ textWrap: 'nowrap', fontFamily: 'Poppins', fontSize: '14px', textAlign: 'right', color: '#707070' }}>Edit Your cart</Link>
                </Grid>
                <Grid container item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid item xs={12} >
                        <FormControl>
                            <RadioGroup row name="shipping-options" value={shippingOption} onChange={(e) => setShippingOption(e.target.value)}>
                                <Box sx={{ display: 'flex', width: { xs: '100%', sm: '100%', md: '270px' }, height: '50px', margin: '23px 15px 21px 0px', padding: '10px 20px', borderRadius: '10px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff' }}>
                                    <FormControlLabel value="ship" control={<Radio inputProps={{ style: { color: 'red' } }} sx={{ color: '#02b3ad', '&.Mui-checked': { color: '#02b3ad', } }} />} label={
                                        <Typography sx={{ color: '#032541', fontSize: '14px', fontFamily: 'Poppins' }}>
                                            Ship
                                        </Typography>
                                    }
                                        sx={{ width: '24px', height: '24px', margin: '15px 0 2px 10px', objectFit: 'contain', color: '#032541', fontSize: '14px', fontFamily: 'Poppins' }} />
                                    <LocalShipping sx={{ width: '57.5px', height: '41.8px', margin: '8px 1.1px 1.1px 190px' }} />
                                </Box>
                                <Box sx={{ display: 'flex', width: { xs: '100%', sm: '100%', md: '270px' }, height: '50px', margin: '23px 15px 21px 0px', padding: '10px 20px', borderRadius: '10px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff' }}>
                                    <FormControlLabel value="pickup" control={<Radio sx={{ color: '#02b3ad', '&.Mui-checked': { color: '#02b3ad', } }} />} label={
                                        <Typography sx={{ color: '#032541', fontSize: '14px', fontFamily: 'Poppins' }}>
                                            Free Pickup
                                        </Typography>
                                    }
                                        sx={{ width: '24px', height: '24px', margin: '15px 0 2px 10px', objectFit: 'contain', color: '#032541', fontSize: '14px', fontFamily: 'Poppins', textWrap: 'nowrap' }} />
                                    <TransferWithinAStation sx={{ width: '57.5px', height: '35.8px', margin: '8px 1.1px 1.1px 170px' }} />
                                </Box>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography sx={{ margin: '21px 11px 16px 0px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, textAlign: 'left', color: '#343434' }}>
                                Customer Details
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl sx={{ width: '100%', maxWidth: { xs: '320px', sm: '340px', md: '300px' }, height: '48px', margin: '16px 1px 11px 0px', padding: '4px 2px 14px 0px', borderRadius: '5px', }}>
                                    <TextField disabled InputProps={{ style: { fontSize: '14px', color: '#000', textAlign: 'left', fontFamily: 'Poppins' } }} aria-readonly value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" sx={{ backgroundColor: '#fafafa', border: 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl sx={{ width: '100%', maxWidth: { xs: '320px', sm: '340px', md: '300px' }, height: '48px', margin: '16px 1px 11px 0px', padding: '4px 2px 14px 0px', borderRadius: '5px', }}>
                                    <TextField disabled InputProps={{ style: { fontSize: '14px', color: '#000', textAlign: 'left', fontFamily: 'Poppins' } }} value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" sx={{ backgroundColor: '#fafafa', border: 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl sx={{ width: '100%', maxWidth: { xs: '320px', sm: '340px', md: '300px' }, height: '48px', margin: '16px 15px 11px 0px', padding: '4px 26px 14px 0px', borderRadius: '5px', }}>
                                    <TextField disabled InputProps={{ style: { fontSize: '14px', color: '#000', textAlign: 'left', fontFamily: 'Poppins' } }} value={phone} onChange={(e) => setPhone(e.target.value)} type="number" placeholder="Phone Number" sx={{ backgroundColor: '#fafafa', border: 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl sx={{ width: '100%', maxWidth: { xs: '320px', sm: '340px', md: '300px' }, height: '48px', margin: '16px 15px 11px 0px', padding: '4px 26px 14px 0px', borderRadius: '5px', }}>
                                    <TextField disabled InputProps={{ style: { fontSize: '14px', color: '#000', textAlign: 'left', fontFamily: 'Poppins' } }} value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" sx={{ backgroundColor: '#fafafa', border: 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    {shippingOption === 'ship' &&
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography sx={{ margin: '21px 11px 16px 0px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, textAlign: 'left', color: '#343434' }}>
                                    Shipping Address
                                </Typography>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <FormControl sx={{ width: '100%', maxWidth: { xs: '320px', sm: '340px', md: '300px' }, height: '48px', margin: '16px 15px 11px 0px', padding: '4px 26px 14px 0px', borderRadius: '5px', }} required>
                                        <TextField InputProps={{ style: { fontSize: '14px', color: '#707070', textAlign: 'left', fontFamily: 'Poppins' } }} error={addressError} helperText={addressError ? 'Please enter address' : ''} value={address} onChange={(e) => setAddress(e.target.value)} required placeholder="Physical Address" sx={{ backgroundColor: '#fff', border: addressError ? '' : 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <FormControl sx={{ width: '100%', maxWidth: { xs: '320px', sm: '340px', md: '300px' }, height: '48px', margin: '16px 15px 11px 0px', padding: '4px 26px 14px 0px', borderRadius: '5px', }}>
                                        <TextField InputProps={{ style: { fontSize: '14px', color: '#707070', textAlign: 'left', fontFamily: 'Poppins' } }} error={cityError} helperText={cityError ? 'Please enter city' : ''} value={city} onChange={(e) => setCity(e.target.value)} required placeholder="City" sx={{ backgroundColor: '#fff', border: cityError ? '' : 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <FormControl sx={{ width: '100%', maxWidth: { xs: '320px', sm: '340px', md: '300px' }, height: '48px', margin: '16px 15px 11px 0px', padding: '4px 26px 14px 0px', borderRadius: '5px', }}>
                                        <TextField InputProps={{ style: { fontSize: '14px', color: '#707070', textAlign: 'left', fontFamily: 'Poppins' } }} error={countyError} helperText={countyError ? 'Please enter county' : ''} value={county} onChange={(e) => setCounty(e.target.value)} required type="text" placeholder="County" sx={{ backgroundColor: '#fff', border: countyError ? '' : 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <FormControl sx={{ width: '100%', maxWidth: { xs: '320px', sm: '340px', md: '300px' }, height: '48px', margin: '16px 15px 11px 0px', padding: '4px 26px 14px 0px', borderRadius: '5px', }}>
                                        <TextField InputProps={{ style: { fontSize: '14px', color: '#707070', textAlign: 'left', fontFamily: 'Poppins' } }} error={postalCodeError} helperText={postalCodeError ? 'Please enter postal code' : ''} value={postalCode} onChange={(e) => setPostalCode(e.target.value)} required type="text" placeholder="Postal code" sx={{ backgroundColor: '#fff', border: postalCodeError ? '' : 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl sx={{ width: '100%', maxWidth: { xs: '320px', sm: '320px', md: '300px', lg: '638px' }, height: '48px', margin: { xs: '6px 15px 11px 0px', sm: '6px 15px 11px 0px', md: '6px 15px 11px 0px', lg: '16px 15px 11px 0px' }, padding: { lg: '4px 26px 14px 0px' }, borderRadius: '5px', }}>
                                    <TextField error={apartmentError} helperText={apartmentError ? 'Please enter apartment' : ''} value={apartment} onChange={(e) => setApartment(e.target.value)} required type="text" placeholder="Apartment,Suite etc. (optional)" sx={{ backgroundColor: '#fff', border: apartmentError ? '' : 'solid 1px #bfbfbf', borderRadius: '5px' }} />
                                </FormControl>
                            </Grid>
                        </Grid>
                    }
                    {shippingOption === 'ship'
                        ?
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography sx={{ margin: '21px 12px 16px 0px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, textAlign: 'left', color: '#343434' }}>
                                    Shipping Region
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} spacing={2} >
                                <FormControl>
                                    <RadioGroup name="shipping-regions" value={shippingRegion} onChange={handleShippingRegion} sx={{ border: shippingRegionError ? 'solid 1px red' : '' }}>
                                        {shippingRegions.map((region) => (
                                            <Box>
                                                <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: { xs: '300px', sm: '300px', md: '280px', lg: '600px' }, height: 'auto', margin: '0px 5px 0px 10px', padding: '10px 20px', borderRadius: '10px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff' }}>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <FormControlLabel value={region._id} control={<Radio inputProps={{ style: { color: 'red', textWrap: 'nowrap' } }} sx={{ flexDirection: 'column', textWrap: 'wrap', color: '#02b3ad', '&.Mui-checked': { color: '#02b3ad', } }} />} label='' sx={{ textWrap: 'nowrap', width: '24px', height: '24px', margin: '1px 20px 2px 1px', objectFit: 'contain', color: '#032541', fontSize: '14px', fontFamily: 'Poppins' }} />
                                                        <Typography sx={{ color: '#032541', fontSize: '14px', fontFamily: 'Poppins', textAlign: 'left' }}>{region.regionName}</Typography>
                                                    </Box>
                                                    <Typography sx={{ textWrap: 'nowrap', margin: '1px 0 0px 14px', fontFamily: 'Poppins', fontSize: '14px', lineHeight: 1.43, textAlign: 'left', color: '#032541' }}>KES {region.shippingCost}</Typography>
                                                </Box>
                                                <Divider sx={{ width: '635px', maxWidth: { xs: '320px', sm: '320px', md: '300px', lg: '635px' }, height: '0.5px', margin: '0px 0 13.3px 10px', color: '#c9c6c6' }} />
                                            </Box>
                                        ))}
                                    </RadioGroup>
                                    <FormHelperText sx={{ fontFamily: 'Poppins', color: 'red', display: shippingRegionError ? '' : 'none' }}>Please select shipping region</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Typography sx={{ textWrap: 'nowrap', margin: '21px 12px 16px 0px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, textAlign: 'left', color: '#343434' }}>
                                    Select a store location
                                </Typography>
                                <Typography sx={{ margin: '8px 30px 10px 0px', fontFamily: 'Poppins', fontSize: '14px', textAlign: 'left', color: '#707070' }}>Using a specific location such as a home address or postcode will get the most accurate results.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Select error={pickupLocationError} sx={{ width: '100%', maxWidth: { xs: '320px', sm: '320px', md: '300px', lg: '635px' }, height: '48px', margin: '10px 25px 24px 0px', padding: ' 0 0 0 19.5px', borderRadius: '5px', border: 'solid 1px #bfbfbf', backgroundColor: '#fff' }} id="select-pickup-location" value={pickupLocation} placeholder="Select A Pickup Location" onChange={(e) => { selectPickupLocation(e.target.value) }}>
                                        {pickupLocations.map((location) => (
                                            <MenuItem value={location._id} sx={{ fontSize: '14px', color: '#000' }}>{location.locationName}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText sx={{ fontFamily: 'Poppins', color: 'red', display: pickupLocationError ? '' : 'none' }}>Please select pickup location</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>}
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography sx={{ margin: '21px 121px 16px 0px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, textAlign: 'left', color: '#343434' }}>
                                Payment  Method
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%', maxWidth: { xs: '320px', sm: '320px', md: '300px', lg: '635px' }, height: 'auto', margin: '0px 5px 40px 0px', padding: '10px 20px', borderRadius: '10px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff', display: mpesaStatus === true ? 'block' : 'none' }}>
                            <FormControl fullWidth>
                                <RadioGroup name="payment-method" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} sx={{ border: paymentMethodError ? 'solid 1px red' : '' }}>
                                    <Box display={{ display: 'flex', }}>
                                        <FormControlLabel value="mpesa" control={<Radio inputProps={{ style: { color: 'red', textWrap: 'nowrap' } }} sx={{ textWrap: 'nowrap', color: '#02b3ad', '&.Mui-checked': { color: '#02b3ad', } }} />} label='' sx={{ textWrap: 'nowrap', width: '24px', height: '24px', margin: '15px 0 2px 10px', objectFit: 'contain', color: '#032541', fontSize: '14px', fontFamily: 'Poppins' }} />
                                        <img src={mpesaIcon} alt="" style={{ width: '63px', height: '23px', margin: '16px 306px 16px 15px', objectFit: 'contain' }} />
                                    </Box>
                                </RadioGroup>
                                {paymentMethod === 'mpesa' && <Typography sx={{ margin: '10px 0 0 15px', fontFamily: 'Poppins', fontSize: '14px', lineHeight: 1.43, textAlign: 'left', color: '#707070', textWrap: 'wrap' }}>You will be redirected to Mpesa to complete the payment.</Typography>}

                                {paymentMethod === 'mpesa' && <FormHelperText sx={{ fontFamily: 'Poppins', color: 'red', display: paymentMethodError ? '' : 'none' }}>Please select payment method</FormHelperText>}
                            </FormControl>
                            {paymentMethod === 'mpesa' && <FormControl sx={{ width: '100%', maxWidth: { xs: '270px', sm: '270px', md: '300px', lg: '580px' }, height: '48px', margin: '10.5px 0 10px 0.5px', padding: '14.5px 12.5px 13.5px 12.5px', borderRadius: '5px', }}>
                                <PhoneInput country={'ke'} inputStyle={{ width: '100%', maxWidth: { xs: '300px', sm: '300px', md: '300px', lg: '580px' }, height: '58px', margin: '10.5px 0 10px 0.5px', padding: '14.5px 12.5px 13.5px 52.5px', borderRadius: '5px', backgroundColor: '#fff', border: mpesaPhoneNumberError ? 'solid 1px red' : 'solid 1px #bfbfbf', borderRadius: '5px' }} value={mpesaPhoneNumber} onChange={mpesaPhoneNumber => { setMpesaPhoneNumber(mpesaPhoneNumber) }} />
                                <FormHelperText sx={{ fontFamily: 'Poppins', color: 'red', display: mpesaPhoneNumberError ? '' : 'none' }}>Please enter a valid phone number</FormHelperText>
                            </FormControl>}
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%', maxWidth: { xs: '320px', sm: '320px', md: '300px', lg: '635px' }, height: 'auto', margin: '0px 15px 40px 0px', padding: '10px 20px', borderRadius: '10px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff', display: bankPaybillStatus === true ? 'block' : 'none' }}>
                            <FormControl fullWidth>
                                <RadioGroup name="payment-method" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} sx={{ border: paymentMethodError ? 'solid 1px red' : '' }}>
                                    <Box display={{ display: 'flex', }}>
                                        <FormControlLabel value="kcbpaybill" control={<Radio inputProps={{ style: { color: 'red', textWrap: 'nowrap' } }} sx={{ textWrap: 'nowrap', color: '#02b3ad', '&.Mui-checked': { color: '#02b3ad', } }} />} label='Mobile Money' sx={{ textWrap: 'nowrap', width: '24px', height: '24px', margin: '15px 0 2px 10px', objectFit: 'contain', color: '#032541', fontSize: '14px', fontFamily: 'Poppins' }} />
                                    </Box>
                                </RadioGroup>
                                {paymentMethod === 'kcbpaybill' && <Typography sx={{ margin: '10px 0 0 15px', fontFamily: 'Poppins', fontSize: '14px', lineHeight: 1.43, textAlign: 'left', color: '#707070', textWrap: 'wrap' }}>STK push will be sent to your phone. Kindly input your M-Pesa pin.</Typography>}
                                {paymentMethod === 'kcbpaybill' && <FormHelperText sx={{ fontFamily: 'Poppins', color: 'red', display: paymentMethodError ? '' : 'none' }}>Please select payment method</FormHelperText>}
                            </FormControl>
                            {paymentMethod === 'kcbpaybill' && <FormControl sx={{ width: '100%', maxWidth: { xs: '270px', sm: '300px', md: '300px', lg: '580px' }, height: '48px', margin: '10.5px 0 10px 0.5px', padding: '14.5px 12.5px 13.5px 12.5px', borderRadius: '5px', }}>
                                <PhoneInput country={'ke'} inputStyle={{ width: '100%', maxWidth: { xs: '300px', sm: '300px', md: '300px', lg: '580px' }, height: '58px', margin: '10.5px 0 10px 0.5px', padding: '14.5px 12.5px 13.5px 52.5px', borderRadius: '5px', backgroundColor: '#fff', border: mobileMoneyNumberError ? 'solid 1px red' : 'solid 1px #bfbfbf', borderRadius: '5px' }} value={mobileMoneyNumber} onChange={mobileMoneyNumber => { setMobileMoneyNumber(mobileMoneyNumber) }} />
                                <FormHelperText sx={{ fontFamily: 'Poppins', color: 'red', display: mobileMoneyNumberError ? '' : 'none' }}>Please enter a valid phone number</FormHelperText>
                            </FormControl>}
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%', maxWidth: { xs: '320px', sm: '320px', md: '300px', lg: '635px' }, height: 'auto', margin: '0px 15px 40px 0px', padding: '10px 20px', borderRadius: '10px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff', display: payCardStatus === true ? 'block' : 'none' }}>
                            <FormControl fullWidth>
                                <RadioGroup name="payment-method" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} sx={{ border: paymentMethodError ? 'solid 1px red' : '' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', margin: '15px 0' }}>
                                        <FormControlLabel value="card" control={<Radio sx={{ color: '#02b3ad', '&.Mui-checked': { color: '#02b3ad' } }} />} sx={{ margin: '0', color: '#032541', fontSize: '14px', fontFamily: 'Poppins', }} />
                                        <img src={cardIcon} alt="Card Icon" style={{ width: '40px', height: '40px', objectFit: 'contain', margin: '0 8px 0 10px' }} />
                                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', color: '#032541', whiteSpace: 'nowrap', color: '#4b4b4b' }}>
                                            Card
                                        </Typography>
                                    </Box>
                                </RadioGroup>
                                {paymentMethod === 'card' && <Typography sx={{ margin: '10px 0 0 15px', fontFamily: 'Poppins', fontSize: '14px', lineHeight: 1.43, textAlign: 'left', color: '#707070', textWrap: 'wrap' }}>You will be redirected to a payment link.</Typography>}
                                {paymentMethod === 'card' && <FormHelperText sx={{ fontFamily: 'Poppins', color: 'red', display: paymentMethodError ? '' : 'none' }}>Please select payment method</FormHelperText>}
                            </FormControl>
                            {/* <FormControl sx={{ width: '100%', maxWidth: { xs: '270px', sm: '300px', md: '300px', lg: '580px' }, height: '48px', margin: '10.5px 0 10px 0.5px', padding: '14.5px 12.5px 13.5px 12.5px', borderRadius: '5px', }}>
                                <PhoneInput country={'ke'} inputStyle={{ width: '100%', maxWidth: { xs: '300px', sm: '300px', md: '300px', lg: '580px' }, height: '58px', margin: '10.5px 0 10px 0.5px', padding: '14.5px 12.5px 13.5px 52.5px', borderRadius: '5px', backgroundColor: '#fff', border: mobileMoneyNumberError ? 'solid 1px red' : 'solid 1px #bfbfbf', borderRadius: '5px' }} value={mobileMoneyNumber} onChange={mobileMoneyNumber => { setMobileMoneyNumber(mobileMoneyNumber) }} />
                                <FormHelperText sx={{ fontFamily: 'Poppins', color: 'red', display: mobileMoneyNumberError ? '' : 'none' }}>Please enter a valid phone number</FormHelperText>
                            </FormControl> */}
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sx={{ justifyContent: 'end' }}>
                        <Button onClick={() => { navigate('/cart') }} variant='outlined' sx={{ justifyContent: 'center', width: '100%', maxWidth: { xs: '135px', sm: '140px', md: '121px', lg: '131px' }, height: '50px', margin: { xs: '0 10% 0 0', sm: '0 10% 0 0', md: '0 10% 0 0', lg: '0 10% 0 0' }, padding: '15px 16.9px 15px 15px', objectFit: 'contain', borderRadius: '5px', border: 'solid 1px #707070', color: '#707070', '&:hover': { border: 'solid 1px #707070', textAlign: 'center' } }}>
                            <Typography sx={{ textWrap: 'nowrap', fontFamily: 'Poppins', fontSize: '14px', textTransform: 'capitalize', fontWeight: 500, textAlign: 'center', color: '#707070' }}>Back To Cart</Typography>
                        </Button>
                        <Button onClick={() => { handleSubmit() }} variant='contained' sx={{ justifyContent: 'center', textAlign: 'center', width: '100%', maxWidth: { xs: '135px', sm: '140px', md: '121px', lg: '131px' }, height: '50px', margin: { xs: '0 10% 0 0', sm: '0 10% 0 0', md: '0 10% 0 0', lg: '0 6% 0 0' }, padding: '15px 16.9px 15px 15px', objectFit: 'contain', opacity: buttonStatus === true ? 0.5 : 1, borderRadius: '5px', backgroundColor: '#02b3ad', '&:hover': { backgroundColor: '#02b3ad' } }}>
                            <Typography sx={{ textWrap: 'nowrap', fontFamily: 'Poppins', fontSize: '14px', textTransform: 'capitalize', fontWeight: 500, textAlign: 'center', color: '#fff', border: 'none' }}>Pay Now</Typography>
                        </Button>
                    </Grid>
                </Grid>
                <Grid spacing={2} item xs={12} sm={12} md={6} lg={6} xl={6}>
                    {cart?.map((item) => (
                        <Grid container item xs={12} sx={{ display: 'flex', width: '100%', maxWidth: { xs: '320px', sm: '320px', md: '610px', lg: '610px' }, height: 'auto', margin: '23px 23px 17px 3px', padding: '12px 14px 21px 10px', borderRadius: '15px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff', overflow: 'hidden' }}>
                            <Grid item xs={12} sm={12} md={8} lg={4} xl={4}>
                                <Button variant='outlined' sx={{ backgroundColor: '#fff', border: '#fff', '& .badge-container': { position: 'absolute', top: '7px', right: '20px', width: '24px', height: '24px', borderRadius: '50%', backgroundColor: '#707070', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', fontWeight: 'bold', fontSize: '12px', border: 'none' }, '&:hover': { border: 'none', backgroundColor: '#fff' } }}>
                                    <Box sx={{display:'flex'}}>
                                        <img src={item.image} style={{ width: '100px', height: '95px', margin: '9px 11px 0 0', padding: '9px 7px 10px 13px', objectFit: 'contain', border: 'solid 0.5px #c9c6c6' }} />
                                        {item?.isPreOrder && <Box sx={{ width: '100%', maxWidth: '60px', height: '100%', maxHeight: '20px', margin: '80px 0 24px -88px', padding: '3px 5px 2px', borderRadius: '2px', backgroundColor: '#02b3ad', alignItems: 'center', verticalAlign: 'middle' }}>
                                            <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 500, color: '#fff', textWrap: 'nowrap', textAlign: 'center',textTransform:'capitalize' }}>Pre-Order</Typography>
                                        </Box>}
                                    </Box>
                                    <div className="badge-container">
                                        <Typography component="sup" style={{ color: '#fff', fontWeight: 'bold', padding: '10px 0px', fontSize: '14px' }}>{item.quantity}</Typography>
                                    </div>
                                </Button>

                            </Grid>
                            <Grid container item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <Grid item xs={4} sm={4} md={2} lg={4} xl={4}>
                                    <Typography sx={{ margin: '10px 0 0px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, textAlign: 'left', color: '#000' }}>{item.name}</Typography>
                                </Grid>
                                <Grid container item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <Box>
                                        {(showAll[item.id] ? Object.entries(item?.variations) : Object.entries(item?.variations).slice(0, 2)).map(([key, value]) => (
                                            <Box sx={{ display: 'flex', textTransform: 'capitalize' }}>
                                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, lineHeight: 2.57, textAlign: 'left', color: '#032541', mr: '2px', textWrap: 'nowrap' }}>{capitalizeFirstLetter(key)}: </Typography>
                                                <Typography sx={{ margin: '0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'left', color: '#707070', textTransform: 'capitalize' }}>{capitalizeFirstLetter(value)}</Typography>
                                            </Box>
                                        ))}
                                        <Typography onClick={() => handleToggleShowAll(item.id)} sx={{ color: '#808285', fontFamily: 'Poppins', fontSize: '12px', textTransform: 'capitalize' }}>
                                            {showAll[item.id] ? (
                                                <Box>

                                                    {item?.additionalServices?.map((services) => (
                                                        <Box key={services.id} sx={{ margin: '0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, lineHeight: 2.57, textAlign: 'left', color: '#032541' }}>
                                                            {services?.items?.map((serviceItem) => (
                                                                <Box key={serviceItem.id} sx={{ display: 'flex' }}>
                                                                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, lineHeight: 2.57, textAlign: 'left', color: '#032541', mr: '2px', textWrap: 'nowrap' }}>
                                                                        {serviceItem?.itemName}:
                                                                    </Typography>
                                                                    <Typography sx={{ margin: '0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'left', color: '#707070' }}>
                                                                        {serviceItem?.isCustomizableValue ?? 'None'}
                                                                    </Typography>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    ))}
                                                    <Box sx={{ display: 'flex' }}>
                                                        Hide Details <KeyboardArrowUp sx={{ width: '100%', maxWidth: '15px', height: '100%', maxHeight: '15px', margin: '1px 0 1px 5px' }} />
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box sx={{ display: 'flex' }}>
                                                    More Details <KeyboardArrowDown sx={{ width: '100%', maxWidth: '15px', height: '100%', maxHeight: '15px', margin: '1px 0 1px 5px' }} />
                                                </Box>
                                            )}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid container item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <Box>
                                        <Typography sx={{ margin: '10px 0px 10px 20px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 550, textAlign: 'left', color: '#000' }}>Ksh {(item.quantity * item.price) + (item.quantity * item.customizationAmount)}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                    <Grid container item xs={12} sx={{ width: '100%', maxWidth: { xs: '320px', sm: '320px', md: '610px', lg: '610px' }, height: '100%', maxHeight: '250px', margin: '23px 23px 30% 3px', padding: '17px 20px 20px 20px', borderRadius: '15px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff', justifyContent: 'end' }}>
                        <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, textAlign: 'left', color: '#707070' }}>Subtotal:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, color: '#000', textAlign: 'left' }}>KES {totalPrice + customizationAmount}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sx={{ display: 'flex', marginTop: '18px' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, textAlign: 'left', color: '#707070', textWrap: 'nowrap' }}>Delivery/Shipping cost:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, color: '#000', textAlign: 'left' }}>KES {shippingCost}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sx={{ display: 'flex', marginTop: '18px' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, textAlign: 'left', color: '#707070', textWrap: 'nowrap' }}>Packaging Fee:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, color: '#000', textAlign: 'left' }}>KES {totalPackagePrice ?? 0}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sx={{ display: 'flex', marginTop: '18px' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, textAlign: 'left', color: '#707070', textWrap: 'nowrap' }}>Total (tax incl.)</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, color: '#02b3ad', textAlign: 'left' }}>KES {totalPrice + customizationAmount + shippingCost + totalPackagePrice}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Modal open={openOrderProcessing} onClose={handleOpenOrderProcessing} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                <Box sx={orderProcessingModal}>
                    <img src={loadingIcon} alt=" " style={{ width: '100%', maxWidth: '125px', height: '100%', maxHeight: '125px', margin: '0 33px 9px 32px', objectFit: 'contain', animation: 'App-logo-spin 2s linear infinite', }} />
                    <Typography sx={{ width: '190px', height: '41px', margin: '9px 33px 100px', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'center', color: '#343434' }}>
                        {paymentMethod === 'card' ? 'You will be redirected to a payment link.' : 'Please check your phone for STK prompt.'}
                    </Typography>
                </Box>
            </Modal>
        </Grid>
    );
}
export default Checkout;