import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HttpRequest } from "../HttpRequest";
import { setPackage } from "../../features/cartSlice";
import secureLocalStorage from "react-secure-storage";

const useBestPackage = () => {
  const [packages, setPackages] = useState([]);
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);

  const fetchAndDetermineBestPackage = async (additionalQuantity = 1) => {
    const businessNumber = secureLocalStorage.getItem("businessId");
    try {
      const response = await HttpRequest({
        method: 'GET',
        url: `/api/v1/ecommerce/packages-in-bs?businessNumber=${businessNumber}`
      });
      console.log('Responses packages', response);
      const availablePackages = response?.response?.data;
      console.log('availablePackages', availablePackages);
      setPackages(availablePackages);

      const totalQuantity = cart.reduce((sum, item) => sum + item.quantity, 0) + additionalQuantity;
      console.log(totalQuantity, 'totalQuantity packages');

      if (!availablePackages || availablePackages.length === 0) return null;

      let bestPkg = null;
      for (const pkg of availablePackages) {
        if (pkg?.noOfItemsInPackage >= totalQuantity && (!bestPkg || pkg?.noOfItemsInPackage < bestPkg?.noOfItemsInPackage)) {
          bestPkg = pkg;
        }
      }
      console.log("bestPkg", bestPkg);
      if (bestPkg!==null && bestPkg?.pricing[0]?.availableStock > 0 ) {
        const packagePayload = {
          id: bestPkg._id,
          image: bestPkg.featuredImage,
          name: bestPkg.productName,
          price: bestPkg.pricing[0]?.discountedPrice,
          quantity: 1,
          variationId: bestPkg.pricing[0]?._id,
        };
        dispatch(setPackage(packagePayload));
        return bestPkg;
      } else {
        console.log('No suitable package found');
        return null;
      }

    } catch (error) {
      console.log('Error fetching products', error);
      return null;
    }
  };

  return { fetchAndDetermineBestPackage };
};

export default useBestPackage;
