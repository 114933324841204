import { Grid, Box, Typography, TextField, FormControl, Radio, OutlinedInput, IconButton, InputLabel, RadioGroup, InputAdornment, Button } from '@mui/material'
import logoImage from "../../../images/eastwestLogo.svg"
import { useState } from 'react';
import Footer from '../footer';
import PinInput from 'react-pin-input';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link, useNavigate } from 'react-router-dom';
import { SuccessAlert } from '../../common/snackbar/success';
import { ErrorAlert } from '../../common/snackbar/error';
import { useDispatch, useSelector } from 'react-redux';
import { HttpRequest } from '../../HttpRequest';
import { setResetMethod, setResetChannel, setResetPin } from '../../../features/forgotPassword';

const PasswordChange = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });

    const { method, channel, pin } = useSelector((state) => state.forgotPassword);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClickShowPassword = () => {
        setShowPassword(true);
    };
    const handleClickHidePassword = () => {
        setShowPassword(false);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = () => {
        if (password === '') {
            setErrorShow({ state: true, message: 'New password is required!' });
            return;
        }
        if (password.length < 4) {
            setErrorShow({ state: true, message: 'Password must be at least 4 characters!' });
            return;
        }
        if (passwordConfirm === '') {
            setErrorShow({ state: true, message: 'Confirm password is required!' });
            return;
        }
        if (passwordConfirm.length < 4) {
            setErrorShow({ state: true, message: 'Confirm Password must be at least 4 characters!' });
            return;
        }
        if (password !== passwordConfirm) {
            setErrorShow({ state: true, message: 'Passwords do not match!' });
            return;
        }

        let payload = {
            userPin: pin,
            userNewPin: password,
            type:'ecommerce'

        };

        if (method === 'email') {
            payload = {
                ...payload,
                userEmail: channel
            };
        } else {
            payload = {
                ...payload,
                phoneNumber: "+254" + channel,
            };
        }

        HttpRequest({
            method: 'PUT',
            url: '/api/resetPinVersion2',
            body: payload
        }).then((response) => {
            console.log(response.response.message);
            if (response.response.Status === "SUCCESS") {
                dispatch(setResetMethod(''));
                dispatch(setResetChannel(''));
                dispatch(setResetPin(''));
                setSuccessShow({ state: true, message: `Password reset successful.` });
                setTimeout(() => {
                    navigate('/login')
                }, 3000);
            } else {
                const message = response?.response?.message;
                setErrorShow({ state: true, message: message })
            }

        }).catch((error) => {
            setErrorShow({ state: true, message: 'Password reset failed. Please try again!' });
        })
    }

    return (
        <Grid container spacing={2} sx={{ minHeight: 'calc(100vh - 64px - 64px)' }}>
            <Grid item xs={12}>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <img onClick={() => navigate('/')} src={logoImage} alt='' style={{ width: '159px', height: '98.5px', margin: { lg: '79px 640px 30.5px 640px', sm: '' }, objectFit: 'contain', cursor: 'pointer' }} />
                    <Typography sx={{ textWrap: 'nowrap', margin: { sm: '', lg: '24px 122px 16px 143px' }, fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 2, letterSpacing: 'normal', textAlign: 'center', color: '#02b3ad' }}>
                        Reset your password
                    </Typography>
                    <Typography sx={{ margin: { lg: '0px 17px 4px 8px', sm: '' }, fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 'normal', letterSpacing: 'normal', textAlign: 'center', color: '#898989' }}>
                        Enter your new password
                    </Typography>
                </Box>
                <Box sx={{ maxWidth: "350px", marginLeft: 'auto', marginRight: "auto", }} component={'div'} display={'flex'} py={1} flexDirection={'column'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                    <Grid container spacing={2}  >
                        <Grid item xs={12}>
                            <Box>
                                <FormControl sx={{ width: '100%', maxWidth: '320px', height: '55px', margin: '0 0 10px 18px', borderRadius: '5px', backgroundColor: '#fff', fontFamily: 'Poppins', borderRadius: '5px', backgroundColor: '#fff' }}>
                                    <InputLabel htmlFor="password" sx={{ fontSize: '14px', color: '#cacaca', fontWeight: 'normal' }}>New Password</InputLabel>
                                    <OutlinedInput autoComplete='off' label="New Password" onChange={(e) => setPassword(e.target.value)} value={password} placeholder="Enter your Password" id="password" type={showPassword ? 'text' : 'password'} endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={showPassword ? handleClickHidePassword : handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>} />
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl sx={{ width: '100%', maxWidth: '320px', height: '55px', margin: '0 0 10px 18px', borderRadius: '5px', backgroundColor: '#fff', fontFamily: 'Poppins', borderRadius: '5px', backgroundColor: '#fff' }}>
                                    <InputLabel htmlFor="confirm-password" sx={{ fontSize: '14px', color: '#cacaca', fontWeight: 'normal' }}>Confirm Password</InputLabel>
                                    <OutlinedInput autoComplete='off' label="Confirm Password" onChange={(e) => setPasswordConfirm(e.target.value)} value={passwordConfirm} placeholder="Confirm your Password" id="password-confirm" type={showPassword ? 'text' : 'password'} endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>} />
                                </FormControl>
                            </Box>
                            <Box>
                                <Button onClick={handleSubmit} variant='contained' sx={{ textWrap: 'nowrap', width: '313px', height: '50px', margin: '31px 18px 14px 19px', padding: '15px 137px 15px 138px', objectFit: 'contain', opacity: password !== '' && passwordConfirm !== '' ? 1 : '0.5', borderRadius: '5px', backgroundColor: '#02b3ad', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'bold', color: '#fff', textTransform: 'capitalize', "&:hover": { backgroundColor: '#02b3ad' } }}>Change Password</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}


export default PasswordChange;