import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomSearchInput from '../common/CustomSearchInput'
import CustomTable from '../common/CustomTable'
import { useNavigate, useParams } from 'react-router-dom';
import CurrencyFormatter from '../common/currencyFormatter';
import productImage from '../../images/productImage.png'
import CustomSelect from '../common/customSelectInput';
import { HttpRequest } from '../HttpRequest';
import imagePlaceholder from "../../images/img-holder-icn.svg";
import editIcon from '../../images/edit-icn.svg';
import deleteIcon from '../../images/delete-icn.svg';
import { Delete, MoreVert, RemoveCircle, RemoveCircleOutlineOutlined } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';

const filterDates = [
  { id: 1, label: 'This Month', value: 'this_month' },
  { id: 2, label: 'This Year', value: 'this_year' },
  { id: 3, label: 'Today', value: 'today' },
]

const renderCategoryColumns = (row) => {

  console.log(row, 'Row detatails');
  return (<Box style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
    {row.productCategory}
  </Box>)
}


const CustomerAdminComponent = () => {
  const [search, setSearch] = useState('')
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customersState, setCustomersState] = useState({ isLoading: false, data: [], returningColumns: [], total: 0, page: 1, pageSize: 10 });
  const navigate = useNavigate();
  const {customerId} = useParams();
  const [customerType,setCustomerType] = useState('');

  const handleRowClick = (event, params) => {
    event.stopPropagation();
    navigate(`/zed_ecommerce/customer/${params?.row?._id}/details`);
  }

  const columns = [
    {
      field: 'name', headerName: 'Name', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}>Name  </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "550" }}>
          <Grid container spacing={2} sx={{ display: 'flex' }}>
            <Grid item>
              <Typography sx={{ marginTop: '0 42px 0 0',fontFamily:'Poppins',fontSize:'16px',fontWeight:550,textAlign:'left',color:'#000',textDecoration:'underline' }}>{params?.row?.customerName}</Typography>
            </Grid>
          </Grid>
        </Box>)
      }
    },
    {
      field: 'email', headerName: 'Email', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Email  </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px' }}>{params?.row?.email}</Typography>
        </Box>)
      }
    },
    {
      field: 'phone', headerName: 'Phone No.', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Phone No.</Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px' }}>{params?.row?.phone}</Typography>
        </Box>)
      }
    },
    {
      field: 'location', headerName: 'location', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Location </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px' }}>{params?.row?.physicalAddress}</Typography>
        </Box>)
      }
    },
    {
      field: 'orders', headerName: 'Orders', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Orders  </Box>) },
      renderCell: (params) => {
        return (<Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}>
          <Typography sx={{ marginTop: '20px' }}>{params?.row?.noOfOrders} Orders</Typography>
        </Box>)
      }
    },
    {
      field: 'amountSpent', headerName: 'Amount Spent', flex: 1,
      renderHeader: (params) => { return (<Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}> Amount Spent  </Box>) },
      renderCell: (params) => { return (<Box onClick={(event)=>handleRowClick(event,params)} style={{ cursor: 'pointer', color: "#707070", fontSize: "15px", fontWeight: "500" }}> Ksh {params?.row?.totalAmountOfOrders} </Box>) }
    },
    {
      field: 'actions', headerName: 'Actions', flex: 1,
      renderHeader: (params) => {
        return (
          <Box style={{ color: "#032541", fontSize: "17px", fontWeight: "700" }}>Actions </Box>)
      },
      renderCell: (params) => {
        return (
          <Box onClick={(event)=>handleRowClick(event,params)} style={{ color: "#032541", fontSize: "17px", fontWeight: "700", display: 'flex' }}>
            <RemoveCircleOutlineOutlined sx={{ color: '#000000', margin: '0 18px 0 2px' }} />
            <Delete sx={{ color: '#DC3545' }} />
          </Box>)
      },
    }
  ]
  const getCustomers = () => {
    setCustomersState((old) => ({ ...old, isLoading: true }));
    HttpRequest({
      method: 'GET',
      url: `/api/v1/ecommerce/getzedecommercecustomers?page=${customersState?.page}&limit=${customersState?.pageSize}&customerType=${customerType}&searchValue=${search}`,
    }).then((data) => {
      setCustomersState({
        ...customersState,
        data: data?.response?.data,
        total: data?.response?.count,
        isLoading: false,
      });
    }).catch((error) => {
      console.error('Error fetching customers:', error);
      setCustomersState((old) => ({ ...old, isLoading: false }));
    });
  };
  useEffect(() => {
    getCustomers();
  }, [customersState?.page, customersState?.pageSize,customerType,search]);
  return (
      <div style={{ marginTop: '4%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ flexDirection: "column" }}>
            <Box>
              <Typography variant='h4' sx={{ color: "#032541", fontSize: "28px", fontStyle: "normal", fontWeight: "600" }}>Customers</Typography>
            </Box>
            <Box>
              <Typography variant='h4' sx={{ color: "#707070", fontSize: "14px", fontStyle: "normal", fontWeight: "normal" }}>View and manage your Customers.</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ marginLeft: "10px" }}>
            <CustomSearchInput placeholder={"Search ...."} name="search" value={search} onChange={(event)=>setSearch(event.target.value)}/>
          </Box>
          {/* <Box sx={{ height: '45px', margin: '0px 16px 20px 750px', padding: '2px 15px 13px',alignItems:'end' }}>
            <CustomSelect value="this_month" options={filterDates} placeholder={"Date"} sm={"xl"} />
          </Box> */}
        </Box>

        <Box>
        <DataGrid
                sx={{ height: 'auto', width: 'auto', fontFamily: 'Poppins', fontSize: '12px', color: '#272d3d', boxShadow: 0, border: 0 }}
                rowCount={customersState?.total}
                pagination
                loading={customersState?.isLoading}
                page={customersState?.page - 1}
                pageSizeOptions={[1, 10, 20, 50, 100]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: customersState?.pageSize,
                      page: customersState?.page - 1,
                    },
                  },
                }}
                pageSize={customersState?.pageSize}
                onPaginationModelChange={(data) => {
                  const { pageSize, page } = data;
                  setCustomersState((old) => ({ ...old, page: page + 1, pageSize: pageSize }));
                }}
                paginationMode="server"
                columns={columns}
                rows={customersState?.data}
                getRowId={(row) => row._id}
              
              />
        </Box>
      </div>
  )
}

export default CustomerAdminComponent