import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import SearchNavBar from "../customer/searchBar";
import { Box, Grid } from "@mui/material";
import TopNavBar from "../customer/topNavBar";
import Footer from "../customer/footer";
import secureLocalStorage from "react-secure-storage";
import Navbar from "../customer/navbar";

const CustomerPrivateRoutes = () => {
  const { isLoggedIn } = useSelector((store) => store.user)
  const group = secureLocalStorage.getItem("group");
  return (
    <>
      {isLoggedIn ? (
        <>
          {group === "Customer" ? (
            <Grid container display={'flex'} direction={'column'} height={'100%'} spacing={2}>
              <Box py={2}>
                <TopNavBar sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }} />
              </Box>
              <Box className="container">
                <SearchNavBar />
                <Navbar />
              </Box>
              
              <Outlet />
              <Box mt={'auto'}>
                <Footer />
              </Box>
            </Grid>
          ) : (
            <Navigate to="/login" />
          )}
        </>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default CustomerPrivateRoutes;
