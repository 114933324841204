import { Box, Button, Divider, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import TopNavBar from './topNavBar';
import "./index.css";
import Header from './header';
import Footer from './footer';
import { useDispatch, useSelector } from 'react-redux';
import { AddCircle, Delete, DeleteOutline, KeyboardArrowDown, KeyboardArrowUp, RemoveCircle, Save, ShoppingCart } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { decrementQuantity, incrementQuantity, removeItem } from '../../features/cartSlice';
import CurrencyFormatter from '../common/currencyFormatter';
import { Component, useEffect, useState } from 'react';
import capitalizeFirstLetter from '../common/capitalize';
import useBestPackage from '../common/packages';
import { SuccessAlert } from '../common/snackbar/success';
import { ErrorAlert } from '../common/snackbar/error';



const Cart = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const stateCart = useSelector((state) => state.cart);
    let cart = stateCart.cart;
    let selectedPackage = stateCart?.package;
    console.log(selectedPackage, 'selectedPackage');
    const totalPrice = cart.reduce((total, item) => {
        const { price, quantity } = item;
        const itemTotalPrice = price * quantity;
        return total + itemTotalPrice;
    }, 0);
    const totalPackagePrice = selectedPackage?.price ?? 0;
    const customizationAmount = cart.reduce((total, item) => {
        const { customizationAmount, quantity } = item;
        const itemTotalPrice = customizationAmount * quantity;
        return total + itemTotalPrice;
    }, 0);

    const [showAll, setShowAll] = useState({});
    const [showAllCustomizations, setShowAllCustomizations] = useState({});
    const [showTextField, setShowTextField] = useState(false);
    const { fetchAndDetermineBestPackage } = useBestPackage();
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    const handleToggleTextField = () => {
        setShowTextField(!showTextField);
    };

    const handleToggleShowAll = (itemId) => {
        setShowAll((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId]
        }));
    };
    const handleToggleShowAllCustomizations = (itemId) => {
        setShowAllCustomizations((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId]
        }));
    }

    const { pathname } = useLocation();

    const handleIncrementQuantity = async (item) => {
        const existingItem = cart.find(
          (product) => product.id === item.id && product.variationId === item.variationId
        ); 
      
        let selectedPackage = await fetchAndDetermineBestPackage(1);
        console.log(selectedPackage,'selectedPackage');
        if(selectedPackage!==null) {
            if (existingItem && existingItem.quantity < existingItem.remainingStock) {
                dispatch(incrementQuantity(item.id));
              } else {
                  setErrorShow({ state: true, message: "The selected quantity exceeds available stock." });
              }
        }else {
         setErrorShow({ state: true, message: "No suitable package found." });
        }
      }
    const handleDecrementQuantity = async (item) => {
        dispatch(decrementQuantity(item?.id))
        await fetchAndDetermineBestPackage(-1);
    }
    const handleRemoveItem = async (item) => {
        dispatch(removeItem({ itemId: item?.id, variationId: item?.variationId }));
        await fetchAndDetermineBestPackage();
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (

            <Grid container spacing={2} xs={12} sx={{ marginTop: '1%', justifyContent: 'center', }}>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', ml: { xs: '5%', sm: '1%', md: '20%' }, }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ display: 'flex', mb: '2%' }}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'bold', color: '#343434', textAlign: 'left', textWrap: 'nowrap' }}>
                                Shopping Cart
                            </Typography>
                            {cart.length > 0 &&
                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', textAlign: 'left', color: '#707070', textWrap: 'wrap' }}>
                                    There {cart.length > 1 ? 'are' : 'is'} {cart.length} item{cart.length > 1 && 's'} in your cart
                                </Typography>}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        {cart.length > 0 && <Button onClick={() => { navigate('/') }} variant='outlined' sx={{ width: '100%', maxWidth: '191px', height: '100%', maxHeight: '48px', padding: '19.5px 11.5px 8.5px', m: '0 2% 0% 0', borderRadius: '4px', border: 'solid 1px #0ab3ad', backgroundColor: '#fff', '& .badge-container': { position: 'absolute', top: '5px', right: '140px', width: '100%', maxWidth: '24px', height: '24px', borderRadius: '50%', backgroundColor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', fontWeight: 'bold', fontSize: '12px', border: 'solid 1px #02b3ad' } }}>
                            <ShoppingCart sx={{ color: '#02b3ad', marginRight: '-2px' }} />
                            <div className="badge-container">
                                <Typography component="sup" style={{ color: '#02b3ad', fontWeight: 'bold', padding: '10px 0px', fontSize: '14px' }}>{cart.length}</Typography>
                            </div>
                            <Typography sx={{ textWrap: 'nowrap', textTransform: 'capitalize', fontSize: '14px', margin: '0px 0 6px 22px', fontFamily: 'Poppins', textAlign: 'center', color: '#02b3ad' }}>Continue Shopping</Typography>
                        </Button>}
                        {cart.length > 0 && <Button onClick={() => { navigate('/checkout') }} variant='contained' sx={{ width: "100%", maxWidth: '155px', height: '48px', margin: '0 0px 0 0', padding: '15px 16.9px 15px 16px', objectFit: 'contain', borderRadius: '5px', backgroundColor: '#02b3ad', '&:hover': { backgroundColor: '#02b3ad' } }}>
                            <ShoppingCart sx={{ color: '0 3.7px 0 3.7px', color: '#fff' }} />
                            <Typography sx={{ textWrap: 'nowrap', width: '68px', height: '20px', margin: ' 0 0 0 10.1px', fontFamily: 'Poppins', fontSize: '13px', textTransform: 'capitalize', fontWeight: 500, textAlign: 'center', color: '#fff', border: 'none' }}>Checkout</Typography>
                        </Button>}
                    </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                    {cart.length === 0 ?
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Typography sx={{ fontFamily: 'Poppins', fontSize: '18px', fontWeight: 'normal', color: '#707070', lineHeight: 'normal', textWrap: 'nowrap' }}>
                                Your cart is empty!
                            </Typography>
                        </Grid>
                        :
                        <Grid container item xs={12} sx={{ alignItems: 'start', p: '22px 16px 22px 20px' }}>
                            {cart?.map((item) => (
                                <Grid container item xs={12} sx={{ width: '100%', maxWidth: '1034px', minHeight: '100px', height: 'auto', padding: { xs: '5%', sm: '5%', md: '2%', lg: '1%', xl: '1%' }, borderRadius: '15px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', backgroundColor: '#fff', display: 'flex', alignItems: 'start', m: { lg: '1% 20% 1% 20%' } }}>
                                    <Grid item xs={6} sm={6} md={6} lg={1} xl={1} sx={{ m: { md: '0 35px 0px 0' } }}>
                                        <Box sx={{ display: 'flex' }}>
                                            <img src={item?.image} alt='' style={{ width: '100%', maxWidth: '100px', height: '100%', maxHeight: '95px', objectFit: 'contain' }} />
                                            {item?.isPreOrder && <Box sx={{ width: '100%', maxWidth: '60px', height: '100%', maxHeight: '20px', margin: '30px 0 24px -68px', padding: '3px 5px 2px', borderRadius: '2px', backgroundColor: '#02b3ad', alignItems: 'center', verticalAlign: 'middle' }}>
                                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', fontWeight: 500, color: '#fff', textWrap: 'nowrap', textAlign: 'center' }}>Pre-Order</Typography>
                                            </Box>}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2} sx={{ width: '100%', maxWidth: '141px' }}>
                                        <Typography sx={{ margin: '0 0 4px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '600', textAlign: 'left', color: '#000', textWrap: 'wrap', width: '100%', maxWidth: '141px' }}>
                                            {item?.name}
                                        </Typography>
                                        <Typography sx={{ margin: '10px 10px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '500', textAlign: 'left', color: '#707070' }}>
                                            {CurrencyFormatter(item?.price)}
                                        </Typography>
                                        <Typography sx={{ margin: '12px 0 12px', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '550', textAlign: 'left', color: '#000', textWrap: 'wrap', width: '100%', maxWidth: '141px' }}>
                                            Customization Charges:
                                        </Typography>
                                        <Typography sx={{ margin: '10px 10px 0 0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: '500', textAlign: 'left', color: '#707070' }}>
                                            {CurrencyFormatter(item?.customizationAmount ?? 0)}
                                        </Typography>
                                    </Grid>
                                    <Grid container item xs={6} sm={6} md={6} lg={2} xl={2} sx={{ width: '100%', maxWidth: '141px', }} >
                                        <Grid item xs={12}>
                                            {(showAll[item.id] ? Object.entries(item?.variations) : Object.entries(item?.variations).slice(0, 2)).map(([key, value]) => (
                                                <Box sx={{ display: 'flex', textTransform: 'capitalize' }}>
                                                    <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, lineHeight: 2.57, textAlign: 'left', color: '#032541', mr: '2px', }}>{capitalizeFirstLetter(key)}: </Typography>
                                                    <Typography sx={{ margin: '0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'left', color: '#707070', textTransform: 'capitalize' }}>{capitalizeFirstLetter(value)}</Typography>
                                                </Box>
                                            ))}
                                            <Typography onClick={() => handleToggleShowAll(item.id)} sx={{ color: '#808285', fontFamily: 'Poppins', fontSize: '12px', textTransform: 'capitalize' }}>
                                                {showAll[item.id] ? (
                                                    <Box sx={{ display: 'flex' }}>
                                                        Hide Details <KeyboardArrowUp sx={{ width: '100%', maxWidth: '15px', height: '100%', maxHeight: '15px', margin: '1px 0 1px 5px' }} />
                                                    </Box>
                                                ) : (
                                                    <Box sx={{ display: 'flex' }}>
                                                        More Details <KeyboardArrowDown sx={{ width: '100%', maxWidth: '15px', height: '100%', maxHeight: '15px', margin: '1px 0 1px 5px' }} />
                                                    </Box>
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={6} sm={6} md={6} lg={2} xl={2} sx={{ margin: { md: '0 35px 0px 0' } }} >
                                        <Grid item xs={12}>
                                            <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, lineHeight: 2.57, textAlign: 'left', color: '#032541', textWrap: 'nowrap' }}>Customization:</Typography>
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left' }}>
                                                {(showAllCustomizations[item.id] ? item?.additionalServices : item?.additionalServices.slice(0, 2)).map((services) => (
                                                    <Box key={services.id} sx={{ margin: '0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, lineHeight: 2.57, textAlign: 'left', color: '#032541' }}>
                                                        {(showAllCustomizations[item.id] ? services?.items : services?.items?.slice(0, 1)).map((serviceItem) => (
                                                            <Box key={serviceItem.id} sx={{ display: 'flex' }}>
                                                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: 500, lineHeight: 2.57, textAlign: 'left', color: '#032541', mr: '2px' }}>
                                                                    {serviceItem?.itemName}:
                                                                </Typography>
                                                                <Typography sx={{ margin: '0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'left', color: '#707070' }}>
                                                                    {serviceItem?.isCustomizableValue ?? 'None'}
                                                                </Typography>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                ))}
                                                {item?.additionalServices?.length > 2 && (
                                                    <Typography onClick={() => handleToggleShowAllCustomizations(item.id)} sx={{ color: '#808285', fontFamily: 'Poppins', fontSize: '12px', textTransform: 'capitalize', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                        {showAllCustomizations[item.id] ? (
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                Hide Details <KeyboardArrowUp sx={{ width: '100%', maxWidth: '15px', height: '100%', maxHeight: '15px', margin: '1px 0 1px 5px' }} />
                                                            </Box>
                                                        ) : (
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                More Details <KeyboardArrowDown sx={{ width: '100%', maxWidth: '15px', height: '100%', maxHeight: '15px', margin: '1px 0 1px 5px' }} />
                                                            </Box>
                                                        )}
                                                    </Typography>
                                                )}
                                                {item?.additionalServices.length === 0 && <Typography sx={{ margin: '0', fontFamily: 'Poppins', fontSize: '14px', fontWeight: 'normal', lineHeight: 2.57, textAlign: 'left', color: '#707070' }}>
                                                    None
                                                </Typography>}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2} sx={{ display: 'flex', justifyContent: 'space-evenly', mt: '30px', alignItems: 'center' }}>
                                        <Typography sx={{ borderRadius: '3px', backgroundColor: '#fff' }}>
                                            <RemoveCircle onClick={() => handleDecrementQuantity(item)} sx={{ color: '#909090', width: '30px', height: '30px', objectFit: 'contain' }} />
                                        </Typography>
                                        <TextField value={item?.quantity} inputProps={{ style: { textAlign: 'center', color: '#000', fontSize: '14px', height: '15px', width: '100%', borderRadius: '5px', margin: '0.5px 10px 0', maxWidth: '16px' } }} variant='outlined' />
                                        <Typography sx={{ borderRadius: '3px', backgroundColor: '#fff' }}>
                                            <AddCircle onClick={() => handleIncrementQuantity(item)} sx={{ color: '#02b3ad', width: '30px', height: '30px', objectFit: 'contain' }} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={6} lg={1} xl={1} sx={{ margin: '36px 3px' }}>
                                        <Typography sx={{ fontFamily: 'Poppins', fontFamily: '16px', fontWeight: 500, textAlign: 'left', color: '#000' }}>
                                            {CurrencyFormatter((item?.quantity * item?.price) + (item?.quantity * item?.customizationAmount))}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={6} lg={1} xl={1} sx={{ textAlign: 'right' }}>
                                        <Delete onClick={() => handleRemoveItem(item)} sx={{ color: '#ef0b0b', marginTop: '35px', width: '30px', height: "30px" }} />
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid container item xs={12} sx={{ backgroundColor: '#fff', justifyContent: 'end', }}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4} sx={{ height: '100%', margin: { lg: '0% 20%' }, padding: '30px 31px 30px 32px', borderRadius: '22px', boxShadow: '3px 0 20px 0 rgba(145, 158, 171, 0.12)', }}>
                                    <Box>
                                        <Box sx={{ display: 'flex', margin: '4px 7px 3px 0', cursor: 'pointer' }} onClick={handleToggleTextField}>
                                            <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, textAlign: 'left', color: '#343434' }}>
                                                Have a coupon/promo code?
                                            </Typography>
                                            {showTextField ? (
                                                <KeyboardArrowUp sx={{ margin: '0 0 0 7px', width: '30px', color: '#707070' }} />
                                            ) : (
                                                <KeyboardArrowDown sx={{ margin: '0 0 0 7px', width: '30px', color: '#707070' }} />
                                            )}
                                        </Box>
                                        {showTextField && (
                                            <TextField
                                                name="Coupon"
                                                value=""
                                                onChange={() => { }}
                                                placeholder="Apply Coupon"
                                                variant="outlined"
                                                sx={{ textAlign: 'center', height: '48px', '& .MuiOutlinedInput-notchedOutline': { borderColor: '#89afad' }, mt: 2 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <button
                                                                style={{
                                                                    border: 'none',
                                                                    backgroundColor: '#02b3ad',
                                                                    height: '48px',
                                                                    margin: '0px',
                                                                    width: '100px',
                                                                    borderRadius: '4px',
                                                                    padding: '14px 10px 15px 18px',
                                                                }}
                                                            >
                                                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '14px', textAlign: 'left', color: '#fff' }}>Apply</Typography>
                                                            </button>
                                                        </InputAdornment>
                                                    ),
                                                    style: { height: '48px', padding: '0', maxWidth: '330px', '& .MuiOutlinedInput-notchedOutline': { borderColor: '#89afad' } },
                                                }}
                                            />
                                        )}
                                    </Box>
                                    <Divider flexItem sx={{ width: '100%', height: '1px', backgroundColor: '#e6eef4', margin: '44px 0px' }} />
                                    <Box sx={{ display: 'flex', marginTop: '10px', justifyContent: 'space-between' }}>
                                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', color: '#707070' }}>Subtotal:</Typography>
                                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, textAlign: 'right', color: '#000' }}>{CurrencyFormatter(totalPrice)}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', marginTop: '10px', justifyContent: 'space-between' }}>
                                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', color: '#707070' }}>Customization:</Typography>
                                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, textAlign: 'right', color: '#000' }}>{CurrencyFormatter(customizationAmount)}</Typography>
                                    </Box>
                                    {totalPackagePrice > 0 && <Box sx={{ display: 'flex', marginTop: '10px', justifyContent: 'space-between' }}>
                                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', color: '#707070' }}>Packaging Fee:</Typography>
                                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, textAlign: 'right', color: '#000' }}>{CurrencyFormatter(totalPackagePrice)}</Typography>
                                    </Box>}
                                    <Box sx={{ display: 'flex', marginTop: '10px', justifyContent: 'space-between' }}>
                                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', color: '#707070' }}>Total (tax incl.):</Typography>
                                        <Typography sx={{ fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, textAlign: 'right', color: '#02b3ad' }}>{CurrencyFormatter(totalPrice + customizationAmount + totalPackagePrice ?? 0)}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: 'end', justifyContent: 'center', mr: { xs: '5%', sm: '5%', md: '10%', lg: '20%', xl: '20%' } }}>
                                {/* <Button variant='outlined' sx={{ width: '100%', height: '100%', maxWidth: '131px', maxHheight: '50px', margin: '0 10px 0 0', padding: '15px 16.9px 15px 16px', objectFit: 'contain', borderRadius: '5px', border: 'solid 1px #707070', '&:hover': { border: 'solid 1px #707070' } }}>
                                    <Save sx={{ color: '0 3.7px 0 3.7px', color: '#707070' }} />
                                    <Typography sx={{ textWrap: 'nowrap', margin: ' 0 0 0 10.1px', fontFamily: 'Poppins', fontSize: '14px', textTransform: 'capitalize', fontWeight: 500, textAlign: 'center', color: '#707070' }}>Save Cart</Typography>
                                </Button> */}
                                <Button onClick={() => { navigate('/checkout') }} variant='contained' sx={{ width: '131px', height: '50px', margin: '10px 0 0 0', padding: '15px 6.9px 15px 16px', objectFit: 'contain', borderRadius: '5px', backgroundColor: '#02b3ad', '&:hover': { backgroundColor: '#02b3ad' } }}>
                                    <ShoppingCart sx={{ color: '0 3.7px 0 3.7px', color: '#fff' }} />
                                    <Typography sx={{ textWrap: 'nowrap', margin: ' 0 0 0 10.1px', fontFamily: 'Poppins', fontSize: '14px', textTransform: 'capitalize', fontWeight: 500, textAlign: 'center', color: '#fff', border: 'none' }}>Checkout</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
    );
}

export default Cart;