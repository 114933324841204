import { Box, Grid, Typography } from '@mui/material'
import "./index.css";
import Header from './header';
import Footer from './footer';
const Faqs = () => {
    return (
        <Grid sx={{ minHeight: '100vh' }}>
            <Box className='container' sx={{ padding: { xs: '20px', sm: '40px' } }}>
                <Typography variant="h4" sx={{ textAlign: 'left', mb: '2%', color: "#343434", fontWeight: "500", fontSize: "25px" }}> FAQs </Typography>
                <Box>
                    <Box>
                        <Typography variant="h4" sx={{ textAlign: 'left', mb: '10px', color: "#010202", fontWeight: "500", fontSize: "14px",fontFamily:"Poppins" }}> What sizes do you offer?</Typography>
                        <Typography variant="h4" sx={{ textAlign: 'left', color: "#707070", fontWeight: "500", fontSize: "14px",fontFamily:"Poppins" }}>  - We offer a wide range of sizes to accommodate all fans, from kids to adult sizes. Our size chart is available on each product page to help you find the perfect fit.</Typography>
                    </Box>
                    <Box sx={{ marginTop: "10px" }}>
                        <Typography variant="h4" sx={{ textAlign: 'left', mb: '10px', color: "#010202", fontWeight: "500", fontSize: "14px",fontFamily:"Poppins" }}> Do you offer customization options? </Typography>
                        <Typography variant="h4" sx={{ textAlign: 'left', color: "#707070", fontWeight: "500", fontSize: "14px",fontFamily:"Poppins" }}>
                            - Yes, we provide customization services for many of our jerseys. Whether you want to add your favorite player's name and number or personalize it with your own name, we've got you covered. We also do a variety of badges among other options.
                        </Typography>
                    </Box>
                    <Box sx={{ marginTop: "10px" }}>
                        <Typography variant="h4" sx={{ textAlign: 'left', mb: '10px', color: "#010202", fontWeight: "500", fontSize: "14px",fontFamily:"Poppins" }}>How do I know if the jersey will fit me?</Typography>
                        <Typography variant="h4" sx={{ textAlign: 'left', color: "#707070", fontWeight: "500", fontSize: "14px",fontFamily:"Poppins" }}>
                            - We understand that sizing can be tricky when shopping online. That's why we provide detailed sizing charts for each product to help you choose the right size. If you're still unsure, feel free to reach out to our customer service team for assistance.
                        </Typography>
                    </Box>
                    <Box sx={{ marginTop: "10px" }}>
                        <Typography variant="h4" sx={{ textAlign: 'left', mb: '10px', color: "#010202", fontWeight: "500", fontSize: "14px",fontFamily:"Poppins" }}>Do you ship internationally?</Typography>
                        <Typography variant="h4" sx={{ textAlign: 'left', color: "#707070", fontWeight: "500", fontSize: "14px",fontFamily:"Poppins" }}>
                            - Yes, we ship our products to customers all over with a strong presence regionally in East Africa. International shipping rates and delivery times may vary, so be sure to check with customer care over shipping out of Kenya.
                        </Typography>
                    </Box>
                    <Box sx={{ marginTop: "10px" }}>
                        <Typography variant="h4" sx={{ textAlign: 'left', mb: '10px', color: "#010202", fontWeight: "500", fontSize: "14px",fontFamily:"Poppins" }}>What payment methods do you accept?</Typography>
                        <Typography variant="h4" sx={{ textAlign: 'left', color: "#707070", fontWeight: "500", fontSize: "14px",fontFamily:"Poppins" }}>
                            - We accept a variety of payment methods to make your shopping experience convenient. These include M-Pesa, Debit/Credit cards (Visa, Mastercard, American Express), PayPal, and other secure payment options. You can select your preferred payment method at checkout.
                        </Typography>
                    </Box>
                    <Box sx={{ marginTop: "10px" }}>
                        <Typography variant="h4" sx={{ textAlign: 'left', mb: '10px', color: "#010202", fontWeight: "500", fontSize: "14px",fontFamily:"Poppins" }}>Is your website secure for online payments?</Typography>
                        <Typography variant="h4" sx={{ textAlign: 'left', color: "#707070", fontWeight: "500", fontSize: "14px",fontFamily:"Poppins" }}>
                            - Yes, our website is equipped with industry-standard encryption technology to ensure that your payment information is secure and protected. You can shop with confidence knowing that your personal and financial data are safe with us.
                        </Typography>
                    </Box>
                    <Box sx={{ marginTop: "10px" }}>
                        <Typography variant="h4" sx={{ textAlign: 'left', mb: '10px', color: "#010202", fontWeight: "500", fontSize: "14px",fontFamily:"Poppins" }}>Can I cancel or modify my order after payment?</Typography>
                        <Typography variant="h4" sx={{ textAlign: 'left', color: "#707070", fontWeight: "500", fontSize: "14px",fontFamily:"Poppins" }}>
                            - Once an order has been placed and payment has been processed, it enters our system for fulfillment. If you need to cancel or modify your order, please contact our customer support team immediately. While we'll do our best to accommodate your request, please note that orders are processed quickly, and we cannot guarantee changes after payment.
                        </Typography>
                    </Box>
                    <Box sx={{ marginTop: "10px" }}>
                        <Typography variant="h4" sx={{ textAlign: 'left', mb: '10px', color: "#010202", fontWeight: "500", fontSize: "14px",fontFamily:"Poppins" }}>Can I use multiple payment methods for a single order?</Typography>
                        <Typography variant="h4" sx={{ textAlign: 'left', color: "#707070", fontWeight: "500", fontSize: "14px",fontFamily:"Poppins" }}>
                            - Unfortunately, we currently only support one payment method per order. If you have specific requirements or encounter any issues with payment, please reach out to our customer support team, and we'll do our best to assist you.
                        </Typography>
                    </Box>
                    <Box sx={{ marginTop: "10px" }}>
                        <Typography variant="h4" sx={{ textAlign: 'left', mb: '10px', color: "#010202", fontWeight: "600", fontSize: "16px",fontFamily:"Poppins" }}> **DISCLAIMER**</Typography>
                        <Typography variant="h4" sx={{ textAlign: 'left', color: "#707070", fontWeight: "500", fontSize: "14px",fontFamily:"Poppins" }}>
                            - Images provided while viewing products may not be 1:1 representation of the kit type edition. EastWest fashion strives to provide accurate representation when purchasing jersey. Hence incase on any questions relating to fan or player editions kindly reach out to us.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
}

export default Faqs;